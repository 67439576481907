import React, { useState } from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import UploadFileIcon from '@mui/icons-material/UploadFile'
interface uploadProps {
  text: string
  dragText: string
  onFileDrop: (event: any) => void
  disabled?: boolean
  error?: any
}
function DropUpload({
  text,
  dragText,
  onFileDrop,
  disabled = false,
  error,
}: uploadProps) {
  const [isDragover, setIsDragover] = useState<boolean>(false)

  const handleOnChange = (event: any) => {
    setIsDragover(false)
    onFileDrop(event)
  }
  return (
    <div
      className={`w-full h-[10vw] lg:h-[5vw] text-subText rounded-md border ${
        disabled ? ' border-[black] opacity-30' : 'border-primaryBg'
      } relative hover:bg-foreground grid place-items-center duration-150 ${error ? 'border-red-500' : ''}`}
    >
      <input
        type="file"
        multiple
        className={`${
          disabled ? '' : 'hover:cursor-pointer'
        } opacity-0 absolute inset-0 w-full h-full`}
        accept="image/*"
        onDragEnter={() => setIsDragover(true)}
        onDragLeave={() => setIsDragover(false)}
        onDragEnd={() => setIsDragover(false)}
        onChange={handleOnChange}
        disabled={disabled}
      />
      <div
        className={`flex gap-2 pointer-events-none ${
          disabled ? 'text-black opacity-30' : 'text-primaryBg'
        } ${error ? 'text-red-500' : ''}`}
      >
        {isDragover ? <UploadFileIcon /> : <AddCircleOutlineIcon />}
        <p>{isDragover ? dragText : text}</p>
      </div>
    </div>
  )
}

export default DropUpload

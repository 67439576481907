import {
  Grid,
  Theme,
  useMediaQuery,
  useTheme,
  IconButton,
  Popover,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Tooltip,
} from '@mui/material'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import BrowseGalleryIcon from '@mui/icons-material/BrowseGallery'
import PhoneIcon from '@mui/icons-material/Phone'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import TsekmoButton from '../../../../common/TsekmoButton'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createCleanLog } from '../../../../../store/app/listing/actions'
import { ApplicationState } from '../../../../../store'
import ImageCarouselList from '../../../../../theme/components/image-list/ImageCarousel'
import { makeStyles } from '@mui/styles'
import UIConstants from '../../../../../theme/constants/ui-constants'
import { ExpandIcon } from '../../../../../theme/components/icons/ExpandIcon'
import { StarIcon } from '../../../../../theme/components/icons/StarIcon'
import { ListingModel } from '../../../../../store/app/listing/types'
import QRCodePrint from '../QRCodePrint'
import QRCode from 'react-qr-code'
import { useNavigate } from 'react-router-dom'
import ListItemActions from './ListItemActions'
import { Share } from '@mui/icons-material'

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share'
import moment from 'moment'
import { useGetUserType } from '../../../../composables'
import { isJson } from '../../../../../app/utils/common-utils'

export interface ListingItemProps {
  listing: ListingModel
  toggleActiveStatus: (e: any, listing_id: number, is_active: boolean) => void
  onDeleteListing: (e: any, listing_id: number, post_id: number) => void
  onUpdateListing: (e: any, listing: ListingModel) => void
}

interface LogItem {
  id: number
  disabled: boolean
  text: string
  checked: boolean
  broken?: boolean
  empty?: boolean
  comment: string
  logTime: Date | null
  category: string
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2.5),
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-between',
    height: 'fit-content',
    borderRadius: '10px',
    boxShadow: UIConstants.cardBoxshadow,
  },
  header: {
    color: UIConstants.primaryBgColor,
    '& .MuiCardHeader-content': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .MuiCardHeader-title': {
      fontSize: '1.25rem',
      fontWeight: 'bold',
    },
    '& .MuiCardHeader-subheader': {
      fontSize: '1rem',
    },
  },
  reviews: {
    fontSize: '.75rem',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
    },
    color: UIConstants.infoColor,
    '& .MuiSvgIcon-root': {
      width: '16px',
      height: '16px',
    },
  },
  centeredContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10px',
  },
  propertycontainer: {
    margin: `0`,
  },
  properties: {
    opacity: 0.5,
    fontSize: '.7rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
    },
  },
  values: {
    color: UIConstants.subText,
    opacity: 1,
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      color: UIConstants.highlightColor,
    },
  },
}))

const ListingCard = ({
  listing,
  toggleActiveStatus,
  onDeleteListing,
  onUpdateListing,
}: ListingItemProps) => {
  const classes = useStyles()
  const { userData } = useSelector((state: ApplicationState) => state.user)
  const imageList = isJson(listing.uploads) && JSON.parse(listing.uploads)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [showQrCodePrint, setShowQrCodePrint] = useState<boolean>(false)
  const [showSecureQrCodePrint, setSecureShowQrCodePrint] =
    useState<boolean>(false)
  const navigate = useNavigate()
  const [openAddCleanLog, setOpenAddCleanLog] = useState<boolean>(false)
  const dispatch = useDispatch()
  const [isAnti, setIsAnti] = useState<boolean>(false)
  const [isBasic, setIsBasic] = useState<boolean>(false)
  const [allPackages, setAllPackages] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const listingData = useSelector(
    (state: ApplicationState) => state.listing.selectedListing
  )
  const [isBusinessOwner, setIsBusinessOwner] = useState<boolean>(false)
  const currentUserType = useGetUserType()

  useEffect(() => {
    if (currentUserType === 'business-owner' || currentUserType === 'admin') {
      setIsBusinessOwner(true)
    }
  }, [currentUserType])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    if (userData.id) {
      setIsAnti(isAntiVandal(userData))
      setIsBasic(isBasicSub(userData))
    }
  }, [userData])

  const isAntiVandal = (u: any) => {
    var returnBool = false
    var packages = u.package_subscribed
    if (packages) {
      const parsedPackages: any[] = JSON.parse(packages)
      const myPackage = 'anti-vandal'

      if (parsedPackages.includes(myPackage)) {
        return true
      }
    }

    return false
  }

  const isBasicSub = (u: any) => {
    var packages = u.package_subscribed
    if (packages) {
      const parsedPackages: any[] = JSON.parse(packages)
      const myPackage = 'basic'

      if (parsedPackages.includes(myPackage)) {
        return true
      }
    }

    return false
  }

  useEffect(() => {
    if (isAnti && isBasic) {
      setAllPackages(true)
    } else {
      setAllPackages(false)
    }
  }, [isAnti, isBasic])
  const [anchorE2, setAnchorE2] = useState(null)
  const handleShareClick = (event: any) => {
    event.stopPropagation()
    setAnchorE2(event.currentTarget)
  }

  const shareURL = (id: number) => {
    let url = 'https://beta.tsekmo.com/share-post?from=community&id=' + id
    return url
  }

  const handleShareClose = (event: any) => {
    event.stopPropagation()
    setAnchorE2(null)
  }

  const [showTooltip, setShowTooltip] = useState<boolean>(false)

  const handleOnClickListing = () => {
    navigate(
      `/${isBusinessOwner ? 'business-owner' : 'customer'}/listing/${
        listing.id
      }`,
      {
        state: { listing_id: listing.id },
      }
    )
  }
  return (
    <Tooltip
      title={!listing.is_active ? 'This listing is deactivated' : ''}
      followCursor
      disableFocusListener={true}
      open={showTooltip}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      onClick={(e) => e.stopPropagation()}
    >
      <Card
        className={`${
          classes.root
        } hover:shadow-xl pointer-events-auto z-0 duration-[0.4s] ${
          listing.is_active ? '' : 'grayscale-[100%]'
        }`}
      >
        <CardHeader
          className={classes.header}
          title={
            <div>
              <span
                onClick={handleOnClickListing}
                className="hover:cursor-pointer hover:underline"
              >
                {listing.title}
              </span>
            </div>
          }
          action={
            isBusinessOwner && (
              <ListItemActions
                {...{
                  listing,
                  toggleActiveStatus,
                  onDeleteListing,
                  onUpdateListing,
                  setShowTooltip,
                }}
              />
            )
          }
        />
        <CardMedia
          component="div"
          className="hover:cursor-pointer"
          onClick={handleOnClickListing}
        >
          <ImageCarouselList imageFileList={imageList} />
        </CardMedia>

        <CardContent
          style={{ padding: '8px' }}
          className="hover:cursor-pointer"
          onClick={handleOnClickListing}
        >
          <div className="px-2 text-sm flex flex-col gap-4 text-primaryBg mt-4">
            <div
              className={`grid ${
                moment(listing?.open_hour).format('h:mm A') ===
                moment(listing?.close_hour).format('h:mm A')
                  ? 'grid-cols-2'
                  : 'grid-cols-3'
              }`}
            >
              <div className="col-span-1 flex flex-col gap-2">
                <div>
                  <LocalOfferIcon />
                  <span className="ml-2">
                    {listing?.price === 0 ? 'Free' : `$${listing?.price}`}
                  </span>
                </div>
              </div>
              <div className="col-span-1">
                <div>
                  <BrowseGalleryIcon />
                  <span className="ml-2">
                    {moment(listing?.open_hour).format('h:mm A') ===
                    moment(listing?.close_hour).format('h:mm A')
                      ? '24 Hours'
                      : `${moment(listing?.open_hour).format('h:mm A')} to
                    ${moment(listing?.close_hour).format('h:mm A')}`}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex max-w-full line-clamp-1">
              <LocationOnIcon />
              <span className="ml-2 line-clamp-1">
                {isJson(listing.location)
                  ? JSON.parse(listing.location).label
                  : listing.location}
              </span>
            </div>
            <div>
              <PhoneIcon />
              <span className="ml-2">{listing?.mobile_number}</span>
            </div>
            <span>Special Discounts:</span>
          </div>
          {/* <Grid container spacing={1} className={classes.propertycontainer}>
          <Grid className={classes.properties} item xs={6}>
            Customers today:
            <span className={classes.values}>{listing.customers_today}</span>
          </Grid>
          <Grid className={classes.properties} item xs={6}>
            Total customers:
            <span className={classes.values}>{listing.total_customers}</span>
          </Grid>

          <Grid className={classes.properties} item xs={12} sm={6}>
            Earnings today:
            <Tooltip
              title={
                <Typography style={{ fontSize: '10px' }}>
                  If you have any plans to monetize your bathrooms, please
                  subscribe to{' '}
                  <a
                    target="_blank"
                    href={process.env.REACT_APP_BASICMONETIZE_URL}
                  >
                    Tsekmo Basic Monetization Package
                  </a>
                  .
                </Typography>
              }
              placement="top"
            >
              <span className={classes.values}>
                {listing.earnings_today ? listing.earnings_today : '0'}
              </span>
            </Tooltip>
          </Grid>
          <Grid className={classes.properties} item xs={12} sm={6}>
            Total earning:
            <Tooltip
              title={
                <Typography style={{ fontSize: '10px' }}>
                  If you have any plans to monetize your bathrooms, please
                  subscribe to{' '}
                  <a
                    target="_blank"
                    href={process.env.REACT_APP_BASICMONETIZE_URL}
                  >
                    Tsekmo Basic Monetization Package
                  </a>
                  .
                </Typography>
              }
              placement="top"
            >
              <span className={classes.values}>
                {listing.total_earning ? listing.total_earning : '0'}
              </span>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid>
          <div className="flex items-center justify-end mr-[20px]">
            <Tooltip
              title={
                <Typography style={{ fontSize: '10px' }}>
                  If you have any plans to monetize your bathrooms, please
                  subscribe to{' '}
                  <a
                    target="_blank"
                    href={process.env.REACT_APP_BASICMONETIZE_URL}
                  >
                    Tsekmo Basic Monetization Package
                  </a>
                  .
                </Typography>
              }
              placement="bottom"
            >
              <InfoIcon style={{ color: '#ff9800' }} />
            </Tooltip>
          </div>
        </Grid> */}
          <Grid item xs={12} className={`${classes.reviews} mt-4 ml-2`}>
            {parseInt(listing.review_count) === 0 ? (
              <StarIcon viewBox="0 0 16 16" style={{ width: 16, height: 16 }} />
            ) : (
              <StarIcon viewBox="0 0 16 16" />
            )}
            <span
              onClick={() =>
                navigate(`/business-owner/listing-reviews/${listing.id}`)
              }
              style={{ margin: '0 8px', cursor: 'pointer' }}
            >
              {listing.review_rating} ({listing.review_count} reviews)
            </span>
            <ExpandIcon viewBox="0 0 16 16" />
            <div>
              <IconButton onClick={handleShareClick}>
                <Share />
              </IconButton>
              <span onClick={handleShareClick}>Share</span>
              <Popover
                open={Boolean(anchorE2)}
                anchorEl={anchorE2}
                onClose={handleShareClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '8px',
                  }}
                >
                  <div style={{ cursor: 'pointer', padding: '4px' }}>
                    <FacebookShareButton
                      url={shareURL(Number(listing.post_id))}
                      quote={'Tsekmo Community'}
                      hashtag={'#Tsekmo Community'}
                    >
                      <FacebookIcon size={40} round={true} />
                    </FacebookShareButton>
                    <TwitterShareButton
                      url={shareURL(Number(listing.post_id))}
                      title={'Tsekmo Community'}
                      hashtags={['#Tsekmo Community']}
                    >
                      <TwitterIcon size={40} round={true} />
                    </TwitterShareButton>
                    <TelegramShareButton
                      url={shareURL(Number(listing.post_id))}
                      title={'Tsekmo Community'}
                    >
                      <TelegramIcon size={40} round={true} />
                    </TelegramShareButton>
                    <LinkedinShareButton
                      url={shareURL(Number(listing.post_id))}
                      title={'Tsekmo Community'}
                    >
                      <LinkedinIcon size={40} round={true} />
                    </LinkedinShareButton>
                    <RedditShareButton
                      url={shareURL(Number(listing.post_id))}
                      title={'Tsekmo Community'}
                    >
                      <RedditIcon size={40} round={true} />
                    </RedditShareButton>
                    <WhatsappShareButton
                      url={shareURL(Number(listing.post_id))}
                      title={'Tsekmo Community'}
                    >
                      <WhatsappIcon size={40} round={true} />
                    </WhatsappShareButton>
                    <EmailShareButton
                      url={shareURL(Number(listing.post_id))}
                      title={'Tsekmo Community'}
                    >
                      <EmailIcon size={40} round={true} />
                    </EmailShareButton>
                  </div>
                </div>
              </Popover>
            </div>
          </Grid>
        </CardContent>
        <div className="mt-4 w-full flex items-center justify-end">
          {!isBusinessOwner && (
            <div className="w-full flex flex-col flex-1">
              <TsekmoButton onClick={handleOnClickListing}>Use</TsekmoButton>
            </div>
          )}
          {isBusinessOwner && listing.qr_code && (
            <div className="flex flex-col items-center">
              <QRCode
                level={'M'}
                value={listing.qr_code + listing.id}
                size={isMobile ? 72 : 70}
                onClick={() => setShowQrCodePrint(true)}
              />
              <QRCodePrint
                open={showQrCodePrint}
                handleClose={() => setShowQrCodePrint(false)}
                qr_code={listing.qr_code + listing.id}
                secureCode={false}
              />

              <p style={{ fontSize: '10px', marginLeft: '-10px' }}>
                {'Bathroom QR code'}
              </p>
            </div>
          )}
        </div>
      </Card>
    </Tooltip>
  )
}
export default ListingCard

import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Paper, Typography, Box, Button, Select, MenuItem, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { Product } from '../../../../store/app/user/types';
import { getRetrieveSubscriptionData, updateSubscription} from '../../../../store/app/user/actions';

const PricingTable: React.FC = () => {
  const [billingCycle, setBillingCycle] = useState<'monthly' | 'yearly'>('yearly');
  const { userData, userRetrieveSubscriptionData, productList } = useSelector(
    (state: ApplicationState) => state.user
  )
  const dispatch = useDispatch()

  const handleBillingCycleChange = (event: SelectChangeEvent<'monthly' | 'yearly'>) => {
    setBillingCycle(event.target.value as 'monthly' | 'yearly');
  };
  
  useEffect(() => {
  }, [userRetrieveSubscriptionData, productList])

  if (!productList || !productList[billingCycle]) {
    return null;
  }

  const currentSubscriptionPlanId = userRetrieveSubscriptionData?.subscriptionData?.getSubscriptionData?.items?.data[0]?.plan?.id;
  const currentSubItemId = userRetrieveSubscriptionData?.subscriptionData?.getSubscriptionData?.items?.data[0]?.id;
  const products = productList[billingCycle];

  const handleUpgrade = (user_id: number, subitem_id: string, price_id: string) => {
    dispatch(updateSubscription(user_id, subitem_id, price_id))
    dispatch(getRetrieveSubscriptionData(user_id));
  }


  return (
    <Box sx={{ padding: 4 }}>
      <FormControl variant="outlined" sx={{ minWidth: 200, marginBottom: 4 }}>
        <InputLabel id="billing-cycle-label">Billing Cycle</InputLabel>
        <Select
          labelId="billing-cycle-label"
          id="billingCycle"
          value={billingCycle}
          onChange={handleBillingCycleChange}
          label="Billing Cycle"
        >
          <MenuItem value="monthly">Monthly</MenuItem>
          <MenuItem value="yearly">Yearly</MenuItem>
        </Select>
      </FormControl>
      <Grid container spacing={3}>
        {products.map((product: Product) => (
          <Grid item key={product.id} xs={12} sm={6} md={4}>
            <Paper
              elevation={3}
              sx={{
                padding: 3,
                backgroundColor: product.id === currentSubscriptionPlanId ? 'rgba(0, 255, 0, 0.1)' : 'inherit',
                border: product.id === currentSubscriptionPlanId ? '2px solid green' : 'none',
                height: '100%', // Set the height to 100% to ensure all cards have the same height
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Box sx={{ flex: 1 }}>
                <Typography variant="h6" gutterBottom>
                  {product.nickname}
                </Typography>
                <Typography variant="h4" gutterBottom>
                  ${(product.unit_amount / 100).toFixed(2)}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  {product.recurring.interval === 'year' ? 'per year' : 'per month'}
                </Typography>
              </Box>
              {product.id !== currentSubscriptionPlanId && (
                <Button size="small" variant="outlined" color="primary" sx={{ mt: 2 }}  onClick={() => handleUpgrade(userData.id!, currentSubItemId, product.id,)}>
                  {product.nickname.includes('Combo') ? 'Upgrade Subscription': 'Change Subscription'}
                </Button>
              )}
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PricingTable;
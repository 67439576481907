import React from 'react'
import { CustomAppBar } from "../../theme/components/app-layout/header/AppBar";
import TsekmoLandingToolBar from "../components/ToolBar";
import Footer from "../../theme/components/app-layout/footer/Footer";
import { Main } from "../../theme/components/app-layout/main/Main";
import { makeStyles } from '@mui/styles';
import { Theme ,  Grid } from '@mui/material';
import UIConstants from '../../theme/constants/ui-constants';
import { useEffect } from 'react';

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    background: UIConstants.rootBgColor,
    height: `100%`,
    width: '100%',
    paddingBottom: `calc(${UIConstants.primaryFooterHeight} + ${theme.spacing(
      0
    )})`
  },
  content: {
    margin:  '2%'
  },
  contentStrong: {
    margin:  '2%',
    fontWeight:'200'
  },
  optionsImages: {
    width: '350px',
    height: '244px',
    borderBottomRightRadius: '40px',
    borderBottomLeftRadius: '40px'
  },
  addExtraPadding: {
    paddingBottom: '70px'
  }
}));


const TermsAndCondition = (props: any) => {
  const classes = useStyle();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div className={classes.root}>
      {!props.isLogedIn && (
        <CustomAppBar>
          <TsekmoLandingToolBar />
        </CustomAppBar>
      )}

      {props.isLogedIn ? (
        <div style={{ minHeight: '50vh'}}>
          <h3>Guest Terms</h3>
          <h4 className={classes.content} >1. Our Mission</h4>
          <p className={classes.content} >
            Our mission is to create a world where you can use everyday amenities
            anywhere anytime. From bathroom to shower to home-made food, browse
            through millions of Listings to find the ones that fit the way you like it. Learn
            more about a Listing by reviewing the description and photos, the Host
            profile, and Guest reviews.
          </p>
          <h4 className={classes.content} >2. Searching and Booking on Tsekmo.</h4>
          <h4 className={classes.content} >2.1 Searching</h4>
          <p className={classes.content} >
            You can search for Host Services by using criteria like the
            type of Host Service, dates, and number of guests. Search results are
            based on their relevance to your search and other criteria like price,
            availability, Reviews, customer service and cancellation, popularity,
            previous trips and saved Listings,. Learn more about search results in
            our Help Center.
          </p>
          <h4 className={classes.content} >2.2 Booking</h4>
          <p className={classes.content} >
            When you book a Listing, you are agreeing to pay all charges
            for your booking including the Listing price, applicable fees, taxes, and any
            other items identified during checkout (collectively, “Total Price”). You are
            also agreeing that Host may charge (as defined in the Payment Terms) in
            order to collect Damage Claim amounts
          </p>

          <h4 className={classes.content} >2.3 Accommodation Reservations</h4>
          <p className={classes.content} >
              An Accommodation Reservation is a
              limited license to enter, occupy, and use the Accommodation. The Host
              retains the right to re-enter the Accommodation during your stay, to the
              extent: (i) it is reasonably necessary, (ii) permitted by your contract with the
              Host, and (iii) consistent with applicable law. If you stay past checkout, the
              Host has the right to make you leave in a manner consistent with applicable
              law, including by imposing reasonable overstay penalties. You may not
              exceed the maximum number of allowed Guests.
          </p>
          <h4 className={classes.content} >3. Cancellations, Travel Issues, Refunds and Booking Modifications.</h4>
          <h4 className={classes.content} >3.1 Cancellations, Travel Issues, and Refunds.</h4>
          <p className={classes.content} >
            In general, if as a Guest
            you cancel a Reservation, the amount refunded to you is determined by
            the cancellation policy that applies to that Reservation.
          </p>
          <h4 className={classes.content} >3.2 Booking Modifications</h4>
          <p className={classes.content} >
            Guests and Hosts are responsible for any
            booking modifications they agree to make via the Tsekmo Platform, and
            agree to pay any additional amounts, fees, or taxes associated with any
            Booking Modification.
          </p>
          <h4 className={classes.content} >4. Your Responsibilities and Assumption of Risk.</h4>
          <h4 className={classes.content} >4.1 Your Responsibilities</h4>
          <p className={classes.content} >
            You are responsible and liable for your own
            acts and omissions and are also responsible for the acts and omissions of
            anyone you invite to join or provide access to any Accommodation,
            Experience or other Host Service. For example, this means: (i) you are
            responsible for leaving an Accommodation (and related personal property)
            in the condition it was in when you arrived, (ii) you are responsible for
            paying all reasonable Damage Claim amounts necessary to cover damage
            that you, your guest(s), or your pet(s) cause to an Accommodation, and (iii)
            you must act with integrity, treat others with respect, and comply with
            applicable laws at all times. If you are booking for an additional guest who
            is a minor or if you bring a minor to a Host Service, you must be legally
            authorized to act on behalf of the minor and you are solely responsible for
            the supervision of that minor.
          </p>
          <h4 className={classes.content} >4.2 Your Assumption of Risk.</h4>
          <p className={classes.content} >
            You acknowledge that many activities carry
            inherent risks and agree that, to the maximum extent permitted by
            applicable law, you assume the entire risk arising out of your access to and
            use of the Tsekmo Platform and any Content, including your stay at any
            Accommodation, participation in any Experience, use of any other Host
            Service, or any other interaction you have with other Members whether in
            person or online. This means it is your responsibility to investigate a Host
            Service to determine whether it is suitable for you. For example, A
            Particular Host Service may carry risk of illness, bodily injury, disability, or
            death, and you freely and willfully assume those risks by choosing to
            participate in those Host Services.
          </p>
          <h3>Host Terms</h3>
          <h4 className={classes.content} >5. Hosting on Tsekmo.</h4>
          <h4 className={classes.content} >5.1 Host</h4>
          <p className={classes.content} >
            As a Host, Tsekmo offers you the right to use the Tsekmo
            Platform to share your Accommodation, Experience, or other Host Service
            with our vibrant community of Hosts and Guests - and earn money doing it.
            It’s easy to create a Listing and you are in control of how you host - set
            your price, availability, and rules for each Listing.
          </p>
          <h4 className={classes.content} >5.2 Contracting with Guests.</h4>
          <p className={classes.content} >
            When you accept a booking request, or
            receive a booking confirmation through the Tsekmo Platform, you are
            entering into a contract directly with the Guest, and are responsible for
            delivering your Host Service (amenities) under the terms and at the price
            specified in your Listing.
          </p>
          <h4 className={classes.content} >5.3 Independence of Hosts.</h4>
          <p className={classes.content} >
              Your relationship with Tsekmo is that of an
              independent individual or entity and not an employee, agent, joint venturer,
              or partner of Tsekmo, except for the Tsekmo Payments (which is done
              through Stripe Connect Payment), which acts as a payment collection
              agent as described in the Payments Terms. Tsekmo does not direct or
              control your Host Service, and you agree that you have complete discretion
              whether and when to provide Host Services, and at what price and on what
              terms to offer them.
          </p>
          <h4 className={classes.content} >6. Managing Your Listing</h4>
          <h4 className={classes.content} >6.1 Creating and Managing Your Listing</h4>
          <p className={classes.content} >
              The Tsekmo Platform provides
              tools that make it easy for you to set up and manage a Listing. Your Listing
              must include complete and accurate information about your Host Service,
              your price, other charges like cleaning fees, and any rules or requirements
              that apply to your Guests or Listing. You are responsible for keeping your
              Listing information (including calendar availability) and content (like photos)
              up-to-date and accurate at all times. We recommend that you obtain
              appropriate insurance for your Host Services and suggest you carefully
              review policy terms and conditions including coverage details and
              exclusions. You may only maintain one Listing per Accommodation.
          </p>
          <h4 className={classes.content} >6.2 Know Your Legal Obligations</h4>
          <p className={classes.content} >
            You are responsible for understanding
            and complying with any laws, rules, regulations, and contracts with third
            parties that apply to your Listing or Host Services. For example: Some
            landlords and leases, or homeowner and condominium association rules,
            restrict or prohibit subletting, short-term rentals and/or longer-term stays.
            Some cities have zoning or other laws that restrict the short-term rental of
            residential properties. Some jurisdictions require Hosts to register, get a
            permit, or obtain a license before providing certain Host Services (such as
            short-term rentals, longer-term stays, preparing food, serving alcohol for
            sale, guiding tours, or operating a vehicle). In some places, the Host
            Services you want to offer may be prohibited altogether. Some jurisdictions
            require that you register Guests who stay at your Accommodation. Some
            jurisdictions have laws that create tenancy rights for Guests and additional
            obligations for Hosts. For example, some places have landlord-tenant, rent
            control, and eviction laws that may apply to longer stays. Check your local
            rules to learn what rules apply to the Host Services you plan to offer.
            Information we provide regarding legal requirements is for informational
            purposes only and you should independently confirm your obligations. You
            are responsible for handling and using personal data of Guests and others
            in compliance with applicable privacy laws and these Terms. If you have
            questions about how local laws apply, you should always seek legal advice
          </p>
          <h4 className={classes.content} >6.3 Search Ranking.</h4>
          <p className={classes.content} >
            The ranking of Listings in search results on the
            Tsekmo Platform depends on a variety of factors, which may include any of
            these main parameters:
          </p>
          <ul className={classes.content} >
            <li>
              <p> 
                Guest search parameters (e.g. number of Guests, time and duration
                of the trip, price range)
              </p>
            </li>
            <li>
              <p> 
                Listing characteristics (e.g. price, calendar availability, number and
                quality of images, Reviews, type of Host Service, Host status, age of
                the Listing, average Guest popularity),
              </p>
            </li>
            <li>
              <p> 
                Guest booking experience (e.g. customer service and cancellation
                history of the Host, ease of booking),
              </p>
            </li>
            <li>
              <p> 
                Host requirements (e.g. minimum or maximum time/nights, booking
                cut-off time), and
              </p>
            </li>
            <li>
              <p> 
                Guest preferences (e.g. previous trips, saved Listings, location from
                where the Guest is searching).
              </p>
            </li>

          </ul>

          <h4 className={classes.content} >6.4 Your Responsibilities</h4>
          <p className={classes.content} >
            You are responsible and liable for your own
            acts and omissions and are also responsible for the acts and omissions of
            anyone you allow to participate in providing your Host Services. You are
            responsible for setting your price and establishing rules and requirements
            for your Listing. You must describe any and all fees and charges in your
            Listing description and may not collect any additional fees or charges
            outside the Tsekmo Platform.
          </p>

          <h4 className={classes.content} >6.5 Your Assumption of Risk.</h4>
          <p className={classes.content} >
            You acknowledge that hosting carries
            inherent risks and agree that you assume the entire risk arising out of your
            access to and use of the Tsekmo Platform, offering Host Services, or any
            interaction you have with other Members whether in person or online. You
            agree that you have had the opportunity to investigate the Tsekmo Platform
            and any laws, rules, regulations, or obligations that may be applicable to
            your Listings or Host Services and that you are not relying upon any
            statement of law made by Tsekmo.
          </p>

          <h4 className={classes.content} >7. Cancellations, Travel Issues, and Booking Modifications</h4>
          <h4 className={classes.content} >7.1 Cancellations and Travel Issues.</h4>
          <p className={classes.content} >
            In general, if a Guest cancels a
            Reservation, the amount paid to you is determined by the cancellation
            policy that applies to that Reservation. As a host, you should not cancel on
            a Guest without a valid reason. If you cancel on a Guest without such a
            valid reason, we may impose a cancellation fee and other consequences.
          </p>
          <h4 className={classes.content} >7.2 Booking Modifications.</h4>
          <p className={classes.content} >
            Hosts and Guests are responsible for any
            Booking Modifications they agree to make via the Tsekmo Platform or
            direct Tsekmo customer service to make on their behalf, and agree to pay
            any additional amounts, fees or taxes associated with a Booking
            Modification.
          </p>
          <h4 className={classes.content} >8. Taxes.</h4>
          <h4 className={classes.content} >8.1 Host Taxes.</h4>
          <p className={classes.content} >
            As a host, you are responsible for determining and
            fulfilling your obligations under applicable laws to report, collect, remit, or
            include in your price any applicable VAT or other indirect taxes, occupancy
            taxes, tourist, income, or other taxes ("Taxes").
          </p>
          <h3>General Terms</h3>
          <h4 className={classes.content} >9. Reviews.</h4>
          <p className={classes.content} >
            After each Host Service, Guests and Hosts will have an opportunity to
            review each other. Your Review must be accurate and may not contain any
            discriminatory, offensive, defamatory, or other language that violates our
            general Review Policy. Reviews are not verified by Tsekmo for accuracy and
            may be incorrect or misleading
          </p>

          <h4 className={classes.content} >10. Content</h4>
          <p className={classes.content} >
            Parts of the Tsekmo Platform enable you to provide feedback, text, photos,
            audio, video, information, and other content (collectively, “Content”). By
            providing Content, in whatever form and through whatever means, you
            grant Tsekmo a non-exclusive, worldwide, royalty-free, irrevocable,
            perpetual, sub-licensable and transferable license to copy, modify, prepare
            derivative works of, distribute, publish and otherwise exploit, that Content,
            without limitation. Tsekmo does not guarantee the accuracy or quality of
            translations and Members are responsible for confirming the accuracy of
            such translations.
          </p>

          <h4 className={classes.content} >11. Fees.</h4>
          <p className={classes.content} >
            Tsekmo may charge subscription and other fees (and applicable Taxes) to
            Hosts and Guests for the right to use the Tsekmo Platform.
          </p>
          <h4 className={classes.content} >12. Tsekmo Platform Rules</h4>
          <h4 className={classes.content} >12.1 Rules.</h4>
          <p className={classes.content} >
            You must follow these rules and must not help or induce others
            to break or circumvent these rules.
          </p>
          <ul className={classes.content} >
            <li>
              <p> Act with integrity and treat others with respect
              <ul className={classes.content} >
                <li>
                  <p> 
                     Do not lie, misrepresent something or someone, or pretend to
                    be someone else.
                  </p>
                </li>
                <li>
                  <p> 
                     Be polite and respectful when you communicate or interact with
                    others.
                  </p>
                </li>
              </ul>  
              </p>
            </li>
            <li>
              <p> 
                  Do not scrape, hack, reverse engineer, compromise or impair the
                  Tsekmo Platform
              </p>
            </li>
            <li>
              <p> 
                Only use the Tsekmo Platform as authorized by these Terms or
                another agreement with us
              </p>
            </li>
            <li>
              <p> 
                Host requirements (e.g. minimum or maximum time/nights, booking
                cut-off time), and
              </p>
            </li>
            <li>
              <p> 
                Guest preferences (e.g. previous trips, saved Listings, location from
                where the Guest is searching).
                <ul className={classes.content} >
                <li>
                  <p> 
                    Do not engage in any practices that are intended to manipulate
                    our search algorithm.
                  </p>
                </li>
                <li>
                  <p> 
                    Do not book Host Services unless you are actually using the
                    Host Services.
                  </p>
                </li>
                <li>
                  <p> 
                    Do not use, copy, display, mirror or frame the Tsekmo Platform,
                    any Content, any Tsekmo branding, or any page layout or
                    design without our consent.
                 </p>
                </li>
              </ul>  
              </p>
            </li>

            <li>
              <p> 
                Honor your legal obligations
                <ul className={classes.content} >
                <li>
                  <p> 
                    Understand and follow the laws that apply to you, including
                    privacy, data protection, and export laws
                  </p>
                </li>
                <li>
                  <p> 
                    Do not use the name, logo, branding, or trademarks of Tsekmo
                    or others without permission.
                  </p>
                </li>
                <li>
                  <p> 
                    Do not use or register any domain name, social media handle,
                    trade name, trademark, branding, logo, or other source
                    identifier that may be confused with Tsekmo branding.
                 </p>
                </li>
                <li>
                  <p> 
                    Do not offer Host Services that violate the laws or agreements
                    that apply to you
                 </p>
                </li>
                <li>
                  <p> 
                    Do not offer or solicit prostitution or participate in or facilitate
                    human trafficking.
                 </p>
                </li>
              </ul>  
              </p>
            </li>

          </ul>

          <h4 className={classes.content} >12.2 Reporting Violations</h4>
          <p className={classes.content} >
            If you believe that a Member, Listing or
            Content poses an imminent risk of harm to a person or property, you
            should immediately contact local authorities before contacting Tsekmo.
          </p>

          <h4 className={classes.content} >12.3 Copyright Notifications</h4>
          <p className={classes.content} >
            If you believe that Content on the Tsekmo
            Platform infringes copyrights, please notify us.
          </p>

          <h4 className={classes.content} >13. Termination, Suspension and other Measures</h4>
          <h4 className={classes.content} >13.1 Term.</h4>
          <p className={classes.content} >
              The agreement between you and Tsekmo reflected by these
              Terms is effective when you access the Tsekmo Platform (for example to
              create an account) and remains in effect until either you or we terminate
              the agreement in accordance with these Terms.
          </p>

          <h4 className={classes.content} >13.2 Termination</h4>
          <p className={classes.content} >
            You may terminate this agreement at any time by
            sending us an email or by deleting your account.
          </p>
          <h4 className={classes.content} >13.3 Member Violations.</h4>
          <p className={classes.content} >
            If (i) you breach these Terms, Tsekmo may, with
            or without prior notice:
          </p>
          <ul className={classes.content} >
            <li>
              <p>
                suspend or limit your access to or use of the Tsekmo Platform and/or
                your account;
              </p>
            </li>
            <li>
              <p>
                suspend or remove Listings, Reviews, or other Content;
              </p>
            </li>
            
            <li>
              <p>
                cancel pending or confirmed bookings; or
              </p>
            </li>

            <li>
              <p>
                suspend or revoke any special status associated with your account
              </p>
            </li>


          </ul>
          <h4 className={classes.content} >13.4 Effect of Termination </h4>
          <p className={classes.content} >
            If you are a Host and terminate your Tsekmo
            account, any confirmed booking(s) will be automatically cancelled and your
            Guests will receive a full refund.
          </p>

          <h4 className={classes.content} >14. Modification. </h4>
          <p className={classes.content} >
            Tsekmo may modify these Terms at any time. When we make material
            changes to these Terms, we will post the revised Terms on the Tsekmo
            Platform and update the “Last Updated” date at the top of these Terms. If
            you disagree with the revised Terms, you may terminate this agreement
            immediately as provided in these Terms. If you do not terminate your
            agreement before the date the revised Terms become effective, your
            continued access to or use of the Tsekmo Platform will constitute
            acceptance of the revised Terms.
          </p>

          <h4 className={classes.content} >15. Resolving Complaints and Damage Claims</h4>
          <p className={classes.content} >
            If a Host provides valid evidence that you, your guest(s), or your
            pet(s)damaged the complaining Member’s real or personal property, or real
            or personal property the complaining Member is responsible for, including
            consequential damages, ("Damage Claim"), the Host can notify the Guest
            as well as Tsekmo and/or seek compensation through …. (TBD)
          </p>

          <h4 className={classes.content} >16. Tsekmo’s Role.</h4>
          <p className={classes.content} >
              We offer you the right to use a platform that enables Members to publish,
              offer, search for, and book Host Services. While we work hard to ensure
              our Members have great experiences using Tsekmo, we do not and cannot
              control the conduct of Guests and Hosts. You acknowledge that Tsekmo
              has the right, but does not have any obligation, to monitor the use of the
              Tsekmo Platform and verify information provided by our Members. For
              example, we may review, disable access to, remove, or edit Content to: (i)
              operate, secure and improve the Tsekmo Platform (including for fraud
              prevention, risk assessment, investigation and customer support
              purposes); (ii) ensure Members ’compliance with these Terms; (iii) comply
              with applicable law or the order or requirement of a court, law enforcement
              or other administrative agency or governmental body; (iv) address Content
              that we determine is harmful or objectionable; (v) take actions set out in
              these Terms; and (vi) maintain and enforce any quality or eligibility criteria,
              including by removing Listings that don’t meet quality and eligibility criteria.
          </p>
          <h4 className={classes.content} >17. Member Accounts.</h4>
          <p className={classes.content} >
            You must register an account to access and use many features of the
            Tsekmo Platform. Registration is only permitted for legal entities,
            partnerships and natural persons who are 18 years or older. You represent
            and warrant that you are not a person or entity barred from using the
            Tsekmo Platform under the laws of the United States, your place of
            residence, or any other applicable jurisdiction. You must provide accurate,
            current, and complete information during registration and keep your
            account information up-to-date. You are responsible for maintaining the
            confidentiality and security of your account credentials and may not
            disclose your credentials to any third party. You are responsible and liable
            for activities conducted through your account and must immediately notify
            Tsekmo if you suspect that your credentials have been lost, stolen, or your
            account is otherwise compromised. If and as permitted by applicable law,
            we may, but have no obligation to (i) ask you to provide identification or
            other information, (ii) undertake checks designed to help verify your identity
            or background, (iii) screen you against third-party databases or other
            sources and request reports from service providers, and (iv) obtain reports
            from public records of criminal convictions or sex offender registrations or
            their local equivalents.
          </p>
          <h4 className={classes.content} >18. Disclaimer of Warranties.</h4>
          <p className={classes.content} >
            <strong>
               We provide the Tsekmo Platform and all Content “as is” without
                warranty of any kind and we disclaim all warranties, whether express
                or implied. For example: (i) we do not endorse or warrant the
                existence, conduct, performance, safety, quality, legality or suitability
                of any Guest, Host, Host Service, Listing or third party; (ii) we do not
                warrant the performance or non-interruption of the Tsekmo Platform;
                and (iii) we do not warrant that verification, identity or background
                checks conducted on Listings or Members (if any) will identify past
                misconduct or prevent future misconduct. Any references to a
                Member or Listing being "verified" (or similar language) indicate only
                that the Member or Listing or Tsekmo has completed a relevant
                verification or identification process and nothing else. The
                disclaimers in these Terms apply to the maximum extent permitted by
                law. If you have statutory rights or warranties we cannot disclaim, the
                duration of any such statutorily required rights or warranties, will be
                limited to the maximum extent permitted by law.
            </strong>
          </p>

          <h4 className={classes.content} >19. Limitations on Liability</h4>
          <p className={classes.content} >
            <strong>
            Neither Tsekmo (including its affiliates and personnel) nor any other
            party involved in creating, producing, or delivering the Tsekmo
            Platform or any Content will be liable for any incidental, special,
            exemplary or consequential damages, including lost profits, loss of
            data or loss of goodwill, service interruption, computer damage or
            system failure or the cost of substitute products or services, or for
            any damages for personal or bodily injury or emotional distress
            arising out of or in connection with (i) these Terms, (ii) the use of or
            inability to use the Tsekmo Platform or any Content, (iii) any
            communications, interactions or meetings you may have with
            someone you interact or meet with through, or as a result of, your use
            of the Tsekmo Platform, or (iv) publishing or booking of a Listing,
            including the provision or use of Host Services, whether based on
            warranty, contract, tort (including negligence), product liability or any
            other legal theory, and whether or not Tsekmo has been informed of
            the possibility of such damage, even if a limited remedy set out in
            these Terms is found to have failed of its essential purpose.
            These limitations of liability and damages are fundamental elements
            of the agreement between you and Tsekmo. If applicable law does not
            allow the limitations of liability set out in these Terms, the above
            limitations may not apply to you.
            </strong>
          </p>
          <h4 className={classes.content} >20. Indemnification.</h4>
          <p className={classes.content} >
            <strong>
              To the maximum extent permitted by applicable law, you agree to
              release, defend (at Tsekmo’s option), indemnify, and hold Tsekmo,
              other affiliates, and their personnel) harmless from and against any
              claims, liabilities, damages, losses, and expenses, including, without
              limitation, reasonable legal and accounting fees, arising out of or in
              any way connected with: (i) your breach of these Terms (including
              any supplemental or additional terms that apply to a product or
              feature), or (ii) your improper use of the Tsekmo Platform, (iii) your
              interaction with any Member, stay at an Accommodation, participation
              in an Experience or other Host Service, including without limitation
              any injuries, losses or damages (whether compensatory, direct,
              incidental, consequential or otherwise) of any kind arising in
              connection with or as a result of such interaction, stay, participation
              or use, (iv) your failure, or our failure at your direction, to accurately
              report, collect or remit Taxes, or (v) your breach of any laws,
              regulations or third party rights such as intellectual property or
              privacy rights.
            </strong>
          </p>

          <h4 className={classes.content} >21. United States Governing Law and Venue</h4>
          <p className={classes.content} >
            If you reside or have your place of establishment in the United States,
            these Terms will be interpreted in accordance with the laws of the State of
            California and the United States of America, without regard to conflict-oflaw
            provisions. Judicial proceedings (other than small claims actions) that
            are excluded from the arbitration agreement must be brought in state or
            federal court in San Francisco, California, unless we both agree to some
            other location. You and we both consent to venue and personal jurisdiction
            in San Francisco, California.
          </p>
          
          <h4 className={classes.content} >22. United States Dispute Resolution and Arbitration Agreement.</h4>
          <h4 className={classes.content} >22.1 Application</h4>
          <p className={classes.content} >
            This Arbitration Agreement only applies to you if your
            country of residence or establishment is the United States. If your country
            of residence or establishment is not the United States, and you
            nevertheless attempt to bring any legal claim against Tsekmo in the United
            States, this Arbitration Agreement will apply for determination of the
            threshold issue of whether this Section applies to you, and all other
            threshold determinations, including residency, arbitrability, venue, and
            applicable law.
          </p>

          <h4 className={classes.content} >22.2 Overview of Dispute Resolution Process</h4>
          <p className={classes.content} >
            Tsekmo is committed to
            participating in a consumer-friendly dispute resolution process. To that end,
            these Terms provide for a two-part process for individuals to whom this
            Section applies: (1) an informal negotiation directly with Tsekmo’s customer
            service team, and if necessary (2) a binding arbitration administered by the
            American Arbitration Association (“AAA”). You and Tsekmo each retain the
            right to seek relief in small claims court as an alternative to arbitration
          </p>

          <h4 className={classes.content} >22.3 Mandatory Pre-Arbitration Dispute Resolution and Notification</h4>
          <p className={classes.content} >
            Notification. At
            least 30 days prior to initiating an arbitration, you and Tsekmo each agree
            to notify the other party of the dispute in writing and attempt in good faith to
            negotiate an informal resolution. You must send your notice of dispute to
            Tsekmo by mailing it to Tsekmo’s agent for service:<strong> CSC Lawyers
            Incorporating Service, 2710 Gateway Oaks Drive, Suite 150N,
            Sacramento, California 95833.</strong> Tsekmo will send its notice of dispute to
            the email address associated with your Tsekmo account. A notice of
            dispute must include: the party’s name and preferred contact information, a
            brief description of the dispute, and the relief sought. If the parties are
            unable to resolve the dispute within the 30-day period, only then may either
            party commence arbitration by filing a written Demand for Arbitration
            (available at www.adr.org) with the AAA and providing a copy to the other
            party as specified in the AAA Rules (available at www.adr.org).
          </p>

          <h4 className={classes.content} >22.3 Mandatory Pre-Arbitration Dispute Resolution and Notification</h4>
          <p className={classes.content} >
            Notification. At
            least 30 days prior to initiating an arbitration, you and Tsekmo each agree
            to notify the other party of the dispute in writing and attempt in good faith to
            negotiate an informal resolution. You must send your notice of dispute to
            Tsekmo by mailing it to Tsekmo’s agent for service:<strong> CSC Lawyers
            Incorporating Service, 2710 Gateway Oaks Drive, Suite 150N,
            Sacramento, California 95833.</strong> Tsekmo will send its notice of dispute to
            the email address associated with your Tsekmo account. A notice of
            dispute must include: the party’s name and preferred contact information, a
            brief description of the dispute, and the relief sought. If the parties are
            unable to resolve the dispute within the 30-day period, only then may either
            party commence arbitration by filing a written Demand for Arbitration
            (available at www.adr.org) with the AAA and providing a copy to the other
            party as specified in the AAA Rules (available at www.adr.org).
          </p>

          <h4 className={classes.content} >22.4 Agreement to Arbitrate</h4>
          <p className={classes.content} >
            <strong>
              You and Tsekmo mutually agree that any
              dispute, claim or controversy arising out of or relating to these Terms
              or the applicability, breach, termination, validity, enforcement or
              interpretation thereof, or any use of the Tsekmo Platform, Host
              Services, or any Content (collectively, “Disputes”) will be settled by
              binding individual arbitration (the “Arbitration Agreement”). If there is a
              dispute about whether this Arbitration Agreement can be enforced or
              applies to our Dispute, you and Tsekmo agree that the arbitrator will
              decide that issue.
            </strong>
           
          </p>

          <h4 className={classes.content} >22.5 Exceptions to Arbitration Agreement.</h4>
          <p className={classes.content} >
            You and Tsekmo each agree
            that the following causes of action and/or claims for relief are exceptions to
            the Arbitration Agreement and will be brought in a judicial proceeding in a
            court of competent jurisdiction : (i) any claim or cause of action alleging
            actual or threatened infringement, misappropriation or violation of a party’s
            copyrights, trademarks, trade secrets, patents, or other intellectual property
            rights; (ii) any claim or cause of action seeking emergency injunctive relief
            based on exigent circumstances (e.g., imminent danger or commission of a
            crime, hacking, cyber-attack); or (iii) a request for the remedy of public
            injunctive relief; or (iv) any individual claim of sexual assault or sexual
            harassment arising from your use of the Tsekmo Platform or Host Services.
            You and Tsekmo agree that the remedy of public injunctive relief will
            proceed after the arbitration of all arbitrable claims, remedies, or causes of
            action, and will be stayed pending the outcome of the arbitration.
           
          </p>

          <h4 className={classes.content} >22.6 Arbitration Rules and Governing Law.</h4>
          <p className={classes.content} >
            This Arbitration Agreement
            evidences a transaction in interstate commerce and the Federal Arbitration
            Act governs all substantive and procedural interpretation and enforcement
            of this provision. The arbitration will be administered by AAA in accordance
            with the Consumer Arbitration Rules and/or other AAA arbitration rules
            determined to be applicable by the AAA (the “AAA Rules“) then in effect,
            except as modified here. The AAA Rules are available at www.adr.org. In
            order to initiate arbitration, a completed written demand (available
            at www.adr.org) must be filed with the AAA and provided to the other
            party, as specified in the AAA rules.
           
          </p>

          <h4 className={classes.content} >23. Google Terms.</h4>
          <p className={classes.content} >
            Some translations on the Tsekmo Platform are
            powered by Google. Google disclaims all warranties related to the
            translations, express or implied, including any warranties of accuracy,
            reliability, and any implied warranties for merchantability, fitness for a
            particular purpose and non-infringement. Some areas of the Tsekmo
            Platform implement Google Maps/Earth mapping services, including
            Google Maps API(s). Your use of Google Maps/Earth is subject to
            the Google Maps/Google Earth Additional Terms of Service .
           
          </p>

          <h4 className={classes.content} >24. Apple Terms.</h4>
          <h4 className={classes.content} >24. Stripe Connect Terms.</h4>
          <h4 className={classes.content} >25. Tsekmo Platform Content</h4>
          <p className={classes.content} >
            Content made available through the
            Tsekmo Platform may be protected by copyright, trademark, and/or other
            laws of the United States and other countries. You acknowledge that all
            intellectual property rights for that Content are the exclusive property of
            Tsekmo and/or its licensors and agree that you will not remove, alter or
            obscure any copyright, trademark, service mark or other proprietary rights
            notices. You may not use, copy, adapt, modify, prepare derivative works of,
            distribute, license, sell, transfer, publicly display, publicly perform, transmit,
            broadcast or otherwise exploit any Content accessed through the Tsekmo
            Platform except to the extent you are the legal owner of that Content or as
            expressly permitted in these Terms. Subject to your compliance with these
            Terms, Tsekmo grants you a limited, non-exclusive, non-sublicensable,
            revocable, non-transferable license to (i) download and use the Application
            on your personal device(s); and (ii) access and view the Content made
            available on or through the Tsekmo Platform and accessible to you, solely
            for your personal and non-commercial use.
          </p>
          <h4 className={classes.content} >26. Emails and SMS</h4>
          <p className={classes.content} >
            You will receive administrative communications from
            us using the email address or other contact information you provide for
            your Tsekmo account. Enrollment in additional email subscription programs
            will not affect the frequency of these administrative emails, though you
            should expect to receive additional emails specific to the program(s) to
            which you have subscribed. You may also receive promotional emails from
            us. No fee is charged for these promotional emails, but third-party data
            rates could apply. You can control whether you receive promotional emails
            using the notification preferences in your account settings. Please note that
            you will not be able to take advantage of certain promotions if you disable
            certain communication settings or do not have an Tsekmo account. In the
            U.S.
          </p>
          <h4 className={classes.content} >27. Contact Us.</h4>
          <p className={classes.content} >
            please email us.

          </p>



        </div>
      ) : (
        <Main>
          <div
            style={{
              minHeight: '50vh'
            }}
          >
                     <h3>Guest Terms</h3>
          <h4 className={classes.content} >1. Our Mission</h4>
          <p className={classes.content} >
            Our mission is to create a world where you can use everyday amenities
            anywhere anytime. From bathroom to shower to home-made food, browse
            through millions of Listings to find the ones that fit the way you like it. Learn
            more about a Listing by reviewing the description and photos, the Host
            profile, and Guest reviews.
          </p>
          <h4 className={classes.content} >2. Searching and Booking on Tsekmo.</h4>
          <h4 className={classes.content} >2.1 Searching</h4>
          <p className={classes.content} >
            You can search for Host Services by using criteria like the
            type of Host Service, dates, and number of guests. Search results are
            based on their relevance to your search and other criteria like price,
            availability, Reviews, customer service and cancellation, popularity,
            previous trips and saved Listings,. Learn more about search results in
            our Help Center.
          </p>
          <h4 className={classes.content} >2.2 Booking</h4>
          <p className={classes.content} >
            When you book a Listing, you are agreeing to pay all charges
            for your booking including the Listing price, applicable fees, taxes, and any
            other items identified during checkout (collectively, “Total Price”). You are
            also agreeing that Host may charge (as defined in the Payment Terms) in
            order to collect Damage Claim amounts
          </p>

          <h4 className={classes.content} >2.3 Accommodation Reservations</h4>
          <p className={classes.content} >
              An Accommodation Reservation is a
              limited license to enter, occupy, and use the Accommodation. The Host
              retains the right to re-enter the Accommodation during your stay, to the
              extent: (i) it is reasonably necessary, (ii) permitted by your contract with the
              Host, and (iii) consistent with applicable law. If you stay past checkout, the
              Host has the right to make you leave in a manner consistent with applicable
              law, including by imposing reasonable overstay penalties. You may not
              exceed the maximum number of allowed Guests.
          </p>
          <h4 className={classes.content} >3. Cancellations, Travel Issues, Refunds and Booking Modifications.</h4>
          <h4 className={classes.content} >3.1 Cancellations, Travel Issues, and Refunds.</h4>
          <p className={classes.content} >
            In general, if as a Guest
            you cancel a Reservation, the amount refunded to you is determined by
            the cancellation policy that applies to that Reservation.
          </p>
          <h4 className={classes.content} >3.2 Booking Modifications</h4>
          <p className={classes.content} >
            Guests and Hosts are responsible for any
            booking modifications they agree to make via the Tsekmo Platform, and
            agree to pay any additional amounts, fees, or taxes associated with any
            Booking Modification.
          </p>
          <h4 className={classes.content} >4. Your Responsibilities and Assumption of Risk.</h4>
          <h4 className={classes.content} >4.1 Your Responsibilities</h4>
          <p className={classes.content} >
            You are responsible and liable for your own
            acts and omissions and are also responsible for the acts and omissions of
            anyone you invite to join or provide access to any Accommodation,
            Experience or other Host Service. For example, this means: (i) you are
            responsible for leaving an Accommodation (and related personal property)
            in the condition it was in when you arrived, (ii) you are responsible for
            paying all reasonable Damage Claim amounts necessary to cover damage
            that you, your guest(s), or your pet(s) cause to an Accommodation, and (iii)
            you must act with integrity, treat others with respect, and comply with
            applicable laws at all times. If you are booking for an additional guest who
            is a minor or if you bring a minor to a Host Service, you must be legally
            authorized to act on behalf of the minor and you are solely responsible for
            the supervision of that minor.
          </p>
          <h4 className={classes.content} >4.2 Your Assumption of Risk.</h4>
          <p className={classes.content} >
            You acknowledge that many activities carry
            inherent risks and agree that, to the maximum extent permitted by
            applicable law, you assume the entire risk arising out of your access to and
            use of the Tsekmo Platform and any Content, including your stay at any
            Accommodation, participation in any Experience, use of any other Host
            Service, or any other interaction you have with other Members whether in
            person or online. This means it is your responsibility to investigate a Host
            Service to determine whether it is suitable for you. For example, A
            Particular Host Service may carry risk of illness, bodily injury, disability, or
            death, and you freely and willfully assume those risks by choosing to
            participate in those Host Services.
          </p>
          <h3>Host Terms</h3>
          <h4 className={classes.content} >5. Hosting on Tsekmo.</h4>
          <h4 className={classes.content} >5.1 Host</h4>
          <p className={classes.content} >
            As a Host, Tsekmo offers you the right to use the Tsekmo
            Platform to share your Accommodation, Experience, or other Host Service
            with our vibrant community of Hosts and Guests - and earn money doing it.
            It’s easy to create a Listing and you are in control of how you host - set
            your price, availability, and rules for each Listing.
          </p>
          <h4 className={classes.content} >5.2 Contracting with Guests.</h4>
          <p className={classes.content} >
            When you accept a booking request, or
            receive a booking confirmation through the Tsekmo Platform, you are
            entering into a contract directly with the Guest, and are responsible for
            delivering your Host Service (amenities) under the terms and at the price
            specified in your Listing.
          </p>
          <h4 className={classes.content} >5.3 Independence of Hosts.</h4>
          <p className={classes.content} >
              Your relationship with Tsekmo is that of an
              independent individual or entity and not an employee, agent, joint venturer,
              or partner of Tsekmo, except for the Tsekmo Payments (which is done
              through Stripe Connect Payment), which acts as a payment collection
              agent as described in the Payments Terms. Tsekmo does not direct or
              control your Host Service, and you agree that you have complete discretion
              whether and when to provide Host Services, and at what price and on what
              terms to offer them.
          </p>
          <h4 className={classes.content} >6. Managing Your Listing</h4>
          <h4 className={classes.content} >6.1 Creating and Managing Your Listing</h4>
          <p className={classes.content} >
              The Tsekmo Platform provides
              tools that make it easy for you to set up and manage a Listing. Your Listing
              must include complete and accurate information about your Host Service,
              your price, other charges like cleaning fees, and any rules or requirements
              that apply to your Guests or Listing. You are responsible for keeping your
              Listing information (including calendar availability) and content (like photos)
              up-to-date and accurate at all times. We recommend that you obtain
              appropriate insurance for your Host Services and suggest you carefully
              review policy terms and conditions including coverage details and
              exclusions. You may only maintain one Listing per Accommodation.
          </p>
          <h4 className={classes.content} >6.2 Know Your Legal Obligations</h4>
          <p className={classes.content} >
            You are responsible for understanding
            and complying with any laws, rules, regulations, and contracts with third
            parties that apply to your Listing or Host Services. For example: Some
            landlords and leases, or homeowner and condominium association rules,
            restrict or prohibit subletting, short-term rentals and/or longer-term stays.
            Some cities have zoning or other laws that restrict the short-term rental of
            residential properties. Some jurisdictions require Hosts to register, get a
            permit, or obtain a license before providing certain Host Services (such as
            short-term rentals, longer-term stays, preparing food, serving alcohol for
            sale, guiding tours, or operating a vehicle). In some places, the Host
            Services you want to offer may be prohibited altogether. Some jurisdictions
            require that you register Guests who stay at your Accommodation. Some
            jurisdictions have laws that create tenancy rights for Guests and additional
            obligations for Hosts. For example, some places have landlord-tenant, rent
            control, and eviction laws that may apply to longer stays. Check your local
            rules to learn what rules apply to the Host Services you plan to offer.
            Information we provide regarding legal requirements is for informational
            purposes only and you should independently confirm your obligations. You
            are responsible for handling and using personal data of Guests and others
            in compliance with applicable privacy laws and these Terms. If you have
            questions about how local laws apply, you should always seek legal advice
          </p>
          <h4 className={classes.content} >6.3 Search Ranking.</h4>
          <p className={classes.content} >
            The ranking of Listings in search results on the
            Tsekmo Platform depends on a variety of factors, which may include any of
            these main parameters:
          </p>
          <ul className={classes.content} >
            <li>
              <p> 
                Guest search parameters (e.g. number of Guests, time and duration
                of the trip, price range)
              </p>
            </li>
            <li>
              <p> 
                Listing characteristics (e.g. price, calendar availability, number and
                quality of images, Reviews, type of Host Service, Host status, age of
                the Listing, average Guest popularity),
              </p>
            </li>
            <li>
              <p> 
                Guest booking experience (e.g. customer service and cancellation
                history of the Host, ease of booking),
              </p>
            </li>
            <li>
              <p> 
                Host requirements (e.g. minimum or maximum time/nights, booking
                cut-off time), and
              </p>
            </li>
            <li>
              <p> 
                Guest preferences (e.g. previous trips, saved Listings, location from
                where the Guest is searching).
              </p>
            </li>

          </ul>

          <h4 className={classes.content} >6.4 Your Responsibilities</h4>
          <p className={classes.content} >
            You are responsible and liable for your own
            acts and omissions and are also responsible for the acts and omissions of
            anyone you allow to participate in providing your Host Services. You are
            responsible for setting your price and establishing rules and requirements
            for your Listing. You must describe any and all fees and charges in your
            Listing description and may not collect any additional fees or charges
            outside the Tsekmo Platform.
          </p>

          <h4 className={classes.content} >6.5 Your Assumption of Risk.</h4>
          <p className={classes.content} >
            You acknowledge that hosting carries
            inherent risks and agree that you assume the entire risk arising out of your
            access to and use of the Tsekmo Platform, offering Host Services, or any
            interaction you have with other Members whether in person or online. You
            agree that you have had the opportunity to investigate the Tsekmo Platform
            and any laws, rules, regulations, or obligations that may be applicable to
            your Listings or Host Services and that you are not relying upon any
            statement of law made by Tsekmo.
          </p>

          <h4 className={classes.content} >7. Cancellations, Travel Issues, and Booking Modifications</h4>
          <h4 className={classes.content} >7.1 Cancellations and Travel Issues.</h4>
          <p className={classes.content} >
            In general, if a Guest cancels a
            Reservation, the amount paid to you is determined by the cancellation
            policy that applies to that Reservation. As a host, you should not cancel on
            a Guest without a valid reason. If you cancel on a Guest without such a
            valid reason, we may impose a cancellation fee and other consequences.
          </p>
          <h4 className={classes.content} >7.2 Booking Modifications.</h4>
          <p className={classes.content} >
            Hosts and Guests are responsible for any
            Booking Modifications they agree to make via the Tsekmo Platform or
            direct Tsekmo customer service to make on their behalf, and agree to pay
            any additional amounts, fees or taxes associated with a Booking
            Modification.
          </p>
          <h4 className={classes.content} >8. Taxes.</h4>
          <h4 className={classes.content} >8.1 Host Taxes.</h4>
          <p className={classes.content} >
            As a host, you are responsible for determining and
            fulfilling your obligations under applicable laws to report, collect, remit, or
            include in your price any applicable VAT or other indirect taxes, occupancy
            taxes, tourist, income, or other taxes ("Taxes").
          </p>
          <h3>General Terms</h3>
          <h4 className={classes.content} >9. Reviews.</h4>
          <p className={classes.content} >
            After each Host Service, Guests and Hosts will have an opportunity to
            review each other. Your Review must be accurate and may not contain any
            discriminatory, offensive, defamatory, or other language that violates our
            general Review Policy. Reviews are not verified by Tsekmo for accuracy and
            may be incorrect or misleading
          </p>

          <h4 className={classes.content} >10. Content</h4>
          <p className={classes.content} >
            Parts of the Tsekmo Platform enable you to provide feedback, text, photos,
            audio, video, information, and other content (collectively, “Content”). By
            providing Content, in whatever form and through whatever means, you
            grant Tsekmo a non-exclusive, worldwide, royalty-free, irrevocable,
            perpetual, sub-licensable and transferable license to copy, modify, prepare
            derivative works of, distribute, publish and otherwise exploit, that Content,
            without limitation. Tsekmo does not guarantee the accuracy or quality of
            translations and Members are responsible for confirming the accuracy of
            such translations.
          </p>

          <h4 className={classes.content} >11. Fees.</h4>
          <p className={classes.content} >
            Tsekmo may charge subscription and other fees (and applicable Taxes) to
            Hosts and Guests for the right to use the Tsekmo Platform.
          </p>
          <h4 className={classes.content} >12. Tsekmo Platform Rules</h4>
          <h4 className={classes.content} >12.1 Rules.</h4>
          <p className={classes.content} >
            You must follow these rules and must not help or induce others
            to break or circumvent these rules.
          </p>
          <ul className={classes.content} >
            <li>
              <p> Act with integrity and treat others with respect
              <ul className={classes.content} >
                <li>
                  <p> 
                     Do not lie, misrepresent something or someone, or pretend to
                    be someone else.
                  </p>
                </li>
                <li>
                  <p> 
                     Be polite and respectful when you communicate or interact with
                    others.
                  </p>
                </li>
              </ul>  
              </p>
            </li>
            <li>
              <p> 
                  Do not scrape, hack, reverse engineer, compromise or impair the
                  Tsekmo Platform
              </p>
            </li>
            <li>
              <p> 
                Only use the Tsekmo Platform as authorized by these Terms or
                another agreement with us
              </p>
            </li>
            <li>
              <p> 
                Host requirements (e.g. minimum or maximum time/nights, booking
                cut-off time), and
              </p>
            </li>
            <li>
              <p> 
                Guest preferences (e.g. previous trips, saved Listings, location from
                where the Guest is searching).
                <ul className={classes.content} >
                <li>
                  <p> 
                    Do not engage in any practices that are intended to manipulate
                    our search algorithm.
                  </p>
                </li>
                <li>
                  <p> 
                    Do not book Host Services unless you are actually using the
                    Host Services.
                  </p>
                </li>
                <li>
                  <p> 
                    Do not use, copy, display, mirror or frame the Tsekmo Platform,
                    any Content, any Tsekmo branding, or any page layout or
                    design without our consent.
                 </p>
                </li>
              </ul>  
              </p>
            </li>

            <li>
              <p> 
                Honor your legal obligations
                <ul className={classes.content} >
                <li>
                  <p> 
                    Understand and follow the laws that apply to you, including
                    privacy, data protection, and export laws
                  </p>
                </li>
                <li>
                  <p> 
                    Do not use the name, logo, branding, or trademarks of Tsekmo
                    or others without permission.
                  </p>
                </li>
                <li>
                  <p> 
                    Do not use or register any domain name, social media handle,
                    trade name, trademark, branding, logo, or other source
                    identifier that may be confused with Tsekmo branding.
                 </p>
                </li>
                <li>
                  <p> 
                    Do not offer Host Services that violate the laws or agreements
                    that apply to you
                 </p>
                </li>
                <li>
                  <p> 
                    Do not offer or solicit prostitution or participate in or facilitate
                    human trafficking.
                 </p>
                </li>
              </ul>  
              </p>
            </li>

          </ul>

          <h4 className={classes.content} >12.2 Reporting Violations</h4>
          <p className={classes.content} >
            If you believe that a Member, Listing or
            Content poses an imminent risk of harm to a person or property, you
            should immediately contact local authorities before contacting Tsekmo.
          </p>

          <h4 className={classes.content} >12.3 Copyright Notifications</h4>
          <p className={classes.content} >
            If you believe that Content on the Tsekmo
            Platform infringes copyrights, please notify us.
          </p>

          <h4 className={classes.content} >13. Termination, Suspension and other Measures</h4>
          <h4 className={classes.content} >13.1 Term.</h4>
          <p className={classes.content} >
              The agreement between you and Tsekmo reflected by these
              Terms is effective when you access the Tsekmo Platform (for example to
              create an account) and remains in effect until either you or we terminate
              the agreement in accordance with these Terms.
          </p>

          <h4 className={classes.content} >13.2 Termination</h4>
          <p className={classes.content} >
            You may terminate this agreement at any time by
            sending us an email or by deleting your account.
          </p>
          <h4 className={classes.content} >13.3 Member Violations.</h4>
          <p className={classes.content} >
            If (i) you breach these Terms, Tsekmo may, with
            or without prior notice:
          </p>
          <ul className={classes.content} >
            <li>
              <p>
                suspend or limit your access to or use of the Tsekmo Platform and/or
                your account;
              </p>
            </li>
            <li>
              <p>
                suspend or remove Listings, Reviews, or other Content;
              </p>
            </li>
            
            <li>
              <p>
                cancel pending or confirmed bookings; or
              </p>
            </li>

            <li>
              <p>
                suspend or revoke any special status associated with your account
              </p>
            </li>


          </ul>
          <h4 className={classes.content} >13.4 Effect of Termination </h4>
          <p className={classes.content} >
            If you are a Host and terminate your Tsekmo
            account, any confirmed booking(s) will be automatically cancelled and your
            Guests will receive a full refund.
          </p>

          <h4 className={classes.content} >14. Modification. </h4>
          <p className={classes.content} >
            Tsekmo may modify these Terms at any time. When we make material
            changes to these Terms, we will post the revised Terms on the Tsekmo
            Platform and update the “Last Updated” date at the top of these Terms. If
            you disagree with the revised Terms, you may terminate this agreement
            immediately as provided in these Terms. If you do not terminate your
            agreement before the date the revised Terms become effective, your
            continued access to or use of the Tsekmo Platform will constitute
            acceptance of the revised Terms.
          </p>

          <h4 className={classes.content} >15. Resolving Complaints and Damage Claims</h4>
          <p className={classes.content} >
            If a Host provides valid evidence that you, your guest(s), or your
            pet(s)damaged the complaining Member’s real or personal property, or real
            or personal property the complaining Member is responsible for, including
            consequential damages, ("Damage Claim"), the Host can notify the Guest
            as well as Tsekmo and/or seek compensation through …. (TBD)
          </p>

          <h4 className={classes.content} >16. Tsekmo’s Role.</h4>
          <p className={classes.content} >
              We offer you the right to use a platform that enables Members to publish,
              offer, search for, and book Host Services. While we work hard to ensure
              our Members have great experiences using Tsekmo, we do not and cannot
              control the conduct of Guests and Hosts. You acknowledge that Tsekmo
              has the right, but does not have any obligation, to monitor the use of the
              Tsekmo Platform and verify information provided by our Members. For
              example, we may review, disable access to, remove, or edit Content to: (i)
              operate, secure and improve the Tsekmo Platform (including for fraud
              prevention, risk assessment, investigation and customer support
              purposes); (ii) ensure Members ’compliance with these Terms; (iii) comply
              with applicable law or the order or requirement of a court, law enforcement
              or other administrative agency or governmental body; (iv) address Content
              that we determine is harmful or objectionable; (v) take actions set out in
              these Terms; and (vi) maintain and enforce any quality or eligibility criteria,
              including by removing Listings that don’t meet quality and eligibility criteria.
          </p>
          <h4 className={classes.content} >17. Member Accounts.</h4>
          <p className={classes.content} >
            You must register an account to access and use many features of the
            Tsekmo Platform. Registration is only permitted for legal entities,
            partnerships and natural persons who are 18 years or older. You represent
            and warrant that you are not a person or entity barred from using the
            Tsekmo Platform under the laws of the United States, your place of
            residence, or any other applicable jurisdiction. You must provide accurate,
            current, and complete information during registration and keep your
            account information up-to-date. You are responsible for maintaining the
            confidentiality and security of your account credentials and may not
            disclose your credentials to any third party. You are responsible and liable
            for activities conducted through your account and must immediately notify
            Tsekmo if you suspect that your credentials have been lost, stolen, or your
            account is otherwise compromised. If and as permitted by applicable law,
            we may, but have no obligation to (i) ask you to provide identification or
            other information, (ii) undertake checks designed to help verify your identity
            or background, (iii) screen you against third-party databases or other
            sources and request reports from service providers, and (iv) obtain reports
            from public records of criminal convictions or sex offender registrations or
            their local equivalents.
          </p>
          <h4 className={classes.content} >18. Disclaimer of Warranties.</h4>
          <p className={classes.content} >
            <strong>
               We provide the Tsekmo Platform and all Content “as is” without
                warranty of any kind and we disclaim all warranties, whether express
                or implied. For example: (i) we do not endorse or warrant the
                existence, conduct, performance, safety, quality, legality or suitability
                of any Guest, Host, Host Service, Listing or third party; (ii) we do not
                warrant the performance or non-interruption of the Tsekmo Platform;
                and (iii) we do not warrant that verification, identity or background
                checks conducted on Listings or Members (if any) will identify past
                misconduct or prevent future misconduct. Any references to a
                Member or Listing being "verified" (or similar language) indicate only
                that the Member or Listing or Tsekmo has completed a relevant
                verification or identification process and nothing else. The
                disclaimers in these Terms apply to the maximum extent permitted by
                law. If you have statutory rights or warranties we cannot disclaim, the
                duration of any such statutorily required rights or warranties, will be
                limited to the maximum extent permitted by law.
            </strong>
          </p>

          <h4 className={classes.content} >19. Limitations on Liability</h4>
          <p className={classes.content} >
            <strong>
            Neither Tsekmo (including its affiliates and personnel) nor any other
            party involved in creating, producing, or delivering the Tsekmo
            Platform or any Content will be liable for any incidental, special,
            exemplary or consequential damages, including lost profits, loss of
            data or loss of goodwill, service interruption, computer damage or
            system failure or the cost of substitute products or services, or for
            any damages for personal or bodily injury or emotional distress
            arising out of or in connection with (i) these Terms, (ii) the use of or
            inability to use the Tsekmo Platform or any Content, (iii) any
            communications, interactions or meetings you may have with
            someone you interact or meet with through, or as a result of, your use
            of the Tsekmo Platform, or (iv) publishing or booking of a Listing,
            including the provision or use of Host Services, whether based on
            warranty, contract, tort (including negligence), product liability or any
            other legal theory, and whether or not Tsekmo has been informed of
            the possibility of such damage, even if a limited remedy set out in
            these Terms is found to have failed of its essential purpose.
            These limitations of liability and damages are fundamental elements
            of the agreement between you and Tsekmo. If applicable law does not
            allow the limitations of liability set out in these Terms, the above
            limitations may not apply to you.
            </strong>
          </p>
          <h4 className={classes.content} >20. Indemnification.</h4>
          <p className={classes.content} >
            <strong>
              To the maximum extent permitted by applicable law, you agree to
              release, defend (at Tsekmo’s option), indemnify, and hold Tsekmo,
              other affiliates, and their personnel) harmless from and against any
              claims, liabilities, damages, losses, and expenses, including, without
              limitation, reasonable legal and accounting fees, arising out of or in
              any way connected with: (i) your breach of these Terms (including
              any supplemental or additional terms that apply to a product or
              feature), or (ii) your improper use of the Tsekmo Platform, (iii) your
              interaction with any Member, stay at an Accommodation, participation
              in an Experience or other Host Service, including without limitation
              any injuries, losses or damages (whether compensatory, direct,
              incidental, consequential or otherwise) of any kind arising in
              connection with or as a result of such interaction, stay, participation
              or use, (iv) your failure, or our failure at your direction, to accurately
              report, collect or remit Taxes, or (v) your breach of any laws,
              regulations or third party rights such as intellectual property or
              privacy rights.
            </strong>
          </p>

          <h4 className={classes.content} >21. United States Governing Law and Venue</h4>
          <p className={classes.content} >
            If you reside or have your place of establishment in the United States,
            these Terms will be interpreted in accordance with the laws of the State of
            California and the United States of America, without regard to conflict-oflaw
            provisions. Judicial proceedings (other than small claims actions) that
            are excluded from the arbitration agreement must be brought in state or
            federal court in San Francisco, California, unless we both agree to some
            other location. You and we both consent to venue and personal jurisdiction
            in San Francisco, California.
          </p>
          
          <h4 className={classes.content} >22. United States Dispute Resolution and Arbitration Agreement.</h4>
          <h4 className={classes.content} >22.1 Application</h4>
          <p className={classes.content} >
            This Arbitration Agreement only applies to you if your
            country of residence or establishment is the United States. If your country
            of residence or establishment is not the United States, and you
            nevertheless attempt to bring any legal claim against Tsekmo in the United
            States, this Arbitration Agreement will apply for determination of the
            threshold issue of whether this Section applies to you, and all other
            threshold determinations, including residency, arbitrability, venue, and
            applicable law.
          </p>

          <h4 className={classes.content} >22.2 Overview of Dispute Resolution Process</h4>
          <p className={classes.content} >
            Tsekmo is committed to
            participating in a consumer-friendly dispute resolution process. To that end,
            these Terms provide for a two-part process for individuals to whom this
            Section applies: (1) an informal negotiation directly with Tsekmo’s customer
            service team, and if necessary (2) a binding arbitration administered by the
            American Arbitration Association (“AAA”). You and Tsekmo each retain the
            right to seek relief in small claims court as an alternative to arbitration
          </p>

          <h4 className={classes.content} >22.3 Mandatory Pre-Arbitration Dispute Resolution and Notification</h4>
          <p className={classes.content} >
            Notification. At
            least 30 days prior to initiating an arbitration, you and Tsekmo each agree
            to notify the other party of the dispute in writing and attempt in good faith to
            negotiate an informal resolution. You must send your notice of dispute to
            Tsekmo by mailing it to Tsekmo’s agent for service:<strong> CSC Lawyers
            Incorporating Service, 2710 Gateway Oaks Drive, Suite 150N,
            Sacramento, California 95833.</strong> Tsekmo will send its notice of dispute to
            the email address associated with your Tsekmo account. A notice of
            dispute must include: the party’s name and preferred contact information, a
            brief description of the dispute, and the relief sought. If the parties are
            unable to resolve the dispute within the 30-day period, only then may either
            party commence arbitration by filing a written Demand for Arbitration
            (available at www.adr.org) with the AAA and providing a copy to the other
            party as specified in the AAA Rules (available at www.adr.org).
          </p>

          <h4 className={classes.content} >22.3 Mandatory Pre-Arbitration Dispute Resolution and Notification</h4>
          <p className={classes.content} >
            Notification. At
            least 30 days prior to initiating an arbitration, you and Tsekmo each agree
            to notify the other party of the dispute in writing and attempt in good faith to
            negotiate an informal resolution. You must send your notice of dispute to
            Tsekmo by mailing it to Tsekmo’s agent for service:<strong> CSC Lawyers
            Incorporating Service, 2710 Gateway Oaks Drive, Suite 150N,
            Sacramento, California 95833.</strong> Tsekmo will send its notice of dispute to
            the email address associated with your Tsekmo account. A notice of
            dispute must include: the party’s name and preferred contact information, a
            brief description of the dispute, and the relief sought. If the parties are
            unable to resolve the dispute within the 30-day period, only then may either
            party commence arbitration by filing a written Demand for Arbitration
            (available at www.adr.org) with the AAA and providing a copy to the other
            party as specified in the AAA Rules (available atwww.adr.org).
          </p>

          <h4 className={classes.content} >22.4 Agreement to Arbitrate</h4>
          <p className={classes.content} >
            <strong>
              You and Tsekmo mutually agree that any
              dispute, claim or controversy arising out of or relating to these Terms
              or the applicability, breach, termination, validity, enforcement or
              interpretation thereof, or any use of the Tsekmo Platform, Host
              Services, or any Content (collectively, “Disputes”) will be settled by
              binding individual arbitration (the “Arbitration Agreement”). If there is a
              dispute about whether this Arbitration Agreement can be enforced or
              applies to our Dispute, you and Tsekmo agree that the arbitrator will
              decide that issue.
            </strong>
           
          </p>

          <h4 className={classes.content} >22.5 Exceptions to Arbitration Agreement.</h4>
          <p className={classes.content} >
            You and Tsekmo each agree
            that the following causes of action and/or claims for relief are exceptions to
            the Arbitration Agreement and will be brought in a judicial proceeding in a
            court of competent jurisdiction : (i) any claim or cause of action alleging
            actual or threatened infringement, misappropriation or violation of a party’s
            copyrights, trademarks, trade secrets, patents, or other intellectual property
            rights; (ii) any claim or cause of action seeking emergency injunctive relief
            based on exigent circumstances (e.g., imminent danger or commission of a
            crime, hacking, cyber-attack); or (iii) a request for the remedy of public
            injunctive relief; or (iv) any individual claim of sexual assault or sexual
            harassment arising from your use of the Tsekmo Platform or Host Services.
            You and Tsekmo agree that the remedy of public injunctive relief will
            proceed after the arbitration of all arbitrable claims, remedies, or causes of
            action, and will be stayed pending the outcome of the arbitration.
           
          </p>

          <h4 className={classes.content} >22.6 Arbitration Rules and Governing Law.</h4>
          <p className={classes.content} >
            This Arbitration Agreement
            evidences a transaction in interstate commerce and the Federal Arbitration
            Act governs all substantive and procedural interpretation and enforcement
            of this provision. The arbitration will be administered by AAA in accordance
            with the Consumer Arbitration Rules and/or other AAA arbitration rules
            determined to be applicable by the AAA (the “AAA Rules“) then in effect,
            except as modified here. The AAA Rules are available at www.adr.org. In
            order to initiate arbitration, a completed written demand (available
            at www.adr.org) must be filed with the AAA and provided to the other
            party, as specified in the AAA rules.
           
          </p>

          <h4 className={classes.content} >23. Google Terms.</h4>
          <p className={classes.content} >
            Some translations on the Tsekmo Platform are
            powered by Google. Google disclaims all warranties related to the
            translations, express or implied, including any warranties of accuracy,
            reliability, and any implied warranties for merchantability, fitness for a
            particular purpose and non-infringement. Some areas of the Tsekmo
            Platform implement Google Maps/Earth mapping services, including
            Google Maps API(s). Your use of Google Maps/Earth is subject to
            the Google Maps/Google Earth Additional Terms of Service.
           
          </p>

          <h4 className={classes.content} >24. Apple Terms.</h4>
          <h4 className={classes.content} >24. Stripe Connect Terms.</h4>
          <h4 className={classes.content} >25. Tsekmo Platform Content</h4>
          <p className={classes.content} >
            Content made available through the
            Tsekmo Platform may be protected by copyright, trademark, and/or other
            laws of the United States and other countries. You acknowledge that all
            intellectual property rights for that Content are the exclusive property of
            Tsekmo and/or its licensors and agree that you will not remove, alter or
            obscure any copyright, trademark, service mark or other proprietary rights
            notices. You may not use, copy, adapt, modify, prepare derivative works of,
            distribute, license, sell, transfer, publicly display, publicly perform, transmit,
            broadcast or otherwise exploit any Content accessed through the Tsekmo
            Platform except to the extent you are the legal owner of that Content or as
            expressly permitted in these Terms. Subject to your compliance with these
            Terms, Tsekmo grants you a limited, non-exclusive, non-sublicensable,
            revocable, non-transferable license to (i) download and use the Application
            on your personal device(s); and (ii) access and view the Content made
            available on or through the Tsekmo Platform and accessible to you, solely
            for your personal and non-commercial use.
          </p>
          <h4 className={classes.content} >26. Emails and SMS</h4>
          <p className={classes.content} >
            You will receive administrative communications from
            us using the email address or other contact information you provide for
            your Tsekmo account. Enrollment in additional email subscription programs
            will not affect the frequency of these administrative emails, though you
            should expect to receive additional emails specific to the program(s) to
            which you have subscribed. You may also receive promotional emails from
            us. No fee is charged for these promotional emails, but third-party data
            rates could apply. You can control whether you receive promotional emails
            using the notification preferences in your account settings. Please note that
            you will not be able to take advantage of certain promotions if you disable
            certain communication settings or do not have an Tsekmo account. In the
            U.S.
          </p>
          <h4 className={classes.content} >27. Contact Us.</h4>
          <p className={classes.content} >
            please email us.

          </p>
          <div className= {classes.addExtraPadding}></div>
          </div>
        </Main>
      )}

      {!props.isLogedIn && (
        <Footer showTwoLevelFooter={true} />
      )}
    </div>
  )
}

export default TermsAndCondition
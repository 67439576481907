import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import React, { Dispatch, SetStateAction, useState } from 'react'
import DropUpload from '../../DropUpload'
import UploadImageList from '../../../../theme/components/image-list/UploadImageList'
import TsekmoButton from '../../TsekmoButton'

interface IDVerificficationProps {
  handleOpenWebcam: (mode: string) => void
  idImageList: any
  userDiscountList: any
  handleSubmit: () => void
  deleteIdImage: (type: string) => void
  handleAddPhoto: (e: any, type: string) => void
}

function IDVerification({
  handleOpenWebcam,
  idImageList,
  userDiscountList,
  handleSubmit,
  deleteIdImage,
  handleAddPhoto,
}: IDVerificficationProps) {
  const addPhoto = (event: any, type: string) => {
    handleAddPhoto(event.target.files[0], type)
  }

  return (
    <div className="p-6 max-w-[600px]">
      <div className="flex flex-col gap-4">
        <span className="text-primaryBg font-semibold text-lg text-center">
          Upload Photo ID
        </span>
        <div className="flex flex-col gap-2">
          <span className="text-sm font-semibold text-subText">
            Instructions:
          </span>
          <span className="text-subtext text-sm">
            Before uploading and sending ID photos please follow the
            instructions below:
          </span>
          <ul className="list-decimal pl-4 text-sm mt-2 pb-6">
            <li>First image will be the FRONT photo of the valid ID</li>
            <li>Second image will be the BACK photo of the valid ID</li>
            <li>Take a selfie while holding your valid ID</li>
          </ul>
          <DropUpload
            text="Add front photo"
            dragText="Upload Photo"
            onFileDrop={(event) => addPhoto(event, 'front')}
          />
          <button
            className="text-blue-600 text-xs hover:underline text-left -mt-1"
            onClick={() => handleOpenWebcam('front')}
          >
            Take a photo instead
          </button>
          <UploadImageList
            imageFileList={idImageList['front']}
            onDelete={() => deleteIdImage('front')}
            columns={1}
          />

          <DropUpload
            text="Add back photo"
            dragText="Upload Photo"
            onFileDrop={(event) => addPhoto(event, 'back')}
          />
          <button
            className="text-blue-600 text-xs hover:underline text-left -mt-1"
            onClick={() => handleOpenWebcam('back')}
          >
            Take a photo instead
          </button>
          <UploadImageList
            imageFileList={idImageList['back']}
            onDelete={() => deleteIdImage('back')}
            columns={1}
          />
          <DropUpload
            text="Add selfie with valid ID"
            dragText="Upload Photo"
            onFileDrop={(event) => addPhoto(event, 'selfie')}
          />
          <button
            className="text-blue-600 text-xs hover:underline text-left -mt-1"
            onClick={() => handleOpenWebcam('selfie')}
          >
            Take a photo instead
          </button>
          <UploadImageList
            imageFileList={idImageList['selfie']}
            onDelete={() => deleteIdImage('selfie')}
            columns={1}
          />
        </div>
        <TsekmoButton onClick={handleSubmit}>Submit</TsekmoButton>
      </div>
    </div>
  )
}

export default IDVerification

import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress, Container, Avatar } from '@mui/material';
import { styled } from '@mui/system';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';

// Custom styling for the CircularProgress
const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const ThankYouPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showRedirectionMessage, setShowRedirectionMessage] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = 'tsekmo-business://profile'; // Attempt redirection
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const checkRedirectionStatus = setTimeout(() => {
      setIsLoading(false); // Stop showing loading after 5 seconds
      setShowRedirectionMessage(true); // Show message if not redirected
    }, 5000);

    return () => clearTimeout(checkRedirectionStatus);
  }, []);

  return (
    <Container maxWidth="sm" sx={{ height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <Logo style={{ width: '100px', height: '100px', marginBottom: '12px', display: 'block', }} />
      
      <Typography variant="h4" gutterBottom sx={{ color: 'primary.main', textAlign: 'center' }}>
        Thank you for your subscription!
      </Typography>
      {isLoading && (
        <>
          <Typography variant="subtitle1" gutterBottom sx={{ color: 'text.secondary', textAlign: 'center' }}>
            You will be redirected shortly...
          </Typography>
        </>
        )}
      {isLoading && <StyledCircularProgress sx={{ mt: 2 }} />}
      {!isLoading && showRedirectionMessage && (
        <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center', mt: 2 }}>
          If you are not redirected, please close this browser window.
        </Typography>
      )}
    </Container>
  );
};

export default ThankYouPage;
import React, { useEffect, useState } from 'react'
import {
  TableHead,
  TableCell,
  TableRow,
  Theme,
  Table,
  TableBody,
  Avatar,
  MenuItem,
  Menu,
  Button,
  TablePagination,
  LinearProgress,
  IconButton,
  Tooltip,
  Dialog,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import UIConstants from '../../../../theme/constants/ui-constants'
import { popoverClasses } from '@mui/material/Popover'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchCleanLog,
  fetchCleanlogStructure,
  fetchSelectedListing,
} from '../../../../store/app/listing/actions'
import { ApplicationState } from '../../../../store'
import VisibilityIcon from '@mui/icons-material/Visibility'
import {
  handleGetListingName,
  handleGetSpecificUser,
} from '../../../composables/helpers'
import moment from 'moment'
import CleanLog from '../components/CleanLog'
import AddCleanLog from '../components/AddCleanLog'
import TsekmoButton from '../../../common/TsekmoButton'
import { use } from 'i18next'
import ResolveCleanLog from '../components/ResolveCleanLog'

const useStyle = makeStyles((theme: Theme) => ({
  header: {
    '&.MuiTableCell-root': {
      color: UIConstants.mainText,
      paddingBottom: '10px',
    },
  },
  body: {
    '&.MuiTableRow-root': {
      color: UIConstants.mainText,
      backgroundColor: 'white',
    },
    '& .MuiTableRow-root': {
      borderRadius: '100px',
    },
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  table: {
    '&.MuiTable-root': {
      borderCollapse: 'separate',
      borderSpacing: '0 10px',
    },
  },
}))

interface CleanLogHomeProps {
  listing_id: number
}
function CleanLogHome({ listing_id }: CleanLogHomeProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const dispatch = useDispatch()
  const [listingId, setCurrentListingId] = useState<number>(0)
  const [cleanLogData, setCleanLogData] = useState<any[]>([])
  const [showAddCleanLog, setShowAddCleanLog] = useState<boolean>(false)
  const [currentCleanLogData, setCurrentCleanLogData] = useState<any>()
  const { selectedListing } = useSelector(
    (state: ApplicationState) => state.listing
  )
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(5)
  const [showCleanLog, setShowCleanLog] = useState<boolean>(false)
  const [issues, setIssues] = useState<any[]>([])

  const { userData } = useSelector((state: ApplicationState) => state.app)
  const { cleaningLogListing, loading } = useSelector(
    (state: ApplicationState) => state.listing
  )
  const classes = useStyle()
  const headers = [
    {
      id: 'avatar',
      label: '',
      width: '5%',
    },
    {
      id: 'date',
      label: 'Date',
      width: '12%',
    },
    {
      id: 'customer',
      label: 'Customer',
      width: '45%',
    },
    {
      id: 'actions',
      label: 'Actions',
      width: '5%',
    },
  ]

  useEffect(() => {
    if (listing_id && userData.id) {
      if (listing_id !== listingId) {
        setPage(1)
        setLimit(5)
      }
      setCurrentListingId(listing_id)
      dispatch(
        // TODO: FIX IMPLEMENTATION
        fetchCleanLog({ listing_id: listing_id })
      )
      // limit: limit, page: page
      dispatch(
        fetchSelectedListing({
          listing_id: listing_id,
          user_id: userData.id,
        })
      )
    }
  }, [listing_id, limit, page, userData.id])

  const handleShowCleanLog = (data: any) => () => {
    setCurrentCleanLogData(data)
    setShowCleanLog(true)
  }

  useEffect(() => {
    async function returnCleanLog() {
      if (cleaningLogListing?.result?.length > 0) {
        const listingCleanLogData = await Promise.all(
          cleaningLogListing?.result.map(async (item: any) => {
            const user = await handleGetSpecificUser(item.user_id)
            const currentUser = user?.data.userData.user_info
            return {
              id: item.id,
              avatar: currentUser?.image_url,
              items: JSON.parse(item.items),
              time: moment(item.logged_at).format('lll'),
              name: currentUser?.first_name + ' ' + currentUser?.last_name,
            }
          })
        )
        setCleanLogData(listingCleanLogData)
      }
    }
    returnCleanLog()
  }, [cleaningLogListing])

  function handleOnRowsPerPageChange(event: any) {
    if (parseInt(event.target.value, 10) > cleaningLogListing?.total) {
      setLimit(cleaningLogListing?.total)
    } else {
      setLimit(parseInt(event.target.value, 10))
    }
    setPage(1)
  }

  function handleOnPageChange(_event: any, newPage: number) {
    setPage(newPage)
  }

  useEffect(() => {
    if (selectedListing) {
      const parsedCleanLog = JSON.parse(selectedListing?.cleanlog_structure)
      const parsedIssues: any = []
      parsedCleanLog?.forEach((category: any) => {
        category.items.forEach((item: any) => {
          if (item.is_grayed_out) {
            parsedIssues.push(item)
          }
        })
      })
      setIssues(parsedIssues)
    }
  }, [selectedListing])

  return (
    <>
      {loading?.cleanLog && <LinearProgress className="mt-2" />}
      <div className="w-full h-full px-10 pt-5">
        <div className="w-full flex justify-end mb-2">
          {issues.length > 0 && (
            <button
              onClick={() => setShowAddCleanLog(true)}
              className="text-sm text-red-500"
            >
              Resolve {issues.length} Issues
            </button>
          )}
        </div>

        {cleanLogData.length === 0 ? (
          <div className="w-full text-center">
            <span>No Clean Logs today</span>
          </div>
        ) : (
          <>
            <Table className={classes.table}>
              <TableHead className={classes.header}>
                <TableRow>
                  {headers.map((header) => (
                    <TableCell
                      width={header.width}
                      sx={{ padding: '0 50px' }}
                      key={header.id}
                      padding="none"
                      align={header.id === 'actions' ? 'center' : 'left'}
                    >
                      {header.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {cleanLogData.length > 0 &&
                  cleanLogData.map((data) => (
                    <TableRow className={classes.body} key={data.id}>
                      <TableCell>
                        {data.avatar ? (
                          <img
                            className="w-[30px h-[30px] rounded-full object-cover"
                            src={data.avatar}
                            alt="user-avatar"
                          />
                        ) : (
                          <Avatar sx={{ width: '30px', height: '30px' }} />
                        )}
                      </TableCell>
                      <TableCell>{data.time}</TableCell>
                      <TableCell>{data.name}</TableCell>
                      <TableCell align="center">
                        <Tooltip title="View Clean Log" placement="right" arrow>
                          <IconButton onClick={handleShowCleanLog(data)}>
                            <VisibilityIcon className="text-gray-300" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {cleanLogData.length > 0 && (
              <TablePagination
                component="div"
                count={cleaningLogListing?.total}
                page={page}
                rowsPerPageOptions={[
                  5,
                  10,
                  25,
                  50,
                  { label: 'All', value: cleaningLogListing?.total },
                ]}
                onPageChange={handleOnPageChange}
                rowsPerPage={limit}
                onRowsPerPageChange={handleOnRowsPerPageChange}
              />
            )}
          </>
        )}
      </div>
      <Dialog
        open={showCleanLog}
        onClose={() => setShowCleanLog(false)}
        maxWidth="xl"
      >
        <CleanLog cleanLogData={currentCleanLogData} />
      </Dialog>
      <Dialog
        open={showAddCleanLog}
        onClose={() => setShowAddCleanLog(false)}
        maxWidth="xl"
      >
        <ResolveCleanLog
          listing={selectedListing}
          handleCloseForm={() => setShowAddCleanLog(false)}
        />
      </Dialog>
    </>
  )
}

export default CleanLogHome

import { IconButton, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import bgImage from '../../../assets/images/background.png';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
    bgimg: {
        backgroundImage: `url(${bgImage})`,
        height: "100vh",
        backgroundPosition: "center",
        backgroundSize: "cover",
        position: "relative",
        color: "white",
        fontFamily: "Courier New, Courier, monospace",
        fontSize: "25px",
    },
    topleft: {
        position: "absolute",
        top: 0,
        left: "16px",
    },
    bottomleft: {
        position: "absolute",
        bottom: "0",
        left: "16px",
    },
    middle: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
    }

}));

export const ComingSoon = () => {
    const classes = useStyles();
    const navigate = useNavigate()
    return <div className={classes.bgimg}>
        <div className={classes.topleft}>
            <IconButton
                onClick={() => navigate(-1)}
            >
                <ArrowBackIcon />
            </IconButton>
        </div>
        <div className={classes.middle}>
            <h1>COMING SOON</h1>
            <hr style={{ margin: 'auto', width: '40%' }} />
            <p>Few days left</p>
        </div>
        <div className={classes.bottomleft}>
            <p>Some text</p>
        </div>
    </div>
}

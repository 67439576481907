
import { Paper, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from '@mui/styles';
import UIConstants from "../../../theme/constants/ui-constants";
import otpTimeout from "../../../assets/images/otp-timeout.svg";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../../../theme/components/buttons/Button";
import { CountdownApi } from "react-countdown";

const useStyles = makeStyles((theme: Theme) => ({
    formControl: {
        margin: `${theme.spacing(3)} ${theme.spacing(1)}`,
        display: 'flex',
        // flexDirection: 'column-reverse',
    },
    heading: {
        margin: `${theme.spacing(1)} ${theme.spacing(4)}`,
        textAlign: 'center',
        '& .mainHeading': {
            fontWeight: 600,
            fontSize: '18px',
            fontFamily: UIConstants.appFont,
            color: UIConstants.primaryBgColor,
            textAlign: 'center',
            marginBottom: theme.spacing(3)
        },
        '& .subHeading': {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '27px',
            fontFamily: UIConstants.appFont,
            color: UIConstants.primaryBgColor,
            textAlign: 'left'
        }
    },
    signUpInputBox: {
        minWidth: '250px',
        margin: `${theme.spacing(4)} 0`,
        width: '100%',
        [theme.breakpoints.up("sm")]: {
            minWidth: '350px',
        },
    },
    signupBox: {
        background: UIConstants.rootBgColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: "100%",
        padding: `0 ${theme.spacing(3)}`,
        position: 'relative',
        top: '4rem',
        height: "calc(100vh - 9rem)",
        [theme.breakpoints.up("sm")]: {
            position: 'unset',
            maxWidth: '400px',
            height: "100%",
            margin: 'auto',
            top: 'unset',
            padding: `${theme.spacing(3)}`,
        },
    }
}));

interface OTPTimeoutProps {
    onReSendOtp: (api?: CountdownApi) => void;
    counterApi: CountdownApi;
}

export default function OTPTimeout({ onReSendOtp, counterApi }: OTPTimeoutProps) {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    return (
        <Paper elevation={matches ? 3 : 0} className={classes.signupBox}>
            <div className={classes.heading}>
                <img
                    src={otpTimeout}
                    alt="otp-timeout"
                />
                <Typography className='mainHeading'>
                    {t('otpScreens.timeout')}
                </Typography>
                <Typography className='subHeading'>
                    <span >{t('otpScreens.timeoutMsg')}</span>
                </Typography>
            </div>
            <div className={classes.signUpInputBox}>
                <div className={classes.formControl}>
                    <div id="recaptcha-container" style={{ display: "flex", justifyContent: "center", width: "100%" }}></div>
                </div>
                <div className={classes.formControl}>
                    <CustomButton
                        type="submit"
                        variant="contained"
                        fullWidth
                        onClick={() => { onReSendOtp(counterApi) }}>
                        {t('otpScreens.resendOtp')}
                    </CustomButton>
                </div>
            </div>
        </Paper >
    );
}



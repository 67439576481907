export const BathroomData = [
  {
    id: 1,
    disabled: false,
    text: 'Door',
    checked: true,
    broken: false,
    comment: '',
    logTime: null,
    category: 'Bathroom',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
  {
    id: 2,
    disabled: false,
    text: 'Door lock',
    checked: true,
    broken: false,
    comment: '',
    logTime: null,
    category: 'Bathroom',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
  {
    id: 3,
    disabled: false,
    text: 'Silling',
    checked: true,
    broken: false,
    comment: '',
    logTime: null,
    category: 'Bathroom',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
  {
    id: 4,
    disabled: false,
    text: 'Floor',
    checked: true,
    broken: false,
    comment: '',
    logTime: null,
    category: 'Bathroom',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
  {
    id: 5,
    disabled: false,
    text: 'Wall',
    checked: true,
    broken: false,
    comment: '',
    logTime: null,
    category: 'Bathroom',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
  {
    id: 6,
    disabled: false,
    text: 'Exhaust fan or vent',
    checked: true,
    broken: false,
    comment: '',
    logTime: null,
    category: 'Bathroom',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
  {
    id: 7,
    disabled: false,
    text: 'Windows',
    checked: true,
    broken: false,
    comment: '',
    logTime: null,
    category: 'Bathroom',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
  {
    id: 8,
    disabled: false,
    text: 'Bathmat/rug',
    checked: true,
    broken: false,
    comment: '',
    logTime: null,
    category: 'Bathroom',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
]

export const ToiletData = [
  {
    id: 1,
    disabled: false,
    text: 'Toilet tank',
    checked: true,
    broken: false,
    comment: '',
    logTime: null,
    category: 'Toilet',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
  {
    id: 2,
    disabled: false,
    text: 'Toilet flush handle or button',
    checked: true,
    broken: false,
    comment: '',
    logTime: null,
    category: 'Toilet',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
  {
    id: 3,
    disabled: false,
    text: 'Toilet bowl',
    checked: true,
    broken: false,
    comment: '',
    logTime: null,
    category: 'Toilet',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
  {
    id: 4,
    disabled: false,
    text: 'Toilet seat',
    checked: true,
    broken: false,
    comment: '',
    logTime: null,
    category: 'Toilet',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
  {
    id: 5,
    disabled: false,
    text: 'Toilet seat cover dispenser',
    checked: true,
    broken: false,
    comment: '',
    logTime: null,
    category: 'Toilet',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
  {
    id: 6,
    disabled: false,
    text: 'Bidet',
    checked: true,
    broken: false,
    comment: '',
    logTime: null,
    category: 'Toilet',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
  {
    id: 7,
    disabled: false,
    text: 'Toilet paper holder',
    checked: true,
    broken: false,
    comment: '',
    logTime: null,
    category: 'Toilet',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
]

export const vanitiesData = [
  {
    id: 1,
    disabled: false,
    text: 'Mirror',
    checked: true,
    broken: false,
    comment: '',
    logTime: null,
    category: 'Vanities',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
  {
    id: 2,
    disabled: false,
    text: 'Faucet or tap',
    checked: true,
    broken: false,
    comment: '',
    logTime: null,
    category: 'Vanities',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
  {
    id: 3,
    disabled: false,
    text: 'Sink or basin',
    checked: true,
    broken: false,
    comment: '',
    logTime: null,
    category: 'Vanities',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
  {
    id: 4,
    disabled: false,
    text: 'Hand Dryer',
    checked: true,
    broken: false,
    comment: '',
    logTime: null,
    category: 'Vanities',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
  {
    id: 5,
    disabled: false,
    text: 'Cabinet',
    checked: true,
    broken: false,
    comment: '',
    logTime: null,
    category: 'Vanities',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
  {
    id: 6,
    disabled: false,
    text: 'Paper towel dispenser',
    checked: true,
    broken: false,
    comment: '',
    logTime: null,
    category: 'Vanities',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
]

export const ShowerData = [
  {
    id: 1,
    disabled: false,
    text: 'Showerhead',
    checked: true,
    broken: false,
    comment: '',
    logTime: null,
    category: 'Shower',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
  {
    id: 2,
    disabled: false,
    text: 'Bathtub',
    checked: true,
    broken: false,
    comment: '',
    logTime: null,
    category: 'Shower',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
  {
    id: 3,
    disabled: false,
    text: 'Shower knobs or handles',
    checked: true,
    broken: false,
    comment: '',
    logTime: null,
    category: 'Shower',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
  {
    id: 4,
    disabled: false,
    text: 'Towel bar or rack',
    checked: true,
    broken: false,
    comment: '',
    logTime: null,
    category: 'Shower',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
  {
    id: 5,
    disabled: false,
    text: 'Shower curtain or shower door',
    checked: true,
    broken: false,
    comment: '',
    logTime: null,
    category: 'Shower',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
]

export const CleaningData = [
  {
    id: 1,
    disabled: false,
    text: 'Toilet brush and holder',
    checked: true,
    broken: false,
    comment: '',
    logTime: null,
    category: 'Cleaning',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
  {
    id: 2,
    disabled: false,
    text: 'Medicine cabinet',
    checked: true,
    broken: false,
    comment: '',
    logTime: null,
    category: 'Cleaning',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
  {
    id: 3,
    disabled: false,
    text: 'Bathroom scale',
    checked: true,
    broken: false,
    comment: '',
    logTime: null,
    category: 'Cleaning',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
  {
    id: 4,
    disabled: false,
    text: 'Bowl brush',
    checked: true,
    broken: false,
    comment: '',
    logTime: null,
    category: 'Cleaning',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
  {
    id: 5,
    disabled: false,
    text: 'Plunger',
    checked: true,
    broken: false,
    comment: '',
    logTime: null,
    category: 'Cleaning',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
  {
    id: 6,
    disabled: false,
    text: 'Chemical cleaner',
    checked: true,
    broken: false,
    comment: '',
    logTime: null,
    category: 'Cleaning',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
]

export const RefillableData = [
  {
    id: 1,
    disabled: false,
    text: 'Deodorizer',
    checked: true,
    empty: false,
    comment: '',
    logTime: null,
    category: 'Refillables',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
  {
    id: 2,
    disabled: false,
    text: 'Soap',
    checked: true,
    empty: false,
    comment: '',
    logTime: null,
    category: 'Refillables',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
  {
    id: 3,
    disabled: false,
    text: 'Hand Sanitizer',
    checked: true,
    empty: false,
    comment: '',
    logTime: null,
    category: 'Refillables',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
  {
    id: 4,
    disabled: false,
    text: 'Tissue',
    checked: true,
    empty: false,
    comment: '',
    logTime: null,
    category: 'Refillables',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
  {
    id: 5,
    disabled: false,
    text: 'Air Freshener',
    checked: true,
    empty: false,
    comment: '',
    logTime: null,
    category: 'Refillables',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
  {
    id: 6,
    disabled: false,
    text: 'Toilet Paper',
    checked: true,
    empty: false,
    comment: '',
    logTime: null,
    category: 'Refillables',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
  {
    id: 7,
    disabled: false,
    text: 'Toilet Seat Cover',
    checked: true,
    empty: false,
    comment: '',
    logTime: null,
    category: 'Refillables',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
  {
    id: 8,
    disabled: false,
    text: 'Paper Towel',
    checked: true,
    empty: false,
    comment: '',
    logTime: null,
    category: 'Refillables',
    uploadedImages: [],
    is_grayed_out: false,
    none: false,
  },
]

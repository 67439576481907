import React, { useEffect, useRef, useState } from 'react'
import TsekmoButton from '../../TsekmoButton'
import {
  Theme,
  Button,
  Tabs,
  Tab,
  Card,
  Menu,
  MenuItem,
  CircularProgress,
  Dialog,
  FormControlLabel,
  Switch,
  FormGroup,
} from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import { makeStyles } from '@mui/styles'
import UIConstants from '../../../../theme/constants/ui-constants'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import CoPresentIcon from '@mui/icons-material/CoPresent'
import TsekrsPostItem from '../components/TsekrsPostItem'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../../store'
import { fetchTsekmoCommunityPosts } from '../../../../store/app/community/actions'
import AddIncidentForm from '../components/AddIncidentForm'
import { useGetUserType } from '../../../composables'
import RateReviewIcon from '@mui/icons-material/RateReview'
import {
  fetchListingUserData,
  fetchListings,
} from '../../../../store/app/listing/actions'
import { cloneDeep } from 'lodash'

const useStyle = makeStyles((theme: Theme) => ({
  tabs: {
    marginTop: '0.75rem',
    '& .MuiTab-root': {
      color: UIConstants.subText,
      textTransform: 'capitalize',
      minHeight: 50,
      marginBottom: '1.25rem',
    },
    '& .MuiTab-root.Mui-selected': {
      color: UIConstants.primaryBgColor,
    },
    '& .MuiTabs-indicator': {
      left: 0,
      backgroundColor: UIConstants.primaryBgColor,
    },
  },
  divClass: {
    maxHeight: 'calc(100vh - 150px)', //hack for getting max height of container since all divs are supposedly 100% //screen height minus padding top and bottom (80px) minus header height (70px)
    overflowY: 'scroll',
  },
}))
function Tsekrs() {
  const dispatch = useDispatch()
  const classes = useStyle()
  const [tabValue, setTabValue] = useState<number>(0)
  const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>()
  const currentUserType = useGetUserType()
  const [openCreateIncidentReport, setOpenIncidentReport] =
    useState<boolean>(false)
  const open = Boolean(anchorEl)
  const [editPostData, setEditPostData] = useState<any>(null)
  const [isEditPost, setIsEditPost] = useState<boolean>(false)
  const [isBlurredPhotos, setIsBlurredPhotos] = useState<boolean>(true)
  const [communityPosts, setCommunityPosts] = useState<any[]>([])


  const handleOpenEditPost = (postData: any): void => {
    setEditPostData(postData)
    setIsEditPost(true)
    setOpenIncidentReport(true)
  }
  const handleOpenIncidentReport = (): void => {
    setOpenIncidentReport(true)
  }
  const handleCloseIncidentReport = (): void => {
    setEditPostData(null)
    setIsEditPost(false)
    setOpenIncidentReport(false)
  }
  const handleChange = (
    event: React.SyntheticEvent,
    newValue: number
  ): void => {
    setTabValue(newValue)
  }
  const handleClick = (event: any): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setAnchorEl(null)
  }
  const { userData } = useSelector((state: ApplicationState) => state.app)
  const { tsekmoCommunityPosts, loading } = useSelector(
    (state: ApplicationState) => state.tsekmoCommunity
  )
  const { listingData } = useSelector(
    (state: ApplicationState) => state.listing
  )

  const handleBlurToggle = (): void => {
    setIsBlurredPhotos(!isBlurredPhotos)
  }

  useEffect(() => {
    fetchPosts()
  }, [userData, tabValue])

  function fetchPosts() {
    if (userData.id) {
      dispatch(fetchListings(userData.id))
    }
    setEditPostData(null)
    setIsEditPost(false)
    if (userData.id && tabValue === 0) {
      dispatch(fetchTsekmoCommunityPosts({ user_id: userData.id }))
    } else if (userData.id && tabValue === 1) {
      dispatch(
        fetchTsekmoCommunityPosts({ user_id: userData.id, is_my_post: true })
      )
    } else if (userData.id && tabValue === 2) {
      dispatch(
        fetchTsekmoCommunityPosts({ user_id: userData.id, is_saved_post: true })
      )
    } else if (userData.id && tabValue === 3) {
      dispatch(fetchTsekmoCommunityPosts({ user_id: userData.id }))
    }
  }

  useEffect(() => {
    setCommunityPosts([])
    if (tsekmoCommunityPosts.length > 0 && listingData.length > 0) {
      if (tabValue !== 3) {
        setCommunityPosts(tsekmoCommunityPosts)
      } else if (tabValue === 3) {
        const tsekmoPostsClone = cloneDeep(tsekmoCommunityPosts)
        const filteredListingIds = listingData.map((listing: any) => listing.id)
        const filteredPosts = tsekmoPostsClone.filter((post: any) =>
          filteredListingIds.includes(post.listing_id)
        )
        setCommunityPosts(filteredPosts)
      }
    }
  }, [tsekmoCommunityPosts, listingData])

  return (
    <div className="w-full h-full overflow-hidden bg-foreground">
      <div className="flex flex-col lg:flex-row w-full h-full">
        <div className="w-full lg:max-w-[30%] flex lg:flex-col flex-col lg:items-center items-center lg:pt-10 h-[10%] lg:h-full">
          <Card className="lg:m-5 m-5 p-5 lg:w-[80%] lg:max-w-[300px] h-[80%] lg:h-fit bg-white rounded-md lg:items-center justify-center lg:justify-center">
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    onChange={handleBlurToggle}
                    checked={isBlurredPhotos}
                  />
                }
                label="Blur Photos?"
              />
            </FormGroup>
          </Card>
          <Card className="lg:m-0 m-5 p-5 lg:w-[80%] lg:max-w-[300px] h-[80%] lg:h-fit bg-white rounded-md flex lg:flex-col items-center justify-center lg:justify-normal lg:p-10">
            <TsekmoButton onClick={handleOpenIncidentReport}>
              {currentUserType === 'business-owner' ||
              currentUserType === 'admin'
                ? '+ Create Incident Report'
                : '+ Report a Bathroom'}
            </TsekmoButton>
            <div className="lg:flex flex-col gap-5 pt-5 hidden">
              <Tabs
                orientation="vertical"
                value={tabValue}
                onChange={handleChange}
                className={classes.tabs}
              >
                <Tab
                  label="All Posts"
                  icon={<AssignmentIndIcon />}
                  iconPosition="start"
                />
                <Tab
                  label="My Posts"
                  icon={<CoPresentIcon />}
                  iconPosition="start"
                />
                <Tab
                  label="Saved Posts"
                  icon={<BookmarkIcon />}
                  iconPosition="start"
                />
                <Tab
                  label="For Review"
                  icon={<RateReviewIcon />}
                  iconPosition="start"
                />
              </Tabs>
            </div>
            <button className="lg:hidden block ml-5" onClick={handleClick}>
              <FilterListIcon />
            </button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              <MenuItem>
                <div className="flex gap-2 items-center text-sm text-subText">
                  <AssignmentIndIcon />
                  All Posts
                </div>
              </MenuItem>
              <MenuItem>
                <div className="flex gap-2 items-center text-sm text-subText">
                  <CoPresentIcon />
                  My Posts
                </div>
              </MenuItem>
              <MenuItem>
                <div className="-ml-1 flex gap-2 items-center text-sm text-subText">
                  <BookmarkIcon />
                  Saved Posts
                </div>
              </MenuItem>
            </Menu>
          </Card>
        </div>
        <div
          className={`w-full gap-10 flex flex-col items-center lg:p-10 lg:pr-24 ${classes.divClass}`}
        >
          {loading.communityData ? (
            <CircularProgress sx={{ color: UIConstants.primaryBgColor }} />
          ) : communityPosts.length > 0 ? (
            communityPosts.map((postData) => (
              <TsekrsPostItem
                key={postData.id}
                postData={postData}
                fetchPosts={fetchPosts}
                handleOpenEditPost={handleOpenEditPost}
                isBlurredPhotos={isBlurredPhotos}
                forReview={tabValue === 3}
              />
            ))
          ) : tabValue === 0 ? (
            'No incident reports.'
          ) : tabValue === 1 ? (
            'You have no posts.'
          ) : tabValue === 2 ? (
            'No saved posts.'
          ) : (
            'No posts for review.'
          )}
        </div>
      </div>
      <Dialog
        open={openCreateIncidentReport}
        onClose={handleCloseIncidentReport}
        maxWidth="xl"
      >
        <AddIncidentForm
          onFinishAdd={fetchPosts}
          closeDialog={handleCloseIncidentReport}
          postData={editPostData}
          isEdit={isEditPost}
          isReportABathroom={currentUserType === 'customer'}
        />
      </Dialog>
    </div>
  )
}

export default React.memo(Tsekrs)

import React from 'react'
import {
  Card,
  CardContent,
  Typography,
  Button,
  CardActions,
} from '@mui/material'
import InfoCard from '../components/InfoCard'
import UIConstants from '../../../../theme/constants/ui-constants'
import { TransactionSummaryChart } from '../../../../app/pages/business-owner/dashboard/TransactionSummary'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../../../store'
import EarningsSummary from '../components/EarningsSummary'

interface InfomationProps {
  listing: any
}

function Information({listing}: InfomationProps) {
  const { dashboardData } = useSelector(
    (state: ApplicationState) => state.businessDashboard
  )
  return (
    <>
      {dashboardData && (
        <div className="grid grid-cols-4 px-14 pb-14 w-full h-full gap-10 gap-y-5">
          <div className="lg:col-span-1 col-span-4 md:col-span-2 flex items-center justify-center min-h-[300px]">
            <InfoCard
              title="Earnings"
              content={`$${dashboardData.total_earnings || 0}`}
            />
          </div>
          <div className="lg:col-span-1 col-span-4 md:col-span-2 flex items-center justify-center min-h-[300px]">
            <InfoCard
              title="Paid Customers"
              content={dashboardData.total_paid_customer || 0}
            />
          </div>
          <div className="lg:col-span-1 col-span-4 md:col-span-2 flex items-center justify-center min-h-[300px]">
            <InfoCard
              title="Free Customers"
              content={dashboardData.total_free_customer || 0}
            />
          </div>
          <div className="lg:col-span-1 col-span-4 md:col-span-2 flex items-center justify-center min-h-[300px]">
            <InfoCard title="Reviews" content={dashboardData.review_count || 0} />
          </div>
          <div className="col-span-4 w-full flex items-center justify-center min-h-[300px]">
            <Card
              sx={{
                minWidth: '100%',
                minHeight: '100%',
                borderRadius: 3,
                display: 'flex',
                color: UIConstants.mainText,
              }}
            >
              <CardContent sx={{ flex: '1' }}>
                <p className="text-md font-bold">Earnings Summary</p>
                <div className="grid grid-cols-1 lg:grid-cols-2 w-full h-full">
                  <div className="col-span-1 p-6 pr-12">
                    <EarningsSummary listing={listing}/>
                  </div>
                  <div className="col-span-1 h-full pr-3 pb-6">
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 w-full min-h-full">
                      <Card
                        className="col-span-1 lg:col-span-2"
                        elevation={0}
                        sx={{ backgroundColor: '#F0FDF4', color: '#4F9464' }}
                      >
                        <CardContent
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            height: '100%',
                            fontWeight: 'bold',
                          }}
                        >
                          <p className=" text-5xl font-black">{dashboardData.most_customer_today || 'None'}</p>
                          <p>Most Customers</p>
                        </CardContent>
                      </Card>
                      <Card
                        className="col-span-1"
                        elevation={0}
                        sx={{ backgroundColor: '#eef2ff', color: '#3730a3' }}
                      >
                        <CardContent
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            height: '100%',
                            fontWeight: 'bold',
                          }}
                        >
                          <p className=" text-5xl font-black">{dashboardData.new_customer}</p>
                          <p>New Customers</p>
                        </CardContent>
                      </Card>
                      <Card
                        className="col-span-1"
                        elevation={0}
                        sx={{ backgroundColor: '#FFB8B8', color: '#D80000' }}
                      >
                        <CardContent
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            height: '100%',
                            fontWeight: 'bold',
                          }}
                        >
                          <p className=" text-5xl font-black">
                            {dashboardData.incident_report || 0}
                          </p>
                          <p className='text-center'>New Incident Reports</p>
                        </CardContent>
                      </Card>
                      <Card
                        className="col-span-1 lg:col-span-2"
                        elevation={0}
                        sx={{ backgroundColor: '#F0FDF4', color: '#4F9464' }}
                      >
                        <CardContent
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            height: '100%',
                            fontWeight: 'bold',
                          }}
                        >
                          <p className=" text-5xl font-black">
                            {dashboardData.review_rating || 0}
                          </p>
                          <p>Average Rating</p>
                        </CardContent>
                      </Card>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      )}
    </>
  )
}

export default Information

import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { firestore } from '../../../../firebaseconfig'
import { useCollectionData } from 'react-firebase-hooks/firestore'

const useStyle = makeStyles((theme: Theme) => ({
  peopleContainer: {
    width: '100%',
    minHeight: '100px',
    borderBottom: '0.1px solid #c9c9c9',
    padding: '15px',
    display: 'flex',
    alignItems: 'center',
    '&:last-child': {
      borderBottom: 'none',
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#f7f7f7',
    },
  },
  userImage: {
    width: '50px',
    height: '50px',
    borderRadius: '100%',
    marginRight: '1rem',
  },
}))
function UserMessage(props: any) {
  const classes = useStyle()
  console.log(props.conversation)
  function emitUserConversationId() {
    props.onClick(props.conversation.userId)
  }
  const messagesRef = firestore.collection(
    (('conversations/' + props.conversation?.userId) as string) + '/messages'
  )
  const messagesQuery = messagesRef.orderBy('createdAt').limitToLast(1)
  const [messages]: any[] = useCollectionData(
    messagesQuery as any,
    {
      idField: 'id',
    }
  )
  return (
    <div className={classes.peopleContainer} onClick={emitUserConversationId}>
      <img
        src={props.conversation.photoURL}
        className={classes.userImage}></img>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span style={{ fontWeight: 'bold' }}>
          {props.conversation.displayName}
        </span>
        <span
          style={{
            color: 'grey',
            fontWeight: 'lighter',
            fontSize: '0.75rem',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '225px',
          }}>
          {messages ? `${props.conversation.displayName}: ${messages[0].text}` : ''}
        </span>
      </div>
    </div>
  )
}
export default UserMessage

let actionCodeSettings = {
	// URL you want to redirect back to. The domain (www.example.com) for this
	// URL must be in the authorized domains list in the Firebase Console.
	url:
		process.env.REACT_APP_DOMAIN + '/signup' ||
		'https://tsekmo-94a9e.web.app/signup',
	
	// This must be true.
	handleCodeInApp: true,
	//   iOS: {
	//     bundleId: 'com.example.ios',
	//   },
	//   android: {
	//     packageName: 'com.example.android',
	//     installApp: true,
	//     minimumVersion: '12',
	//   },
	//   dynamicLinkDomain: 'example.page.link',
};
export default actionCodeSettings;

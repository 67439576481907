import { CustomAppBar } from '../../theme/components/app-layout/header/AppBar'
import { useTranslation } from 'react-i18next'
import Carousel from 'react-material-ui-carousel'
import { makeStyles } from '@mui/styles'
import {
  Dialog,
  Grid,
  Modal,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import TsekmoLandingToolBar from '../components/ToolBar'
import UIConstants from '../../theme/constants/ui-constants'
import { Main } from '../../theme/components/app-layout/main/Main'
import Footer from '../../theme/components/app-layout/footer/Footer'
import { CustomButton } from '../../theme/components/buttons/Button'
import logo from '../../assets/images/logo.svg'
import mobileLogo from '../../assets/images/logo_mobile.svg'
import TsekmoMobile from '../../assets/images/tsekmo-mobile.png'
import MobileViewTsekmoMobile from '../../assets/images/mobile-view-tsekmo-screen.png'
import imageOptions1 from '../../assets/images/image-option1.png'
import imageOptions2 from '../../assets/images/image-option2.png'
import imageOptions3 from '../../assets/images/image-option3.png'
import imageOptions4 from '../../assets/images/image-option4.png'
import imageOptions5 from '../../assets/images/image-option5.png'
import imageOptions6 from '../../assets/images/image-option6.png'
import slide1 from '../../assets/images/slide1.png'
import slide2 from '../../assets/images/slide2.png'
import slide3 from '../../assets/images/slide3.png'
import slide4 from '../../assets/images/slide4.png'
import slide5 from '../../assets/images/slide5.png'
import imageOptions8 from '../../assets/images/image-option8.png'
import imageOptions9 from '../../assets/images/image-option9.png'
import { useNavigate } from 'react-router-dom'
import card1 from '../../assets/images/card1.png'
import card2 from '../../assets/images/card2.png'
import card3 from '../../assets/images/card3.png'
import card4 from '../../assets/images/card4.png'
import card5 from '../../assets/images/card5.png'
import FloatingButton from '../components/FloatingButton'
import { useEffect, useState } from 'react'
import AddIncidentForm from '../../app.v2/common/Tsekrs/components/AddIncidentForm'

const items = [
  {
    id: 1,
    image: 'slide1',
    title: 'Macbook Pro',
  },
  {
    id: 2,
    image: 'slide2',
    title: 'Home Appliances',
  },
  {
    id: 3,
    image: 'slide3',
    title: 'Home Appliances',
  },
]
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: UIConstants.rootBgColor,
    minHeight: `calc(100vh - ${UIConstants.appBarHeight}px - ${
      UIConstants.primaryFooterHeight
    } - ${UIConstants.secondaryFooterHeight} - ${theme.spacing(8)})`,
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  btnDiv: {
    display: 'flex',
    justifyContent: 'center',
  },
  btnStyle: {
    height: '75px',
    width: '350px',
    fontSize: '28px',
    backgroundColor: UIConstants.primaryBgColor,
    color: 'white',
  },
  carouselHeading: {
    margin: 'auto',
    marginTop: '30px',
    [theme.breakpoints.down('md')]: {
      margin: '27px',
    },
    '& .mainHeading': {
      fontWeight: 500,
      fontSize: '36px',
      lineHeight: '54px',
      fontFamily: UIConstants.appFont,
      color: UIConstants.primaryBgColor,
    },
    '& .description': {
      fontWeight: 'normal',
      fontSize: '24px',
      lineHeight: '36px',
      fontFamily: UIConstants.appFont,
      color: UIConstants.primaryBgColor,
      opacity: '0.7',
      textAlign: 'center',
    },
  },
  secondRowHeading: {
    margin: 'auto',
    marginTop: '0px',
    [theme.breakpoints.down('md')]: {
      margin: '27px',
    },
    '& .mainHeading': {
      fontWeight: 500,
      fontSize: '28px',
      lineHeight: '54px',
      fontFamily: UIConstants.appFont,
      color: UIConstants.primaryBgColor,
    },
    '& .description': {
      fontWeight: 'normal',
      fontSize: '24px',
      lineHeight: '36px',
      fontFamily: UIConstants.appFont,
      color: UIConstants.primaryBgColor,
      opacity: '0.7',
      textAlign: 'center',
    },
  },
  heading: {
    margin: `114px ${theme.spacing(5)} 0 128px`,
    [theme.breakpoints.down('md')]: {
      margin: '27px',
    },
    '& .mainHeading': {
      fontWeight: 500,
      fontSize: '36px',
      lineHeight: '54px',
      fontFamily: UIConstants.appFont,
      color: UIConstants.primaryBgColor,
    },
    '& .description': {
      marginTop: theme.spacing(4),
      fontWeight: 'normal',
      fontSize: '24px',
      lineHeight: '36px',
      fontFamily: UIConstants.appFont,
      color: UIConstants.primaryBgColor,
      opacity: '0.7',
    },
  },
  servicesTiles: {
    marginTop: '248px',
    backgroundColor: UIConstants.secondaryColor,
    position: 'relative',
    justifyContent: 'center',
  },
  carouselTiles: {
    marginTop: '200px',
    position: 'relative',
    justifyContent: 'center',
    backgroundColor: UIConstants.secondaryColor,
  },
  mainTiles: {
    marginTop: '200px',
    position: 'relative',
    justifyContent: 'center',
  },
  topContainer: {
    margin: 'auto',
    position: 'relative',
    justifyContent: 'center',
  },
  secondRowTiles: {
    marginTop: '100px',
    position: 'relative',
    justifyContent: 'center',
    backgroundColor: UIConstants.secondaryColor,
  },
  main: {
    width: '100%',
    margin: 0,
  },
  topfooterStyle: {
    background: UIConstants.secondaryColor,
    height: UIConstants.secondaryFooterHeight,
    display: 'flex',
    position: 'relative',
    top: '80px',
    marginBottom: '80px',
  },
  topfootercontainer: {
    width: '100%',
    margin: theme.spacing(2),
  },
  optionsImages: {
    width: '350px',
    height: '244px',
    borderBottomRightRadius: '40px',
    borderBottomLeftRadius: '40px',
  },
  carouselImages: {
    width: '900px',
    height: '490px',
    borderBottomRightRadius: '40px',
    borderBottomLeftRadius: '40px',
  },
  secondRowImages: {
    width: '350px',
    height: '244px',
    borderBottomRightRadius: '40px',
    borderBottomLeftRadius: '40px',
  },
  footer: {
    position: 'relative',
  },
  imageContainer: {
    width: '350px',
    height: '400px',
    margin: '0 23px 0 23px',
    bottom: '165px',
    position: 'relative',
    backgroundColor: UIConstants.rootBgColor,
    borderRadius: '8px',
    textAlign: 'center',

    '& .cardtitle': {
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '27px',
      color: UIConstants.rootBgColor,
      marginTop: '12px',
    },
  },

  secondImageContainer: {
    width: '350px',
    height: '335px',
    margin: '0 23px 0 23px',
    bottom: '165px',
    position: 'relative',
    backgroundColor: UIConstants.rootBgColor,
    borderRadius: '8px',
    textAlign: 'center',

    '& .cardtitle': {
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '27px',
      color: UIConstants.rootBgColor,
      marginTop: '12px',
    },
  },

  carouselImageContainer: {
    width: '100%',
    height: '480px',
    margin: '0 23px 0 23px',
    bottom: '165px',
    position: 'relative',
    borderRadius: '8px',
    textAlign: 'center',
    backgroundColor: 'transparent !important',
    display: 'grid',
    placeItems: 'center',

    '& .cardtitle': {
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '27px',
      color: UIConstants.rootBgColor,
      marginTop: '12px',
    },
  },
  gridItem: {
    padding: '0 !important',
    backgroundColor: UIConstants.primaryBgColor,
  },
  tsekmomobilesection: {
    height: '1030px',
    width: '100%',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      height: '800px',
    },
  },
  tsekmomobileimage: {
    position: 'absolute',
    right: '-100px',
    top: '0px',
  },
  carouselImage: {
    width: '100%',
    height: '290px',
  },
  buttonContainer: {
    margin: 'auto',
    height: 'auto',
  },
}))

interface CustomWindow extends Window {
  google?: any
}

export default function LandingPage() {
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  const appLogo = matches ? logo : mobileLogo
  const { t } = useTranslation()
  const matchesMd = useMediaQuery(theme.breakpoints.down('lg'))
  const navigate = useNavigate()
  const [openBathroomModal, setOpenBathroomModal] = useState<boolean>(false)
  const [currentLocation, setCurrentLocation] = useState<string>('')
  const handleRegisterClick = () => {
    navigate('/login')
  }

  const customWindow = window as CustomWindow

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const geocoder = new customWindow.google.maps.Geocoder()
          // Use Geocoding to get location name
          const latlng = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }
          geocoder.geocode(
            { location: latlng },
            (results: any, status: any) => {
              if (status === 'OK') {
                if (results[0]) {
                  setCurrentLocation(results[0].formatted_address)
                } else {
                  console.error('No results found')
                }
              } else {
                console.error('Geocoder failed due to: ' + status)
              }
            }
          )
        },
        (error) => {
          console.error(error.message)
        }
      )
    } else {
      console.error('Geolocation is not supported by this browser.')
    }
  }

  useEffect(() => {
    if (currentLocation === '') {
      getLocation()
    }
  }, [currentLocation])

  return (
    <>
      <CustomAppBar>
        <TsekmoLandingToolBar />
      </CustomAppBar>
      <Main className={classes.main}>
        <div className={classes.root}>
          <div className={classes.carouselHeading}>
            <Typography className="mainHeading">
              {'Tsekmo: Revolutionizing Restroom Access for Millions'}
            </Typography>
            <Typography className="description">
              {
                'Bridging the Gap in Public Restroom Accessibility for Incontinence Sufferers Worldwide'
              }
            </Typography>
          </div>
          <Grid
            container
            spacing={1}
            rowGap={3}
            className={classes.carouselTiles}
          >
            <Grid
              item
              classes={{
                item: classes.gridItem,
              }}
              className={classes.carouselImageContainer}
            >
              <img className={classes.carouselImages} src={slide1} />
              <div className={classes.btnDiv}>
                <CustomButton
                  classes={{
                    root: classes.btnStyle,
                  }}
                  variant="contained"
                  onClick={() =>
                    window.location.replace('https://www.tsekmo.com')
                  }
                >
                  Join us
                </CustomButton>
              </div>
            </Grid>

            {/* <Carousel>
                            {
                                items.map((item, i) => <Paper>
                                    <h2>{item.title}</h2>
                                    <img
                                        className={classes.carouselImage}
                                        src={slide1}
                                    />
                                </Paper>)
                            }
                        </Carousel> */}
            {/* <Grid
                            item
                            classes={{
                                item: classes.gridItem
                            }}
                            className={classes.buttonContainer}
                        >
                            <button>Join US!</button>
                        </Grid> */}
          </Grid>

          <div className={classes.secondRowHeading}>
            <Typography className="mainHeading">
              {
                'Share Tsekmo as a gift to your fellow business owners and keep your community safe'
              }
            </Typography>
            <Typography className="description">
              {
                'See below what features your and your fellow business owners can use'
              }
            </Typography>
          </div>

          <Grid container spacing={1} rowGap={3} className={classes.mainTiles}>
            <Grid
              item
              classes={{
                item: classes.gridItem,
              }}
              className={classes.secondImageContainer}
            >
              <img className={classes.secondRowImages} src={card1} />
              <Typography className="cardtitle">
                {'Register your business and bathroom users'}
              </Typography>
            </Grid>
            <Grid
              item
              classes={{
                item: classes.gridItem,
              }}
              className={classes.secondImageContainer}
            >
              <img className={classes.secondRowImages} src={card2} />
              <Typography className="cardtitle">
                {'Post your toilet by uploading photos in Tsekmo App'}
              </Typography>
            </Grid>
            <Grid
              item
              classes={{
                item: classes.gridItem,
              }}
              className={classes.secondImageContainer}
            >
              <img className={classes.secondRowImages} src={card3} />
              <Typography className="cardtitle">
                {'Monitor activities and incidents in your dashboard'}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            rowGap={3}
            className={classes.secondRowTiles}
          >
            <Grid
              item
              classes={{
                item: classes.gridItem,
              }}
              className={classes.secondImageContainer}
            >
              <img className={classes.secondRowImages} src={card4} />
              <Typography className="cardtitle">
                {
                  'Use incident report for reporting vandalism, destruction and more'
                }
              </Typography>
            </Grid>
            <Grid
              item
              classes={{
                item: classes.gridItem,
              }}
              className={classes.secondImageContainer}
            >
              <img className={classes.secondRowImages} src={card5} />
              <Typography className="cardtitle">
                {'Optional ID verification if troubles are rampant'}
              </Typography>
            </Grid>
          </Grid>
          {/* <Grid container spacing={1} rowGap={3} className={classes.servicesTiles}>

                        <Grid
                            item
                            classes={{
                                item: classes.gridItem
                            }}
                            className={classes.imageContainer}
                        >
                            <img
                                className={classes.optionsImages}
                                src={imageOptions4}
                            />
                            <Typography className="cardtitle">
                                {t('landingPage.optionsTitle4')}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            classes={{
                                item: classes.gridItem
                            }}
                            className={classes.imageContainer}
                        >
                            <img
                                className={classes.optionsImages}
                                src={imageOptions5}
                            />
                            <Typography className="cardtitle">
                                {t('landingPage.optionsTitle5')}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            classes={{
                                item: classes.gridItem
                            }}
                            className={classes.imageContainer}
                        >
                            <img
                                className={classes.optionsImages}
                                src={imageOptions6}
                            />
                            <Typography className="cardtitle">
                                {t('landingPage.optionsTitle6')}
                            </Typography>
                        </Grid>
                    </Grid> */}

          {/* <div className={classes.heading}>
                        <Typography className="mainHeading">
                            {t('landingPage.mainHeading1')}
                        </Typography>
                        <Typography className="description">
                            {t('landingPage.description1')}
                        </Typography>
                    </div>
                    <Grid container spacing={1} rowGap={3} className={classes.servicesTiles}>
                        <Grid
                            item
                            classes={{
                                item: classes.gridItem
                            }}
                            className={classes.imageContainer}
                        >
                            <img
                                className={classes.optionsImages}
                                src={imageOptions1}
                            />
                            <Typography className="cardtitle">
                                {t('landingPage.optionsTitle1')}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            classes={{
                                item: classes.gridItem
                            }}
                            className={classes.imageContainer}
                        >
                            <img
                                className={classes.optionsImages}
                                src={imageOptions2}
                            />
                            <Typography className="cardtitle">
                                {t('landingPage.optionsTitle2')}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            classes={{
                                item: classes.gridItem
                            }}
                            className={classes.imageContainer}
                        >
                            <img
                                className={classes.optionsImages}
                                src={imageOptions3}
                            />
                            <Typography className="cardtitle">
                                {t('landingPage.optionsTitle3')}
                            </Typography>
                        </Grid>
                    </Grid> */}

          {/* <div className={classes.heading}>
                        <Typography className="mainHeading">
                            {t('landingPage.mainHeading3')}
                        </Typography>
                        <Typography className="description">
                            {t('landingPage.description3')}
                        </Typography>
                    </div>
                    <Grid container spacing={1} rowGap={3} className={classes.servicesTiles}>
                        <Grid
                            item
                            classes={{
                                item: classes.gridItem
                            }}
                            className={classes.imageContainer}
                        >
                            <img
                                className={classes.optionsImages}
                                src={imageOptions7}
                            />
                            <Typography className="cardtitle">
                                {t('landingPage.optionsTitle7')}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            classes={{
                                item: classes.gridItem
                            }}
                            className={classes.imageContainer}
                        >
                            <img
                                className={classes.optionsImages}
                                src={imageOptions8}
                            />
                            <Typography className="cardtitle">
                                {t('landingPage.optionsTitle8')}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            classes={{
                                item: classes.gridItem
                            }}
                            className={classes.imageContainer}
                        >
                            <img
                                className={classes.optionsImages}
                                src={imageOptions9}
                            />
                            <Typography className="cardtitle">
                                {t('landingPage.optionsTitle9')}
                            </Typography>
                        </Grid>
                    </Grid>  */}

          <Grid
            container
            className={classes.tsekmomobilesection}
            spacing={3}
            sx={{
              flexDirection: { md: 'column', lg: 'row' },
              alignItems: { md: 'center', lg: 'flex-start' },
              justifyContent: {
                xs: 'center',
                sm: 'center',
                md: 'center',
                lg: 'flex-start',
              },
            }}
          >
            <Grid
              style={{
                height: matchesMd ? '350px' : 'unset',
              }}
              item
              sm={12}
              md={5}
              className={classes.heading}
            >
              <Typography className="mainHeading">
                {t('landingPage.mainHeading4')}
              </Typography>
              <Typography className="description">
                {t('landingPage.description4')}
              </Typography>
            </Grid>

            <Grid
              style={{
                height: matchesMd ? '155px' : 'unset',
                width: matchesMd ? '180px' : 'unset',
              }}
              position="relative"
              item
              // sm={12}
              md={4}
            >
              <img
                className={classes.tsekmomobileimage}
                src={matchesMd ? MobileViewTsekmoMobile : TsekmoMobile}
              />
            </Grid>
          </Grid>
        </div>
      </Main>
      {/* <div className={`${classes.topfooterStyle}`}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className={classes.topfootercontainer}>
                    <Grid item md={4} xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                            src={appLogo}
                            alt={'Logo'}
                        />
                    </Grid>
                    <Grid item md={8} xs={6}>

                    </Grid>
                    <Grid item md={4} xs={6} >
                        Protect your bathroom from vandalism and earn money.
                    </Grid>
                    <Grid item md={8} xs={6}>

                    </Grid>
                </Grid>
            </div> */}
      <FloatingButton
        text="Report a bathroom"
        onClick={() => setOpenBathroomModal(true)}
      />
      <Footer showTwoLevelFooter={true} />
      <Dialog
        open={openBathroomModal}
        onClose={() => setOpenBathroomModal(false)}
        maxWidth="xl"
      >
        <AddIncidentForm
          isReportABathroom={true}
          currentLocation={currentLocation}
          closeDialog={() => setOpenBathroomModal(false)}
        />
      </Dialog>
    </>
  )
}

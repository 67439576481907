import React from 'react'
import { CustomAppBar } from "../../theme/components/app-layout/header/AppBar";
import TsekmoLandingToolBar from "../components/ToolBar";
import Footer from "../../theme/components/app-layout/footer/Footer";
import { Main } from "../../theme/components/app-layout/main/Main";
import { makeStyles } from '@mui/styles';
import { Theme ,  Grid } from '@mui/material';
import UIConstants from '../../theme/constants/ui-constants';
import { useEffect } from 'react';

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    background: UIConstants.rootBgColor,
    height: `100%`,
    width: '100%',
    paddingBottom: `calc(${UIConstants.primaryFooterHeight} + ${theme.spacing(
      0
    )})`
  },
  content: {
    margin:  '2%'
  },
  optionsImages: {
    width: '350px',
    height: '244px',
    borderBottomRightRadius: '40px',
    borderBottomLeftRadius: '40px'
  },
  addExtraPadding: {
    paddingBottom: '70px'
  }
}));


const PrivacyPolicy = (props: any) => {
  const classes = useStyle();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <div className={classes.root}>
      {!props.isLogedIn && (
        <CustomAppBar>
          <TsekmoLandingToolBar />
        </CustomAppBar>
      )}

      {props.isLogedIn ? (
        <div style={{ minHeight: '50vh'}}>
          
          Tsekmo (“we” or “us”) is committed to protecting and respecting your privacy. This policy (together with our main Terms of Use (the “Terms”) and any other documents referred to in it) sets out the basis on which any personal information (as defined in Vermont`s Data Privacy Regime and California`s Consumer Privacy Act (CCPA)) (the “Personal Information”) we collect from corporate account holders or individual users or visitors to our website, or that is uploaded to our website, will be processed by us. Account holders, users and visitors of our website or owners of Personal Information collected by us (each, “you”) should read the following carefully to understand our views and practices regarding your Personal Information and how we will treat it.

 

By providing any Personal Information to us, you consent to the collection, use, disclosure and transfer of such Personal Information in the manner and for the purposes set out below.

 

<br /><br /><b>Information we may collect<br /></b>

We may collect and process the following data which may contain Personal Information:

<br />

a)      information that you provide by filling in forms on the tsekmo.co website (“our website”), including information provided at the time of registering to use our website, subscribing to any services provided by us, posting material, reporting a problem with our website, or requesting further services;

<br />

b)     information, data, documents or images that you upload onto our website;

<br />

c)      details of transactions you carry out through our website;

<br />

d)     details of your visits to our website, resources that you access and actions you are working on through the website;

<br />

e)      if you contact us, a record of that correspondence; and
<br />
 

f)      responses to surveys that we send to you, although you do not have to respond to them.

 

<br /><br /><b>IP Addresses<br /></b>

We may also collect and process information about your device, including where available your IP address, operating system and browser type, for system administration and to report aggregate information to our business partners. This is statistical data about our users’ browsing actions and patterns and does not identify any individual.

 

<br /><br /><b>Cookies<br /></b>

Please refer to our Cookie Policy for more information on how we use cookies.

 

<br /><br /><b>Where we store your data<br /></b>

The Personal Information that we collect may be transferred to, and stored at, a destination outside the USA. It may also be processed by staff operating outside the USA who work for us or for one of our suppliers. Such staff maybe engaged in, among other things, the fulfilment of your services ordered by you, the processing of your payment details and the provision of support services. By submitting any Personal Information to us, you agree to this transfer, storing or processing. We will take all steps reasonably necessary to ensure that your Personal Information is treated securely and in accordance with this privacy policy.

 

All information you provide to us is stored on our secure servers. Where we have given you (or where you have chosen) a password which enables you to access certain parts of our website, you are responsible for keeping this password confidential. We ask you not to share the password with anyone.

 

Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your Personal Information, we cannot guarantee the security of your Personal Information transmitted to our website; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorized access.

 

<br /><br /><b>Uses made of the information<br /></b>

We use information held, including Personal Information, in the following manner:
<br />
 

a)      to ensure that content from our website is presented in the most effective manner for you and for your device;
<br />
 

b)     to provide you with information, products or services that you request from us, and to otherwise carry out our obligations arising from any contracts entered into between you and us;

<br />

c)      to provide you with information, products or services which we feel may interest you, where you have consented to be contacted for such purposes;

 
<br />
d)     to allow you to participate in interactive features of our service, when you choose to do so;

<br />

e)      to notify you about changes to our services;

<br />

f)      to investigate any complaints relating to the use of our website or any suspected unlawful activities;

<br />

g)     complying with any applicable laws, regulations, codes of practice, guidelines, or rules, or to assist in law enforcement and investigations conducted by any governmental and/or regulatory authority;

<br />

h)     any other purposes for which you have provided the information; and

<br />

i)       carrying out whatever else is reasonable or related to or in connection with the above and our provision of services to you.

 

<br /><br /><b>Disclosure of your information<br /></b>

We may disclose your Personal Information to any member of our group, which means any corporation deemed to be related to us.

 

We may disclose your Personal Information to third parties:
<br />
 

a)      for the purposes of providing products or services that you request from us, fulfilling our obligations arising from any contracts entered into between you and us, processing payments in connection therewith or otherwise in connection with your use of our website;
<br />
 

b)     where a third party claims that any content posted or uploaded by you to our website constitutes a violation of their rights under Singapore law, in which case we may disclose your identity to that third party;
<br />
 

c)      in the event that we sell or buy any business or assets, in which case we may disclose your Personal Information to the prospective seller or buyer of such business or assets; or

<br />

d)     if we or substantially all of our shares or assets are acquired by a third party, in which case Personal Information held by us about our customers will be one of the transferred assets.

<br />

We may also disclose your Personal Information to a governmental or regulatory body, law enforcement, or other authorities, in order to enforce our terms of use for the website, to cooperate with any direction, request or order from such parties or to report any suspected unlawful activity.

 

<br /><br /><b>Consent<br /></b>

By providing your Personal Information to us, you consent to the collection, use and disclosure of your Data by us for the purposes set out in this privacy policy (“Purposes”).

 

Where any Personal Information relates to a third party, you represent and warrant that the Personal Information is up-to-date, complete, and accurate and that you have obtained the third party’s prior consent for our collection, use and disclosure of their Personal Information for the Purposes. You agree that you shall promptly provide us with written evidence of such consent upon demand by us.

 

Each use of our services by you shall constitute a fresh agreement for us to collect, use and disclose the Personal Information in accordance with this privacy policy.

 

You may withdraw your consent and request us to stop using and/or disclosing your Personal Information for any or all of the Purposes by submitting your request to us in writing. Should you withdraw your consent to the collection, use or disclosure of your Personal Information, it may impact our ability to proceed with your transactions, agreements or interactions with us. Prior to you exercising your choice to withdraw your consent, we will inform you of the consequences of the withdrawal of your consent. Please note that your withdrawal of consent will not prevent us from exercising our legal rights (including any remedies) or undertaking any steps as we may be entitled to at law.

 

<br /><br /><b>Your Rights<br /></b>

As a Vermont or California Resident or Citizen, you may have the right to request, twice in a 12-month period, the following information about the personal information we have collected about you during the past 12 months:

 <br />

·        the categories and specific pieces of personal information we have collected about you; <br />

 

·        the categories of sources from which we collected the personal information; <br />

 

·        the business or commercial purpose for which we collected or sold the personal information; <br />

 

·        the categories of third parties with whom we shared the personal information; and <br />

 

·        the categories of personal information about you that we sold or disclosed for a business purpose, and the categories of third parties to whom we sold or disclosed that information for a business purpose.

 <br />

You also may have the right to request that we provide you with (1) a list of certain categories of personal information we have disclosed to third parties for their direct marketing purposes during the immediately preceding calendar year and (2) the identity of those third parties. In addition, you have the right to request that we delete the personal information we have collected from you.

 

You have the right to opt out of the sale of your information. To opt out, please contact us.

 

To help protect your privacy and maintain security, we take steps to verify your identity before granting you access to your personal information or complying with your request. To the extent permitted by applicable law, we may charge a reasonable fee to comply with your request.

 

We will not deny, charge different prices for, or provide a different level or quality of goods or services if you choose to exercise these rights. 

 

<br /><br /><b>GDPR Data Subject Rights<br /></b>

The following rights arise from the GDPR for you as a Citizen of the European Union:<br />

·        Pursuant to Art. 15 GDPR, you may request information about your personal data processed by us. In particular, you can request information about the processing purposes, the categories of personal data, the categories of recipients to whom your data have been or will be disclosed, the planned storage period, the existence of a right to rectification, erasure, restriction of processing or objection, the existence of a right of complaint, the origin of your data if it has not been collected by us, about a transfer to third countries or to international organizations, and about the existence of automated decision-making, including profiling, and, if applicable, meaningful information about its details.

 

·        Pursuant to Art. 16 GDPR, you can immediately request the correction of inaccurate or the completion of your personal data stored by us.

 

·        Pursuant to Art. 17 GDPR, you may request the erasure of your personal data stored by us, unless the processing is necessary for the exercise of the right to freedom of expression and information, for compliance with a legal obligation, for reasons of public interest or for the establishment, exercise or defense of legal claims.

 

·        Pursuant to Art. 18 GDPR, you may request the restriction of the processing of your personal data if you dispute the accuracy of the data, the processing is unlawful, we no longer need the data and you object to their erasure because you need them for the assertion, exercise or defense of legal claims. You also have the right under Article 18 of the GDPR if you have objected to the processing in accordance with Article 21 of the GDPR.

 

·        Pursuant to Art. 20 GDPR, you may request to receive your personal data that you have provided to us in a structured, commonly used and machine-readable format or you may request that it be transferred to another controller.

 

·        Pursuant to Art. 7 (3) GDPR, you may revoke your consent once given to us at any time. This has the consequence that we may no longer continue the data processing based on this consent for the future.

 

·        In accordance with Art. 77 GDPR, you have the right to complain to a supervisory authority. As a rule, you can contact the supervisory authority of your usual place of residence, your place of work or our company headquarters for this purpose.

 

·        Right of objection. When your personal data is processed on the basis of legitimate interests pursuant to Art. 6 (1) sentence 1 lit. f GDPR, you have the right to object to the processing of your personal data pursuant to Art. 21 GDPR, insofar as there are grounds for doing so that arise from your particular situation or the objection is directed against direct advertising. In the case of direct advertising, you have a general right of objection, which is implemented by us without specifying a particular situation.

 

<br /><br /><b>Accuracy<br /></b>

We endeavor to ensure that all decisions involving your Personal Information are based upon accurate and timely information. However, we rely on you to disclose all relevant information to us and to inform us of any changes in your Personal Information. As such, please disclose all relevant information necessary for us to provide services to you and ensure all information submitted to us is up-to-date, complete, and accurate. Kindly inform us promptly if there are any changes in your Personal Information.

 

<br /><br /><b>Retention<br /></b>

We may retain your Personal Information for as long as may be necessary to fulfil the purpose for which it was collected, or as required or permitted by applicable laws. We will cease to retain your Personal Information or remove the means by which the data can be associated with you, as soon as it is reasonable to assume that such retention no longer serves the purpose for which the Personal Information was collected and is no longer necessary for legal or business purposes.

 

<br /><br /><b>Third Party Policies<br /></b>

Our website may, from time to time, contain links to and from the websites of our partner networks, business partners and affiliates. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any Data to these websites.

 

<br /><br /><b>Data Intermediary<br /></b>

Where we process your Personal Information as a data intermediary on behalf of a third party, we will process your Personal Information in accordance with the instructions of the third party and shall use it only for the purposes agreed between you and the third party. All such Personal Information will be protected and retained in accordance with this privacy policy.

 

We will take steps to inform the third party of any requests, complaints, or questions that you may have regarding such Personal Information.

 

<br /><br /><b>Security<br /></b>

State-of-the-art internet technologies are used to ensure the security of your data. During the online enquiry process, your details are secured with SSL encryption. For secure storage of your data, the systems are protected by firewalls that prevent unauthorized access from outside. In addition, technical and organizational security measures are used to protect the Personal Information you have provided against accidental or intentional manipulation, loss, destruction or access by unauthorized persons.

 

<br /><br /><b>Data Breaches/Notification<br /></b>

Databases or data sets that include Personal Information may be breached inadvertently or through wrongful intrusion. Upon becoming aware of a data breach, Tsekmo will notify all affected individuals whose Personal Information may have been compromised, and the notice will be accompanied by a description of action being taken to reconcile any damage as a result of the data breach. Notices will be provided as expeditiously as possible after the breach was discovered.

 

<br /><br /><b>Confirmation of Confidentiality<br /></b>

All company employees must maintain the confidentiality of Personal Information as well as company proprietary data to which they may have access and understand that such Personal Information is to be restricted to only those with a business need to know. Employees with ongoing access to such data will sign acknowledgment reminders annually attesting to their understanding of this company requirement.

 

<br /><br /><b>Existence of automated decision-making<br /></b>

As a responsible company, we do not use automated decision-making or profiling.

 

<br /><br /><b>External Links<br /></b>

Our website contains links to the online services of other providers. We hereby point out that we have no influence on the content of the linked online services and the compliance with data protection regulations by their providers.

 

<br /><br /><b>Personal Information and children<br /></b>

Our services are aimed at people aged 18 and over. We will not knowingly collect, use or disclose Personal Information from minors under the age of 18 without first obtaining consent from a legal guardian through direct offline contact.

 

<br /><br /><b>Changes to our Privacy Policy<br /></b>

 

Any changes we may make to our privacy policy in the future will be posted on our website and, where appropriate, notified to you by e-mail.

 

<br /><br /><b>Contact<br /></b>

Questions, comments, and requests regarding this privacy policy are welcome and if you have any concerns about a possible compromise of your privacy or misuse of your personal information on our part, please contact us.<br /><br />
        </div>
        
      ) : (
        <Main>
          Tsekmo (“we” or “us”) is committed to protecting and respecting your privacy. This policy (together with our main Terms of Use (the “Terms”) and any other documents referred to in it) sets out the basis on which any personal information (as defined in Vermont`s Data Privacy Regime and California`s Consumer Privacy Act (CCPA)) (the “Personal Information”) we collect from corporate account holders or individual users or visitors to our website, or that is uploaded to our website, will be processed by us. Account holders, users and visitors of our website or owners of Personal Information collected by us (each, “you”) should read the following carefully to understand our views and practices regarding your Personal Information and how we will treat it.

 

By providing any Personal Information to us, you consent to the collection, use, disclosure and transfer of such Personal Information in the manner and for the purposes set out below.

 

<br /><br /><b>Information we may collect<br /></b>

We may collect and process the following data which may contain Personal Information:

<br />

a)      information that you provide by filling in forms on the tsekmo.co website (“our website”), including information provided at the time of registering to use our website, subscribing to any services provided by us, posting material, reporting a problem with our website, or requesting further services;

<br />

b)     information, data, documents or images that you upload onto our website;

<br />

c)      details of transactions you carry out through our website;

<br />

d)     details of your visits to our website, resources that you access and actions you are working on through the website;

<br />

e)      if you contact us, a record of that correspondence; and
<br />
 

f)      responses to surveys that we send to you, although you do not have to respond to them.

 

<br /><br /><b>IP Addresses<br /></b>

We may also collect and process information about your device, including where available your IP address, operating system and browser type, for system administration and to report aggregate information to our business partners. This is statistical data about our users’ browsing actions and patterns and does not identify any individual.

 

<br /><br /><b>Cookies<br /></b>

Please refer to our Cookie Policy for more information on how we use cookies.

 

<br /><br /><b>Where we store your data<br /></b>

The Personal Information that we collect may be transferred to, and stored at, a destination outside the USA. It may also be processed by staff operating outside the USA who work for us or for one of our suppliers. Such staff maybe engaged in, among other things, the fulfilment of your services ordered by you, the processing of your payment details and the provision of support services. By submitting any Personal Information to us, you agree to this transfer, storing or processing. We will take all steps reasonably necessary to ensure that your Personal Information is treated securely and in accordance with this privacy policy.

 

All information you provide to us is stored on our secure servers. Where we have given you (or where you have chosen) a password which enables you to access certain parts of our website, you are responsible for keeping this password confidential. We ask you not to share the password with anyone.

 

Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your Personal Information, we cannot guarantee the security of your Personal Information transmitted to our website; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorized access.

 

<br /><br /><b>Uses made of the information<br /></b>

We use information held, including Personal Information, in the following manner:
<br />
 

a)      to ensure that content from our website is presented in the most effective manner for you and for your device;
<br />
 

b)     to provide you with information, products or services that you request from us, and to otherwise carry out our obligations arising from any contracts entered into between you and us;

<br />

c)      to provide you with information, products or services which we feel may interest you, where you have consented to be contacted for such purposes;

 
<br />
d)     to allow you to participate in interactive features of our service, when you choose to do so;

<br />

e)      to notify you about changes to our services;

<br />

f)      to investigate any complaints relating to the use of our website or any suspected unlawful activities;

<br />

g)     complying with any applicable laws, regulations, codes of practice, guidelines, or rules, or to assist in law enforcement and investigations conducted by any governmental and/or regulatory authority;

<br />

h)     any other purposes for which you have provided the information; and

<br />

i)       carrying out whatever else is reasonable or related to or in connection with the above and our provision of services to you.

 

<br /><br /><b>Disclosure of your information<br /></b>

We may disclose your Personal Information to any member of our group, which means any corporation deemed to be related to us.

 

We may disclose your Personal Information to third parties:
<br />
 

a)      for the purposes of providing products or services that you request from us, fulfilling our obligations arising from any contracts entered into between you and us, processing payments in connection therewith or otherwise in connection with your use of our website;
<br />
 

b)     where a third party claims that any content posted or uploaded by you to our website constitutes a violation of their rights under Singapore law, in which case we may disclose your identity to that third party;
<br />
 

c)      in the event that we sell or buy any business or assets, in which case we may disclose your Personal Information to the prospective seller or buyer of such business or assets; or

<br />

d)     if we or substantially all of our shares or assets are acquired by a third party, in which case Personal Information held by us about our customers will be one of the transferred assets.

<br />

We may also disclose your Personal Information to a governmental or regulatory body, law enforcement, or other authorities, in order to enforce our terms of use for the website, to cooperate with any direction, request or order from such parties or to report any suspected unlawful activity.

 

<br /><br /><b>Consent<br /></b>

By providing your Personal Information to us, you consent to the collection, use and disclosure of your Data by us for the purposes set out in this privacy policy (“Purposes”).

 

Where any Personal Information relates to a third party, you represent and warrant that the Personal Information is up-to-date, complete, and accurate and that you have obtained the third party’s prior consent for our collection, use and disclosure of their Personal Information for the Purposes. You agree that you shall promptly provide us with written evidence of such consent upon demand by us.

 

Each use of our services by you shall constitute a fresh agreement for us to collect, use and disclose the Personal Information in accordance with this privacy policy.

 

You may withdraw your consent and request us to stop using and/or disclosing your Personal Information for any or all of the Purposes by submitting your request to us in writing. Should you withdraw your consent to the collection, use or disclosure of your Personal Information, it may impact our ability to proceed with your transactions, agreements or interactions with us. Prior to you exercising your choice to withdraw your consent, we will inform you of the consequences of the withdrawal of your consent. Please note that your withdrawal of consent will not prevent us from exercising our legal rights (including any remedies) or undertaking any steps as we may be entitled to at law.

 

<br /><br /><b>Your Rights<br /></b>

As a Vermont or California Resident or Citizen, you may have the right to request, twice in a 12-month period, the following information about the personal information we have collected about you during the past 12 months:

 <br />

·        the categories and specific pieces of personal information we have collected about you; <br />

 

·        the categories of sources from which we collected the personal information; <br />

 

·        the business or commercial purpose for which we collected or sold the personal information; <br />

 

·        the categories of third parties with whom we shared the personal information; and <br />

 

·        the categories of personal information about you that we sold or disclosed for a business purpose, and the categories of third parties to whom we sold or disclosed that information for a business purpose.

 <br />

You also may have the right to request that we provide you with (1) a list of certain categories of personal information we have disclosed to third parties for their direct marketing purposes during the immediately preceding calendar year and (2) the identity of those third parties. In addition, you have the right to request that we delete the personal information we have collected from you.

 

You have the right to opt out of the sale of your information. To opt out, please contact us.

 

To help protect your privacy and maintain security, we take steps to verify your identity before granting you access to your personal information or complying with your request. To the extent permitted by applicable law, we may charge a reasonable fee to comply with your request.

 

We will not deny, charge different prices for, or provide a different level or quality of goods or services if you choose to exercise these rights. 

 

<br /><br /><b>GDPR Data Subject Rights<br /></b>

The following rights arise from the GDPR for you as a Citizen of the European Union:<br />

·        Pursuant to Art. 15 GDPR, you may request information about your personal data processed by us. In particular, you can request information about the processing purposes, the categories of personal data, the categories of recipients to whom your data have been or will be disclosed, the planned storage period, the existence of a right to rectification, erasure, restriction of processing or objection, the existence of a right of complaint, the origin of your data if it has not been collected by us, about a transfer to third countries or to international organizations, and about the existence of automated decision-making, including profiling, and, if applicable, meaningful information about its details.

 

·        Pursuant to Art. 16 GDPR, you can immediately request the correction of inaccurate or the completion of your personal data stored by us.

 

·        Pursuant to Art. 17 GDPR, you may request the erasure of your personal data stored by us, unless the processing is necessary for the exercise of the right to freedom of expression and information, for compliance with a legal obligation, for reasons of public interest or for the establishment, exercise or defense of legal claims.

 

·        Pursuant to Art. 18 GDPR, you may request the restriction of the processing of your personal data if you dispute the accuracy of the data, the processing is unlawful, we no longer need the data and you object to their erasure because you need them for the assertion, exercise or defense of legal claims. You also have the right under Article 18 of the GDPR if you have objected to the processing in accordance with Article 21 of the GDPR.

 

·        Pursuant to Art. 20 GDPR, you may request to receive your personal data that you have provided to us in a structured, commonly used and machine-readable format or you may request that it be transferred to another controller.

 

·        Pursuant to Art. 7 (3) GDPR, you may revoke your consent once given to us at any time. This has the consequence that we may no longer continue the data processing based on this consent for the future.

 

·        In accordance with Art. 77 GDPR, you have the right to complain to a supervisory authority. As a rule, you can contact the supervisory authority of your usual place of residence, your place of work or our company headquarters for this purpose.

 

·        Right of objection. When your personal data is processed on the basis of legitimate interests pursuant to Art. 6 (1) sentence 1 lit. f GDPR, you have the right to object to the processing of your personal data pursuant to Art. 21 GDPR, insofar as there are grounds for doing so that arise from your particular situation or the objection is directed against direct advertising. In the case of direct advertising, you have a general right of objection, which is implemented by us without specifying a particular situation.

 

<br /><br /><b>Accuracy<br /></b>

We endeavor to ensure that all decisions involving your Personal Information are based upon accurate and timely information. However, we rely on you to disclose all relevant information to us and to inform us of any changes in your Personal Information. As such, please disclose all relevant information necessary for us to provide services to you and ensure all information submitted to us is up-to-date, complete, and accurate. Kindly inform us promptly if there are any changes in your Personal Information.

 

<br /><br /><b>Retention<br /></b>

We may retain your Personal Information for as long as may be necessary to fulfil the purpose for which it was collected, or as required or permitted by applicable laws. We will cease to retain your Personal Information or remove the means by which the data can be associated with you, as soon as it is reasonable to assume that such retention no longer serves the purpose for which the Personal Information was collected and is no longer necessary for legal or business purposes.

 

<br /><br /><b>Third Party Policies<br /></b>

Our website may, from time to time, contain links to and from the websites of our partner networks, business partners and affiliates. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any Data to these websites.

 

<br /><br /><b>Data Intermediary<br /></b>

Where we process your Personal Information as a data intermediary on behalf of a third party, we will process your Personal Information in accordance with the instructions of the third party and shall use it only for the purposes agreed between you and the third party. All such Personal Information will be protected and retained in accordance with this privacy policy.

 

We will take steps to inform the third party of any requests, complaints, or questions that you may have regarding such Personal Information.

 

<br /><br /><b>Security<br /></b>

State-of-the-art internet technologies are used to ensure the security of your data. During the online enquiry process, your details are secured with SSL encryption. For secure storage of your data, the systems are protected by firewalls that prevent unauthorized access from outside. In addition, technical and organizational security measures are used to protect the Personal Information you have provided against accidental or intentional manipulation, loss, destruction or access by unauthorized persons.

 

<br /><br /><b>Data Breaches/Notification<br /></b>

Databases or data sets that include Personal Information may be breached inadvertently or through wrongful intrusion. Upon becoming aware of a data breach, Tsekmo will notify all affected individuals whose Personal Information may have been compromised, and the notice will be accompanied by a description of action being taken to reconcile any damage as a result of the data breach. Notices will be provided as expeditiously as possible after the breach was discovered.

 

<br /><br /><b>Confirmation of Confidentiality<br /></b>

All company employees must maintain the confidentiality of Personal Information as well as company proprietary data to which they may have access and understand that such Personal Information is to be restricted to only those with a business need to know. Employees with ongoing access to such data will sign acknowledgment reminders annually attesting to their understanding of this company requirement.

 

<br /><br /><b>Existence of automated decision-making<br /></b>

As a responsible company, we do not use automated decision-making or profiling.

 

<br /><br /><b>External Links<br /></b>

Our website contains links to the online services of other providers. We hereby point out that we have no influence on the content of the linked online services and the compliance with data protection regulations by their providers.

 

<br /><br /><b>Personal Information and children<br /></b>

Our services are aimed at people aged 18 and over. We will not knowingly collect, use or disclose Personal Information from minors under the age of 18 without first obtaining consent from a legal guardian through direct offline contact.

 

<br /><br /><b>Changes to our Privacy Policy<br /></b>

 

Any changes we may make to our privacy policy in the future will be posted on our website and, where appropriate, notified to you by e-mail.

 

<br /><br /><b>Contact<br /></b>

Questions, comments, and requests regarding this privacy policy are welcome and if you have any concerns about a possible compromise of your privacy or misuse of your personal information on our part, please contact us.<br /><br />
     <br /><br />   
        </Main>
      )}

      {!props.isLogedIn && (
        <Footer showTwoLevelFooter={true} />
      )}
    </div>
  )
}

export default PrivacyPolicy
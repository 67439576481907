import React, { useEffect } from 'react';

interface ChatMessageProps {
  message: string;
}

const ChatMessage: React.FC<ChatMessageProps> = ({ message }) => {

  useEffect(() => {
    
  }, [])  

  return (
    <div className="message">
      {message}
    </div>
  );
};

export default ChatMessage;
import * as React from 'react';
import { Theme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import FavoriteIcon from '@mui/icons-material/Favorite';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import UIConstants from '../../../theme/constants/ui-constants';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { PriceTagIcon } from '../../../theme/components/icons/PriceTagIcon';
import { LocationIcon } from '../../../theme/components/icons/LocationIcon';
import { Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { StarIcon } from '../../../theme/components/icons/StarIcon';
import { ListingsModel } from '../../../store/app/listing/types';
import ImageCarouselList from '../../../theme/components/image-list/ImageCarousel';


const useStyles = makeStyles((theme: Theme) => ({
    header: {
        color: UIConstants.primaryBgColor,
        '& .MuiCardHeader-title': {
            fontSize: '1.25rem',
            fontWeight: 'bold',
        },
        '& .MuiCardHeader-subheader': {
            fontSize: '1rem',
        }
    },
    labels: {
        fontSize: '1rem',
        fontWeight: 'bold',
        color: UIConstants.primaryBgColor,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingLeft: theme.spacing(2)
    },
    headerTitleText: {
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    cardHeaderContect: {
        overflow: 'hidden'
    }
}));

interface AllListingItemProps {
    listingsData: ListingsModel;
    setFavourites: (isFavorites: boolean, listing_id: number) => void;
    onClick: (listing: ListingsModel) => void;
}

export default function AllListingItem({ listingsData, setFavourites, onClick }: AllListingItemProps) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const imageList = JSON.parse(listingsData.uploads);
    return (
        <Card style={{ boxShadow: UIConstants.cardBoxshadow, cursor: 'pointer' }} onClick={() => onClick(listingsData)}>
            <CardHeader
                classes={{
                    content: classes.cardHeaderContect
                }}
                className={classes.header}
                title={(
                    <Tooltip
                        title={listingsData.title}
                    >
                        <div
                            className={classes.headerTitleText}
                        >
                            {listingsData.title}
                        </div>
                    </Tooltip>
                )}
                subheader={(
                    <span style={{ color: UIConstants.primaryBgColor }}>
                        <StarIcon viewBox="0 0 16 16" style={{ width: 16, height: 16 }} />{listingsData.review_rating}
                        <span style={{ opacity: "0.5" }}>({listingsData.review_count})</span>
                    </span>
                )}
            />
            <CardMedia
                component="div"
                style={{ height: "100%" }}
                children={(<ImageCarouselList imageFileList={imageList} orientation="V" />)}
            />
            <CardContent sx={{ padding: theme.spacing(1) }}>
                <IconButton aria-label="location" disableRipple={true} style={{ width: "100%" }}>
                    <LocationIcon />
                    <span className={classes.labels}>{listingsData.location}</span>
                </IconButton>
            </CardContent>
            <CardActions sx={{ display: 'flex', justifyContent: "space-between" }}>
                <IconButton aria-label="price" disableRipple={true}>
                    <PriceTagIcon />
                    <span className={classes.labels}>{listingsData.free_use ? 'Free' : `${listingsData.price}`}</span>
                </IconButton>

                <IconButton aria-label="add to favorites" onClick={(e) => { e.stopPropagation(); setFavourites((!listingsData.is_favorite), listingsData.id) }}>
                    {listingsData.is_favorite ? <FavoriteIcon color="error" /> : <FavoriteBorderIcon color="error" />}
                </IconButton>
            </CardActions>
        </Card>
    );
}

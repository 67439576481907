import {
  Theme,
  useMediaQuery,
  useTheme,
  Dialog,
  Typography,
  Box,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import UIConstants from '../../../../../theme/constants/ui-constants'
import { CustomButton } from '../../../../../theme/components/buttons/Button'
import { useEffect, useState } from 'react'
import CleanLogTable from './CleanLogTable'
import { useDispatch, useSelector } from 'react-redux'
import {
  createCleanLog,
  fetchCleanlogStructure,
} from '../../../../../store/app/listing/actions'
import {
  BathroomData,
  CleaningData,
  RefillableData,
  ShowerData,
  ToiletData,
  vanitiesData,
} from './Data'
import { ApplicationState } from '../../../../../store'

const useStyles = makeStyles((theme: Theme) => ({
  dialogContainer: {
    padding: theme.spacing(3),
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
  },
  heading: {
    margin: theme.spacing(1),
    textAlign: 'center',
    padding: '20px',
    '& .subHeading': {
      fontSize: '14px',
      padding: '0 50px',
    },
    '& .mainHeading': {
      fontWeight: 600,
      fontSize: '18px',
      fontFamily: UIConstants.appFont,
      color: UIConstants.primaryBgColor,
      textAlign: 'center',
    },
  },
}))

interface LogItem {
  id: number
  disabled: boolean
  text: string
  checked: boolean
  broken?: boolean
  empty?: boolean
  comment: string
  logTime: Date | null
  category: string
}
interface CleanLogProps {
  saveCleanLog: (selectedItems: any) => void
}

function CleanLog({ saveCleanLog }: CleanLogProps) {
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { userData } = useSelector((state: ApplicationState) => state.user)
  const { cleanLogStructure } = useSelector(
    (state: ApplicationState) => state.listing
  )
  const [openAddCleanLog, setOpenAddCleanLog] = useState<boolean>(false)
  const handleCloseCleanLog = () => {
    setOpenAddCleanLog(false)
  }
  const listing = {
    user_id: userData?.id || 0,
    listing_id: userData?.id || 0,
  }
  const [bathroom, setBathroom] = useState<LogItem[]>(BathroomData)
  const [toilet, setToilet] = useState<LogItem[]>(ToiletData)
  const [vanities, setVanities] = useState<LogItem[]>(vanitiesData)
  const [shower, setShower] = useState<LogItem[]>(ShowerData)
  const [cleaning, setCleaning] = useState<LogItem[]>(CleaningData)
  const [refillables, setRefillables] = useState<LogItem[]>(RefillableData)
  const [selectAllBathroom, setSelectAllBathroom] = useState<boolean>(false)
  const [selectAllToilet, setSelectAllToilet] = useState<boolean>(false)
  const [selectAllVanities, setSelectAllVanities] = useState<boolean>(false)
  const [selectAllShower, setSelectAllShower] = useState<boolean>(false)
  const [selectAllCleaning, setSelectAllCleaning] = useState<boolean>(false)

  const submitCleanLog = async () => {
    // const selectedBathroomItems = bathroom.filter((item) => item.checked);
    // const selectedToiletItems = toilet.filter((item) => item.checked);
    // const selectedVanitiesItems = vanities.filter((item) => item.checked);
    // const selectedShowerItems = shower.filter((item) => item.checked);
    // const selectedCleaningItems = cleaning.filter((item) => item.checked);

    const selectedItems = [
      { name: 'Refillables', items: refillables },
      { name: 'Vanities', items: vanities },
      { name: 'Bathroom', items: bathroom },
      { name: 'Toilet', items: toilet },
      { name: 'Shower', items: shower },
      { name: 'Cleaning', items: cleaning },
    ]

    saveCleanLog(JSON.stringify(selectedItems))

    // dispatch(
    //   createCleanLog({
    //     user_id: listing?.user_id || 0,
    //     listing_id: listing?.listing_id || 0,
    //     logged_at: new Date(),
    //     items: JSON.stringify(selectedItems),
    //   })
    // );
    // Save(selectedItems);
    handleCloseCleanLog()
  }

  const handleOpenCleanLog = () => {
    setOpenAddCleanLog(true)
  }

  useEffect(() => {
    if (userData.id) {
      dispatch(fetchCleanlogStructure(userData.id))
    }
  }, [userData])

  useEffect(() => {
    if (cleanLogStructure && cleanLogStructure.length > 0) {
      setBathroom(
        cleanLogStructure.find((category: any) => category.name === 'Bathroom')
          .items
      )
      setToilet(
        cleanLogStructure.find((category: any) => category.name === 'Toilet')
          .items
      )
      setVanities(
        cleanLogStructure.find((category: any) => category.name === 'Vanities')
          .items
      )
      setShower(
        cleanLogStructure.find((category: any) => category.name === 'Shower')
          .items
      )
      setCleaning(
        cleanLogStructure.find((category: any) => category.name === 'Cleaning')
          .items
      )
      setRefillables(
        cleanLogStructure.find(
          (category: any) => category.name === 'Refillables'
        ).items
      )
    }
  }, [cleanLogStructure])

  return (
    <>
      <CustomButton
        type="button"
        variant="contained"
        fullWidth
        onClick={handleOpenCleanLog}
      >
        {'Clean Log by Tsekmo'}
      </CustomButton>
      <Dialog
        fullScreen={isMobile}
        open={openAddCleanLog}
        onClose={handleCloseCleanLog}
        aria-labelledby="responsive-dialog-title"
        maxWidth={isMobile ? 'xs' : 'md'}
      >
        <div className={classes.heading}>
          <Typography className="mainHeading">{'Add cleaning log'}</Typography>
          <Typography className="subHeading">
            {
              'Checked items mean the last time and date that your bathroom has been seen clean to monitor cleanliness.'
            }
          </Typography>
        </div>
        <div className={classes.dialogContainer}>
          <CleanLogTable
            bathroom={bathroom}
            toilet={toilet}
            vanities={vanities}
            shower={shower}
            cleaning={cleaning}
            refillables={refillables}
            setBathroom={setBathroom}
            setToilet={setToilet}
            setVanities={setVanities}
            setShower={setShower}
            setCleaning={setCleaning}
            setRefillables={setRefillables}
            selectAllBathroom={selectAllBathroom}
            selectAllToilet={selectAllToilet}
            selectAllVanities={selectAllVanities}
            selectAllShower={selectAllShower}
            selectAllCleaning={selectAllCleaning}
            setSelectAllBathroom={setSelectAllBathroom}
            setSelectAllToilet={setSelectAllToilet}
            setSelectAllVanities={setSelectAllVanities}
            setSelectAllShower={setSelectAllShower}
            setSelectAllCleaning={setSelectAllCleaning}
          />
          <Box className={classes.actionContainer}>
            <CustomButton
              type="button"
              variant="contained"
              fullWidth
              onClick={() => {
                submitCleanLog()
              }}
            >
              {'Save Clean Log'}
            </CustomButton>
          </Box>
        </div>
      </Dialog>
    </>
  )
}

export default CleanLog

import React from 'react'
import {
  TableHead,
  TableCell,
  TableRow,
  Theme,
  Table,
  TableBody,
  Avatar,
  MenuItem,
  Menu,
  Button,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import UIConstants from '../../../../theme/constants/ui-constants'
import { popoverClasses } from '@mui/material/Popover'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { RedFlag } from '../../../../theme/components/icons/Flags'

const useStyle = makeStyles((theme: Theme) => ({
  header: {
    '&.MuiTableCell-root': {
      color: UIConstants.mainText,
      paddingBottom: '10px',
    },
  },
  body: {
    '&.MuiTableRow-root': {
      color: UIConstants.mainText,
      backgroundColor: 'white',
    },
    '& .MuiTableRow-root': {
      borderRadius: '100px',
    },
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  table: {
    '&.MuiTable-root': {
      borderCollapse: 'separate',
      borderSpacing: '0 10px',
    },
  },
}))

function BillList() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const classes = useStyle()
  const headers = [
    {
      id: 'avatar',
      label: '',
      width: '5%',
    },
    {
      id: 'time',
      label: 'Time',
      width: '10%',
    },
    {
      id: 'name',
      label: 'Name',
      width: '20%',
    },
    {
      id: 'message',
      label: 'Message',
      width: '55%',
    },
    {
      id: 'status',
      label: 'status',
      width: '5%',
    },
    {
      id: 'actions',
      label: 'Actions',
      width: '5%',
    },
  ]

  const testData = [
    {
      id: 1,
      name: 'Lucian Casey',
      time: '4:19 PM',
      message: 'velit in',
      status: <RedFlag />,
    },
    {
      id: 2,
      name: 'Helen Lowe',
      time: '11:57 AM',
      message: 'lobortis risus.',
      status: <RedFlag />,
    },
    {
      id: 3,
      name: 'Maryam Park',
      time: '1:28 AM',
      message: 'volutpat nunc sit amet',
      status: <RedFlag />,
    },
    {
      id: 4,
      name: 'Jermaine Caldwell',
      time: '7:30 AM',
      message: 'senectus',
      status: <RedFlag />,
    },
    {
      id: 5,
      name: 'Vincent Sims',
      time: '1:38 PM',
      message: 'elementum purus, accumsan',
      status: <RedFlag />,
    },
  ]
  return (
    <div className="w-full h-full pt-5">
      <div className="flex flex-col gap-4 w-full h-full">
        <div className="p-5">
          <p className="text-xl font-bold text-mainText">Billed Customers</p>
        </div>
        <div className="p-10 px-14 w-full h-full bg-foreground">
          <Table className={classes.table}>
            <TableHead className={classes.header}>
              {headers.map((header) => (
                <TableCell
                  width={header.width}
                  sx={{ padding: '0 50px' }}
                  key={header.id}
                  padding="none"
                  align={header.id === 'actions' ? 'right' : 'left'}>
                  {header.label}
                </TableCell>
              ))}
            </TableHead>
            <TableBody>
              {testData.map((data) => (
                <TableRow className={classes.body} key={data.id}>
                  <TableCell>
                    <Avatar sx={{ width: '30px', height: '30px' }} />
                  </TableCell>
                  <TableCell>{data.time}</TableCell>
                  <TableCell>{data.name}</TableCell>
                  <TableCell>{data.message}</TableCell>
                  <TableCell align="center">{data.status}</TableCell>
                  <TableCell align="center">
                    <div>
                      <button onClick={handleClick}>
                        <MoreVertIcon />
                      </button>
                      <Menu
                        id="user-actions-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        sx={{
                          zIndex: 0,
                          [`& .${popoverClasses.paper}`]: {
                            boxShadow: 'none',
                          },
                        }}>
                        <MenuItem onClick={() => console.log(data.id)}>
                          Bill Now
                        </MenuItem>
                        <MenuItem onClick={handleClose}>Resolve</MenuItem>
                        <MenuItem onClick={handleClose}>Block</MenuItem>
                      </Menu>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  )
}

export default BillList

import {
  Stack,
  MenuItem,
  Select,
  InputLabel,
  TextField,
  FormControl,
  IconButton,
  Theme,
  OutlinedInput,
  FormControlLabel,
  InputAdornment,
  CircularProgress,
} from '@mui/material'
import SelectInput from '@mui/material/Select/SelectInput'
import { InputText } from '../../../../theme/components/input/TextField'
import { Controller, useForm } from 'react-hook-form'
import React, {
  Dispatch,
  SetStateAction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import UIConstants from '../../../../theme/constants/ui-constants'
import LocationSelector from '../../../../theme/components/input/LocationSelector'
import { makeStyles } from '@mui/styles'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import MobileTimePicker from '@mui/lab/MobileTimePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { ClockIcon } from '../../../../theme/components/icons/ClockIcon'
import { IncrementIcon } from '../../../../theme/components/icons/IncrementIcon'
import UploadImageList from '../../../../theme/components/image-list/UploadImageList'
import { Toggle } from '../../../../theme/components/input/Toggle'
import { CustomButton } from '../../../../theme/components/buttons/Button'
import DropUpload from '../../DropUpload'
import {
  addOrEditTsekmoCommunityPostData,
  fetchListingData,
  fetchTsekmoCommunityPosts,
  reportBathroom,
} from '../../../../store/app/community/actions'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../../store'
import { useGetUserType } from '../../../composables'
import { ListingDataModel } from '../../../../store/app/community/types'
import _ from 'lodash'
import { UserType } from '../../../../store/app/user/types'
import { isJson } from '../../../../app/utils/common-utils'
import { fetchListingTypes } from '../../../../store/app/listing/actions'
import { ListingType } from '../../../../store/app/listing/types'
import CleanLogChecker from '../../Listing/components/CleanLogChecker'
import {
  BathroomData,
  CleaningData,
  RefillableData,
  ShowerData,
  ToiletData,
  vanitiesData,
} from '../../../business-owner/Listing/components/CleanLog/Data'
import { PhoneInput } from '../../../../theme/components/input/PhoneField'

interface IFormInput {
  incident_type: string
  listing_id: number
  description: string
  date: Date
  tagged_users: string
  image_list: string
  quick_estimate: number
  professional_estimate: number
  rules: string
  is_private: boolean
  can_share: boolean
  owner_id: number
  post_date: Date
  is_active: boolean
  location: string
  tag_customer: boolean
  file_list: any
  listing_type: any
  store_name: string
  store_address: any
  store_email: any
  store_contact_number: any
  anonymous: boolean
}

interface IncidentFormProps {
  onFinishAdd?: () => void
  closeDialog?: () => void
  isEdit?: boolean
  postData?: any
  isFromCustomerPayment?: boolean
  customerPaymentListingData?: any
  disabled?: boolean
  disableSubmitBtn?: boolean
  cleanLogData?: any[]
  setCleanLogData?: Dispatch<SetStateAction<any[]>>
  isReportABathroom?: boolean
  currentLocation?: string
}

const defaultCleanLogStructure = [
  { name: 'Refillables', items: RefillableData },
  { name: 'Vanities', items: vanitiesData },
  { name: 'Bathroom', items: BathroomData },
  { name: 'Toilet', items: ToiletData },
  { name: 'Shower', items: ShowerData },
  { name: 'Cleaning', items: CleaningData },
]

const useStyle = makeStyles((theme: Theme) => ({
  inputBorderPrimary: {
    '&.MuiInputBase-root': {
      '& > fieldset': {
        borderColor: UIConstants.primaryBgColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: UIConstants.primaryBgColor,
      },
    },
  },
  textInput: {
    '& label': {
      color: UIConstants.primaryBgColor,
    },

    '& label.Mui-focused': {
      color: UIConstants.primaryBgColor,
    },

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: UIConstants.primaryBgColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: UIConstants.primaryBgColor,
      },
    },
  },
  icon: {
    fill: UIConstants.primaryBgColor,
  },
  label: {
    color: UIConstants.primaryBgColor,
    padding: `${theme.spacing(2)} 0 0 0`,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  toggleBtn: {
    color: UIConstants.primaryBgColor,
    margin: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

const AddIncidentForm = forwardRef(
  (
    {
      onFinishAdd,
      closeDialog,
      isEdit,
      postData,
      isFromCustomerPayment = false,
      customerPaymentListingData,
      disabled = false,
      disableSubmitBtn,
      cleanLogData,
      setCleanLogData,
      isReportABathroom = false,
      currentLocation,
    }: IncidentFormProps,
    ref
  ) => {
    const classes = useStyle()
    const dispatch = useDispatch()
    const incidentReportForm = useForm<IFormInput>({ mode: 'all' })
    const currentUserType = useGetUserType()
    const [submittingForm, setSubmittingForm] = useState<boolean>(false)
    const [incidentCleanLogData, setIncidentCleanLogData] = useState<any>()
    const { loading } = useSelector(
      (state: ApplicationState) => state.tsekmoCommunity
    )
    const { userData } = useSelector((state: ApplicationState) => state.user)
    const { listingData } = useSelector(
      (state: ApplicationState) => state.businessCommunity
    )
    const [currentFileList, setCurrentFileList] = useState<any>([])
    const [remoteFileList, setRemoteFileList] = useState<any>([])
    const { listingTypes } = useSelector(
      (state: ApplicationState) => state.listing
    )
    const countries = process.env.REACT_APP_SUPPORTED_COUNTRIES?.split(',')

    const incidentTypeList: string[] = [
      'Vandalism',
      'Drugs Related',
      'Uncleanliness',
      'Overtime Usage',
      'Others',
    ]
    useImperativeHandle(ref, () => ({
      checkIncidentFormValid: () => {
        incidentReportForm.trigger()
        const currentData = incidentReportForm.getValues()
        console.log(currentData)
        if (
          currentData.incident_type === '' ||
          currentData.description === '' ||
          currentData.file_list === null
        ) {
          return false
        } else {
          return true
        }
      },
      getIncidentForm: () => {
        return incidentReportForm
      },
    }))

    const handleDeleteImage = (index: any) => {
      let fileList = currentFileList
      if (remoteFileList.length > 0) {
        fileList = remoteFileList
      }

      // TODO: FIX IMAGE NOT GETTING DELETED FROM CLEAN LOG

      const fileListArray = Array.from(fileList)
      const finalList = [
        ...fileListArray.slice(0, index),
        ...fileListArray.slice(index + 1),
      ]

      setCurrentFileList(finalList)

      // let currentCleanLogData = cleanLogData
      //   ? cleanLogData
      //   : incidentCleanLogData

      // let updatedCleanLogData = currentCleanLogData.forEach((category: any) => {
      //   category.items.forEach((item: any) => {
      //     if (item.imageList !== undefined && item?.imageList.length > 0) {
      //       const imageList = Array.from(item.imageList).filter(
      //         (image: any) => image !== fileList[index]
      //       )
      //       item.imageList = imageList
      //     }
      //   })
      // })

      // if (setCleanLogData) {
      //   setCleanLogData(updatedCleanLogData)
      // } else {
      //   setIncidentCleanLogData(updatedCleanLogData)
      // }
    }

    const updateFileList = (fileList: any) => {
      setCurrentFileList(fileList)
    }

    const addPhoto = (event: any) => {
      if (currentFileList === null) {
        setCurrentFileList(event.target.files)
      } else {
        setCurrentFileList([
          ...currentFileList,
          ...Array.from(event.target.files),
        ])
      }
    }

    useEffect(() => {
      if (cleanLogData) {
        //get count of all images inside cleanLogData
        const imageCount = cleanLogData.reduce(
          (acc: number, category: any) =>
            acc +
            category.items.reduce(
              (acc: number, item: any) =>
                acc + (item.imageList ? item.imageList.length : 0),
              0
            ),
          0
        )

        //compare current count based on currentFileList
        if (imageCount !== currentFileList.length) {
          const imageList: any[] = []
          cleanLogData.forEach((category: any) => {
            category.items.forEach((item: any) => {
              if (item.imageList !== undefined && item?.imageList.length > 0) {
                imageList.push(...item.imageList)
              }
            })
          })

          setCurrentFileList(imageList)
        }
      } else if (incidentCleanLogData && incidentCleanLogData?.length > 0) {
        //get count of all images inside incidentCleanLogData
        const imageCount = incidentCleanLogData.reduce(
          (acc: number, category: any) =>
            acc +
            category.items.reduce(
              (acc: number, item: any) =>
                acc + (item.imageList ? item.imageList.length : 0),
              0
            ),
          0
        )

        //compare current count based on currentFileList
        if (imageCount !== currentFileList.length) {
          const imageList: any[] = []
          incidentCleanLogData.forEach((category: any) => {
            category.items.forEach((item: any) => {
              if (item.imageList !== undefined && item?.imageList.length > 0) {
                imageList.push(...item.imageList)
              }
            })
          })

          setCurrentFileList(imageList)
        }
      }
    }, [cleanLogData, incidentCleanLogData])

    const handleSubmit = () => {
      setSubmittingForm(true)
      const dt = incidentReportForm.getValues()
      const form_data = new FormData()
      if (isReportABathroom) {
        form_data.append('store_name', dt.store_name)
        form_data.append('store_address', dt.location)
        form_data.append('store_email', dt.store_email)
        form_data.append('store_contact_number', dt.store_contact_number)
        form_data.append('listing_type', dt.listing_type)
        form_data.append('anonymous', 1 as any)
        form_data.append('can_share', 0 as any)
        form_data.append('is_private', 0 as any)
        form_data.append('location', dt.location as any)
        console.log(incidentCleanLogData)
        const currentCleanLogData: any = {
          logged_at: Date.now(),
          items: incidentCleanLogData
            ? incidentCleanLogData
            : defaultCleanLogStructure,
        }
        currentCleanLogData.items = currentCleanLogData.items.map(
          (category: any) => ({
            name: category.name,
            items: category.items.map((item: any) => ({
              ...item,
              comment:
                !item.checked && item.comment === ''
                  ? 'Not Clean'
                  : item.comment,
            })),
          })
        )
        currentCleanLogData.items.forEach((category: any) => {
          category.items.forEach((item: any) => {
            if (item.imageList !== undefined && item?.imageList.length > 0) {
              Array.from(item.imageList).forEach(
                (image: any, index: number) => {
                  form_data.append(
                    'uploadedImages',
                    image as File,
                    `${image.name}___${item.category}___${item.id}___${
                      index + 1
                    }`
                  )
                }
              )
            }
          })
        })

        form_data.append('cleanLogData', JSON.stringify(currentCleanLogData))
      } else {
        form_data.append('listing_id', dt.listing_id.toString())
        form_data.append('incident_type', dt.incident_type)
        form_data.append('quick_estimate', dt.quick_estimate as any)
        form_data.append(
          'professional_estimate',
          dt.professional_estimate as any
        )
        form_data.append('rules', dt.rules)
        form_data.append('is_private', dt.is_private as any)
        form_data.append('can_share', !dt.can_share as any)
        if (userData.id) {
          form_data.append('owner_id', userData.id.toString())
        }
        form_data.append(
          'owner',
          `${userData.user_info?.first_name} ${userData.user_info?.last_name}`
        )
        form_data.append(
          'tagged_users',
          dt.tagged_users ? JSON.stringify(dt.tagged_users) : '""'
        )
        form_data.append('owner_mobile', userData?.mobile_number as any)
        form_data.append('owner_email', userData?.email as any)
        if (
          userData.user_type === UserType.CUSTOMER_WITHOUT_SUBSCRIPTION ||
          userData.user_type === UserType.CUSTOMER_WITH_SUBSCRIPTION
        ) {
          form_data.append('is_customer', 'true')
        }
        form_data.append(
          'location',
          listingData.find(
            (listing) =>
              listing.id === incidentReportForm.getValues('listing_id')
          )?.location as any
        )
        if (dt.file_list) {
          for (let i = 0; i < dt.file_list?.length; i++) {
            form_data.append('uploadedImages', dt.file_list[i])
          }
        }
      }

      form_data.append('description', dt.description)
      form_data.append('file_list', dt.file_list)
      // form_data.append('post_date', dt.post_date.toString())
      form_data.append('date', dt.date.toString())

      form_data.append('not_in_list', '0')
      form_data.append('is_approved', 'true')

      //TODO: remove navigate from addOrEditTsekmoCommunityPostData, right now set to false
      if (isReportABathroom) {
        dispatch(reportBathroom(form_data))
      } else {
        dispatch(
          addOrEditTsekmoCommunityPostData(
            form_data,
            isEdit ? true : false,
            false
          )
        )
      }
    }

    useEffect(() => {
      if (submittingForm && !loading.communityData) {
        if (onFinishAdd) {
          onFinishAdd()
        }
        setSubmittingForm(false)
        if (closeDialog) {
          closeDialog()
        }
      }
    }, [loading.communityData])

    useEffect(() => {
      if (submittingForm && !loading.reportBathroom) {
        setSubmittingForm(false)
        if (closeDialog) {
          closeDialog()
        }
      }
    }, [loading.reportBathroom])

    const handleChangeListing = (id: number) => {
      const currentLocation = listingData.find(
        (listing) => listing.id === id
      )?.location
      incidentReportForm.setValue(
        'location',
        currentLocation ? JSON.parse(currentLocation)?.label : ''
      )
    }

    useEffect(() => {
      //we set current file list whenever we update currentFileList state
      incidentReportForm.setValue('file_list', currentFileList, {
        shouldValidate: true,
        shouldDirty: true,
      })
    }, [currentFileList])

    useEffect(() => {
      dispatch(fetchListingTypes())
    }, [])

    useEffect(() => {
      if (userData.id) {
        dispatch(
          fetchListingData(
            userData.id,
            currentUserType === 'business-owner' || currentUserType === 'admin'
              ? true
              : false
          )
        )
      }
    }, [userData.id])

    useEffect(() => {
      if (isEdit && postData && userData.id) {
        incidentReportForm.reset({
          incident_type: postData.incident_type,
          listing_id: postData.listing_id,
          description: postData.description,
          date: postData.date,
          tagged_users: postData.tagged_users,
          quick_estimate: postData.quick_estimate,
          professional_estimate: postData.professional_estimate,
          rules: postData.rules,
          is_private: postData.is_private,
          can_share: postData.can_share,
          owner_id: postData.owner_id,
          is_active: postData.is_active,
          post_date: postData.post_date,
          image_list: postData.image_list,
          location: postData?.location
            ? isJson(postData.location)
              ? JSON.parse(postData?.location).label
              : postData.location
            : '',
        })
        setRemoteFileList(JSON.parse(postData.image_list))
      }
    }, [isEdit, postData, userData])

    useEffect(() => {
      if (currentLocation && currentLocation !== '') {
        incidentReportForm.setValue('location', currentLocation)
      }
    }, [currentLocation])

    useEffect(() => {
      if (isFromCustomerPayment) {
        incidentReportForm.reset({
          listing_id: customerPaymentListingData.id,
          location: isJson(customerPaymentListingData.location)
            ? JSON.parse(customerPaymentListingData.location)?.label
            : customerPaymentListingData.location,
          quick_estimate: 0,
          professional_estimate: 0,
          is_private: false,
        })
      }
    }, [isFromCustomerPayment])

    return (
      <div
        className={`p-8  ${
          isFromCustomerPayment ? '' : 'min-w-[850px] max-w-[850px] px-14'
        }`}
      >
        {isReportABathroom ? (
          <div className="flex flex-col text-center pb-8">
            <span className="text-primaryBg font-semibold text-lg text-center">
              Report a Bathroom to Tsekrs Community
            </span>
            <span className="text-subText mb-8">
              Add an image that you want to report, it will also be sent to the
              business owner if email or contact number is provided
            </span>
          </div>
        ) : (
          <div className="text-center pb-8">
            <span className="text-primaryBg mb-8 font-semibold text-lg ">
              Report incident to Tsekrs Community
            </span>
          </div>
        )}
        {!isFromCustomerPayment && (
          <div className="pb-12">
            <CleanLogChecker
              cleanLogData={incidentCleanLogData}
              setCleanLogData={setIncidentCleanLogData}
              listing={{ cleanlog_structure: defaultCleanLogStructure }}
              updateFileList={updateFileList}
            />
          </div>
        )}
        {!isReportABathroom ? (
          <form className="flex flex-col gap-4 min-w-[450px]">
            <Controller
              name="incident_type"
              control={incidentReportForm.control}
              defaultValue={''}
              render={({ field }) => (
                <FormControl size="small" disabled={disabled}>
                  <InputLabel
                    sx={{
                      color: UIConstants.primaryBgColor,
                      '&.Mui-focused': { color: UIConstants.primaryBgColor },
                    }}
                  >
                    Incident Type
                  </InputLabel>
                  <Select
                    {...field}
                    label="Incident Type"
                    {...incidentReportForm.register('incident_type', {
                      required: 'This field is required',
                    })}
                    error={!!incidentReportForm.formState.errors?.incident_type}
                    className={classes.inputBorderPrimary}
                    inputProps={{
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                  >
                    {incidentTypeList?.map(
                      (incidentType: string, index: number) => (
                        <MenuItem
                          value={incidentType}
                          key={incidentType + index}
                        >
                          {incidentType}
                        </MenuItem>
                      )
                    )}
                  </Select>
                  {incidentReportForm.formState.errors?.incident_type && (
                    <span className="text-xs font-normal text-red-600 mt-2 ml-4">
                      {
                        incidentReportForm.formState.errors?.incident_type
                          ?.message
                      }
                    </span>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="listing_id"
              control={incidentReportForm.control}
              defaultValue={-99}
              render={({ field }) => (
                <FormControl size="small" disabled={isFromCustomerPayment}>
                  <InputLabel
                    sx={{
                      color: UIConstants.primaryBgColor,
                      '&.Mui-focused': { color: UIConstants.primaryBgColor },
                    }}
                  >
                    Listing
                  </InputLabel>
                  <Select
                    className={classes.inputBorderPrimary}
                    sx={{
                      '&:focus': {
                        backgroundColor: 'yellow',
                      },
                    }}
                    {...field}
                    label="Listing"
                    {...incidentReportForm.register('listing_id', {
                      required: 'This field is required',
                    })}
                    error={!!incidentReportForm.formState.errors?.listing_id}
                    inputProps={{
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                  >
                    {listingData?.map(
                      (listing: ListingDataModel, index: number) => (
                        <MenuItem
                          value={listing.id}
                          key={listing.title + index}
                          onClick={() => handleChangeListing(listing.id)}
                        >
                          {listing.title}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              )}
            />
            <Controller
              name="location"
              control={incidentReportForm.control}
              rules={{
                required: 'Location required' as string,
              }}
              defaultValue={''}
              render={({ field }) => (
                <LocationSelector
                  {...field}
                  disabled={true}
                  label="Location or Addresss"
                  size="small"
                  error={incidentReportForm.formState.errors?.location}
                  helperText={
                    incidentReportForm.formState.errors?.location?.message
                  }
                />
              )}
            />
            <Controller
              name="description"
              control={incidentReportForm.control}
              defaultValue={''}
              render={({ field }) => (
                <TextField
                  disabled={disabled}
                  className={classes.textInput}
                  {...field}
                  label="Incident Report"
                  size="small"
                  {...incidentReportForm.register('description', {
                    required: 'This field is required',
                  })}
                  error={!!incidentReportForm.formState.errors?.description}
                  helperText={
                    incidentReportForm.formState.errors?.description?.message
                  }
                  variant="outlined"
                  multiline
                  minRows={2}
                  maxRows={4}
                />
              )}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Controller
                name="date"
                control={incidentReportForm.control}
                defaultValue={new Date()}
                rules={{
                  required: 'This field is required',
                }}
                render={({ field }) => (
                  <MobileTimePicker
                    ampm={true}
                    value={field.value}
                    onChange={field.onChange}
                    disabled={disabled}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={classes.textInput}
                        label={'Date and time'}
                        id="input-with-icon-textfield"
                        variant="outlined"
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <ClockIcon />
                            </InputAdornment>
                          ),
                        }}
                        error={!!incidentReportForm.formState.errors?.date}
                        helperText={
                          incidentReportForm.formState.errors?.date?.message
                        }
                      />
                    )}
                  />
                )}
              />
            </LocalizationProvider>

            {!isFromCustomerPayment && (
              <>
                <span className="text-xs text-subText -mt-2">
                  The date and time is corresponding to the last time the
                  listing is cleaned
                </span>
                <Controller
                  name="quick_estimate"
                  control={incidentReportForm.control}
                  defaultValue={undefined}
                  render={({ field }) => (
                    <TextField
                      className={classes.textInput}
                      {...field}
                      label="Quick Estimate"
                      size="small"
                      {...incidentReportForm.register('quick_estimate', {
                        required: 'This field is required',
                      })}
                      error={
                        !!incidentReportForm.formState.errors?.quick_estimate
                      }
                      helperText={
                        incidentReportForm.formState.errors?.quick_estimate
                          ?.message
                      }
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                <Controller
                  name="professional_estimate"
                  control={incidentReportForm.control}
                  defaultValue={undefined}
                  render={({ field }) => (
                    <TextField
                      className={classes.textInput}
                      {...field}
                      label="Professional Estimate"
                      size="small"
                      {...incidentReportForm.register('professional_estimate', {
                        required: 'This field is required',
                      })}
                      error={
                        !!incidentReportForm.formState.errors
                          ?.professional_estimate
                      }
                      helperText={
                        incidentReportForm.formState.errors
                          ?.professional_estimate?.message
                      }
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </>
            )}

            <Controller
              name="file_list"
              control={incidentReportForm.control}
              defaultValue={null}
              rules={
                postData?.image_list
                  ? {}
                  : {
                      required: 'A photo of the incident is required',
                      validate: {
                        //TODO FIND WAY TO CHECK IF FILE IS UPLOADED
                        check: (value) =>
                          incidentReportForm.formState.dirtyFields.file_list
                            ? value !== null
                            : true,
                      },
                    }
              }
              render={({ field }) => (
                <DropUpload
                  {...field}
                  text="Add Photo"
                  dragText="Upload Photo"
                  disabled={disabled}
                  onFileDrop={(event) => addPhoto(event)}
                />
              )}
            />

            <UploadImageList
              imageFileList={currentFileList}
              onDelete={handleDeleteImage}
              columns={1}
              remoteImageFileList={
                remoteFileList.length > 0 ? remoteFileList : undefined
              }
            />
            {/* <FormControlLabel
          control={
            <Controller
              name="is_private"
              control={incidentReportForm.control}
              defaultValue={false}
              render={({ field }) => (
                <Toggle checked={field.value} onChange={field.onChange} />
              )}
            />
          }
          label={'Set this post as private'}
          labelPlacement="start"
          className={classes.toggleBtn}
        />
        <p style={{ color: '#7F675B', fontSize: '12px' }}>
          Private posts will be seen by only you and the previous bathroom users
          with in the time frame that you’ve cleaned or the last time you have
          seen your bathroom clean.
        </p> */}

            {!disableSubmitBtn ? (
              <>
                <FormControlLabel
                  disabled={disabled}
                  control={
                    <Controller
                      name="can_share"
                      control={incidentReportForm.control}
                      defaultValue={false}
                      render={({ field }) => (
                        <Toggle
                          checked={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  }
                  label={'Disable sharing of this post by others'}
                  labelPlacement="start"
                  className={classes.toggleBtn}
                />
                <CustomButton
                  type="button"
                  variant="contained"
                  className="mt-8"
                  disabled={
                    !incidentReportForm.formState.isValid ||
                    loading.communityData
                  }
                  fullWidth
                  onClick={handleSubmit}
                >
                  {loading.communityData ? (
                    <CircularProgress size={25} />
                  ) : (
                    'Post'
                  )}
                </CustomButton>
              </>
            ) : (
              ''
            )}
          </form>
        ) : (
          <form className="flex flex-col gap-4 min-w-[450px]">
            <Controller
              name="store_name"
              control={incidentReportForm.control}
              defaultValue={''}
              render={({ field }) => (
                <TextField
                  disabled={disabled}
                  className={classes.textInput}
                  {...field}
                  label="Store Name"
                  size="small"
                  {...incidentReportForm.register('store_name', {
                    required: 'This field is required',
                  })}
                  error={!!incidentReportForm.formState.errors?.store_name}
                  helperText={
                    incidentReportForm.formState.errors?.store_name?.message
                  }
                  variant="outlined"
                  multiline
                />
              )}
            />
            <Controller
              name="location"
              control={incidentReportForm.control}
              rules={{
                required: 'Location required' as string,
              }}
              defaultValue={''}
              render={({ field }) => (
                <LocationSelector
                  disabled={currentLocation && currentLocation !== ''}
                  {...field}
                  label="Location or Address"
                  size="small"
                  error={incidentReportForm.formState.errors?.location}
                  helperText={
                    incidentReportForm.formState.errors?.location?.message
                  }
                />
              )}
            />
            <Controller
              name="listing_type"
              control={incidentReportForm.control}
              defaultValue={listingTypes.length > 0 ? listingTypes[0].id : 1}
              render={({ field }) => (
                <InputText
                  fullWidth
                  select
                  {...field}
                  label="Bathroom Type"
                  size="small"
                >
                  {listingTypes?.map(
                    (listingType: ListingType, index: number) => (
                      <MenuItem
                        value={listingType.id}
                        key={listingType.name + index}
                      >
                        {listingType.name}
                      </MenuItem>
                    )
                  )}
                </InputText>
              )}
            />
            <Controller
              name="description"
              control={incidentReportForm.control}
              defaultValue={''}
              render={({ field }) => (
                <TextField
                  disabled={disabled}
                  className={classes.textInput}
                  {...field}
                  label="Incident Report"
                  size="small"
                  {...incidentReportForm.register('description', {
                    required: 'This field is required',
                  })}
                  error={!!incidentReportForm.formState.errors?.description}
                  helperText={
                    incidentReportForm.formState.errors?.description?.message
                  }
                  variant="outlined"
                  multiline
                  minRows={2}
                  maxRows={4}
                />
              )}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Controller
                name="date"
                control={incidentReportForm.control}
                defaultValue={new Date()}
                rules={{
                  required: 'This field is required',
                }}
                render={({ field }) => (
                  <MobileTimePicker
                    ampm={true}
                    value={field.value}
                    onChange={field.onChange}
                    disabled={disabled}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={classes.textInput}
                        label={'Date and time'}
                        id="input-with-icon-textfield"
                        variant="outlined"
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <ClockIcon />
                            </InputAdornment>
                          ),
                        }}
                        error={!!incidentReportForm.formState.errors?.date}
                        helperText={
                          incidentReportForm.formState.errors?.date?.message
                        }
                      />
                    )}
                  />
                )}
              />
            </LocalizationProvider>
            <Controller
              name="store_email"
              control={incidentReportForm.control}
              defaultValue={''}
              render={({ field }) => (
                <TextField
                  disabled={disabled}
                  className={classes.textInput}
                  {...field}
                  label="Store Email Address (optional)"
                  type="email"
                  size="small"
                  error={!!incidentReportForm.formState.errors?.store_email}
                  helperText={
                    incidentReportForm.formState.errors?.store_email?.message
                  }
                  variant="outlined"
                  multiline
                />
              )}
            />
            <PhoneInput
              onlyCountries={countries}
              fullWidth
              label="Store Contact Number (optional)"
              variant="outlined"
              size="small"
              defaultCountry={'us'}
              required={false}
              control={incidentReportForm.control}
              name={'store_contact_number'}
              error={incidentReportForm.formState.errors?.store_contact_number}
              helperText={
                incidentReportForm.formState.errors?.store_contact_number
                  ?.message
              }
            ></PhoneInput>
            <Controller
              name="file_list"
              control={incidentReportForm.control}
              defaultValue={null}
              rules={
                postData?.image_list
                  ? {}
                  : {
                      required: 'A photo of the incident is required',
                      validate: {
                        //TODO FIND WAY TO CHECK IF FILE IS UPLOADED
                        check: (value) =>
                          incidentReportForm.formState.dirtyFields.file_list
                            ? value !== null
                            : true,
                      },
                    }
              }
              render={({ field }) => (
                <DropUpload
                  {...field}
                  text="Add Photo"
                  dragText="Upload Photo"
                  disabled={disabled}
                  onFileDrop={(event) => addPhoto(event)}
                />
              )}
            />

            <UploadImageList
              imageFileList={currentFileList}
              onDelete={handleDeleteImage}
              columns={1}
              remoteImageFileList={
                remoteFileList.length > 0 ? remoteFileList : undefined
              }
            />
            {!disableSubmitBtn ? (
              <CustomButton
                type="button"
                variant="contained"
                className="mt-8"
                disabled={
                  !incidentReportForm.formState.isValid || loading.communityData
                }
                fullWidth
                onClick={handleSubmit}
              >
                {loading.communityData || loading.reportBathroom ? (
                  <CircularProgress size={25} />
                ) : isReportABathroom ? (
                  'Report Bathroom'
                ) : (
                  'Post'
                )}
              </CustomButton>
            ) : (
              ''
            )}
          </form>
        )}
      </div>
    )
  }
)

export default AddIncidentForm

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CustomButton } from '../../../theme/components/buttons/Button';
import { useEffect } from 'react';

interface EmailAuthProps {
    open: boolean;
    handleClose: () => void;
}

export default function TermsAndCondtions({ open, handleClose }: EmailAuthProps) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{t('signUp.termsAndConditionsHeader')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('signUp.termsAndConditions')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <CustomButton
                    onClick={handleClose}
                    variant="contained"
                    fullWidth>{t('signUp.close')}</CustomButton>
            </DialogActions>
        </Dialog>
    );
}

import React from 'react'
import { CustomAppBar } from "../../theme/components/app-layout/header/AppBar";
import TsekmoLandingToolBar from "../components/ToolBar";
import Footer from "../../theme/components/app-layout/footer/Footer";
import { Main } from "../../theme/components/app-layout/main/Main";
import { makeStyles } from '@mui/styles';
import { Theme ,  Grid } from '@mui/material';
import UIConstants from '../../theme/constants/ui-constants';
import testimonial1 from '../../assets/images/about1.jpg'
import testimonial2 from '../../assets/images/about2.jpg'
import testimonial3 from '../../assets/images/about3.jpg'
import { textAlign } from '@mui/system';
import { useEffect } from 'react';


const useStyle = makeStyles((theme: Theme) => ({
  root: {
    background: UIConstants.rootBgColor,
    height: `100%`,
    width: '100%',
    paddingBottom: `calc(${UIConstants.primaryFooterHeight} + ${theme.spacing(
      0
    )})`
  },
  aboutContent: {
    margin:  '2%'
  },
  optionsImages: {
    maxWidth: '350px',
    width:'88%',
    height: '244px',
    position:'relative',
    "&:hover > div ": {
      opacity:0.9
    },
    "&:hover > img": {
      opacity:0.25
    }

    
  },
  addExtraPadding: {
    paddingBottom: '70px'
  },

  gridImageStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  fullWidth: {
    width: '100%',
    height: '244px',
    position:'absolute',
    opacity:10,
    
  },
  imageText: {
    color: 'black',
    opacity: 0,
    position:'absolute',
    left:'50%',
    top:'50%',
    transform: 'translate(-50%, -50%)',
    transition: '.5s ease',
    width:'100%',
    textAlign: 'center'
    

  }
}));


const AboutUs = (props: any) => {
  const classes = useStyle();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <div className={classes.root}>
      {!props.isLogedIn && (
        <CustomAppBar>
          <TsekmoLandingToolBar />
        </CustomAppBar>
      )}

      {props.isLogedIn ? (
        <div
          style={{
            minHeight: '50vh'
          }}
        >
          <h3>About us</h3>
          <p className={classes.aboutContent}>
            Teskmo was born in 2022 when one nurse thought of a solution for those who had an urgent need to use bathroom while traveling to work.  Most hosts such as the Gas Station Owners love to welcome the guest to use their bathroom but always are wary of Vandalism and Graffiti which are costly. Tsekmo and its team built a simple platform to solve this problem for people on the go as well as the host… Initial Launch will support bathrooms but soon after the platform enables Hosts offer unique amenities, Food and services that make it possible for guests to experience the world in a more authentic, greener, and connected way.
          </p>

          <h3>Founder</h3>
          <p className={classes.aboutContent}>
            Dan Garcia
          </p>
          <h3>Teskmo Stories </h3>
            <p className={classes.aboutContent}>
              <Grid   container spacing={1} >
                <Grid  className={classes.gridImageStyle} spacing={1} item xs={12} sm={4}>
                  <h4>Testimonial 1</h4>
                  <div  className={classes.optionsImages}> 
                     <img className={classes.fullWidth} src={testimonial1}     />
                     <div className ={ classes.imageText}>Just love the app</div>
                  </div>
                </Grid>
                <Grid  className={classes.gridImageStyle} spacing={1} item xs={12} sm={4}>
                  <h4>Testimonial 2</h4>
                  <div  className={classes.optionsImages}> 
                     <img className={classes.fullWidth} src={testimonial2}     />
                     <div className ={ classes.imageText}>Great to know the service is available as I travel </div>
                  </div>
                 
                </Grid>
                <Grid  className={classes.gridImageStyle} spacing={1} item xs={12} sm={4}>
                  <h4>Testimonial 3</h4>
                  <div  className={classes.optionsImages}> 
                     <img className={classes.fullWidth} src={testimonial3}     />
                     <div className ={ classes.imageText}> Thank you Tsekmo for allowing us offer this service</div>
                  </div>
                </Grid>
              </Grid>
            </p>
        </div>
      ) : (
        <Main>
          <div
            style={{
              minHeight: '50vh'
            }}
          >
            <h3>About us</h3>
            <p className={classes.aboutContent}>
              Teskmo was born in 2022 when one nurse thought of a solution for those who had an urgent need to use bathroom while traveling to work.  Most hosts such as the Gas Station Owners love to welcome the guest to use their bathroom but always are wary of Vandalism and Graffiti which are costly. Tsekmo and its team built a simple platform to solve this problem for people on the go as well as the host… Initial Launch will support bathrooms but soon after the platform enables Hosts offer unique amenities, Food and services that make it possible for guests to experience the world in a more authentic, greener, and connected way.
            </p>
            <h3>Founder</h3>
            <p className={classes.aboutContent}>
              Dan Garcia
            </p>

            <h3>Teskmo Stories </h3>
            <p className={classes.aboutContent}>
            <Grid container spacing={1} className={classes.addExtraPadding} >
                <Grid  className={classes.gridImageStyle} spacing={1}item xs={12} sm={4}>
                  <h4>Testimonial 1</h4>
                  <div  className={classes.optionsImages}> 
                     <img className={classes.fullWidth} src={testimonial1}     />
                     <div className ={ classes.imageText}>Easy: With a few clicks, listed and showcased my Bathrooms</div>
                  </div>
                </Grid>
                <Grid className={classes.gridImageStyle}  spacing={1} item xs={12} sm={4}>
                  <h4>Testimonial 2</h4>
                  <div  className={classes.optionsImages}> 
                     <img className={classes.fullWidth} src={testimonial2}     />
                     <div className ={ classes.imageText}>Efective: Can report any vandalism and issue to be fixed</div>
                  </div>
                </Grid>
                <Grid className={classes.gridImageStyle} spacing={1} item xs={12} sm={4}>
                  <h4>Testimonial 3</h4>
                  <div  className={classes.optionsImages}> 
                     <img className={classes.fullWidth} src={testimonial3}     />
                     <div className ={ classes.imageText}> Efficient: Got it back to as good as before for the next guest in no time!</div>
                  </div>
                </Grid>
              </Grid>
            </p>
          </div>
        </Main>
      )}

      {!props.isLogedIn && (
        <Footer showTwoLevelFooter={true} />
      )}
    </div>
  )
}

export default AboutUs
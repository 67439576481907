// @ts-nocheck
import {
  Avatar,
  Button,
  Card,
  Theme,
  Popover,
  TextField,
  Tooltip,
  Menu,
  MenuItem,
  Dialog,
} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import React, { useEffect, useRef, useState } from 'react'
import ImageCarouselList from '../../../../theme/components/image-list/ImageCarousel'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import UIConstants from '../../../../theme/constants/ui-constants'
import ChatIcon from '@mui/icons-material/Chat'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import SendIcon from '@mui/icons-material/Send'
import { makeStyles } from '@mui/styles'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import moment from 'moment'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  savePostComment,
  savePost,
  unsavePost,
  savePostReaction,
  approveIncidentReport,
  declineIncidentReport,
} from '../../../../store/app/community/actions'
import { fetchUser } from '../../../../store/app/user/actions'
import { fetchPostComments } from '../../../../store/app/community/actions'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  LinkedinShareButton,
  LinkedinIcon,
  RedditShareButton,
  RedditIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from 'react-share'
import PostCommentItem from './PostCommentItem'
import { ApplicationState } from '../../../../store'
import { callApi } from '../../../../app/utils/api'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import TsekmoButton from '../../TsekmoButton'
import { deleteTsekmoCommunityPost } from '../../../../store/app/community/actions'
import { useGetUserType } from '../../../composables'
import { isJson } from '../../../../app/utils/common-utils'
import EditIncidentReportByBoForm from './EditIncidentReportByBoForm'
interface TsekrsPostItemProps {
  postData: any
  fetchPosts: () => void
  handleOpenEditPost: (post_id: number) => void
  isBlurredPhotos: boolean
  forReview?: boolean
}
const useStyle = makeStyles((theme: Theme) => ({
  commentInput: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '24px',
      borderColor: UIConstants.subText,
      fontSize: '12px',
    },
  },
}))

function TsekrsPostItem({
  postData,
  fetchPosts,
  handleOpenEditPost,
  isBlurredPhotos,
  forReview,
}: TsekrsPostItemProps) {
  const dispatch = useDispatch()
  const { userData } = useSelector((state: ApplicationState) => state.app)
  const { user_info } = useSelector(
    (state: ApplicationState) => state.user.userData
  )
  const { loading } = useSelector(
    (state: ApplicationState) => state.tsekmoCommunity
  )

  const classes = useStyle()
  const currentUserType = useGetUserType()
  const [showDescription, setShowDescription] = useState<boolean>(false)
  const [formattedPostItem, setFormattedPostItem] = useState<any>({})
  const [openShareTooltip, setOpenShareTooltip] = useState<boolean>(false)
  const [commentValue, setCommentValue] = useState<string>('')
  const [anchorEl2, setAnchorEl2] = useState(null)
  const [anchorEl1, setAnchorEl1] = useState(null)
  const [postCommentData, setPostCommentData] = useState<any>([])
  const [latestComments, setLatestComments] = useState<any>([])
  const [page, setPage] = useState<number>(1)
  const commentInputRef = useRef<any>()
  const limit: number = 5
  const emojis = ['👍', '❤️', '😂', '😢', '😡', '😎']
  const user_name = `${user_info?.first_name} ${user_info?.last_name}`
  const [loadingComments, setLoadingComments] = useState<boolean>(false)
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
  const [openMenuDialog, setOpenMenuDialog] = useState<boolean>(false)
  const [isDeletingPost, setIsDeletingPost] = useState<boolean>(false)
  const openMenu = Boolean(menuAnchorEl)
  const [openEditReportForReview, setOpenEditReportForReview] =
    useState<boolean>(false)
  const [updatingStatus, setUpdatingStatus] = useState<boolean>(false)
  const handleCloseEditForm = (): void => {
    setOpenEditReportForReview(false)
  }

  const handleOpenMenu = (event: any) => {
    setMenuAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setMenuAnchorEl(null)
  }

  const handleDeletePost = () => {
    setIsDeletingPost(true)
    dispatch(deleteTsekmoCommunityPost(postData.id, userData.id))
  }

  useEffect(() => {
    if (!loading.postStatus && updatingStatus) {
      fetchPosts()
      setUpdatingStatus(false)
    }
  }, [loading.postStatus])

  useEffect(() => {
    if (!loading.deletingPost && isDeletingPost) {
      fetchPosts()
      setOpenMenuDialog(false)
      setIsDeletingPost(false)
    }
  }, [loading.deletingPost])

  const handleClosePopover = (e: any, anchor: string) => {
    if (anchor === 'react') {
      setAnchorEl1(null)
    } else {
      setAnchorEl2(null)
    }
  }

  const handleClickPopover = (e: any, anchor: string) => {
    if (anchor === 'react') {
      setAnchorEl1(e.currentTarget)
    } else {
      setAnchorEl2(e.currentTarget)
    }
  }

  const setTextInputRef = (element: HTMLInputElement) => {
    commentInputRef.current = element
  }

  const handleFocusTextField = () => {
    if (commentInputRef.current) {
      commentInputRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'center',
      })
      commentInputRef.current.focus({ preventScroll: true })
    }
  }

  const handleSaveComment = () => {
    if (commentValue === '') return
    let data = {
      post_id: postData.id,
      user_id: userData.id,
      owner: user_name,
      comment: commentValue.trim(),
    }
    dispatch(savePostComment(data))
    setCommentValue('')
    setPostCommentData([...postCommentData, data])
  }

  const fetchPostComments = async (data: any) => {
    try {
      setLoadingComments(true)
      const res: any = await callApi('get', 'public/post_comments', data, {
        'Content-Type': 'application/json',
      })
      if (res.error) {
        console.warn('Unexpected issue occurred')
      } else {
        setLoadingComments(false)
        return res
      }
    } catch (error) {
      console.error('ERROR', error)
    }
  }

  const viewMoreComments = () => {
    if (page <= latestComments.lastPage) {
      let currentPage = page
      currentPage++
      fetchPostComments({
        post_id: postData.id,
        limit: limit,
        page: currentPage,
      }).then((postComments) => {
        setLatestComments(postComments.data)
      })
      setPage(currentPage)
    }
  }

  const viewLessComments = (): void => {
    setPostCommentData(postCommentData.slice(-5))
    setPage(1)
  }

  const handleSaveOrUnsavePost = () => {
    if (postData.id) {
      let data = {
        post_id: postData.id,
        user_id: userData.id,
        is_saved: true,
      }
      if (formattedPostItem.is_saved !== 1) {
        setFormattedPostItem({ ...formattedPostItem, is_saved: 1 }) //We set is_saved value for post locally to prevent fetching all post data again
        dispatch(savePost(data))
      } else {
        setFormattedPostItem({ ...formattedPostItem, is_saved: 0 })
        dispatch(unsavePost(data))
      }
    }
  }

  const handleReactPost = (index) => {
    // TODO: Add delete reaction to api and integrate here
    if (postData.id) {
      let data = {
        post_id: postData.id,
        user_id: userData.id,
        reaction: index,
      }
      dispatch(savePostReaction(data))

      const currentReactions = formattedPostItem.reactions

      if (
        currentReactions.reactions.find(
          (reaction) => reaction.user_id === userData.id
        )
      ) {
        currentReactions.reactions.splice(
          //Splice current index of current user reaction and update locally to prevent re-fetching data
          currentReactions.reactions.indexOf(
            currentReactions.reactions.find(
              (reaction) => reaction.user_id === userData.id
            )
          ),
          1
        )
        currentReactions.reactions.push({
          user_id: userData.id,
          reaction: index,
        })
      }

      let formattedEmojis = []
      formattedEmojis = formatExistingEmojis(currentReactions.reactions)

      setFormattedPostItem({
        ...formattedPostItem,
        reactions: currentReactions,
        emoji_list: formattedEmojis,
      })
    }
  }

  useEffect(() => {
    if (
      latestComments?.total !== 0 &&
      postData.id &&
      postCommentData.length === 0
    ) {
      fetchPostComments({ post_id: postData.id, limit: limit, page: 1 }).then(
        (postComments) => {
          setLatestComments(postComments.data)
        }
      )
    }
  }, [postCommentData, postData])

  const formatExistingEmojis = (emoji_list) => {
    const currentEmojis = {}
    emoji_list.forEach((reaction: any) => {
      currentEmojis[`reaction_${reaction.reaction}`] = currentEmojis[
        `reaction_${reaction.reaction}`
      ]
        ? {
            emoji: reaction.reaction,
            count: currentEmojis[`reaction_${reaction.reaction}`].count + 1,
          }
        : {
            emoji: reaction.reaction,
            count: 1,
          }
    })

    return Object.values(currentEmojis).sort((a, b) => {
      return b.count - a.count
    })
  }

  useEffect(() => {
    if (postData) {
      let formattedEmojis = []

      if (postData?.reactions?.total > 0) {
        formattedEmojis = formatExistingEmojis(postData.reactions.reactions)
      }

      setFormattedPostItem({
        owner: postData.owner,
        date: moment(postData.post_date).format('lll'),
        location: isJson(postData.location)
          ? JSON.parse(postData.location).label
          : postData.location,
        imageList: isJson(postData.image_list)
          ? JSON.parse(postData.image_list)
          : postData.image_list,
        description: postData.description,
        quick_estimate: postData.quick_estimate.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        }),
        professional_estimate: postData.professional_estimate.toLocaleString(
          'en-US',
          {
            style: 'currency',
            currency: 'USD',
          }
        ),
        comments: postData.commentData,
        is_saved: postData?.is_saved,
        reactions: postData.reactions,
        emoji_list: formattedEmojis,
      })
    }
  }, [postData])

  useEffect(() => {
    //Whenever we fetch comments, latest comments get pushed to postCommentData
    if (latestComments && latestComments.comments) {
      const newComments: any[] = []
      latestComments.comments.forEach((comment: any) => {
        if (
          postCommentData.length > 0 &&
          postCommentData.find(
            (currentComment: any) => currentComment.id === comment.id
          )
        )
          return
        newComments.unshift(comment)
      })
      setPostCommentData([...newComments, ...postCommentData])
    }
  }, [latestComments])

  const handleApprovePost = () => {
    setUpdatingStatus(true)
    dispatch(
      approveIncidentReport({
        user_id: postData.reporter_id,
        post_id: postData.id,
      })
    )
  }

  const handleRejectPost = () => {
    dispatch(declineIncidentReport({ post_id: postData.id }))
  }

  return (
    <div className="flex flex-col w-[80%] bg-white rounded-md shadow-md">
      <div className="flex px-5 pt-5 w-full">
        <Avatar sx={{ marginTop: '5px' }} />
        <div className="ml-4 w-full">
          <div className="flex justify-between w-full items-center">
            <p className="font-semibold text-xl text-mainText">
              {formattedPostItem?.owner || 'Bathroom Report'}
            </p>
            <div>
              <Button
                sx={{
                  borderRadius: '100%',
                  padding: '8px',
                  minWidth: 'fit-content',
                }}
                onClick={handleSaveOrUnsavePost}
              >
                {formattedPostItem.is_saved &&
                formattedPostItem.is_saved === 1 ? (
                  <BookmarkIcon />
                ) : (
                  <BookmarkBorderIcon />
                )}
              </Button>
              {postData?.owner_id === userData.id &&
              currentUserType !== 'customer' ? (
                <>
                  <button onClick={(e) => handleOpenMenu(e)}>
                    <MoreVertIcon />
                  </button>
                  <Menu
                    id="post-actions-menu"
                    anchorEl={menuAnchorEl}
                    open={openMenu}
                    onClose={(e) => handleCloseMenu(e)}
                  >
                    <MenuItem onClick={() => handleOpenEditPost(postData)}>
                      Edit
                    </MenuItem>
                    <MenuItem onClick={() => setOpenMenuDialog(true)}>
                      Delete
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                ''
              )}
              <Dialog
                open={openMenuDialog}
                onClose={() => setOpenMenuDialog(false)}
              >
                <div className="p-8 flex flex-col text-center gap-4 text-mainText">
                  <p className="font-semibold">
                    Are you sure you want to delete this post?
                  </p>
                  <div className="flex gap-2 justify-center">
                    <Button
                      sx={{
                        backgroundColor: 'transparent',
                        color: UIConstants.mainText,
                        textTransform: 'capitalize',
                      }}
                      onClick={() => setOpenMenuDialog(false)}
                    >
                      No
                    </Button>
                    <TsekmoButton onClick={handleDeletePost}>Yes</TsekmoButton>
                  </div>
                </div>
              </Dialog>
            </div>
          </div>
          <p className="text-subText text-xs">{formattedPostItem?.date}</p>
          <p className="text-subText text-xs line-clamp-1">
            {formattedPostItem?.location}
          </p>
        </div>
      </div>
      <div className="w-full mt-5 px-5">
        <ImageCarouselList
          orientation="V"
          imageFileList={formattedPostItem?.imageList}
          isBlurred={isBlurredPhotos}
        />
      </div>
      <div
        className={`w-full text-mainText px-10 pt-5 ${
          showDescription ? '' : 'line-clamp-3'
        }`}
      >
        {forReview && postData.is_approved === 0 ? (
          <div className="flex justify-between pb-4">
            <span className="font-semibold">Unsettled</span>
            <div className="flex gap-4">
              <Button
                sx={{
                  backgroundColor: 'transparent',
                  color: UIConstants.mainText,
                  textTransform: 'capitalize',
                }}
                onClick={handleRejectPost}
              >
                Decline
              </Button>
              <TsekmoButton onClick={handleApprovePost}>Accept</TsekmoButton>
            </div>
          </div>
        ) : (
          forReview && (
            <div className="flex justify-between pb-4">
              <span className="font-semibold">Unsettled</span>
              <TsekmoButton onClick={() => setOpenEditReportForReview(true)}>
                Edit
              </TsekmoButton>
            </div>
          )
        )}
        <p className="text-md ">{formattedPostItem?.description}</p>
        <p className="text-subText font-semibold text-xs mt-4">
          {/* Last Cleaned: <span className="font-normal">November 20, 2023</span> */}
        </p>
        <p className="text-subText font-semibold text-xs underline decoration-subText mt-1">
          Damage price estimates
        </p>
        <p className="text-subText font-semibold text-xs">
          Quick estimate:{' '}
          <span className="font-normal">
            {formattedPostItem?.quick_estimate}
          </span>
        </p>
        <p className="text-subText font-semibold text-xs">
          Professional Estimate:{' '}
          <span className="font-normal">
            {formattedPostItem?.professional_estimate}
          </span>
        </p>
      </div>
      <div className="flex justify-end px-5">
        <button
          className="text-sm text-[#0c4b9c] font-bold pr-5 mt-2 hover:underline"
          onClick={() => setShowDescription(!showDescription)}
        >
          {showDescription ? 'Show Less' : 'Show More'}
        </button>
      </div>
      {/* React Counter */}
      <div className="flex items-center justify-between px-10 mt-4">
        <Tooltip
          placement="right"
          title={
            <p>
              {formattedPostItem?.emoji_list?.length > 0 &&
                formattedPostItem.emoji_list.map((reaction, index: number) => (
                  <span className="mr-1" key={`${reaction.id}-${index}`}>{`${
                    emojis[reaction.emoji]
                  } ${reaction.count}`}</span>
                ))}
            </p>
          }
        >
          <p className="text-subText text-xs">
            {formattedPostItem?.emoji_list?.length > 0
              ? formattedPostItem.emoji_list.length === 2
                ? `${emojis[formattedPostItem.emoji_list[0].emoji]} ${
                    emojis[formattedPostItem.emoji_list[1].emoji]
                  } ${formattedPostItem.reactions.total}`
                : `${emojis[formattedPostItem.emoji_list[0].emoji]} ${
                    formattedPostItem.reactions.total
                  }`
              : ''}
          </p>
        </Tooltip>
        <p
          className="text-xs text-subText hover:underline hover:cursor-pointer"
          onClick={handleFocusTextField}
        >
          {latestComments?.total} comments
        </p>
      </div>
      {/* Action Bar */}
      <div className="grid grid-cols-3 px-5 mt-2">
        <div className="flex col-span-1 justify-center">
          <Button
            sx={{
              display: 'flex',
              color: UIConstants.subText,
              gap: '5px',
              textTransform: 'capitalize',
            }}
            onMouseEnter={(e) => handleClickPopover(e, 'react')}
          >
            <ThumbUpOffAltIcon sx={{ width: '20px', height: '20px' }} />
            <p>React</p>
          </Button>
          <Popover
            open={Boolean(anchorEl1)}
            anchorEl={anchorEl1}
            onClose={(e) => handleClosePopover(e, 'react')}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <div
              onMouseLeave={(e) => handleClosePopover(e, 'react')}
              style={{ display: 'flex', gap: '5px', padding: '8px' }}
            >
              {emojis.map((emoji, index) => (
                <button
                  key={emoji + index}
                  className={`w-8 h-8 text-md flex items-center p-2 hover:bg-blue-100 hover:cursor-pointer rounded-full ${
                    //if current user has a reaction, get the index from emoji and add background to show currently selected reaction
                    formattedPostItem.reactions?.reactions?.length > 0
                      ? formattedPostItem.reactions?.reactions?.find(
                          (reaction) => reaction.user_id === userData.id
                        )?.reaction === index
                        ? 'bg-blue-100'
                        : ''
                      : ''
                  }`}
                  onClick={() => handleReactPost(index)}
                >
                  <p className="-ml-[2.5px]">{emoji}</p>
                </button>
              ))}
            </div>
          </Popover>
        </div>
        <div className="flex col-span-1 justify-center">
          <Button
            sx={{
              display: 'flex',
              color: UIConstants.subText,
              gap: '5px',
              textTransform: 'capitalize',
            }}
            onClick={handleFocusTextField}
          >
            <ChatIcon sx={{ width: '20px', height: '20px' }} />
            <p>Comment</p>
          </Button>
        </div>
        <div
          className="flex col-span-1 justify-center"
          onMouseEnter={() => {
            if (postData.can_share !== 1) {
              setOpenShareTooltip(true)
            }
          }}
          onMouseLeave={() => setOpenShareTooltip(false)}
        >
          <Tooltip
            title={<p>User has disabled sharing for this post</p>}
            open={openShareTooltip}
            placement="right"
          >
            <Button
              sx={{
                display: 'flex',
                color: UIConstants.subText,
                gap: '5px',
                textTransform: 'capitalize',
              }}
              disabled={postData.can_share !== 1}
              onMouseEnter={(e) => handleClickPopover(e, 'share')}
            >
              <SendIcon sx={{ width: '20px', height: '20px' }} />
              <p>Share</p>
            </Button>
          </Tooltip>

          <Popover
            open={Boolean(anchorEl2)}
            anchorEl={anchorEl2}
            onClose={(e) => handleClosePopover(e, 'share')}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <div
              className="p-4"
              onMouseLeave={(e) => handleClosePopover(e, 'share')}
            >
              <div className="flex justify-center gap-2">
                <FacebookShareButton
                  url={''}
                  quote={'Tsekmo Community'}
                  hashtag={'#Tsekers'}
                >
                  <FacebookIcon size={32} round={true} />
                </FacebookShareButton>
                <TwitterShareButton
                  url={''}
                  title={'Tsekmo Community'}
                  hashtags={['#Tsekers']}
                >
                  <TwitterIcon size={32} round={true} />
                </TwitterShareButton>
                <TelegramShareButton url={''} title={'Tsekmo Community'}>
                  <TelegramIcon size={32} round={true} />
                </TelegramShareButton>
                <LinkedinShareButton url={''} title={'Tsekmo Community'}>
                  <LinkedinIcon size={32} round={true} />
                </LinkedinShareButton>
                <RedditShareButton url={''} title={'Tsekmo Community'}>
                  <RedditIcon size={32} round={true} />
                </RedditShareButton>
                <WhatsappShareButton url={''} title={'Tsekmo Community'}>
                  <WhatsappIcon size={32} round={true} />
                </WhatsappShareButton>
                <EmailShareButton url={''} title={'Tsekmo Community'}>
                  <EmailIcon size={32} round={true} />
                </EmailShareButton>
              </div>
              <div className="flex gap-2 items-center mt-3">
                <TextField size="small"></TextField>
                <Button
                  sx={{
                    borderRadius: '100%',
                    padding: '8px',
                    minWidth: 'fit-content',
                    color: UIConstants.subText,
                  }}
                >
                  <ContentPasteIcon />
                </Button>
              </div>
            </div>
          </Popover>
        </div>
      </div>
      <div className="flex flex-col justify-between bg-foreground min-h-[200px] mt-4 p-5">
        {latestComments &&
        postCommentData &&
        page <= latestComments?.lastPage &&
        postCommentData.length < latestComments.total ? (
          <div className="flex items-center">
            <button
              className="text-xs text-subText hover:text-mainText font-bold pr-5 mt-2 hover:underline"
              onClick={viewMoreComments}
            >
              <p>View more comments</p>
            </button>
            {loadingComments ? (
              <CircularProgress
                size={20}
                sx={{
                  marginTop: '5px',
                  marginLeft: '-5px',
                  color: UIConstants.subText,
                }}
              />
            ) : (
              ''
            )}
          </div>
        ) : postCommentData.length > 5 ? (
          <div>
            <button
              className="text-xs text-subText hover:text-mainText font-bold pr-5 mt-2 hover:underline"
              onClick={viewLessComments}
            >
              <p>View less comments</p>
            </button>
          </div>
        ) : (
          ''
        )}
        <div className="flex flex-col gap-4 my-4">
          {postCommentData.length !== 0 ? (
            postCommentData &&
            postCommentData.map((comment: any, index: number) => {
              return (
                <PostCommentItem
                  key={`${comment}-${index}`}
                  commentData={comment}
                />
              )
            })
          ) : (
            <span className="text-subText text-xs text-center">
              No comments
            </span>
          )}
        </div>
        <div className="flex mt-4">
          <TextField
            value={commentValue}
            onChange={(e) => setCommentValue(e.target.value)}
            inputRef={setTextInputRef}
            placeholder="Write a comment"
            size="small"
            fullWidth
            className={classes.commentInput}
            multiline
            maxRows={3}
          ></TextField>
          <div className=" w-20 grid place-items-center">
            <Button
              onClick={handleSaveComment}
              sx={{
                color: UIConstants.subText,
                borderRadius: '100%',
                padding: '8px',
                minWidth: 'fit-content',
              }}
            >
              <SendIcon sx={{ width: '20px', height: '20px' }} />
            </Button>
          </div>
        </div>
      </div>
      <Dialog
        open={openEditReportForReview}
        onClose={handleCloseEditForm}
        maxWidth="xl"
      >
        <EditIncidentReportByBoForm post_id={postData.id} />
      </Dialog>
    </div>
  )
}

export default TsekrsPostItem

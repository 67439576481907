import React, { useEffect, useRef, useState } from 'react'
import TsekmoButton from '../../TsekmoButton'
import {
  Theme,
  Button,
  Tabs,
  Tab,
  Card,
  Menu,
  MenuItem,
  Avatar,
  TextField,
  Dialog,
} from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import { makeStyles } from '@mui/styles'
import UIConstants from '../../../../theme/constants/ui-constants'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import CoPresentIcon from '@mui/icons-material/CoPresent'
import TsekmoSocialPostItem from '../components/TsekmoSocialPostItem'
import DropUpload from '../../DropUpload'

const useStyle = makeStyles((theme: Theme) => ({
  tabs: {
    marginTop: '0.75rem',
    '& .MuiTab-root': {
      color: UIConstants.subText,
      textTransform: 'capitalize',
      minHeight: 50,
      marginBottom: '1.25rem',
    },
    '& .MuiTab-root.Mui-selected': {
      color: UIConstants.primaryBgColor,
    },
    '& .MuiTabs-indicator': {
      left: 0,
      backgroundColor: UIConstants.primaryBgColor,
    },
  },
  divClass: {
    maxHeight: 'calc(100vh - 110px)', //hack for getting max height of container since all divs are supposedly 100% //screen height minus padding top and bottom (80px) minus header height (70px)
    overflowY: 'scroll',
  },
}))
function Tsekrs() {
  const classes = useStyle()
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const handleCloseDialog = () => {
    setOpenDialog(false)
  }
  const handleOpenDialog = () => {
    setOpenDialog(true)
  }
  return (
    <div className="w-full h-full overflow-hidden bg-foreground">
      <div className="flex flex-col w-full h-full">
        <div className="w-full flex flex-col items-center lg:p-10">
          <div className="flex gap-2 lg:w-[59%] mr-2 rounded-md">
            <Avatar />
            <div
              className="w-full h-full rounded-full border bg-white px-5 flex items-center text-subText hover:cursor-text"
              onClick={handleOpenDialog}
              onKeyDown={handleOpenDialog}>
              Have something to post?
            </div>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
              <Card className="p-5 lg:p-10 flex flex-col">
                <p className="lg:hidden block text-center mb-2 font-bold text-primaryBg">
                  Create Post
                </p>
                <div className="flex w-full">
                  <Avatar className="lg:flex hidden" />
                  <div className="ml-2 w-full">
                    <p className="font-semibold lg:block hidden">
                      Cyril Verdad
                    </p>
                    <div className="flex flex-col gap-4 w-full lg:min-w-[400px] pt-2">
                      <TextField
                        placeholder="Title"
                        size="small"
                        fullWidth
                        multiline></TextField>
                      <TextField
                        placeholder="Description"
                        size="small"
                        fullWidth
                        multiline
                        rows={5}
                        maxRows={5}></TextField>
                      <TextField
                        placeholder="Location"
                        size="small"
                        fullWidth
                        multiline
                        maxRows={3}></TextField>
                      <DropUpload text="Add Photo" dragText="Upload Photo" onFileDrop={() => {return}}/>
                      <div className="w-full flex justify-end">
                        <TsekmoButton>
                         + Create Post
                        </TsekmoButton>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </Dialog>
          </div>
        </div>
        <div
          className={`w-full gap-10 flex flex-col items-center lg:px-10 ${classes.divClass}`}>
          <TsekmoSocialPostItem />
          <TsekmoSocialPostItem />

          {/* <TsekrsPostItem /> */}
        </div>
      </div>
    </div>
  )
}

export default Tsekrs

import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { UserData } from '../../../../store/app/user/types';
import { useDispatch } from 'react-redux';
import { cancelSubscription, getProductList, getRetrieveSubscriptionData } from '../../../../store/app/user/actions';
import { useNavigate } from 'react-router-dom';

interface SubscriptionStatusProps {
  subscriptionData: any;
  userData: UserData;
}

const SubscriptionStatus: React.FC<SubscriptionStatusProps> = ({subscriptionData, userData}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const data = subscriptionData?.getSubscriptionData;

  console.log(`Check here data: ${JSON.stringify(data)}`)

  if (!data) {
    return (
      <Box sx={{ minWidth: 275 }}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h5" component="div">
              No Data
            </Typography>
          </CardContent>
        </Card>
      </Box>
    );
  }

  const handleCancel = (user_id: number) => {
    dispatch(cancelSubscription(user_id));
    //Disaptch retrieve subscription again for clearing the data
    dispatch(getRetrieveSubscriptionData(user_id));
  }

  const handleUpdate = (limit: number, user_type: number) => {
    dispatch(getProductList('', limit, user_type));
    navigate('/business-owner/pricing-table')
  }

  const { current_period_end, current_period_start, status, plan } = data;
  
  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h5" component="div">
            Current Subscription
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Product Name: {plan.nickname}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Expiration: {new Date(current_period_end * 1000).toLocaleDateString()}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Subscribed At: {new Date(current_period_start * 1000).toLocaleDateString()}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Price: ${plan.amount_decimal / 100}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Status: {status}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {status === 'trialing' ? `Trial End: ${new Date(subscriptionData.getSubscriptionData.trial_end * 1000).toLocaleDateString()}` : ''}
          </Typography>
        </CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
          <Button size="small" variant="outlined" color="error" onClick={() => handleCancel(userData.id!)}>
            Cancel Subscription
          </Button>
          <Button size="small" variant="outlined" color="primary" sx={{ ml: 1 }} onClick={() => handleUpdate(18,userData.user_type!)}>
            Upgrade Subscription
          </Button>
        </Box>
      </Card>
    </Box>
  );
  
}

export default SubscriptionStatus;



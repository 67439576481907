import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  SetStateAction,
  Dispatch,
} from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Badge,
  Checkbox,
  Tooltip,
  Theme,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { makeStyles } from '@mui/styles'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { createCleanLog } from '../../../../store/app/listing/actions'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import UIConstants from '../../../../theme/constants/ui-constants'
import ImageScroller from './ImageScroller'
import { isJson } from '../../../../app/utils/common-utils'
interface LogItem {
  id: number
  disabled: boolean
  text: string
  checked: boolean
  broken?: boolean
  empty?: boolean
  comment: string
  logTime: Date | null
  category: string
  imageList: FileList
}
interface CleanLogCheckerProps {
  listing: any
  cleanLogData: any
  setCleanLogData: Dispatch<SetStateAction<any[]>>
  updateFileList?: (files: any) => void
}

const useStyle = makeStyles((theme: Theme) => ({
  textField: {
    fontSize: 12,
  },
}))

/**
 * @deprecated Use 'BathroomCleanLogChecker' instead
 */
const CleanLogChecker = forwardRef(
  (
    {
      listing,
      cleanLogData,
      setCleanLogData,
      updateFileList,
    }: CleanLogCheckerProps,
    ref
  ) => {
    const classes = useStyle()
    const [cleanLogItems, setCleanLogItems] = useState<any>()
    const [currentFileList, setCurrentFileList] = useState<any>([])

    useImperativeHandle(ref, () => ({
      getCleanLogData() {
        return cleanLogItems
      },
    }))

    useEffect(() => {
      if (cleanLogData?.length > 0) {
        setCleanLogItems(cleanLogData)
      } else {
        setCleanLogItems(
          isJson(listing?.cleanlog_structure)
            ? JSON.parse(listing?.cleanlog_structure)
            : listing?.cleanlog_structure
        )
        setCleanLogItems((logItems: any) =>
          logItems.map((item: any) => ({
            ...item,
            items: item.items.map((item: any) => ({
              ...item,
              logTime: new Date(),
            })),
          }))
        )
      }
    }, [listing])

    useEffect(() => {
      if (!window.location.pathname.toString().includes('customer/listing/')) {
        return
      }
      if (cleanLogItems) {
        setCleanLogData(cleanLogItems)
      }
    }, [cleanLogItems])

    const handleCheckItem = (cleanLogItem: LogItem) => {
      const updatedCleanLogItems = cleanLogItems
      const itemCategory = updatedCleanLogItems.find(
        (item: any) => item.name === cleanLogItem.category
      )
      const itemCategoryIdx = updatedCleanLogItems.indexOf(itemCategory)
      const itemIdx = itemCategory.items.indexOf(
        itemCategory.items.find((item: any) => item.id === cleanLogItem.id)
      )
      itemCategory.items[itemIdx].broken = false
      if (itemCategory.items[itemIdx].checked) {
        itemCategory.items[itemIdx].checked = false
      } else {
        itemCategory.items[itemIdx].checked = true
      }
      updatedCleanLogItems[itemCategoryIdx] = itemCategory
      setCleanLogItems([...updatedCleanLogItems])
    }

    const handleSelectAll = (cleanLogItem: any) => {
      const updatedCleanLogCategory = cleanLogItem
      const cleanLogCategoryIdx = cleanLogItems.indexOf(updatedCleanLogCategory)
      if (cleanLogItem.items.every((item: any) => item.checked === true)) {
        updatedCleanLogCategory.items = updatedCleanLogCategory.items.map(
          (categoryItem: any) => ({
            ...categoryItem,
            checked: false,
            broken: false,
            imageList: [],
          })
        )
      } else {
        updatedCleanLogCategory.items = updatedCleanLogCategory.items.map(
          (categoryItem: any) => ({
            ...categoryItem,
            checked: true,
            broken: false,
          })
        )
      }
      const updatedCleanLogItems = cleanLogItems
      updatedCleanLogItems[cleanLogCategoryIdx] = updatedCleanLogCategory
      setCleanLogItems([...updatedCleanLogItems])
    }

    const handleToggleBroken = (cleanLogItem: LogItem) => {
      const updatedCleanLogItems = cleanLogItems
      const itemCategory = updatedCleanLogItems.find(
        (item: any) => item.name === cleanLogItem.category
      )
      const itemCategoryIdx = updatedCleanLogItems.indexOf(itemCategory)
      const itemIdx = itemCategory.items.indexOf(
        itemCategory.items.find((item: any) => item.id === cleanLogItem.id)
      )

      if (itemCategory.name === 'Refillables') {
        if (itemCategory.items[itemIdx].empty) {
          itemCategory.items[itemIdx].empty = false
        } else {
          itemCategory.items[itemIdx].empty = true
        }
      } else {
        if (itemCategory.items[itemIdx].broken) {
          itemCategory.items[itemIdx].broken = false
        } else {
          itemCategory.items[itemIdx].broken = true
        }
      }
      itemCategory.items[itemIdx].imageList = []
      updatedCleanLogItems[itemCategoryIdx] = itemCategory
      setCleanLogItems([...updatedCleanLogItems])
    }

    const handleInsertComment = (e: any, cleanLogItem: LogItem) => {
      const updatedCleanLogItems = cleanLogItems
      const itemCategory = updatedCleanLogItems.find(
        (item: any) => item.name === cleanLogItem.category
      )
      const itemCategoryIdx = updatedCleanLogItems.indexOf(itemCategory)
      const itemIdx = itemCategory.items.indexOf(
        itemCategory.items.find((item: any) => item.id === cleanLogItem.id)
      )
      itemCategory.items[itemIdx].comment = e.target.value
      updatedCleanLogItems[itemCategoryIdx] = itemCategory
      setCleanLogItems([...updatedCleanLogItems])
    }

    const handleAddCleanLogImage = (id: number, category: string, e: any) => {
      const updatedCleanLogItems = cleanLogItems
      const itemCategory = updatedCleanLogItems.find(
        (item: any) => item.name === category
      )
      const itemCategoryIdx = updatedCleanLogItems.indexOf(itemCategory)
      const itemIdx = itemCategory.items.indexOf(
        itemCategory.items.find((item: any) => item.id === id)
      )

      if (itemCategory.items[itemIdx].imageList === undefined) {
        itemCategory.items[itemIdx].imageList = e.target.files
        setCurrentFileList(e.target.files)
      } else {
        itemCategory.items[itemIdx].imageList = [
          ...itemCategory.items[itemIdx].imageList,
          ...Array.from(e.target.files),
        ]
        setCurrentFileList((currentFileList: any) => [
          ...currentFileList,
          ...Array.from(e.target.files),
        ])
      }

      updatedCleanLogItems[itemCategoryIdx] = itemCategory
      setCleanLogItems([...updatedCleanLogItems])
    }

    const handleDeleteCleanLogImage = (item: any, index: number) => {
      const updatedCleanLogItems = cleanLogItems
      const itemCategory = updatedCleanLogItems.find(
        (itemCategory: any) => itemCategory.name === item.category
      )
      const itemCategoryIdx = updatedCleanLogItems.indexOf(itemCategory)
      const itemIdx = itemCategory.items.indexOf(
        itemCategory.items.find(
          (categoryItem: any) => categoryItem.id === item.id
        )
      )
      let imageListClone = itemCategory.items[itemIdx].imageList
      imageListClone = Array.from(imageListClone)
      imageListClone.splice(index, 1)

      setCurrentFileList((currentFileList: any) => {
        const currentFileListClone = Array.from(currentFileList)
        currentFileListClone.splice(index, 1)
        return currentFileListClone
      })
      itemCategory.items[itemIdx].imageList = imageListClone as FileList
      updatedCleanLogItems[itemCategoryIdx] = itemCategory
      setCleanLogItems([...updatedCleanLogItems])
    }

    useEffect(() => {
      setCleanLogData(cleanLogItems)
      updateFileList && updateFileList(currentFileList)
    }, [currentFileList])

    return (
      <div className="w-full" ref={ref as React.RefObject<HTMLDivElement>}>
        <span className="ml-4 text-sm text-mainText">
          {cleanLogItems &&
            moment(cleanLogItems[0].items[0].logTime).format('lll')}
        </span>
        <div className="mt-4"></div>
        {cleanLogItems &&
          cleanLogItems.map(
            (cleanLogItem: any) =>
              cleanLogItem.items.every(
                (item: LogItem) => item.disabled === false
              ) && (
                <Accordion key={cleanLogItem.name}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${cleanLogItem.name}-content`}
                    id={`${cleanLogItem.name}-header`}
                  >
                    <Badge
                      badgeContent={`+
                    ${
                      cleanLogItem.items.length -
                      cleanLogItem.items.filter(
                        (item: LogItem) => item.disabled
                      ).length
                    }`}
                      color="primary"
                      sx={{ '&.MuiBadge-root': { fontSize: '12px' } }}
                    >
                      <Typography
                        sx={{ marginRight: '18px' }}
                        className="text-sm font-semibold text-mainText"
                      >
                        {cleanLogItem.name}
                      </Typography>
                    </Badge>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="-mt-6">
                      {/* TABLE */}
                      <div className="border">
                        {/* TABLE HEAD */}
                        <div className="border-b-[1px] w-full flex items-center gap-2 py-1 px-4">
                          <Tooltip title="Select All" arrow placement="right">
                            <Checkbox
                              size="small"
                              checked={cleanLogItem.items.every(
                                (item: any) => item.checked === true
                              )}
                              onChange={() => handleSelectAll(cleanLogItem)}
                            />
                          </Tooltip>
                          <span className="text-sm font-semibold text-mainText">
                            Item
                          </span>
                        </div>
                        {cleanLogItem.items.map(
                          (item: LogItem) =>
                            !item.disabled && (
                              // TABLE ROW
                              <div
                                key={item.id}
                                className="w-full flex items-center py-4 px-4 border border-gray-50 h-fit"
                              >
                                {/* TABLE CELL */}
                                <div>
                                  <Checkbox
                                    size="small"
                                    checked={item.checked}
                                    onChange={() => handleCheckItem(item)}
                                  />
                                </div>
                                <div className="text-mainText text-sm font-medium ml-2 w-[20%]">
                                  {item.text}
                                </div>
                                <div className="w-full ml-24 flex flex-col h-fit">
                                  <div className="items-center h-full text-mainText text-sm font-medium flex w-full">
                                    {item.checked ? (
                                      <span className="font-semibold text-[#9ABC66] ml-auto">
                                        Clean ✓
                                      </span>
                                    ) : (
                                      <div className="flex w-full gap-6 items-center">
                                        <div className="flex gap-1">
                                          <h1>
                                            {item.category === 'Refillables'
                                              ? 'None'
                                              : 'Broken?'}
                                          </h1>
                                          <Tooltip
                                            arrow
                                            title={
                                              item.category === 'Refillables'
                                                ? 'Item needs refilling'
                                                : 'Item is broken'
                                            }
                                            placement="right"
                                          >
                                            <Checkbox
                                              size="small"
                                              checked={
                                                item.category === 'Refillables'
                                                  ? item.empty
                                                  : item.broken
                                              }
                                              onChange={() =>
                                                handleToggleBroken(item)
                                              }
                                              sx={{ padding: 0 }}
                                            ></Checkbox>
                                          </Tooltip>
                                        </div>
                                        <div className="grid place-items-center p-2 relative hover:bg-gray-200 active:bg-gray-300 duration-150 rounded-full">
                                          <AddPhotoAlternateIcon
                                            sx={{
                                              width: '23px',
                                              height: '23px',
                                              color: UIConstants.subText,
                                            }}
                                          />
                                          <input
                                            type="file"
                                            multiple
                                            className="hover:cursor-pointer opacity-0 absolute inset-0 w-full h-full"
                                            accept="image/*"
                                            onChange={(event) =>
                                              handleAddCleanLogImage(
                                                item.id,
                                                item.category,
                                                event
                                              )
                                            }
                                          />
                                        </div>
                                        <div>
                                          <Tooltip
                                            followCursor={true}
                                            title="Add a comment"
                                          >
                                            <TextField
                                              fullWidth
                                              placeholder="Not Clean ✘"
                                              size="small"
                                              className="text-sm"
                                              InputProps={{
                                                classes: {
                                                  input: classes.textField,
                                                },
                                              }}
                                              onChange={(e) =>
                                                handleInsertComment(e, item)
                                              }
                                            />
                                          </Tooltip>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  {item.imageList &&
                                    item.imageList.length > 0 && (
                                      <div className="w-full mt-2">
                                        {/* <ImageScroller
                                          currentItem={item}
                                          deleteImage={
                                            handleDeleteCleanLogImage
                                          }
                                          imageFileList={item.imageList}
                                        /> */}
                                      </div>
                                    )}
                                </div>
                                {/* <div className="flex">
                                  <div className="flex gap-4 items-center">
                                    {item.checked ? (
                                      <span className="font-semibold text-[#9ABC66]">
                                        Clean ✓
                                      </span>
                                    ) : (
                                      <Tooltip
                                        followCursor={true}
                                        title="Add a comment"
                                      >
                                        <TextField
                                          fullWidth
                                          placeholder="Not Clean ✘"
                                          size="small"
                                          className="text-sm"
                                          InputProps={{
                                            classes: {
                                              input: classes.textField,
                                            },
                                          }}
                                          onChange={(e) =>
                                            handleInsertComment(e, item)
                                          }
                                        />
                                      </Tooltip>
                                    )}
                                    {!item.checked && (
                                      <div className="w-full h-full flex items-center">
                                        <div className="grid place-items-center relative hover:bg-gray-200 active:bg-gray-300 duration-150 rounded-full">
                                          <AddPhotoAlternateIcon
                                            sx={{
                                              width: '22px',
                                              height: '22px',
                                              color: UIConstants.subText,
                                            }}
                                          />
                                          <input
                                            type="file"
                                            multiple
                                            className="hover:cursor-pointer opacity-0 absolute inset-0 w-full h-full"
                                            accept="image/*"
                                            onChange={(event) =>
                                              handleAddCleanLogImage(
                                                item.id,
                                                item.category,
                                                event
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="ml-4">
                                          <ImageScroller
                                            currentItem={item}
                                            deleteImage={
                                              handleDeleteCleanLogImage
                                            }
                                            imageFileList={item.imageList}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div> */}
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              )
          )}
      </div>
    )
  }
)

export default CleanLogChecker

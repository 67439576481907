import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Checkbox,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useEffect } from 'react'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import UIConstants from '../../../../theme/constants/ui-constants'
import ImageScroller from '../../../common/Listing/components/ImageScroller'
import { makeStyles } from '@mui/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

interface CleanLogProps {
  cleanLogData: any
}
interface LogItem {
  id: number
  disabled: boolean
  text: string
  checked: boolean
  broken?: boolean
  empty?: boolean
  comment: string
  logTime: Date | null
  category: string
  imageList: any
}

function CleanLogHome({ cleanLogData }: CleanLogProps) {
  return (
    <div className="p-8 min-w-[850px] max-w-[850px] overflow-x-hidden">
      <div className="w-full text-center mb-8 flex flex-col gap-2">
        <span className="font-semibold text-xl text-primaryBg">Clean Log</span>
      </div>
      <div className="mb-2">
        <span className="text-xs text-subText">
          Made by {cleanLogData.name} at {cleanLogData.time}
        </span>
      </div>

      {cleanLogData &&
        cleanLogData.items.map(
          (cleanLogItem: any) =>
            cleanLogItem.items.every(
              (item: LogItem) => item.disabled === false
            ) && (
              <Accordion key={cleanLogItem.name}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${cleanLogItem.name}-content`}
                  id={`${cleanLogItem.name}-header`}
                >
                  <Badge
                    badgeContent={`+
                    ${
                      cleanLogItem.items.length -
                      cleanLogItem.items.filter(
                        (item: LogItem) => item.disabled
                      ).length
                    }`}
                    color="primary"
                    sx={{ '&.MuiBadge-root': { fontSize: '12px' } }}
                  >
                    <Typography
                      sx={{ marginRight: '18px' }}
                      className="text-sm font-semibold text-mainText"
                    >
                      {cleanLogItem.name}
                    </Typography>
                  </Badge>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="-mt-6">
                    {/* TABLE */}
                    <div className="border">
                      {/* TABLE HEAD */}
                      <div className="border-b-[1px] w-full flex items-center gap-2 py-1 px-4">
                        <span className="text-sm font-semibold text-mainText">
                          Item
                        </span>
                      </div>
                      {cleanLogItem.items.map(
                        (item: LogItem) =>
                          !item.disabled && (
                            // TABLE ROW
                            <div
                              key={item.id}
                              className="w-full flex items-center py-4 px-4 border border-gray-50 h-fit"
                            >
                              {/* TABLE CELL */}
                              <div className="text-mainText text-sm font-medium ml-2 w-[20%]">
                                {item.text}
                              </div>
                              <div className="w-full ml-24 flex flex-col h-fit">
                                <div className="items-center h-full text-mainText text-sm font-medium flex w-full">
                                  {item.checked ? (
                                    <span className="font-semibold text-[#9ABC66] ml-auto">
                                      Clean ✓
                                    </span>
                                  ) : (
                                    <>
                                      <div className="flex max-w-[300px] gap-6 items-center">
                                        {item.imageList &&
                                          item.imageList.length > 0 && (
                                            <ImageScroller
                                              webImageFiles={item.imageList}
                                            />
                                          )}
                                      </div>
                                      <textarea disabled className='ml-auto border p-2'>{item.comment}</textarea>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          )
                      )}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            )
        )}
    </div>
  )
}

export default CleanLogHome

import { Paper, Theme, Typography, useMediaQuery, useTheme, MenuItem } from '@mui/material';
import { SelectInput } from '../../../theme/components/input/Select';
import { makeStyles } from '@mui/styles';
import UIConstants from '../../../theme/constants/ui-constants';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, } from 'react-router-dom';
import { CustomButton } from '../../../theme/components/buttons/Button';
import { useMemo } from 'react';
import background from "../../../assets/images/background.png";
import logo from "../../../assets/images/logo.svg";
import mobileLogo from "../../../assets/images/logo_mobile.svg";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { CreateActionModel, UserData, UserType } from '../../../store/app/user/types';
import { useState , useEffect} from 'react';
import { AuthData } from '../../../store/app/types';
import { checkZipCode, updateZipEligibilityStatus, setWaitlistEmail} from '../../../store/app/user/actions'
import { InputText } from '../../../theme/components/input/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        background: UIConstants.rootBgColor,
        backgroundImage: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundSize: 'contain',
        backgroundPosition: 'center top',
        minHeight: `100vh`,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'column',
        [theme.breakpoints.up("sm")]: {
            flexDirection: 'row',
            backgroundSize: 'cover',
        },
    },
    logodiv: {
        minHeight: "9rem",
        position: 'fixed',
        top: '1.5rem',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        [theme.breakpoints.up("sm")]: {
            marginTop: "15%",
            position: 'unset',
            top: 'unset',
            width: 'auto'
        }
    },
    logo: {
        height: '40px',
        [theme.breakpoints.up("sm")]: {
            height: '70px',
            marginLeft: "15%",
        },
    },
    quiz: {
        background: UIConstants.rootBgColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: "100%",
        padding: `0 ${theme.spacing(3)}`,
        position: 'relative',
        top: '4rem',
        height: "calc(100vh - 9rem)",
        [theme.breakpoints.up("sm")]: {
            position: 'unset',
            maxWidth: '750px',
            height: "100%",
            margin: 'auto',
            top: 'unset',
            padding: `${theme.spacing(3)}`,
        },
    },
    questions: {
        display: 'flex',
    },
    signupBox: {
        background: UIConstants.rootBgColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: "100%",
        padding: `0 ${theme.spacing(3)}`,
        position: 'relative',
        top: '4rem',
        height: "calc(100vh - 9rem)",
        [theme.breakpoints.up("sm")]: {
            position: 'unset',
            maxWidth: '400px',
            height: "100%",
            margin: 'auto',
            top: 'unset',
            padding: `${theme.spacing(3)}`,
        },
    },
    heading: {
        margin: `${theme.spacing(1)} ${theme.spacing(4)}`,
        '& .mainHeading': {
            fontWeight: 600,
            fontSize: '18px',
            fontFamily: UIConstants.appFont,
            color: UIConstants.primaryBgColor
        },
        '& .subHeading': {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '27px',
            fontFamily: UIConstants.appFont,
            color: UIConstants.primaryBgColor,

        }
    },
    signUpInputBox: {
        minWidth: '250px',
        margin: `${theme.spacing(4)} 0`,
        width: '100%',
        [theme.breakpoints.up("sm")]: {
            minWidth: '350px',
        },
    },
    formControl: {
        margin: `${theme.spacing(3)} ${theme.spacing(1)}`,
        display: 'flex',
        // flexDirection: 'column-reverse',
    },
    becomeAHost: {
        color: UIConstants.primaryBgColor,
        margin: 0, width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    termsAndCondition: {
        color: UIConstants.primaryBgColor,
        '& .MuiFormControlLabel-label': {
            fontSize: '14px',
            opacity: '0.5'
        },
        '& .MuiFormControlLabel-label:hover': {
            fontSize: '14px',
            opacity: '1'
        }
    },
    labelOtherSignup: {
        color: UIConstants.primaryBgColor,
        fontSize: '14px',
        opacity: '0.5'
    },
    link: {
        textDecoration: "none",
        color: UIConstants.primaryBgColor,
        fontSize: '14px',
        fontWeight: 400,
        opacity: '.5',
        "&:hover": {
            opacity: '1',
        }
    },
    privacyPolicyText: {
        color: UIConstants.primaryBgColor
    },
    buttons: {
        margin: '10px'
    },
    iconSVG: {
        '& svg' : {
            width: '4em',
            height: '4em'
        }
    },
    waitlist: {
        marginTop: '20px',
        maxWidth: '500px',
        alignContent: 'center',
        margin: 'auto'
    }
}));

interface IFormInput {

    email: string;
    zipcode: string;

    businessIndustry: string;

}

function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

interface SignUpProps {
    setUser: (user: UserData) => void;
    setValidUser: (isValidUser: boolean) => void;
    createUser: (model: CreateActionModel) => void;
    userData: UserData,
    setAuthData: (model: AuthData) => void;
    authData: AuthData;
}

export default function ZipCheck() {
    let navigate = useNavigate();
    let query = useQuery();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const appLogo = matches ? logo : mobileLogo;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isZipCodeEligible = useSelector((state: ApplicationState) => state.user.isZipCodeEligible);
    const [isShowNotification, setShowNotification] = useState<boolean>(true);
    const { control, register, handleSubmit, formState: { errors, isValid }, watch } = useForm<IFormInput>({
        mode: 'all'
    });

    const [isStartPage, setStartPage] = useState<boolean>(true);
    const [isSecondPage, setSecondPage] = useState<boolean>(false);
    const [notEligible, setEligibility] = useState<boolean>(false);
    const [isZipCodeCheck, setZipcodeCheck] = useState<boolean>(false);
    const [quizData] = useState([{
        id: 1, answer: ''
    },
{
    id:2, answer: ''
}]);

    useEffect(() => {
        setShowNotification(isZipCodeEligible);
    }, [isZipCodeEligible]);

    const onSubmit: SubmitHandler<IFormInput> = data => {
       const zipCodeData = data.zipcode.toLowerCase();
       const zipWithRemoveWhiteSpace = zipCodeData.replace(/\s/g,'');
       const zipCode = zipWithRemoveWhiteSpace.replace(/[^a-zA-Z0-9 ]/g, "");
       const business = data.businessIndustry;
       const fromParam = query.get("from");
       dispatch(checkZipCode({ zipCode , business, fromParam, navigate}));
       setZipcodeCheck(false);
       /**/
    }

    const clearState = () => {
        setStartPage(true);
        setSecondPage(false);
        setEligibility(false);
        setZipcodeCheck(false);
        setShowNotification(true);
    };

    const waitListEmail: SubmitHandler<IFormInput> = data => {
        const email = data.email;
        clearState();
        dispatch(setWaitlistEmail({ email, navigate}));
        /**/
     }

    const logoClick = ()=> {
        navigate(`/landing`);
    }

    const businessIndustryTypeList:string[] = ["Restaurants","ConvenienceStores","GasStations","Supermarkets","ClothingRetailStores","LibrariesAndPublicBuildings","SchoolsAndUniversities","MovieTheatersAndEntertainmentVenues","HomeImprovementCenters","DollarStores","DepartmentStores","ShoppingMalls","ElectronicRetailStores","AirportsAndTransportationHubs","PublicParksAndRecreationalFacilities"];

    const quizAnswerClick = (quiz:any, answer:any) => {
        switch (quiz) {
            case 1:
                quizData[0].answer = answer
                // quizData[0].answer = answer;
                setStartPage(false);
                setSecondPage(true);
                break;
            case 2:
                quizData[1].answer = answer;
                // quizData[1].answer = answer;
                setSecondPage(false);
                if (quizData[0].answer == 'yes' || quizData[1].answer == 'yes') {
                    setZipcodeCheck(true);
                } else {
                    setEligibility(true);
                }
                break;
            default:
                break;
        }
    };

    const isValidEmail = (email: string) =>
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            email
    );

    return (
        <>
            <div className={classes.root}>
                <div className={classes.logodiv}> <img
                    src={appLogo}
                    alt={t('appName')}
                    className={classes.logo}
                    onClick={() => logoClick()}
                /></div>
                    <Paper elevation={matches ? 3 : 0} className={classes.signupBox}>
                        <div>
                            <div className={classes.heading}>
                                <Typography className='subHeading'>
                                    {'Please enter your zipcode to check your eligibility'}
                                </Typography>
                            </div>
                            <div className={classes.signUpInputBox}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className={classes.formControl}>
                                            <Controller
                                                name='zipcode'
                                                control={control}
                                                defaultValue={''}
                                                render={({ field }) => (
                                                    <InputText fullWidth
                                                        variant="outlined"
                                                        error={!!errors?.zipcode}
                                                        size="small"
                                                        label={'Zipcode'}
                                                        {...register('zipcode', {
                                                            required: 'zipcode is required',
                                                        })}
                                                        {...field}
                                                        helperText={errors?.zipcode?.message} />
                                                )}
                                            />
                                    </div>
                                    <div className={classes.formControl}>
                                    <Controller
                                            name='businessIndustry'
                                            control={control}
                                            defaultValue= 'GasolineStation'
                                            render={({ field }) => (
                                                <SelectInput
                                                    {...field}
                                                    label='Business Industry'
                                                    size="small"
                                                    labelId="signup-country-select">
                                                    {businessIndustryTypeList?.map((IndustryType: string, index: number) =>
                                                    (<MenuItem
                                                        value={IndustryType}
                                                        key={IndustryType + index}>
                                                        {t(`signUp.${IndustryType}`)}
                                                    </MenuItem>))
                                                    }
                                                </SelectInput>
                                            )}
                                        />
                                    </div>
                                    <div className={classes.formControl}>
                                        <CustomButton
                                            type="submit"
                                            variant="contained"
                                            disabled={!isValid}
                                            fullWidth>{'Check Eligibility'}</CustomButton>
                                    </div>
                                </form >
                            </div>
                        </div>
                    </Paper>
            </div>
        </>
    );
}


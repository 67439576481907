import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { CustomButton } from '../../../../theme/components/buttons/Button'
import { Controller, useForm } from 'react-hook-form'
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
} from '@mui/material'
import UIConstants from '../../../../theme/constants/ui-constants'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../../store'
import { fetchListingData } from '../../../../store/app/community/actions'
import { fetchListingTypes } from '../../../../store/app/listing/actions'
import LocationSelector from '../../../../theme/components/input/LocationSelector'
import DropUpload from '../../DropUpload'
import UploadImageList from '../../../../theme/components/image-list/UploadImageList'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { ClockIcon } from '../../../../theme/components/icons/ClockIcon'
import { ListingDataModel } from '../../../../store/app/community/types'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import MobileTimePicker from '@mui/lab/MobileTimePicker'
import { isJson } from '../../../../app/utils/common-utils'
import { event } from 'react-ga'
import { cloneDeep } from 'lodash'

import { makeStyles } from '@mui/styles'

const useStyle = makeStyles((theme: Theme) => ({
  inputBorderPrimary: {
    '&.MuiInputBase-root': {
      '& > fieldset': {
        borderColor: UIConstants.primaryBgColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: UIConstants.primaryBgColor,
      },
    },
  },
  textInput: {
    '& label': {
      color: UIConstants.primaryBgColor,

      '&.Mui-disabled': {
        color: '#9d9e9e',
      },
    },

    '& label.Mui-focused': {
      color: UIConstants.primaryBgColor,
    },

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: UIConstants.primaryBgColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: UIConstants.primaryBgColor,
      },
    },
  },
}))

interface IFormInput {
  incident_type: string
  listing_id: number
  description: string
  date: Date
  rules: string
  owner_id: number
  location: string
  listing_type: any
  file_list: any
}

interface CustomFile extends File {
  fileName?: string
}

interface BathroomIncidentFormProps {
  listing: any
  uploadImages: any
  onAddImage: (image: any) => void
  updateUploadImages: (images: any) => void
  handleDeleteImageFromIncidentForm: (index: any) => void
  isBrokenPresent: boolean
}

const BathroomIncidentForm = forwardRef(
  (
    {
      listing,
      uploadImages,
      onAddImage,
      updateUploadImages,
      handleDeleteImageFromIncidentForm,
      isBrokenPresent,
    }: BathroomIncidentFormProps,
    ref
  ) => {
    const classes = useStyle()
    const incidentReportForm = useForm<IFormInput>({ mode: 'all' })
    const dispatch = useDispatch()
    const incidentTypeList: string[] = [
      'Vandalism',
      'Drugs Related',
      'Uncleanliness',
      'Others',
    ]
    const { userData } = useSelector((state: ApplicationState) => state.user)
    const { listingData } = useSelector(
      (state: ApplicationState) => state.businessCommunity
    )

    useImperativeHandle(ref, () => ({
      getIncidentReportForm: () => {
        return incidentReportForm
      },
    }))

    useEffect(() => {
      if (userData.id && listingData.length === 0) {
        dispatch(fetchListingTypes())
        dispatch(fetchListingData(userData.id, false))
        incidentReportForm.setValue('listing_id', listing.id)
        incidentReportForm.setValue(
          'location',
          isJson(listing.location)
            ? JSON.parse(listing.location)?.label
            : listing.location
        )
        incidentReportForm.setValue('owner_id', userData.id)
      }
    }, [userData.id, listing])

    useEffect(() => {
      incidentReportForm.setValue('file_list', uploadImages)
    }, [uploadImages])

    const handleChangeListing = (id: number) => {
      const currentLocation = listingData.find(
        (listing) => listing.id === id
      )?.location
      incidentReportForm.setValue(
        'location',
        currentLocation ? JSON.parse(currentLocation)?.label : ''
      )
    }

    const addPhoto = (event: any) => {
      const files = event.target.files
      Array.from(files).forEach((file: any) => {
        const newImage: CustomFile = new File(
          [file],
          Date.now() + '_' + file.name,
          {
            type: file.type,
          }
        )
        newImage.fileName = newImage.name
        onAddImage(newImage)
      })
      incidentReportForm.trigger()
    }

    const deletePhoto = (index: number) => {
      handleDeleteImageFromIncidentForm(index)
      incidentReportForm.trigger()
    }

    return (
      <div>
        <form className="flex flex-col gap-4 max-w-[620px]">
          <Controller
            name="incident_type"
            control={incidentReportForm.control}
            defaultValue={''}
            render={({ field }) => (
              <FormControl size="small" className={classes.textInput}>
                <InputLabel
                  sx={{
                    color: UIConstants.primaryBgColor,
                    '&.Mui-focused': { color: UIConstants.primaryBgColor },
                  }}
                  error={!!incidentReportForm.formState.errors?.incident_type}
                  disabled={!isBrokenPresent}
                >
                  Incident Type
                </InputLabel>
                <Select
                  {...field}
                  disabled={!isBrokenPresent}
                  label="Incident Type"
                  {...incidentReportForm.register('incident_type', {
                    required: 'This field is required',
                  })}
                  error={!!incidentReportForm.formState.errors?.incident_type}
                >
                  {incidentTypeList?.map(
                    (incidentType: string, index: number) => (
                      <MenuItem value={incidentType} key={incidentType + index}>
                        {incidentType}
                      </MenuItem>
                    )
                  )}
                </Select>
                {incidentReportForm.formState.errors?.incident_type && (
                  <span className="text-xs font-normal text-red-600 mt-2 ml-4">
                    {
                      incidentReportForm.formState.errors?.incident_type
                        ?.message
                    }
                  </span>
                )}
              </FormControl>
            )}
          />

          <Controller
            name="listing_id"
            control={incidentReportForm.control}
            defaultValue={-99}
            render={({ field }) => (
              <FormControl size="small" disabled={true}>
                <InputLabel
                  sx={{
                    color: UIConstants.primaryBgColor,
                    '&.Mui-focused': { color: UIConstants.primaryBgColor },
                  }}
                >
                  Listing
                </InputLabel>
                <Select
                  sx={{
                    '&:focus': {
                      backgroundColor: 'yellow',
                    },
                  }}
                  {...field}
                  label="Listing"
                  {...incidentReportForm.register('listing_id', {
                    required: 'This field is required',
                  })}
                  error={!!incidentReportForm.formState.errors?.listing_id}
                >
                  {listingData?.map(
                    (listing: ListingDataModel, index: number) => (
                      <MenuItem
                        value={listing.id}
                        key={listing.title + index}
                        onClick={() => handleChangeListing(listing.id)}
                      >
                        {listing.title}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            )}
          />
          <Controller
            name="location"
            control={incidentReportForm.control}
            rules={{
              required: 'Location required' as string,
            }}
            defaultValue={''}
            render={({ field }) => (
              <LocationSelector
                {...field}
                disabled={true}
                label="Location or Addresss"
                size="small"
                error={incidentReportForm.formState.errors?.location}
                helperText={
                  incidentReportForm.formState.errors?.location?.message
                }
              />
            )}
          />
          <Controller
            name="description"
            control={incidentReportForm.control}
            defaultValue={''}
            render={({ field }) => (
              <TextField
                {...field}
                label="Incident Report"
                disabled={!isBrokenPresent}
                size="small"
                className={classes.textInput}
                {...incidentReportForm.register('description', {
                  required: 'This field is required',
                })}
                error={!!incidentReportForm.formState.errors?.description}
                helperText={
                  incidentReportForm.formState.errors?.description?.message
                }
                variant="outlined"
                multiline
                minRows={2}
                maxRows={4}
              />
            )}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              name="date"
              control={incidentReportForm.control}
              defaultValue={new Date()}
              rules={{
                required: 'This field is required',
              }}
              render={({ field }) => (
                <MobileTimePicker
                  ampm={true}
                  value={field.value}
                  onChange={field.onChange}
                  disabled={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={'Date and time'}
                      id="input-with-icon-textfield"
                      variant="outlined"
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <ClockIcon />
                          </InputAdornment>
                        ),
                      }}
                      error={!!incidentReportForm.formState.errors?.date}
                      helperText={
                        incidentReportForm.formState.errors?.date?.message
                      }
                    />
                  )}
                />
              )}
            />
          </LocalizationProvider>

          <span className="text-xs text-subText -mt-2">
            The date and time is corresponding to the last time the listing is
            cleaned
          </span>

          <Controller
            name="file_list"
            control={incidentReportForm.control}
            defaultValue={null}
            rules={{
              required: 'A photo of the incident is required',
             
            }}
            render={({ field }) => (
              <>
                <DropUpload
                  {...field}
                  disabled={!isBrokenPresent}
                  text="Add Photo"
                  dragText="Upload Photo"
                  onFileDrop={(event) => addPhoto(event)}
                  error={!!incidentReportForm.formState.errors?.file_list}
                />
                {incidentReportForm.formState.errors?.file_list && (
                  <span className="text-xs font-normal text-red-600 -mt-2 ml-4">
                    {incidentReportForm.formState.errors?.file_list?.message}
                  </span>
                )}
              </>
            )}
          />

          <UploadImageList
            imageFileList={uploadImages}
            onDelete={deletePhoto}
            columns={1}
          />
        </form>
      </div>
    )
  }
)

export default BathroomIncidentForm

import {
    Paper,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
    TextField,
  } from '@mui/material'
  import { makeStyles } from '@mui/styles'
  import UIConstants from '../../../theme/constants/ui-constants'
  import { useTranslation } from 'react-i18next'
  import { CustomButton } from '../../../theme/components/buttons/Button'
  import { useEffect, useMemo } from 'react'
  import background from '../../../assets/images/background.png'
  import logo from '../../../assets/images/logo.svg'
  import mobileLogo from '../../../assets/images/logo_mobile.svg'
  import { useLocation, useNavigate } from 'react-router-dom'
  import { Controller, SubmitHandler, useForm } from 'react-hook-form'
  import _ from 'lodash'
  import { useDispatch, useSelector } from 'react-redux'
  import {
    bathroomChallenge,
    saveBathroomCredential,
  } from '../../../store/app/user/actions'
  import { ApplicationState } from '../../../store'
  import { PhoneInput } from '../../../theme/components/input/PhoneField'
  import { parsePhoneNumberFromString } from 'libphonenumber-js';

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      background: UIConstants.rootBgColor,
      backgroundImage: `url(${background})`,
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',
      backgroundSize: 'contain',
      backgroundPosition: 'center top',
      minHeight: `100vh`,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-around',
      flexDirection: 'column',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        backgroundSize: 'cover',
      },
    },
    logodiv: {
      minHeight: '9rem',
      position: 'fixed',
      top: '1.5rem',
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginTop: '15%',
        position: 'unset',
        top: 'unset',
        width: 'auto',
      },
    },
    logo: {
      height: '40px',
      [theme.breakpoints.up('sm')]: {
        height: '70px',
        marginLeft: '15%',
      },
    },
    signupBox: {
      background: UIConstants.rootBgColor,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '100%',
      padding: `0 ${theme.spacing(3)}`,
      position: 'relative',
      top: '4rem',
      height: 'calc(100vh - 9rem)',
      [theme.breakpoints.up('sm')]: {
        position: 'unset',
        maxWidth: '400px',
        height: '100%',
        margin: 'auto',
        top: 'unset',
        padding: `${theme.spacing(3)}`,
      },
    },
    heading: {
      margin: `${theme.spacing(1)} ${theme.spacing(4)}`,
      '& .mainHeading': {
        fontWeight: 400,
        fontSize: '18px',
        fontFamily: UIConstants.appFont,
        color: UIConstants.primaryBgColor,
      },
      '& .subHeading': {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '27px',
        fontFamily: UIConstants.appFont,
        color: UIConstants.primaryBgColor,
        opacity: 0.5,
      },
    },
    signUpInputBox: {
      minWidth: '250px',
      margin: `${theme.spacing(4)} 0`,
      width: '100%',
    },
    formControl: {
      margin: `${theme.spacing(3)} ${theme.spacing(1)}`,
      display: 'flex',
      // flexDirection: 'column-reverse',
    },
    becomeAHost: {
      color: UIConstants.primaryBgColor,
      margin: 0,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    termsAndCondition: {
      color: UIConstants.primaryBgColor,
      '& .MuiFormControlLabel-label': {
        fontSize: '14px',
        opacity: '0.5',
      },
    },
    labelOtherSignup: {
      color: UIConstants.primaryBgColor,
      fontSize: '14px',
      opacity: '0.5',
    },
    inputBorderPrimary: {
      '&.MuiInputBase-root': {
        '& > fieldset': {
          borderColor: UIConstants.primaryBgColor,
        },
        '&.Mui-focused fieldset': {
          borderColor: UIConstants.primaryBgColor,
        },
      },
    },
  }))
  

    interface IFormInput {
        fullName: string
        email: string
        mobileNumber: string
        product: string
    }
  
    function useQuery() {
        const { search } = useLocation();
        return useMemo(() => new URLSearchParams(search), [search]);
    }

    const detectCountryFromNumber = (phoneNumber: string) => {
        const phoneNumberObject = parsePhoneNumberFromString(phoneNumber);
        if (phoneNumberObject && phoneNumberObject.isValid()) {
          return phoneNumberObject.country; // e.g. "US" or "IN" (ISO 3166-1 alpha-2 country code)
        }
        return null;
    };
  
  const BathroomChallenge: React.FC = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme()
    const { bathroomData, bathroomFetchError } = useSelector((state: ApplicationState) => state.user)
    const matches = useMediaQuery(theme.breakpoints.up('sm'))
    const appLogo = matches ? logo : mobileLogo
    const countries = process.env.REACT_APP_SUPPORTED_COUNTRIES?.split(',')
    const {
      control,
      handleSubmit,
      formState: {isValid },
      reset,
    } = useForm<IFormInput>({
      mode: 'all',
      defaultValues: {
        fullName: '',
        product: '',
        email: '',
        mobileNumber: '',
      },
    })
    let query = useQuery();

    const email = query.get('email');

    const navigate = useNavigate()
  
    const onSubmit: SubmitHandler<IFormInput> = (data) => {
        const country = detectCountryFromNumber(data.mobileNumber);
        const dataToSave = {
            ...data,
            country_code: country
        }
      dispatch(saveBathroomCredential(dataToSave));
      navigate('/')
    }

    useEffect (() => {
        if(email) {
            dispatch(bathroomChallenge(email));
        } else {
            navigate('/not-allowed');
        }
    }, [dispatch, email, navigate]);
    
    useEffect(() => {
        if(bathroomFetchError && Object.keys(bathroomData).length === 0) {
            navigate('/error');
        } else {
            // Reset form values with bathroomData once it's loaded and not empty
            reset({
                fullName: bathroomData.name || '',
                product: bathroomData.product_variant || '',
                email: bathroomData.email || '',
                mobileNumber: bathroomData.mobile_number || '',
            });
        }
      }, [bathroomData, bathroomFetchError, reset, navigate]);
  
    return (
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.root}>
            <div className={classes.logodiv}>
              {' '}
              <img src={appLogo} alt={t('appName')} className={classes.logo} />
            </div>
            <Paper elevation={matches ? 3 : 0} className={classes.signupBox}>
              <div className={classes.heading}>
                <Typography className="mainHeading">
                  {t('personalInfo.mainHeading')}
                </Typography>
              </div>
              <div className={classes.signUpInputBox}>
                <div className={classes.formControl}>
                    <Controller
                        name="fullName"
                        control={control}
                        render={({ field }) => (
                        <TextField
                            {...field}
                            label={t('bathroomChallenge.fullName')}
                            fullWidth
                            disabled={true}
                            variant="outlined"
                            size="small"
                            InputLabelProps={{
                            style: { 
                                fontSize: '18px', 
                                transform: 'translate(10px, -10px) scale(0.75)', // Position the label above
                            },
                            }}
                        />
                        )}
                    />
                </div>
                <div className={classes.formControl}>
                    <Controller
                        name="product"
                        control={control}
                        render={({ field }) => (
                        <TextField
                            {...field}
                            label={t('bathroomChallenge.productHeading')}
                            fullWidth
                            disabled={true}
                            variant="outlined"
                            size="small"
                            InputLabelProps={{
                            style: { 
                                fontSize: '18px', 
                                transform: 'translate(10px, -10px) scale(0.75)', // Position the label above
                            },
                            }}
                        />
                        )}
                    />
                </div>
                <div className={classes.formControl}>
                    <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                        <TextField
                            {...field}
                            label={t('bathroomChallenge.email')}
                            fullWidth
                            variant="outlined"
                            size="small"
                            InputLabelProps={{
                            style: { 
                                fontSize: '18px', 
                                transform: 'translate(10px, -10px) scale(0.75)', // Position the label above
                            },
                            }}
                        />
                        )}
                    />
                </div>
                <div className={classes.formControl}>
                    <PhoneInput
                        onlyCountries={countries}
                        fullWidth
                        label={t('bathroomChallenge.mobileNumber')}
                        variant="outlined"
                        defaultValue={bathroomData?.mobile_number}
                        size="small"
                        defaultCountry={'us'}
                        control={control}
                        name={'mobileNumber'}
                        ></PhoneInput>
                </div>
                <div className={classes.formControl}>
                  <CustomButton
                    type="submit"
                    variant="contained"
                    fullWidth
                    disabled={!isValid}
                  >{(t('bathroomChallenge.registerBtn'))}
                  </CustomButton>
                </div>
              </div>
            </Paper>
          </div>
        </form>
      </>
    )
  }
  export default BathroomChallenge;
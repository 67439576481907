import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSpecificIncidentReport } from '../../../../store/app/community/actions'
import EditIncidentReportCleanLog from './EditIncidentReportCleanLog'
import EditIncidentReportForm from './EditIncidentReportForm'
import {
  CircularProgress,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from '@mui/material'
import TsekmoButton from '../../TsekmoButton'
import { cloneDeep } from 'lodash'
import { editIncidentReportByBo } from '../../../../store/app/listing/actions'
import mime from 'mime'

interface EditIncidentReportByBoFormProps {
  post_id: any
}

interface CustomFile extends File {
  fileName?: string
  key?: string
  location?: string
  uri?: string
}

function EditIncidentReportByBoForm({
  post_id,
}: EditIncidentReportByBoFormProps) {
  const dispatch = useDispatch()
  const incidentReport = useSelector(
    (state: any) => state.businessCommunity.specificIncidentReport
  )
  const [damagedItems, setDamagedItems] = useState<any>([])
  const [cleanLog, setCleanLog] = useState<any>([])
  const [uploadedImages, setUploadedImages] = useState<any>([])
  const [remoteImages, setRemoteImages] = useState<any>([])
  const ReportBathroomCleanLogChecker = useRef<any>(null)
  const ReportBathroomIncidentReport = useRef<any>(null)
  useEffect(() => {
    dispatch(getSpecificIncidentReport({ post_id: post_id }))
  }, [post_id])

  useEffect(() => {
    if (incidentReport) {
      const formattedCleanLog = JSON.parse(incidentReport.items).map(
        (category: any) => ({
          ...category,
          items: category.items.filter((item: any) => item.broken),
        })
      )
      setCleanLog(formattedCleanLog)
      setRemoteImages(
        JSON.parse(incidentReport.image_list).map((image: any) => ({
          ...image,
          fileName: image.name,
          uri: image.location,
          type: mime.getType(image.name),
        }))
      )
      console.log(incidentReport)
    }
  }, [incidentReport])

  const handleUpdateDamagedItems = (e: any, item: any) => {
    const damagedItemsClone = cloneDeep(damagedItems)

    if (
      damagedItemsClone.filter((damagedItem: any) => damagedItem.id === item.id)
        .length > 0
    ) {
      const index = damagedItemsClone.findIndex(
        (damagedItem: any) => damagedItem.id === item.id
      )
      if (e.target.value !== '') {
        damagedItemsClone[index].amount = e.target.value
        setDamagedItems(damagedItemsClone)
      } else {
        setDamagedItems(
          damagedItemsClone.filter(
            (damagedItem: any) => damagedItem.id !== item.id
          )
        )
      }
    } else {
      setDamagedItems([
        ...damagedItemsClone,
        {
          id: item.id,
          name: item.text,
          amount: e.target.value,
        },
      ])
    }
  }

  const formatNumber = (num: any) => {
    return (Math.round(num * 100) / 100).toFixed(2)
  }

  const steps = ['Clean Log', 'Incident Report']

  const handleAddImage = (image: any) => {
    console.log(uploadedImages)
    setUploadedImages((prevState: any) => [...prevState, image])
  }

  const handleUpdateCleanLog = (cleanLog: any) => {
    setCleanLog(cleanLog)
  }

  const updateUploadImages = (images: any) => {
    setUploadedImages(images)
  }

  const onSubmit = async () => {
    const requestBody = {
      cleanLogData: [],
      incidentData: {
        tagged_users: ``,
        damage_items: [],
        is_approved: false,
        not_in_list: false,
        reporter_id: 0,
        bo_incident_type: '',
        bo_description: '',
      },
      cleanLogId: 0,
      listingId: 0,
      postId: 0,
      userId: 0,
    }
    console.log(incidentReport)
    const form_data = new FormData()
    const newIncidentReportData = ReportBathroomIncidentReport.current
      .getIncidentReportForm()
      .getValues()
    console.log('NEW DATA', newIncidentReportData)
    const incidentReportClone = cloneDeep(incidentReport)
    const updatedIncidentReport = cloneDeep(incidentReport)
    delete updatedIncidentReport.items
    delete updatedIncidentReport.image_list
    delete updatedIncidentReport.cleanlog_structure
    delete updatedIncidentReport.anonymous
    delete updatedIncidentReport.notif_token
    delete updatedIncidentReport.listing_type
    delete updatedIncidentReport.is_active
    delete updatedIncidentReport.is_resolved
    delete updatedIncidentReport.is_customer
    delete updatedIncidentReport.owner_email
    delete updatedIncidentReport.costing
    delete updatedIncidentReport.cleanlog_id
    delete updatedIncidentReport.owner_id
    delete updatedIncidentReport.owner_mobile
    delete updatedIncidentReport.store_name
    delete updatedIncidentReport.store_phone
    delete updatedIncidentReport.store_email
    delete updatedIncidentReport.id
    updatedIncidentReport.uploadedImages = [
      ...remoteImages,
      ...uploadedImages,
    ] as any
    updatedIncidentReport.damage_items = damagedItems
    updatedIncidentReport.bo_description = newIncidentReportData.description
    updatedIncidentReport.bo_incident_type = newIncidentReportData.incident_type
    updatedIncidentReport.can_share = newIncidentReportData.can_share
    updatedIncidentReport.quick_estimate = damagedItems.reduce(
      (acc: any, item: any) => acc + parseFloat(item.amount),
      0
    )

    form_data.append(
      'clean_log',
      JSON.stringify({
        logged_at: Date.now(),
        items: ReportBathroomCleanLogChecker.current.getCleanLog(),
      }) as any
    )
    form_data.append('incidentData', JSON.stringify(updatedIncidentReport))
    form_data.append('post_id', incidentReportClone.id)
    form_data.append('cleanlog_id', incidentReportClone.cleanlog_id)
    form_data.append('listing_id', incidentReportClone.listing_id)
    form_data.append('user_id', incidentReportClone.reporter_id)

    const tempUploadedImages = [...remoteImages, ...uploadedImages]
    console.log([...remoteImages, ...uploadedImages])
    await Promise.all(
      tempUploadedImages.map(async (image: any) => {
        if (image.location) {
          console.log('THIS RUNS BEFORE AWAIT')
          const tempBlobImg = await fetch(image.uri).then((res) => res.blob())
          const newImage: CustomFile = new File([tempBlobImg], image.name, {
            type: tempBlobImg.type,
          })
          newImage.uri = image.location
          newImage.fileName = image.name
          console.log('THIS RUNS 1', newImage)
          form_data.append('uploadedImages', newImage as CustomFile, image.name)
        } else {
          console.log('THIS RUNS 2', image)
          image.uri = null
          form_data.append('uploadedImages', image as CustomFile, image.name)
        }
      })
    )
    console.log(JSON.parse(incidentReport.items))
    // Array.from(tempUploadedImages).forEach((image: any) => {
    //   if (image.uri) {
    //     const blob = new Blob([JSON.stringify(image, null, 2)], {
    //       type: 'application/json',
    //     })
    //     const file: CustomFile = new File([blob], image.fileName, {
    //       type: 'application/json',
    //     })
    //     file.uri = image.uri
    //     file.fileName = image.fileName
    //     form_data.append('uploadedImages', file as File, image.fileName)
    //   } else {
    //     form_data.append('uploadedImages', image as File, image.name)
    //   }
    // })

    // const promises = Array.from(tempUploadedImages).map(async (image: any) => {
    //   if (image.uri) {
    //     console.log("RUNNING BEFORE FETCH")
    //     const response = await fetch(image.location);
    //     const blob = await response.blob();
    //     const file: CustomFile = new File([blob], image.name, { type: image.type });
    //     file.fileName = image.name;
    //     file.uri = image.location;
    //   } else {
    //     return image;
    //   }
    // });
  
    // // Wait for all promises to resolve
    // const processedFiles = await Promise.all(promises);
  
    // // Append the processed files to the form_data
    // processedFiles.forEach((file, index) => {
    //   form_data.append('uploadedImages', file as File, tempUploadedImages[index].name);
    // });
  
    console.log(form_data)
    dispatch(editIncidentReportByBo(form_data))
    console.log(
      'CLEAN LOG',
      ReportBathroomCleanLogChecker.current.getCleanLog()
    )
  }

  const handleDeleteImageFromIncidentForm = (index: any) => {
    const currentImage = uploadedImages[index]
    ReportBathroomCleanLogChecker.current?.deleteImage(currentImage)
  }

  return (
    //UNFINISHED
    <div className="p-8 flex flex-col gap-6 max-h-[90vh] overflow-y-scroll">
      <div className=" text-center w-[620px]">
        <span className="font-semibold text-xl text-primaryBg">
          Edit Incident Report
        </span>
      </div>
      {incidentReport ? (
        <Stepper activeStep={0} orientation="vertical">
          {steps.map((label) => (
            <Step key={label} active={true}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                {label === steps[0] && (
                  <EditIncidentReportCleanLog
                    updateUploadImages={updateUploadImages}
                    cleanLog={cleanLog}
                    setCleanLog={handleUpdateCleanLog}
                    handleUpdateDamagedItems={handleUpdateDamagedItems}
                    onAddImage={handleAddImage}
                    uploadedImages={uploadedImages}
                    ref={ReportBathroomCleanLogChecker}
                  />
                )}
                {label === steps[1] && (
                  <EditIncidentReportForm
                    handleDeleteImageFromIncidentForm={
                      handleDeleteImageFromIncidentForm
                    }
                    incidentReport={incidentReport}
                    uploadedImages={uploadedImages}
                    remoteImages={remoteImages}
                    ref={ReportBathroomIncidentReport}
                  />
                )}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      ) : (
        <div className="w-full text-center">
          <CircularProgress size={36} />
        </div>
      )}
      <div>
        <span className="font-semibold">Damaged Items:</span>
        <ul className="list-decimal">
          {damagedItems.length > 0 ? (
            damagedItems.map((item: any) => (
              <li className="mx-10 mt-2">
                <div className="flex justify-between">
                  <span>{item.name}</span>
                  <span>${formatNumber(parseFloat(item.amount))}</span>
                </div>
              </li>
            ))
          ) : (
            <div className="pt-2">
              <span className="ml-5">No damaged items</span>
            </div>
          )}
        </ul>
        {damagedItems.length > 0 && (
          <>
            <div className="flex justify-end mx-10">
              <span className="font-semibold border-t-2 mt-2 pt-2">
                Total:{' '}
                <span>
                  $
                  {formatNumber(
                    damagedItems.reduce(
                      (acc: any, item: any) => acc + parseFloat(item.amount),
                      0
                    )
                  )}
                </span>
              </span>
            </div>
          </>
        )}
      </div>

      <TsekmoButton onClick={onSubmit}>Submit</TsekmoButton>
    </div>
  )
}

export default EditIncidentReportByBoForm

import React, { forwardRef, useImperativeHandle } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { InputText } from '../../../../theme/components/input/TextField'
import { Rating } from '@mui/material'
import { StarBorder } from '@mui/icons-material'

const BathroomReviewForm = forwardRef(({}, ref) => {
  interface IOFormInput {
    rating: number | null
    review_desc: string
  }
  const reviewForm = useForm<IOFormInput>({
    mode: 'all',
  })

  useImperativeHandle(ref, () => ({
    getReviewForm: () => {
      return reviewForm
    },
  }))

  return (
    <div className="flex flex-col gap-2">
      <Controller
        name="rating"
        control={reviewForm.control}
        defaultValue={null}
        rules={{
          required: { value: true, message: 'Please rate your experience' },
          validate: { check: (value) => value !== 0 },
        }}
        render={({ field }) => (
          <div className="flex flex-col gap-1">
            <Rating
              {...field}
              precision={0.5}
              emptyIcon={
                <StarBorder style={{ opacity: 0.55 }} fontSize="inherit" />
              }
            />
            {reviewForm.formState.errors?.rating && (
              <span className="text-xs font-light text-red-700">
                {reviewForm.formState.errors?.rating?.message}
              </span>
            )}
          </div>
        )}
      />
      <Controller
        name="review_desc"
        control={reviewForm.control}
        defaultValue={''}
        render={({ field }) => (
          <InputText
            fullWidth
            multiline
            minRows={3}
            variant="outlined"
            {...reviewForm.register('review_desc', {
              required: 'This field is required',
            })}
            error={!!reviewForm.formState.errors?.review_desc}
            size="small"
            label="Write your experience"
            {...field}
            helperText={reviewForm.formState.errors?.review_desc?.message}
          />
        )}
      />
    </div>
  )
})

export default BathroomReviewForm

import { format } from 'date-fns';

export function isJson(str: string) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}

function stringToColor(string: string) {
	let hash = 0;
	let i;

	/* eslint-disable no-bitwise */
	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = '#';

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.substr(-2);
	}
	/* eslint-enable no-bitwise */

	return color;
}

export function stringAvatar(name: string) {
	return {
		sx: {
			bgcolor: stringToColor(name),
		},
		children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
	};
}

export function getDateOnly(date: string, outFormat = 'yyyy-MM-dd') {
	return format(new Date(date), outFormat);
}

export function getDisplayDate(date: string, outFormat = 'eee dd MMM yyyy') {
	return format(new Date(date), outFormat);
}

export function getTimeOnly(date: string, outFormat = 'hh:mm a') {
	if(date){
		return format(new Date(date), outFormat);
	}else{
		return "";
	}
}

export function getDateTime(date: string, outFormat = 'MM-dd-yyyy hh:mm a') {
	if(date){
		return format(new Date(date), outFormat);
	}else{
		return "";
	}
}

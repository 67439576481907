import React from 'react'
import { Button, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import UIConstants from '../../theme/constants/ui-constants'

const useStyle = makeStyles((theme: Theme) => ({
  button: (props: ButtonProps) => ({
    backgroundColor: props.color ? props.color : UIConstants.primaryBgColor,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: UIConstants.highlightColor,
    },
  }),
}))

interface ButtonProps {
  onClick?: (e: any) => void,
  children?: any,
  color?: string,
}
function TsekmoButton({onClick, children, ...props }: ButtonProps) {
  const classes = useStyle(props)
  return (
    <Button
      className={classes.button}
      variant="contained"
      disableElevation
      onClick={onClick}>
      {children}
    </Button>
  )
}

export default TsekmoButton

import { callApi } from '../../app/utils/api'

export async function handleGetSpecificUser(id: number) {
  try {
    const response = await callApi('get', `private/user`, {user_id: id} )
    if (response.error) {
      return null
    } else {
      return response
    }
  } catch (e) {
    console.log('error', e)
  }
}

export async function handleGetListingName(id: number) {
  try {
    const response = await callApi('get', `private/listing`, id)
    if (response.error) {
      return null
    } else {
      return response
    }
  } catch (e) {
    console.log('error', e)
  }
}
import React, { useEffect } from 'react'
import { UserBusinessModel, UserData } from '../../../../store/app/user/types';
import { Button } from '@mui/material'
import axios from 'axios'
interface SubscriptionProps {
  userBusinessData: UserBusinessModel | undefined;
  userData : UserData;
}

const Subscriptions: React.FC<SubscriptionProps> = ({userData, userBusinessData}) => {

  const UseStripePricingTable = (pricingTableId: string, clientReferenceId?: number) => {
    useEffect(() => {      
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/pricing-table.js';
        script.async = true;
        document.body.appendChild(script);
    
        return () => {
          document.body.removeChild(script);
        };
    }, []);
  
    return React.createElement('stripe-pricing-table', {
      'pricing-table-id': pricingTableId,
      'publishable-key': 'pk_test_51K5vZMBawIx9AGNeAmLKm75HaEmMKkqH8pnA5tyfvJGnTkLVOXemqH7S13Xlc7yFy0UTaKDGonD1ymZzfvynoPED005xfzH2Q6',
      'client-reference-id': clientReferenceId,
    });
  };
  
  const StripePricingTable = ({ userType }: { userType: number }) => {
    if (userType === 1) {
      return UseStripePricingTable('prctbl_1PGPi0BawIx9AGNegiUWKA6F', userData.id);
    } else if (userType === 3) {
      return UseStripePricingTable('prctbl_1PEmZeBawIx9AGNek3a2uLdl', userData.id);
    } else if (userType === 4) {
      return UseStripePricingTable('prctbl_1PEmhdBawIx9AGNe7RYRNBp3', userData.id);
    } else {
      return null;
    }
  };

  const headers = {
    'Content-Type': 'application/json',
    Authorization:
      'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjJkOWI0ZTY5ZTMyYjc2MTVkNGNkN2NhZmI4ZmM5YjNmODFhNDFhYzAiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiZ2lueHggdHJpcHBsZXgiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUNnOG9jTG9TeDZkVjRkd0wteXpVcDAzM21RNEZKaVYzY3I0NnBZcno0NHZGTzc1Um1yVER3PXM5Ni1jIiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL3RzZWttLWJldGEiLCJhdWQiOiJ0c2VrbS1iZXRhIiwiYXV0aF90aW1lIjoxNzE0MDUxOTY4LCJ1c2VyX2lkIjoiTlpXUkVrcVpQek5OZjBUYjl5aGdaRUthWmx1MSIsInN1YiI6Ik5aV1JFa3FaUHpOTmYwVGI5eWhnWkVLYVpsdTEiLCJpYXQiOjE3MTQwNTE5NjgsImV4cCI6MTcxNDA1NTU2OCwiZW1haWwiOiJnaW54eDE5MDVAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZ29vZ2xlLmNvbSI6WyIxMDkxMzY0MDg5MjAwNjkxNzIzMTQiXSwiZW1haWwiOlsiZ2lueHgxOTA1QGdtYWlsLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6Imdvb2dsZS5jb20ifX0.LS0O3N5yDkM94pIiZ9euLI8bTnHkEGXNQi0iS-T6gbQqq3IJtv-zkFSiy5oB-jxaadTa7fpG8E1QkX9TAGhaGRykKMCvyJHtAwVXyymnWtDvDEbIYjOj0gRiRhe8eTmRAXL82BI3IadQYwds74L-w5NXZMPEqBLBG3aE0adFZcvRFcT8hljLZjKVwY1wFyp0DPbJLrLCd8Y2wDTOrRuEWhShv5AEqV0SiTr8tM9_RZ1xbv7BnC3r2S_6VH2DRRf84rKpw5AaiQySSs2z-wYAK5Ne-hdlyyA9uXx-b61Ga8X0t_ngMbMG2CqhqG42gw7wN_84cmdxfHD1u1y7g37H9A',
  }

  const connectData = {
    user_id: userData.id,
    refresh_url: `${process.env.REACT_APP_DOMAIN}/stripe-process`,
    return_url: `${process.env.REACT_APP_DOMAIN}/business-owner/settings`,
  }

  //Button for connecting to stripe
  const handleConnectStripe = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/private/connectstripeaccount`,
        connectData,
        {
          headers: headers,
        }
      )
      .then((res) => {
        console.log(res)
        console.log(res.data)
        window.open(res.data.url, '_blank')
      })
      .catch((err) => {
        if (err.response.status === 409) {
          console.log('Conflict 409')
        }
      })
  }

  return (
    <div>
      {userBusinessData?.stripe_account_id ? (
        <StripePricingTable userType={userData.user_type} />
      ) : (
        <Button onClick={handleConnectStripe} variant='outlined'>Connect to Stripe</Button>
      )}
    </div>
  )
  
}

export default Subscriptions;

import React, { useEffect } from 'react'
import CustomerRoutes from './Routes'
import Header from '../common/header/Header'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../store'
import { fetchUser } from '../../store/app/user/actions'
function CustomerHomePage() {
  const dispatch = useDispatch()
  const { userData } = useSelector((state: ApplicationState) => state.app)

  useEffect(() => {
    if (userData.id) {
      dispatch(fetchUser({user_id: userData.id, mobile_number: userData?.mobile_number, email: userData?.email}))
    }
  }, [userData])
  return (
    <>
      <div className="bg-[#E2E8F0] min-w-screen min-h-screen flex justify-center flex-grow-0 lg:p-10 p-0">
        <div className="min-w-full min-h-full lg:min-w-[85%] lg:max-w-[85%] bg-white rounded-lg shadow-xl flex flex-col">
          <Header />
          <div className="h-full">
            <CustomerRoutes />
          </div>
        </div>
      </div>

      {/* <BusinessOwnerRoutes /> */}
    </>
  )
}

export default CustomerHomePage

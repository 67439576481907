import { Grid, TextField, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import UserMessage from './components/UserMessage'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import { auth, firestore } from '../../../firebaseconfig'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import firebase from 'firebase/compat/app'
import CircularProgress from '@mui/material/CircularProgress'
import SendIcon from '@mui/icons-material/Send'
import UIConstants from '../../../theme/constants/ui-constants'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

const useStyle = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '100vh',
    marginBottom: '10px',
  },
  gridBody: {
    width: '100%',
    height: '100vh',
    maxHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  conversationsBody: {
    position: 'relative',
    alignItems: 'center',
    width: '100%',
  },
  messageBody: {
    borderLeft: '0.1px solid #c9c9c9',
    borderRight: '0.1px solid #c9c9c9',
    width: '100%',
  },
  mainMessages: {
    padding: '20px 30px',
    overflow: 'scroll',
    width: '100%',
    minHeight: '85%',
  },
  mainConversations: {
    overflow: 'scroll',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  conversationsHeader: {
    width: '100%',
    backgroundColor: 'white',
    padding: '20px',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '30px',
    flexDirection: 'column',
    gap: '10px',
  },
  inputSticky: {
    width: '100%',
    backgroundColor: 'white',
    border: '0.1px solid #c9c9c9',
    maxHeight: '40px',
    borderRadius: '15px',
    fontSize: '1rem',
    padding: '15px',
  },
  messageRow: {
    width: '100%',
    display: 'flex',
    marginBottom: '8px',
  },
  sent: {
    justifyContent: 'flex-end',
  },
  message: {
    padding: '5px 20px 5px 20px',
    borderRadius: '25px',
    maxWidth: '100%',
    position: 'relative',
    backgroundColor: '#f5f5f5',
  },
  userMessage: {
    backgroundColor: UIConstants.primaryBgColor,
    color: 'white',
  },
  image: {
    width: '20px',
    height: '20px',
    backgroundColor: UIConstants.primaryColor,
    position: 'absolute',
    borderRadius: '100%',
    right: '-12px',
    bottom: '-4px',
  },
  userImage: {
    left: '-12px',
    bottom: '-4px',
  },
  chatHeader: {
    backgroundColor: UIConstants.primaryBgColor,
    width: '100%',
    padding: '20px',
    height: '8%',
    boxShadow: 'inset -2px 2px 40px -35px rgba(0,0,0,0.75)',
    color: 'white',
    display: 'flex',
  },
  inputBody: {
    minHeight: '7%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    borderTop: '0.1px solid #c9c9c9',
  },
  chatInput: {
    minHeight: '100%',
    minWidth: '90%',
    border: 'none',
    padding: '10px',
    overflow: 'auto',
    outline: 'none',
    resize: 'none',
    fontSize: '1rem',
  },
  inputButton: {
    backgroundColor: 'transparent',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    transition: '0.2s ease',
    width: '100%',
    height: '100%',
    color: UIConstants.primaryBgColor,
    '&:hover': {
      color: 'gray',
      cursor: 'pointer',
      backgroundColor: '#f2f2f2',
    },
  },
  openConversation: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

interface CurrentFirebaseUser {
  id: string | null
  email?: string | null
  phoneNumber?: string | null
  photoURL?: string | null
  displayName?: string | null
}

const MessageBody = (props: any) => {
  const classes = useStyle()
  const [formValue, setFormValue] = useState('')

  const messagesRef = firestore.collection(
    (('conversations/' + props.currentUser?.id) as string) + '/messages'
  )
  const messagesQuery = messagesRef.orderBy('createdAt').limit(25)
  const [messages, loadingMessages]: any[] = useCollectionData(
    messagesQuery as any,
    {
      idField: 'id',
    }
  )

    async function handleSubmit(e: any) {
    e.preventDefault()
    setFormValue('')
    await firebase
      .firestore()
      .collection('conversations')
      .doc(props.currentUser?.id as string)
      .collection('messages')
      .add({
        text: formValue,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        userId: props.adminUser?.id,
        photoURL: 'https://visualpharm.com/assets/314/Admin-595b40b65ba036ed117d36fe.svg',
        displayName: 'Admin',
      })
  }

  return (
    <>
      <div className={classes.chatHeader}>{props.currentUser.displayName}</div>
      <div className={classes.mainMessages}>
        {messages &&
          messages.map((msg: any) => {
            return (
              <div
                key={msg.userId}
                className={`${classes.messageRow} ${
                  props.adminUser?.id === msg.userId ? classes.sent : ''
                }`}>
                <div
                  className={`${classes.message} ${
                    props.adminUser?.id === msg.userId
                      ? classes.userMessage
                      : ''
                  }`}>
                  {msg.text}
                  <img
                    className={`${classes.image} ${
                      props.adminUser?.id === msg.userId
                        ? classes.image
                        : classes.userImage
                    }`}
                    alt="User"
                    src={msg.photoURL}></img>
                </div>
              </div>
            )
          })}
      </div>
      <form onSubmit={handleSubmit} className={classes.inputBody}>
        <input
          className={classes.chatInput}
          placeholder="Write your message"
          onChange={(e) => setFormValue(e.target.value)}
          value={formValue}
        />

        <button type="submit" className={classes.inputButton}>
          <SendIcon fontSize="small" />
        </button>
      </form>
    </>
  )
}

const ChatView = (props: any) => {
  const classes = useStyle()
  const [isConversationOpen, setIsConversationOpen] = useState<boolean>(false)
  const [selectedUserChat, setSelectedUserChat] = useState<string>('')
  const [adminUser, setAdminUser] = useState<CurrentFirebaseUser>()
  const conversationsRef = firestore.collection('conversations')
  const query = conversationsRef.orderBy('createdAt').limit(25)
  const [conversations, loadingConversations]: any[] = useCollectionData(
    query as any,
    {
      idField: 'id',
    }
  )

  const auth = getAuth()
  onAuthStateChanged(auth, (user) => {
    if (user && !adminUser) {
      setAdminUser({
        id: user.uid!,
        email: user?.email,
        phoneNumber: user?.phoneNumber,
        photoURL: user?.photoURL,
        displayName: user?.displayName,
      })
    }
  })

  function openConversation(userId: string) {
    setSelectedUserChat(
      conversations.find((item: any) => item.userId === userId)
    )
    setIsConversationOpen(!isConversationOpen)
  }

  return (
    <>
      <div className={classes.container}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <div className={`${classes.gridBody} ${classes.conversationsBody}`}>
              <div className={classes.conversationsHeader}>
                <h2>Conversations</h2>
                <input
                  placeholder="Search a person"
                  className={classes.inputSticky}></input>
              </div>
              <div className={classes.mainConversations}>
                {loadingConversations ? (
                  <CircularProgress size={25} />
                ) : (
                  conversations?.map((conversationItem: any) => (
                    <UserMessage
                      conversation={conversationItem}
                      onClick={openConversation}
                    />
                  ))
                )}
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={`${classes.gridBody} ${classes.messageBody} ${isConversationOpen ? '' : classes.openConversation}`}>
              {isConversationOpen ? (
                <MessageBody
                  adminUser={adminUser}
                  currentUser={selectedUserChat}
                />
              ) : (
                'Open a conversation to get started'
              )}
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.gridBody}></div>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default ChatView

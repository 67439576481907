import React, { forwardRef, useImperativeHandle } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import ImageScroller from '../../Listing/components/ImageScroller'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import UIConstants from '../../../../theme/constants/ui-constants'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import { cloneDeep, get } from 'lodash'

interface EditIncidentReportCleanLogProps {
  cleanLog: any
  handleUpdateDamagedItems: (e: any, item: any) => void
  onAddImage: (image: any) => void
  setCleanLog: (cleanLog: any) => void
  uploadedImages: any
  updateUploadImages: (images: any) => void
}

interface LogItem {
  id: number
  disabled: boolean
  text: string
  checked: boolean
  broken?: boolean
  empty?: boolean
  comment: string
  logTime: Date | null
  category: string
  uploadedImages: any
}

interface CustomFile extends File {
  fileName?: string
}

const EditIncidentReportCleanLog = forwardRef(
  (
    {
      cleanLog,
      handleUpdateDamagedItems,
      setCleanLog,
      onAddImage,
      updateUploadImages,
      uploadedImages,
    }: EditIncidentReportCleanLogProps,
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      deleteImage(file: any) {
        handleDeleteImage(file)
      },
      getCleanLog() {
        return cleanLog
      }
    }))
    const handleAddCleanLogImage = (e: any, selectedItem: LogItem) => {
      const cleanLogClone = cloneDeep(cleanLog)
      const newImages: any[] = []
      Array.from(e.target.files).forEach((file: any) => {
        const newImage: CustomFile = new File(
          [file],
          Date.now() + '_' + file.name,
          {
            type: file.type,
          }
        )
        newImage.fileName = newImage.name
        newImages.push(newImage)
        onAddImage(newImage)
      })

      const updatedCleanLog = cleanLogClone.map((category: any) => {
        if (category.name === selectedItem.category) {
          return {
            ...category,
            items: category.items.map((item: LogItem) => {
              if (item.id === selectedItem.id) {
                return {
                  ...item,
                  uploadedImages: [...item.uploadedImages, ...newImages],
                }
              }
              return item
            }),
          }
        }
        return category
      })
      console.log(updatedCleanLog)
      setCleanLog(updatedCleanLog)
    }

    const handleAddCost = (e: any, selectedItem: LogItem) => {
      const cleanLogClone = cloneDeep(cleanLog)
      const updatedCleanLog = cleanLogClone.map((category: any) => {
        if (category.name === selectedItem.category) {
          return {
            ...category,
            items: category.items.map((item: LogItem) => {
              if (item.id === selectedItem.id) {
                return {
                  ...item,
                  cost: e.target.value,
                }
              }
              return item
            }),
          }
        }
        return category
      })
      setCleanLog(updatedCleanLog)
      handleUpdateDamagedItems(e, selectedItem)
    }

    const handleDeleteImage = (item: any) => {
      const cleanLogClone = cloneDeep(cleanLog)
      const updatedCleanLog = cleanLogClone.map((category: any) => {
        return {
          ...category,
          items: category.items.map((logItem: LogItem) => {
            return {
              ...logItem,
              uploadedImages: logItem?.uploadedImages?.filter(
                (image: any) => image.name !== item.name
              ),
            }
          }),
        }
      })
      setCleanLog(updatedCleanLog)
      updateUploadImages(
        uploadedImages.filter((image: any) => image.name !== item.name)
      )
    }

    return (
      <div className="w-[620px]">
        {cleanLog.length > 0 &&
          cleanLog.map(
            (category: any) =>
              category.items.length > 0 &&
              category.items.every(
                (item: LogItem) => item.disabled === false
              ) && (
                <Accordion key={category.name}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${category.name}-content`}
                    id={`${category.name}-header`}
                  >
                    <Badge
                      badgeContent={`+
                ${
                  category.items.length -
                  category.items.filter((item: LogItem) => item.disabled).length
                }`}
                      color="primary"
                      sx={{ '&.MuiBadge-root': { fontSize: '12px' } }}
                    >
                      <Typography
                        sx={{ marginRight: '18px' }}
                        className="text-sm font-semibold text-mainText"
                      >
                        {category.name}
                      </Typography>
                    </Badge>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="-mt-4">
                      {/* TABLE */}
                      <div className="border">
                        {/* TABLE HEAD */}
                        <div className="border-b-[1px] w-full flex items-center gap-2 py-1 px-4">
                          <span className="text-sm font-semibold text-mainText">
                            Item
                          </span>
                        </div>
                        {category.items.map(
                          (item: LogItem) =>
                            !item.disabled &&
                            item.category !== 'refillables' && (
                              // TABLE ROW
                              <div className="flex flex-col border-b px-2 pl-4">
                                <div className="h-[64px] flex justify-between items-center">
                                  <div className="flex items-center">
                                    <div className="w-[150px]">
                                      <span className="break-words text-mainText">
                                        {item.text}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="flex items-center gap-2">
                                    <>
                                      <Tooltip
                                        followCursor={true}
                                        title="Edit comment"
                                      >
                                        <TextField
                                          fullWidth
                                          placeholder={item.comment}
                                          size="small"
                                          className="text-sm"
                                          // onChange={(e) =>
                                          //   handleInsertComment(e, item)
                                          // }
                                        />
                                      </Tooltip>
                                      <div className="relative p-2 hover:bg-gray-100 active:bg-gray-300 duration-150 rounded-full">
                                        <Tooltip
                                          title="Add a photo"
                                          placement="right"
                                        >
                                          <AddPhotoAlternateIcon
                                            sx={{
                                              color: UIConstants.subText,
                                            }}
                                          />
                                        </Tooltip>
                                        <input
                                          type="file"
                                          multiple
                                          className="hover:cursor-pointer opacity-0 absolute inset-0 w-full h-full"
                                          accept="image/*"
                                          onChange={(e) =>
                                            handleAddCleanLogImage(e, item)
                                          }
                                        />
                                      </div>
                                      <Tooltip
                                        followCursor={true}
                                        title="Price Estimate"
                                      >
                                        <TextField
                                          fullWidth
                                          size="small"
                                          className="text-sm"
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                $
                                              </InputAdornment>
                                            ),
                                          }}
                                          onChange={(e) =>
                                            handleAddCost(e, item)
                                          }
                                          type="number"
                                          inputProps={{
                                            min: 0,
                                          }}
                                        />
                                      </Tooltip>
                                    </>
                                  </div>
                                </div>
                                {item?.uploadedImages?.length > 0 && (
                                  <ImageScroller
                                    webImageFiles={item.uploadedImages}
                                    // deleteFile={handleDeleteImage}
                                  />
                                )}
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              )
          )}
      </div>
    )
  }
)

export default EditIncidentReportCleanLog

import React, { useEffect } from 'react'
import ListingReviewItem from '../components/ListingReviewItem'
import { Pagination } from '@mui/material'
import UIConstants from '../../../../theme/constants/ui-constants'
import { getReview } from '../../../../store/app/review/actions'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../../store'

interface ListingReviewsProps {
  listing_id: number
}
function ListingReviews({ listing_id }: ListingReviewsProps) {
  const dispatch = useDispatch()
  useEffect(() => {
    if (listing_id) {
      dispatch(getReview({ listing_id: listing_id }))
    }
  }, [listing_id])
  const { reviews } = useSelector((state: ApplicationState) => state.review)
  const limit = 5

  return (
    <div>
      <h1 className="font-semibold text-lg text-primaryBg">Listing Reviews</h1>
      <div className="w-full flex flex-col gap-4 mt-4 ">
        {reviews.length > 0
          ? reviews.map((item, idx) => (
              <ListingReviewItem key={`review-${idx}`} reviewData={item}/>
            ))
          : 'No Reviews Yet.'}
      </div>
      {reviews.length > limit ? (
        <div className="grid place-items-center mt-4">
          <Pagination
            count={5}
            // page={page}
            // onPageChange={handleChangePage}
            sx={{
              ul: {
                '& .MuiPaginationItem-root': {
                  '&.Mui-selected': {
                    background: UIConstants.primaryBgColor,
                    color: 'white',
                  },
                },
              },
            }}
          />
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default ListingReviews

import React, { useEffect, useState } from 'react'
import {
  TableHead,
  TableCell,
  TableRow,
  Theme,
  Table,
  TableBody,
  Avatar,
  MenuItem,
  Menu,
  Button,
  TablePagination,
  LinearProgress,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import UIConstants from '../../../../theme/constants/ui-constants'
import { popoverClasses } from '@mui/material/Popover'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCustomersToday } from '../../../../store/app/business_dashboard/actions'
import { ApplicationState } from '../../../../store'
import { handleGetSpecificUser } from '../../../composables/helpers'
import moment from 'moment'

const useStyle = makeStyles((theme: Theme) => ({
  header: {
    '&.MuiTableCell-root': {
      color: UIConstants.mainText,
      paddingBottom: '10px',
    },
  },
  body: {
    '&.MuiTableRow-root': {
      color: UIConstants.mainText,
      backgroundColor: 'white',
    },
    '& .MuiTableRow-root': {
      borderRadius: '100px',
    },
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  table: {
    '&.MuiTable-root': {
      borderCollapse: 'separate',
      borderSpacing: '0 10px',
    },
  },
}))

interface BillListProps {
  listing_id: number
}

function BillList({ listing_id }: BillListProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const dispatch = useDispatch()
  const open = Boolean(anchorEl)
  const [listingId, setCurrentListingId] = useState<number>(0)
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(5)
  const [customers_today, setCustomersToday] = useState<any>([])
  const { loading } = useSelector(
    (state: ApplicationState) => state.businessDashboard
  )
  const { customersToday } = useSelector(
    (state: ApplicationState) => state.businessDashboard
  )
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const classes = useStyle()
  const headers = [
    {
      id: 'avatar',
      label: '',
      width: '5%',
    },
    {
      id: 'date',
      label: 'Date',
      width: '12%',
    },
    {
      id: 'customer',
      label: 'Customer',
      width: '45%',
    },
    {
      id: 'actions',
      label: 'Actions',
      width: '5%',
    },
  ]

  useEffect(() => {
    if (listing_id) {
      if (listing_id !== listingId) {
        setPage(1)
        setLimit(5)
      }
      setCurrentListingId(listing_id)
      dispatch(
        fetchCustomersToday({
          listing_id: listing_id,
          limit: limit,
          page: page,
        })
      )
    }
  }, [listing_id, limit, page])

  useEffect(() => {
    async function returnCustomersToday() {
      if (customersToday?.customers_today?.length > 0) {
        const currentCustomerData = await Promise.all(
          customersToday?.customers_today.map(async (item: any) => {
            const user = await handleGetSpecificUser(item.user_id)
            const currentUser = user?.data.userData.user_info
            return {
              id: item.id,
              avatar: currentUser?.image_url,
              time: moment(item.date_time).format('lll'),
              name: currentUser?.first_name + ' ' + currentUser?.last_name,
            }
          })
        )
        setCustomersToday(currentCustomerData)
      }
    }
    returnCustomersToday()
  }, [customersToday])

  function handleOnRowsPerPageChange(event: any) {
    if (parseInt(event.target.value, 10) > customers_today?.total) {
      setLimit(customers_today?.total)
    } else {
      setLimit(parseInt(event.target.value, 10))
    }
    setPage(1)
  }

  function handleOnPageChange(_event: any, newPage: number) {
    setPage(newPage)
  }
  return (
    <>
      {loading?.customersToday && <LinearProgress className="mt-2" />}
      <div className="w-full h-full px-10 pt-5">
        {customers_today.length > 0 ? (
          <>
            <Table className={classes.table}>
              <TableHead className={classes.header}>
                <TableRow>
                  {headers.map((header) => (
                    <TableCell
                      width={header.width}
                      sx={{ padding: '0 50px' }}
                      key={header.id}
                      padding="none"
                      align={header.id === 'actions' ? 'center' : 'left'}
                    >
                      {header.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {customers_today.length > 0 &&
                  customers_today.map((data: any) => (
                    <TableRow className={classes.body} key={data.id}>
                      <TableCell>
                        {data.avatar ? (
                          <img
                            className="w-[30px h-[30px] rounded-full object-cover"
                            src={data.avatar}
                            alt="user-avatar"
                          />
                        ) : (
                          <Avatar sx={{ width: '30px', height: '30px' }} />
                        )}
                      </TableCell>
                      <TableCell>{data.time}</TableCell>
                      <TableCell>{data.name}</TableCell>
                      <TableCell align="center">
                        <div>
                          <button onClick={handleClick}>
                            <MoreVertIcon />
                          </button>
                          <Menu
                            id="user-actions-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            sx={{
                              zIndex: 0,
                              [`& .${popoverClasses.paper}`]: {
                                boxShadow: 'none',
                              },
                            }}
                          >
                            <MenuItem onClick={() => console.log(data.id)}>
                              Bill Now
                            </MenuItem>
                            <MenuItem onClick={handleClose}>Resolve</MenuItem>
                            <MenuItem onClick={handleClose}>Block</MenuItem>
                          </Menu>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>

            <TablePagination
              component="div"
              count={customersToday?.total}
              page={page}
              rowsPerPageOptions={[
                5,
                10,
                25,
                50,
                { label: 'All', value: customersToday?.total },
              ]}
              onPageChange={handleOnPageChange}
              rowsPerPage={limit}
              onRowsPerPageChange={handleOnRowsPerPageChange}
            />
          </>
        ) : (
          <div className="flex flex-col justify-center pt-4 items-center">
            <h1>No customers billed.</h1>
          </div>
        )}
      </div>
    </>
  )
}

export default BillList

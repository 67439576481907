import { Avatar, Card, Theme } from '@mui/material'
import React from 'react'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import { makeStyles } from '@mui/styles'
import { NONAME } from 'dns'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../../store'
import moment from 'moment'
import { updateProfilePicture } from '../../../../store/app/user/actions'

const useStyle = makeStyles((theme: Theme) => ({
  image: {
    width: '200px',
    height: '200px',
    borderRadius: '100%',
    border: 'none',
    objectFit: 'cover',
    [theme.breakpoints.down(1024)]: {
      height: '100px',
      width: '100px',
      borderRadius: '100%',
      border: 'none',
    },
  },
}))
function Profile() {
  const classes = useStyle()
  const dispatch = useDispatch()
  const { userData, businessInfo } = useSelector(
    (state: ApplicationState) => state.user
  )

  const handleUploadProfilePicture = (event: any): void => {
    if (userData.id) {
      const file_list = event.target.files
      const form_data = new FormData()
      form_data.append('user_id', userData.id.toString())
      form_data.append('userImage', file_list[0])
      dispatch(updateProfilePicture(form_data))
    }
  }
  return (
    <div className="w-full h-full bg-foreground">
      <div className="flex lg:flex-row flex-col items-center lg:items-start w-full h-full">
        <div className="w-full lg:w-1/3 h-full flex flex-col items-center justify-center lg:justify-normal p-8 lg:p-14">
          <div className="flex flex-col items-center">
            <div className="mt-4 text-xs text-subText decoration-subText hover:underline hover:cursor-pointer relative flex flex-col items-center gap-8">
              {userData && userData.user_info?.image_url ? (
                <img
                  className={classes.image}
                  src={userData.user_info?.image_url}
                  referrerPolicy="no-referrer"
                  alt="user"
                />
              ) : (
                <Avatar className={classes.image} />
              )}
              <input
                type="file"
                onChange={(event) => handleUploadProfilePicture(event)}
                className="hover:cursor-pointer opacity-0 absolute inset-0 w-full h-full"
                accept="image/*"
              />
              Change profile picture
            </div>
          </div>
        </div>
        <div className="lg:mt-0 w-full lg:w-2/3 lg:p-14 flex flex-row text-mainText">
          <Card className="w-fit h-fit p-14 flex flex-col gap-4 rounded-md">
            <p className="flex gap-1 items-center font-semibold text-xl">
              <span className="-mt-[2px]">
                <AccountBoxIcon sx={{ width: '20px', height: '20px' }} />
              </span>
              Profile
            </p>
            <div className="w-[50px] h-1 -mt-2 bg-mainText"></div>
            <div className="mt-1">
              <p className="font-semibold">Name:</p>
              <p className="text-subText text-sm">
                {userData &&
                  `${userData.user_info?.first_name} ${userData.user_info?.last_name}`}
              </p>
            </div>
            <div className="mt-1">
              <p className="font-semibold">Email:</p>
              <p className="text-subText text-sm">
                {userData && userData?.email ? userData?.email : '-'}
              </p>
            </div>
            <div className="mt-1">
              <p className="font-semibold">Mobile Number:</p>
              <p className="text-subText text-sm">
                {' '}
                {userData && userData?.mobile_number
                  ? userData?.mobile_number
                  : '-'}
              </p>
            </div>
            <div>
              <p className="font-semibold">Member Since:</p>
              <p className="text-subText text-sm">
                {userData &&
                  moment(userData?.created_at).format('MMMM DD, YYYY')}
              </p>
            </div>
            {/* <div>
              <p className="font-semibold">Address:</p>
              <p className="text-subText text-sm">
                This is a long test address This is a long test address This is
                a long test address This is a long test address This is a long
                test address This is a long test address{' '}
              </p>
            </div> */}
            <div>
              <p className="font-semibold">Business Name:</p>
              <p className="text-subText text-sm">
                {businessInfo?.business_name}
              </p>
            </div>
            <div>
              <p className="font-semibold">Business Industry:</p>
              <p className="text-subText text-sm">
                {businessInfo?.business_Industry}
              </p>
            </div>
            <div>
              <p className="font-semibold">Business Address:</p>
              <p className="text-subText text-sm">
                {businessInfo &&
                  businessInfo.location &&
                  JSON.parse(businessInfo?.location as string).label}
              </p>
            </div>
          </Card>
          <div className="hidden lg:flex w-1/2"></div>
        </div>
      </div>
    </div>
  )
}

export default Profile

import { StarBorder } from '@mui/icons-material'
import { Avatar, Rating, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyle = makeStyles((theme: Theme) => ({
  image: {
    width: '32px',
    height: '32px',
    borderRadius: '100%',
    border: 'none',
    [theme.breakpoints.down(1024)]: {
      height: '100px',
      width: '100px',
      borderRadius: '100%',
      border: 'none',
    },
  },
}))


function ListingReviewItem({ reviewData }: any) {
  const classes = useStyle()
  return (
    <div className="flex flex-col border-b-[1px] py-4">
      <div className="flex gap-2 align-start">
      <img
              className={classes.image}
              src={reviewData?.user_imageUrl}
              referrerPolicy="no-referrer"
              alt="user"
            />
        <div className="flex flex-col gap-0 ">
          <h1 className="font-semibold text-mainText text-lg">
            {reviewData.user}
          </h1>
          <span className='-ml-[2.5px]'>
          <Rating
              value={reviewData.rating}
              name="size-small"
              size="small"
              precision={0.5}
              emptyIcon={
                <StarBorder style={{ opacity: 0.55 }} fontSize="inherit" />
              }
            />
          </span>
          <span className="text-xs text-subText">{reviewData.created_at}</span>
          <span className="text-sm text-mainText mt-4">
            {reviewData.review_desc}
          </span>
        </div>
      </div>
    </div>
  )
}

export default ListingReviewItem

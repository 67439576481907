import React, { useContext, useEffect, useState } from 'react'
import { Card, Tab, Tabs, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import UIConstants from '../../../../theme/constants/ui-constants'
import DiscountIcon from '@mui/icons-material/Discount'
import LoyaltyIcon from '@mui/icons-material/Loyalty'

import SpecialDiscount from '../components/SpecialDiscount'
import Subscriptions from '../components/Subscriptions'
import SubscriptionStatus from '../components/SubscriptionStatus'

import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getRetrieveSubscriptionData, getUserBusiness } from '../../../../store/app/user/actions'
import { ApplicationState } from '../../../../store'
import io from 'socket.io-client';
import { useSocket } from './SocketContext'

const useStyle = makeStyles((theme: Theme) => ({
  tabs: {
    marginTop: '0.75rem',
    '& .MuiTab-root': {
      color: UIConstants.subText,
      textTransform: 'capitalize',
      minHeight: 50,
      marginBottom: '1.25rem',
    },
    '& .MuiTab-root.Mui-selected': {
      color: UIConstants.primaryBgColor,
    },
    '& .MuiTabs-indicator': {
      left: 0,
      backgroundColor: UIConstants.primaryBgColor,
    },
  },
}))

function Settings() {
  const classes = useStyle()
  const [tabValue, setTabValue] = useState<number>(0)
  const location = useLocation()
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }
  const [receivedData, setReceivedData] = useState(null);
  const dispatch = useDispatch()
  
  const { userData, userBusinessStripeData, userRetrieveSubscriptionData, productList } = useSelector(
    (state: ApplicationState) => state.user
  )
  useEffect(() => {
    if (location.state?.tab) {
      setTabValue(location.state.tab)
    }
  }, [location])


  const { socket, isConnected, connect, disconnect } = useSocket();

  useEffect(() => {
    console.log(`Check : socket : ${socket}, isConnected: ${isConnected}`);
    console.log(`Check userRetrieveSubscriptionData : ${JSON.stringify(userRetrieveSubscriptionData)}`)
    if (socket && isConnected) {

      socket.on('testEvent', (data) => {
        console.log('Received test event:', data);
      });
      
      socket.on('AccountUpdated', (data) => {
        console.log('Received AccountUpdated event:', data);
      });
    }

    return () => {
      if (socket) {
        socket.off('AccountUpdated');
      }
    };
  }, [socket, isConnected]);

  useEffect(() => {
    if(userData && userData.id) { 
      dispatch(getUserBusiness(userData.id));
      dispatch(getRetrieveSubscriptionData(userData.id));
    }
  }, [dispatch, userData])

  return (
    <div className="w-full h-full bg-foreground flex flex-col lg:flex-row">
      <div className="w-full lg:max-w-[30%] flex lg:flex-row flex-col justify-center lg:items-start items-center lg:my-0 my-10 lg:pt-10 h-[10%] lg:h-full">
        <Card className="p-5 lg:w-[80%] lg:max-w-[300px] h-[80%] lg:h-fit bg-white rounded-md flex lg:flex-col items-center justify-center lg:justify-normal lg:p-10">
          <Tabs
            orientation="vertical"
            value={tabValue}
            onChange={handleChange}
            className={classes.tabs}
          >
            <Tab
              label="Special Discounts"
              icon={<DiscountIcon />}
              iconPosition="start"
            ></Tab>
            <Tab
              label="Subscriptions"
              icon={<LoyaltyIcon />}
              iconPosition="start"
            ></Tab>
            <Tab
              label="Subscription Status"
              icon={<LoyaltyIcon />}
              iconPosition="start"
            ></Tab>
          </Tabs>
        </Card>
      </div>
      <div className="h-full w-full gap-10 flex flex-col items-center lg:p-10 lg:pr-24">
        <Card className="p-2 w-full lg:max-w-full h-[80%] h-fit bg-white rounded-md flex lg:flex-col items-center justify-center lg:justify-normal lg:p-10">
          <div className="w-full h-full" hidden={tabValue !== 0}>
            <SpecialDiscount />
          </div>
          <div className="w-full h-full" hidden={tabValue !== 1}>
            <Subscriptions userData={userData} userBusinessData={userBusinessStripeData}/>
          </div>
          <div className="w-full h-full" hidden={tabValue !== 2}>
            <SubscriptionStatus subscriptionData={userRetrieveSubscriptionData?.subscriptionData} userData={userData} />
          </div>
        </Card>
      </div>
    </div>
  )
}

export default Settings

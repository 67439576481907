import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../../store'
import { fetchDashboardData } from '../../../../store/app/business_dashboard/actions'
import { format } from 'path'
interface EarningsSummaryProps {
  listing: any
}
const EarningsSummary = ({ listing }: EarningsSummaryProps) => {
  const { dashboardData } = useSelector(
    (state: ApplicationState) => state.businessDashboard
  )

  const dispatch = useDispatch()
  const [chartData, setChartData] = useState<any>([])
  const [selectedCategory, setSelectedCategory] = useState<any>({
    prev: '',
    current: '1D',
  })
  const [options, setOptions] = useState<any>(null)

  useEffect(() => {
    if (dashboardData.chart_data !== '' && listing) {
      const parsedData = JSON.parse(dashboardData.chart_data)
      const formattedChartData: any[] = []
      parsedData?.forEach((data: any) => {
        formattedChartData.push([data.title, data.value.toFixed(2)])
      })

      setOptions({
        chart: {
          id: 'datetime-area-chart',
          type: 'area',
        },
        series: [
          {
            name: 'Earnings',
            data: formattedChartData.map((data: any) => data[1]),
          }
        ],
        xaxis: {
          categories: formattedChartData.map((data: any) => data[0]),
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth',
        },
        title: {
          text: '',
          align: 'left',
        },
      })
    }
  }, [dashboardData, listing])

  useEffect(() => {
    if (selectedCategory.prevState !== '' && listing) {
      dispatch(
        fetchDashboardData({
          user_id: listing?.user_id,
          listing_id: listing.id,
          time_frame: selectedCategory.current,
        })
      )
    }
  }, [selectedCategory, listing])

  // const chartData = [
  //   {
  //     name: 'Series 1',
  //     data: [
  //       [new Date('2024-02-01').getTime(), 30],
  //       [new Date('2024-02-02').getTime(), 40],
  //       [new Date('2024-02-03').getTime(), 45],
  //       [new Date('2024-02-04').getTime(), 50],
  //       [new Date('2024-02-05').getTime(), 60],
  //       [new Date('2024-02-06').getTime(), 70],
  //       [new Date('2024-02-07').getTime(), 75],
  //       [new Date('2024-02-08').getTime(), 80],
  //     ],
  //   },
  // ]

  return (
    <div className="mt-4">
      <div className="row">
        <div className="toolbar">
          <button
            id="one_month"
            className={`px-2 w-fit border mr-2 hover:bg-subText hover:text-white duration-150 ${
              selectedCategory.current === '1D' &&
              'bg-primaryBg text-white font-semibold'
            }`}
            onClick={() =>
              setSelectedCategory((prevState: any) => ({
                prev: prevState.current,
                current: '1D',
              }))
            }
          >
            1D
          </button>

          <button
            id="six_months"
            className={`px-2 w-fit border mr-2 hover:bg-subText hover:text-white duration-150 ${
              selectedCategory.current === '7D' &&
              'bg-primaryBg text-white font-semibold'
            }`}
            onClick={() =>
              setSelectedCategory((prevState: any) => ({
                prev: prevState.current,
                current: '7D',
              }))
            }
          >
            7D
          </button>

          <button
            id="one_year"
            className={`px-2 w-fit border mr-2 hover:bg-subText hover:text-white duration-150 ${
              selectedCategory.current === '1M' &&
              'bg-primaryBg text-white font-semibold'
            }`}
            onClick={() =>
              setSelectedCategory((prevState: any) => ({
                prev: prevState.current,
                current: '1M',
              }))
            }
          >
            1M
          </button>

          <button
            id="ytd"
            className={`px-2 w-fit border mr-2 hover:bg-subText hover:text-white duration-150 ${
              selectedCategory.current === 'YTD' &&
              'bg-primaryBg text-white font-semibold'
            }`}
            onClick={() =>
              setSelectedCategory((prevState: any) => ({
                prev: prevState.current,
                current: 'YTD',
              }))
            }
          >
            YTD
          </button>

          <button
            id="all"
            className={`px-2 w-fit border mr-2 hover:bg-subText hover:text-white duration-150 ${
              selectedCategory.current === 'MAX' &&
              'bg-primaryBg text-white font-semibold'
            }`}
            onClick={() =>
              setSelectedCategory((prevState: any) => ({
                prev: prevState.current,
                current: 'MAX',
              }))
            }
          >
            MAX
          </button>
        </div>
        {options && options?.series?.length > 0 && (
          <div className="mixed-chart">
            <Chart
            options={options}
              series={options.series}
              type="area"
              width="100%"
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default EarningsSummary

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../../store'
import { fetchAllListingsForCustomer } from '../../../../store/app/listing/actions'
import { Grid } from '@mui/material'
import ListingCard from '../../../business-owner/Listing/components/Listing/ListingCard'

function Dashboard() {
  const { userData } = useSelector((state: ApplicationState) => state.app)
  const { listingData } = useSelector(
    (state: ApplicationState) => state.listing
  )
  const dispatch = useDispatch()
  useEffect(() => {
    if (userData.id) {
      dispatch(fetchAllListingsForCustomer({ user_id: userData.id }))
    }
  }, [userData])

  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full h-full bg-foreground p-8">
        <Grid container spacing={4}>
          {listingData.map((listing, index) => (
            <Grid key={index} item xs={12} sm={6} md={4}>
              <ListingCard
                toggleActiveStatus={() => {}}
                onDeleteListing={() => {}}
                onUpdateListing={() => {}}
                listing={listing}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  )
}

export default Dashboard


import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import UIConstants from '../../theme/constants/ui-constants';
import logo from "../../assets/images/logo.svg";
import mobileLogo from "../../assets/images/logo_mobile.svg";
import Toolbar from '@mui/material/Toolbar';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CustomButton } from '../../theme/components/buttons/Button';
import { useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { NavLink, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
    toolBar: {
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',

    },
    logo: {
        cursor: "pointer",
    },
    logoDiv: {
        height: '100%',
        alignItems: 'center',
        display: 'flex',
    },
    navlinks: {
        display: "flex",
        minHeight: '28px',
        alignItems: 'center'
    },
    link: {
        textDecoration: "none",
        color: UIConstants.primaryBgColor,
        fontSize: "18px",
        fontWeight: 400,
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(4),
        },
        lineHeight: '27px',
        opacity: '.5',
        "&:hover": {
            opacity: '1',
        }, '&.activeClass': {
            opacity: '1',
            color: UIConstants.primaryBgColor,
            textDecoration: 'underline'
          }
    },
    loginButton: {
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(4),
        },
        marginLeft: theme.spacing(2),
    },
    removeDecoration: {
        textDecoration: "none"
    },
}));

interface LandingToolbarProps {
    showNavigation?: boolean;
}

export default function TsekmoLandingToolBar({ showNavigation = true }: LandingToolbarProps) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const appLogo = matches ? logo : mobileLogo;
    const classes = useStyles();
    const { t } = useTranslation();
    const location = useLocation().pathname;
    const navigate = useNavigate();
    const logoClick = ()=> {
        navigate(`/landing`);
        window.scrollTo(0, 0);
    }
    const zipcodeEnabled = process.env.REACT_APP_FEATURE_ZIPCODE_RESTRICTION == "true" ? true : false;
    return (
        <Toolbar className={classes.toolBar}>
            <div className={classes.logoDiv}>
                <img className={classes.logo}
                    src={appLogo}
                    alt={t('appName')}
                    onClick={() => logoClick()}
                /></div>
            {showNavigation && (<div className={classes.navlinks}>
                {zipcodeEnabled ? (
                    matches && 
                    // <Link to={ "https://www.tsekmo.com" } replace={true} className={classes.link} >
                    //     {t('landingPageToolBar.hostSignUp')}
                    // </Link>
                    <a href={process.env.REACT_APP_CLICKFUNNEL_URL} className={classes.link} rel="noopener noreferrer"> {t('landingPageToolBar.hostSignUp')} </a>
                    ) : (
                    matches && <Link to={{ pathname: '/signup', search: '?isHost=true' }} className={classes.link} >
                        {t('landingPageToolBar.hostSignUp')}
                    </Link>)
                }
                {/* REVERT FOR NEXT RELEASE */}
                {/* {(matches && <Link to="/pricing" className={`${classes.link} ${'/pricing' === location ? 'activeClass' : ''}`} >
                    {t('landingPageToolBar.Pricing')}
                </Link>)} */}
                {matches && <Link to="/bathrooms" className={classes.link}>
                    {'Public Bathrooms'}
                </Link> }
                <Link to="/" className={classes.link}>
                    Bathrooms Reported
                </Link>
                <Link to="/signup" className={classes.link}>
                    {t('landingPageToolBar.signUp')}
                </Link>

                <Link to="/login" className={classes.removeDecoration}>
                    <CustomButton name='login-button' variant="contained" size='small' className={classes.loginButton}>
                        {t('landingPageToolBar.login')}
                    </CustomButton>
                </Link>

            </div>)}

        </Toolbar>
    );
}

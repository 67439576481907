import React, { useEffect, useState } from 'react'
import { Slider, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { Controller, useForm } from 'react-hook-form'
import { InputText } from '../../../../theme/components/input/TextField'
import { Toggle } from '../../../../theme/components/input/Toggle'
import TsekmoButton from '../../../common/TsekmoButton'
import { CustomButton } from '../../../../theme/components/buttons/Button'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUser, setUserDiscounts } from '../../../../store/app/user/actions'
import { ApplicationState } from '../../../../store'

interface IFormInput {
  senior: number
  parent: number
  mobility: number
  paying_customer: number
  homeless: number
  tourist_discount: number
  tsekrs: number
}

function SpecialDiscount() {
  const dispatch = useDispatch()
  const currentForm = useForm<IFormInput>({
    defaultValues: {
      senior: 0,
      homeless: 0,
      paying_customer: 0,
      mobility: 0,
      tourist_discount: 0,
      parent: 0,
      tsekrs: 50,
    },
    mode: 'all',
  })
  const { userData } = useSelector((state: ApplicationState) => state.app)
  const currentUser = useSelector(
    (state: ApplicationState) => state.user.userData
  )
  const [formValues, setFormValues] = useState<IFormInput>({
    senior: 0,
    parent: 0,
    mobility: 0,
    paying_customer: 0,
    homeless: 0,
    tourist_discount: 0,
    tsekrs: 50,
  })
  const handleFormChange = (event: any) => {
    // Restrict Tsekrs discount to be below 50 for Tsekrs Discount
    if (event.target.value >= 50 || event.target.name != 'tsekrs') {
      currentForm.setValue(
        `${event.target.name as keyof IFormInput}`,
        event.target.value > 100 ? 100 : event.target.value
      )
      currentForm.clearErrors()
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value > 100 ? 100 : event.target.value,
      })
    }
  }

  // Set-up slider labels
  const marks = [];
  for (let i = 0; i <= 100; i += 5) {
    marks.push({
        value: i,
        label: i
    });
  }
  const useStyles = makeStyles((theme: Theme) => ({
    mark: {
      fontSize: 10
    }
  }));
  const classes = useStyles();

  useEffect(() => {
    if (userData.id) {
      dispatch(fetchUser({user_id: userData.id, mobile_number: userData?.mobile_number, email: userData?.email}))
    }
  }, [userData.id])

  useEffect(() => {
    if (currentUser.user_discounts) {
      setFormValues(currentUser.user_discounts)
      currentForm.reset({
        senior: currentUser.user_discounts.senior,
        parent: currentUser.user_discounts.parent,
        mobility: currentUser.user_discounts.mobility,
        paying_customer: currentUser.user_discounts.paying_customer,
        homeless: currentUser.user_discounts.homeless,
        tourist_discount: currentUser.user_discounts.tourist_discount,
        tsekrs: currentUser.user_discounts.tsekrs,
      })
    }
  }, [currentUser.user_discounts])

  const onSubmit = (data: any) => {
    dispatch(setUserDiscounts({ ...data, user_id: userData.id }))
  }
  return (
    <form
      className="w-full flex flex-col gap-4 p-5 text-mainText"
      onSubmit={currentForm.handleSubmit(onSubmit)}
    >
      <p className="font-medium text-2xl underline decoration-mainText">
        Special Discounts
      </p>
      <div className="flex justify-between w-full mt-5">
        <div className="w-full mr-10">
          <p className="line-clamp-1">{`Tsekrs Community (%)`}</p>
          <Slider
            key="tsekrs"
            name="tsekrs"
            value={formValues.tsekrs}
            onChange={handleFormChange}
            valueLabelDisplay="auto"
            defaultValue={50}
            marks={marks}
            step={5}
            min={0}
            max={100}
            classes={{ markLabel: classes.mark }}
          />
        </div>
        <Controller
          name="tsekrs"
          control={currentForm.control}
          rules={{ max: 100 }}
          render={({ field }) => (
            <InputText
              fullWidth
              style={{
                width: '70px',
                marginTop: '-2px',
                padding: '2px !important',
                textAlign: 'center',
              }} // Set the width as a percentage
              {...currentForm.register('tsekrs', {
                required: 'This field is required',
                onChange: (e) => handleFormChange(e),
              })}
              {...field}
              size="small"
              error={!!currentForm.formState.errors?.senior}
            />
          )}
        />
      </div>
      <div className="flex justify-between w-full">
        <div className="w-full mr-10">
          <p className="line-clamp-1">{`Senior Citizen Discount (%)`}</p>
          <Slider
            key="senior"
            name="senior"
            value={formValues.senior}
            onChange={handleFormChange}
            marks={marks}
            step={5}
            min={0}
            max={100}
            valueLabelDisplay="auto"
            classes={{ markLabel: classes.mark }}
          />
        </div>
        <Controller
          name="senior"
          control={currentForm.control}
          rules={{ max: 100 }}
          render={({ field }) => (
            <InputText
              fullWidth
              style={{
                width: '70px',
                marginTop: '-2px',
                padding: '2px !important',
                textAlign: 'center',
              }} // Set the width as a percentage
              {...currentForm.register('senior', {
                required: 'This field is required',
                onChange: (e) => handleFormChange(e),
              })}
              {...field}
              size="small"
              error={!!currentForm.formState.errors?.senior}
            />
          )}
        />
      </div>
      <div className="flex justify-between w-full">
        <div className="w-full mr-10">
          <p className="line-clamp-1">{`Parent with Child (%)`}</p>
          <Slider
            key="parent"
            name="parent"
            value={formValues.parent}
            onChange={handleFormChange}
            marks={marks}
            step={5}
            min={0}
            max={100}
            valueLabelDisplay="auto"
            classes={{ markLabel: classes.mark }}
          />
        </div>

        <Controller
          name="parent"
          control={currentForm.control}
          rules={{ max: 100 }}
          render={({ field }) => (
            <InputText
              fullWidth
              style={{
                width: '70px',
                marginTop: '-2px',
                padding: '2px !important',
                textAlign: 'center',
              }} // Set the width as a percentage
              {...currentForm.register('parent', {
                required: 'This field is required',
                onChange: (e) => handleFormChange(e),
              })}
              {...field}
              size="small"
              error={!!currentForm.formState.errors?.parent}
            />
          )}
        />
      </div>
      <div className="flex justify-between w-full">
        <div className="w-full mr-10">
          <p className="line-clamp-1">{`Mobility Disability (%)`}</p>
          <Slider
            key="mobility"
            name="mobility"
            value={formValues.mobility}
            onChange={handleFormChange}
            marks={marks}
            step={5}
            min={0}
            max={100}
            valueLabelDisplay="auto"
            classes={{ markLabel: classes.mark }}
          />
        </div>

        <Controller
          name="mobility"
          control={currentForm.control}
          rules={{ max: 100 }}
          render={({ field }) => (
            <InputText
              fullWidth
              style={{
                width: '70px',
                marginTop: '-2px',
                padding: '2px !important',
                textAlign: 'center',
              }} // Set the width as a percentage
              {...currentForm.register('mobility', {
                required: 'This field is required',
                onChange: (e) => handleFormChange(e),
              })}
              {...field}
              size="small"
              error={!!currentForm.formState.errors?.mobility}
            />
          )}
        />
      </div>
      <div className="flex justify-between w-full">
        <div className="w-full mr-10">
          <p className="line-clamp-1">{`Paying Customer Discount (%)`}</p>
          <Slider
            key="paying_customer"
            name="paying_customer"
            value={formValues.paying_customer}
            onChange={handleFormChange}
            marks={marks}
            step={5}
            min={0}
            max={100}
            valueLabelDisplay="auto"
            classes={{ markLabel: classes.mark }}
          />
        </div>

        <Controller
          name="paying_customer"
          control={currentForm.control}
          rules={{ max: 100 }}
          render={({ field }) => (
            <InputText
              fullWidth
              style={{
                width: '70px',
                marginTop: '-2px',
                padding: '2px !important',
                textAlign: 'center',
              }} // Set the width as a percentage
              {...currentForm.register('paying_customer', {
                required: 'This field is required',
                onChange: (e) => handleFormChange(e),
              })}
              {...field}
              size="small"
              error={!!currentForm.formState.errors?.paying_customer}
            />
          )}
        />
      </div>
      <div className="flex justify-between w-full">
        <div className="w-full mr-10">
          <p className="line-clamp-1">{`Homeless Discount (%)`}</p>
          <Slider
            key="homeless"
            name="homeless"
            value={formValues.homeless}
            onChange={handleFormChange}
            marks={marks}
            step={5}
            min={0}
            max={100}
            valueLabelDisplay="auto"
            classes={{ markLabel: classes.mark }}
          />
        </div>

        <Controller
          name="homeless"
          control={currentForm.control}
          rules={{ max: 100 }}
          render={({ field }) => (
            <InputText
              fullWidth
              style={{
                width: '70px',
                marginTop: '-2px',
                padding: '2px !important',
                textAlign: 'center',
              }} // Set the width as a percentage
              {...currentForm.register('homeless', {
                required: 'This field is required',
                onChange: (e) => handleFormChange(e),
              })}
              {...field}
              size="small"
              error={!!currentForm.formState.errors?.homeless}
            />
          )}
        />
      </div>
      <div className="flex justify-between w-full">
        <div className="w-full mr-10">
          <p className="line-clamp-1">{`Tourist Discount (%)`}</p>
          <Slider
            key="tourist_discount"
            name="tourist_discount"
            value={formValues.tourist_discount}
            onChange={handleFormChange}
            marks={marks}
            step={5}
            min={0}
            max={100}
            valueLabelDisplay="auto"
            classes={{ markLabel: classes.mark }}
          />
        </div>

        <Controller
          name="tourist_discount"
          control={currentForm.control}
          rules={{ max: 100 }}
          render={({ field }) => (
            <InputText
              fullWidth
              style={{
                width: '70px',
                marginTop: '-2px',
                padding: '2px !important',
                textAlign: 'center',
              }} // Set the width as a percentage
              {...currentForm.register('tourist_discount', {
                required: 'This field is required',
                onChange: (e) => handleFormChange(e),
              })}
              {...field}
              size="small"
              error={!!currentForm.formState.errors?.tourist_discount}
            />
          )}
        />
      </div>

      <div className="flex justify-end">
        <CustomButton type="submit">Save</CustomButton>
      </div>
    </form>
  )
}

export default SpecialDiscount

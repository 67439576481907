import { Dialog, Grid, Modal, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import UIConstants from '../../../../theme/constants/ui-constants'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../../store'
import { ListingModel } from '../../../../store/app/listing/types'
import { useTranslation } from 'react-i18next'
import ListingCard from './Listing/ListingCard'
import { useEffect, useState } from 'react'
import {
  deleteLisitng,
  fetchListings,
  fetchOthersListings,
  updateListingStatus,
} from '../../../../store/app/listing/actions'
import { fetchBusinessRoa } from '../../../../store/app/business_dashboard/actions'
import { getOwnerBusinessCommunityPost } from '../../../../store/app/community/actions'
import { UserType } from '../../../../store/app/user/types'
import AddItem from './AddItem'

interface ListingActions {
  toggleActiveStatus: (e: any, listing_id: number, is_active: boolean) => void
  onDeleteListing: (e: any, listing_id: number, post_id: number) => void
  onUpdateListing: (e: any, listing: ListingModel) => void
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: UIConstants.foreground,
    height: `100%`,
    width: '100%',
    paddingBottom: `calc(${UIConstants.primaryFooterHeight} + ${theme.spacing(
      4
    )})`,
  },
}))

const listingItems = (
  listings: ListingModel[],
  active_count: number,
  isAddListingActive: boolean,
  listingActions: ListingActions,
  setIsOpenAddListing: Function,

  t: any
) => {
  if (listings && listings.length > 0) {
    return listings.map((listing, index) => (
      <ListingCard {...listingActions} listing={listing} key={index} />
    ))
  }
}

const ListingData = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { userData } = useSelector((state: ApplicationState) => state.app)
  const { listingData, active_count } = useSelector(
    (state: ApplicationState) => state.listing
  )
  const { isAddListingActive } = useSelector(
    (state: ApplicationState) => state.businessCommunity
  )
  const isSubscribed =
    userData.user_type === UserType.BUSINESS_OWNER_WITH_SUBSCRIPTION
  const [isOpenAddListing, setIsOpenAddListing] = useState<boolean>(false)
  const [selectedLisitng, setSelectedLisitng] = useState<
    ListingModel | undefined
  >(undefined)

  useEffect(() => {
    if (userData.id) {
      dispatch(getOwnerBusinessCommunityPost(userData.id))
      dispatch(fetchListings(userData.id))
      if (isSubscribed) {
        dispatch(fetchOthersListings(userData.id))
      }
    }
  }, [])

  useEffect(() => {
    if (userData.id) {
      dispatch(fetchBusinessRoa(userData.id))
    }
  }, [userData.id])

  useEffect(() => {
    if (userData.id) {
      dispatch(getOwnerBusinessCommunityPost(userData.id))
    }
  }, [listingData])

  const toggleActiveStatus = (
    e: any,
    listing_id: number,
    is_active: boolean
  ) => {
    e.stopPropagation()
    dispatch(
      updateListingStatus({
        listing_id,
        is_active,
      })
    )
  }

  const onDeleteListing = (e: any, listing_id: number, post_id: number) => {
    e.stopPropagation()
    if (userData.id) {
      dispatch(
        deleteLisitng(listing_id)
      )
    }
  }

  const onUpdateListing = (e: any, listing: ListingModel) => {
    e.stopPropagation()
    setSelectedLisitng(listing)
    setIsOpenAddListing(true)
  }

  const handleEditListingCloseAction = () => {
    setIsOpenAddListing(false)
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        {listingData.map((listing, index) => (
          <Grid key={index} item xs={12} sm={6} md={4}>
            <ListingCard
              {...{ toggleActiveStatus, onDeleteListing, onUpdateListing }}
              listing={listing}
            />
          </Grid>
        ))}
      </Grid>
      <AddItem
        open={isOpenAddListing}
        handleClose={handleEditListingCloseAction}
        listingData={selectedLisitng}
      />
    </div>
  )
}
export default ListingData

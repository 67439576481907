import React, { useEffect, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import Modal from '@mui/material/Modal'

interface ImageScrollerProps {
  imageFileList?: any
  currentItem?: any
  deleteFile?: (item: any) => void
  deleteImage?: (item: any, index: number) => void
  webImageFiles?: any
}
function ImageScroller({
  imageFileList,
  deleteFile,
  deleteImage,
  currentItem,
  webImageFiles,
}: ImageScrollerProps) {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openIndex, setOpenIndex] = useState<number>(0)
  const handleCloseModal = () => {
    setOpenModal(false)
    setOpenIndex(0)
  }

  const handleOpenModal = (index: number) => {
    setOpenIndex(index)
    setOpenModal(true)
  }

  const handleDeleteImage = (index: number, item: any) => {
    deleteFile && deleteFile(item)
    deleteImage && deleteImage(currentItem, index)
  }

  useEffect(() => {
    console.log(imageFileList)
  })

  const checkImageSrc = (item: any) => {
    if (item instanceof File) {
      return URL.createObjectURL(item)
    } else {
      return item.uri || item.location
    }
  }

  return (
    <div className="flex gap-4 h-[82px] pt-2 w-auto overflow-x-scroll">
      {imageFileList && imageFileList.length > 0
        ? Array.from(imageFileList).map((item, index) => (
            <div
              className="relative h-[50px] w-[50px]"
              key={`cleanLogImage-${index}`}
            >
              <img
                alt={`cleanLog-${index}`}
                onClick={() => handleOpenModal(index)}
                className="max-h-[50px] max-w-[50px] object-cover rounded-md"
                src={checkImageSrc(item)}
              ></img>
              <div
                className="absolute top-0 right-0 rounded-full bg-red-700 w-[17px] h-[17px] grid place-items-center -mt-2 -mr-2 hover:bg-red-600 hover:cursor-pointer"
                onClick={() => handleDeleteImage(index, item)}
              >
                <DeleteIcon
                  sx={{ width: '10px', height: '10px', color: 'white' }}
                />
              </div>
            </div>
          ))
        : ''}
      {webImageFiles && webImageFiles.length > 0
        ? webImageFiles.map((item: any, index: number) => (
            <div
              className="relative h-[50px] w-[50px]"
              key={`cleanLogImage-${index}`}
            >
              <img
                alt={`cleanLog-${index}`}
                className="max-h-[50px] max-w-[50px] object-cover rounded-md"
                onClick={() => handleOpenModal(index)}
                src={checkImageSrc(item)}
              ></img>
              {item instanceof File && (
                <div
                  className="absolute top-0 right-0 rounded-full bg-red-700 w-[17px] h-[17px] grid place-items-center -mt-2 -mr-2 hover:bg-red-600 hover:cursor-pointer"
                  onClick={() => handleDeleteImage(index, item)}
                >
                  <DeleteIcon
                    sx={{ width: '10px', height: '10px', color: 'white' }}
                  />
                </div>
              )}
            </div>
          ))
        : ''}
      <Modal open={openModal} onClose={() => handleCloseModal()}>
        <div
          className="w-screen h-screen flex justify-center items-center duration-150"
          onClick={() => handleCloseModal()}
        >
          {imageFileList && imageFileList.length > 0 ? (
            <img
              alt={`cleanLog-${openIndex}`}
              onClick={(e) => e.stopPropagation()}
              className=" max-h-[75%] object-contain"
              src={checkImageSrc(imageFileList[openIndex])}
            />
          ) : (
            ''
          )}
          {webImageFiles && webImageFiles.length > 0 ? (
            <img
              alt={`cleanLog-${openIndex}`}
              onClick={(e) => e.stopPropagation()}
              className=" max-h-[75%] object-contain"
              src={checkImageSrc(webImageFiles[openIndex])}
            />
          ) : (
            ''
          )}
        </div>
      </Modal>
    </div>
  )
}

export default ImageScroller

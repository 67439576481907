import { Paper, Theme, Typography, useMediaQuery, useTheme, MenuItem, Popover } from '@mui/material';
import { SelectInput } from '../../../theme/components/input/Select';
import { makeStyles } from '@mui/styles';
import UIConstants from '../../../theme/constants/ui-constants';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, } from 'react-router-dom';
import { CustomButton } from '../../../theme/components/buttons/Button';
import { useEffect, useMemo, useState } from 'react';
import React from 'react';
import { fetchTsekmoCommunityPostShare, fetchTsekmoCleaningLog } from '../../../store/app/community/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import ImageGridReverse from '../../../theme/components/image-list/ImageGridReverse';
import ImageGrid from '../../../theme/components/image-list/ImageGrid';
import logo from "../../../assets/images/logo.svg";
import mobileLogo from "../../../assets/images/logo_mobile.svg";
import { getTimeOnly, getDisplayDate } from '../../utils/common-utils';

import { Chat, Send, Share } from '@mui/icons-material';
import {
    EmailShareButton,
    FacebookShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton,
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon
} from "react-share";

import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: '20px',
        background: UIConstants.rootBgColor,
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundSize: 'contain',
        backgroundPosition: 'center top',
        // height: '600px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'column',
        [theme.breakpoints.up("sm")]: {
            flexDirection: 'row',
            backgroundSize: 'cover',
        },
    },
    logo: {
        cursor: "pointer",
        width: "80px"
    },
    logoDiv: {
        height: '100%',
        alignItems: 'center',
        display: 'flex',
    },
}));
function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}
export default function SharePost() {
    const dispatch = useDispatch();
    let query = useQuery();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const { t } = useTranslation();
    const { postShare } = useSelector((state: ApplicationState) => state.tsekmoCommunity);
    const listing_id = () => {
        let id = query.get("id") ? query.get("id") : 0;
        return parseInt(id as string);
    }
    const fromCommunity = () => {
        let from = query.get("from") == 'community' ? true : false;
        return from;
    }

    const fromListing = () => {
        let from = query.get("from") == 'listing' ? true : false;
        return from;
    }

    useEffect(() => {
        if (listing_id) {
            dispatch(fetchTsekmoCommunityPostShare(listing_id(), fromCommunity()));
        }
    }, []);

    const appLogo = matches ? logo : mobileLogo;
    const [anchorE2, setAnchorE2] = useState(null);
    const handleShareClick = (event: any) => {
        setAnchorE2(event.currentTarget);
    };

    const shareURL = (id: number) => {
        let url = 'https://beta.tsekmo.com/share-post?from=true&id=' + id;
        return url;
    }

    const handleShareClose = () => {
        setAnchorE2(null);
    };

    return (
        <>
            <div className={classes.root}>
                {postShare.image_list ? (
                    <Paper style={{ marginTop: '20px', marginBottom: '20px', padding: '10px' }}>
                        <div style={{ marginBottom: '20px', paddingBottom: '30px' }}>
                            <div style={{ padding: '10px' }}>

                                {postShare.image_list ? (
                                    // <ImageGridReverse imageFileList={JSON.parse(postShare.image_list)} gap={3}/>
                                    <ImageGrid imageFileList={JSON.parse(postShare.image_list)}></ImageGrid>
                                ) : <></>}
                            </div>
                            <div style={{ display: "flex", justifyContent: 'space-between', marginTop: '10px', paddingLeft: '10px', paddingRight: '10px', fontFamily: 'Poppins', }}>
                                {postShare.description}

                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0 10px' }}>
                                <span>Get to know more about Tsekmo</span>
                                <a style={{ color: 'white', textDecoration: 'none' }} href="https://www.tsekmo.com"><img className={classes.logo}
                                    src={appLogo}
                                    alt={t('appName')}
                                /></a>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
                                <div>
                                    {postShare.post_date ? postShare.post_date : <></>}
                                </div>
                                <div>
                                <IconButton onClick={handleShareClick}>
                                    <Share />
                                </IconButton>
                                <span>Share</span>
                                <Popover
                                    open={Boolean(anchorE2)}
                                    anchorEl={anchorE2}
                                    onClose={handleShareClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'column', padding: '8px' }}>

                                        <div style={{ cursor: 'pointer', padding: '4px' }}>
                                            <FacebookShareButton url={shareURL(Number(postShare.id))}
                                                quote={'Tsekmo Community'}
                                                hashtag={'#Tsekmo Community'}>
                                                <FacebookIcon size={40} round={true} />
                                            </FacebookShareButton>
                                            <TwitterShareButton url={shareURL(Number(postShare.id))}
                                                title={'Tsekmo Community'}
                                                hashtags={['#Tsekmo Community']}>
                                                <TwitterIcon size={40} round={true} />
                                            </TwitterShareButton>
                                            <TelegramShareButton url={shareURL(Number(postShare.id))}
                                                title={'Tsekmo Community'}>
                                                <TelegramIcon size={40} round={true} />
                                            </TelegramShareButton>
                                            <LinkedinShareButton url={shareURL(Number(postShare.id))}
                                                title={'Tsekmo Community'}>
                                                <LinkedinIcon size={40} round={true} />
                                            </LinkedinShareButton>
                                            <RedditShareButton url={shareURL(Number(postShare.id))}
                                                title={'Tsekmo Community'}>
                                                <RedditIcon size={40} round={true} />
                                            </RedditShareButton>
                                            <WhatsappShareButton url={shareURL(Number(postShare.id))}
                                                title={'Tsekmo Community'}
                                            >
                                                <WhatsappIcon size={40} round={true} />
                                            </WhatsappShareButton>
                                            <EmailShareButton url={shareURL(Number(postShare.id))}
                                                title={'Tsekmo Community'}>
                                                <EmailIcon size={40} round={true} />
                                            </EmailShareButton>
                                        </div>
                                    </div>
                                </Popover>
                            </div>
                            </div>

                        </div>
                    </Paper>
                ) : <></>}

            </div>
        </>
    );
}


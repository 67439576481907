import React, { useState } from 'react'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import { makeStyles } from '@mui/styles'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    position: 'fixed',
    top: '200px',
    width: '240px',
    height: '60px',
    backgroundColor: '#1C2577',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    transition: '0.6s ease-in-out',
  },
  fab: {
    fontSize: '18px',
    textTransform: 'none',
    fontWeight: '400',
    backgroundColor: '#1C2577',
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    paddingRight: '20px',
    border: 'none',
    color: 'white',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#303a96',
    },
  },
  chevron: {
    outline: 'none',
    border: 'none',
    color: 'white',
    backgroundColor: 'transparent',
    left: '5px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '10px',
    width: '40px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#303a96',
    },
  },
}))

type FloatingButtonProps = {
  onClick: () => void
  text: string
}

const FloatingButton: React.FC<FloatingButtonProps> = ({ onClick, text }) => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(true);
  function openBtn() {
    setIsOpen(!isOpen)
  }

  return (
    <div className={classes.buttonContainer} style={{
      right: isOpen ? '10px' : '-200px',
    }}>
      <button className={classes.chevron} onClick={openBtn}>
        {
          isOpen ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />
        }
      </button>
      <button className={classes.fab} onClick={onClick}>
        {text}
      </button>
    </div>
  )
}

export default FloatingButton

import React from 'react';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LockIcon from '@mui/icons-material/Lock'; // Lock icon for access denied

const StyledNotAllowedPage = styled('div')(({ theme }) => ({
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  textAlign: 'center',
}));

function NotAllowedPage() {
  return (
    <StyledNotAllowedPage>
      <Container maxWidth="sm">
        <Box>
          <LockIcon sx={{ fontSize: 64, color: 'error.main', mb: 3 }} />
          <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
            Access Denied
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            You do not have permission to view this page.
          </Typography>
        </Box>
      </Container>
    </StyledNotAllowedPage>
  );
}

export default NotAllowedPage;
/**
 * TOSHIBA
 * Copyright(c) TOSHIBA 2021 All Rights Reserved.
 *
 * File Name	: sagas.ts
 * Package	: store
 * Author	: Sajith Rajan
 * Created At	: 01-12-2021
 * Purpose	: ApiListPage store saga file.
 *
 * [Change history]
 *
 * Modified By	:
 * Date		:
 * Description	:
 */

import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { ApiResponse, callApi } from '../../../app/utils/api'
import toaster from '../../../theme/components/Toaster'
import { postReview, getReview, setReview, postHideReview } from './actions'
import { ReviewActionTypes } from './types'

const paths = {
  postReview: 'private/review',
  fetchReviews: 'private/listing_reviews',
  hideReviews: 'private/review_hide',
}

function* handlePostReview(action: ReturnType<typeof postReview>) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const res: ApiResponse = yield call(
      callApi,
      'post',
      paths.postReview,
      action.payload
    )
    if (res.error) {
      toaster.error('Failed to post review.')
    } else {
      toaster.success('Thanks for your feedback.')
      yield put(getReview({ listing_id: action.payload.listing_id }))
    }
  } catch (err) {
    toaster.error('Failed to post review.')
  }
}

function* handleFetchReviews(action: ReturnType<typeof setReview>) {
  try {
    const res: ApiResponse = yield call(
      callApi,
      'get',
      paths.fetchReviews,
      action.payload
    )
    if (res.error) {
      toaster.error('Unable to fetch Reviews.')
    } else {
      yield put(setReview(res.data.ReviewData))
    }
  } catch (err) {
    toaster.error('Unable to fetch reviews.')
  }
}

function* handleHideReviews(action: ReturnType<typeof postHideReview>) {
  try {
    const res: ApiResponse = yield call(
      callApi,
      'post',
      paths.hideReviews,
      action.payload
    )
    if (res.error) {
      toaster.error('Unable to fetch Reviews.')
    } else {
      toaster.success('Review hidden Successfully')
      yield put(getReview({ listing_id: action.payload.listing_id }))
    }
  } catch (err) {
    toaster.error('Unable to fetch reviews.')
  }
}

function* watchPostReview() {
  yield takeEvery(ReviewActionTypes.POST_REVIEW, handlePostReview)
}

function* watchGetReview() {
  yield takeEvery(ReviewActionTypes.FETCH_REVIEWS, handleFetchReviews)
}

function* watchHideReview() {
  yield takeEvery(ReviewActionTypes.HIDE_REVIEWS, handleHideReviews)
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* reviewSagas() {
  yield all([
    fork(watchPostReview),
    fork(watchGetReview),
    fork(watchHideReview),
  ])
}

export default reviewSagas

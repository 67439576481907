import React from 'react'
import { Avatar } from '@mui/material'

function PostCommentItem() {
  return (
    <div className="flex gap-2 text-sm">
      <Avatar sx={{ width: '24px', height: '24px', marginTop: '4px' }} />
      <div className="w-full bg-white rounded-xl p-2 px-4 text-mainText">
        <p className=" font-semibold">Cyril Verdad</p>
        <p className="text-sm">Amazing!</p>
      </div>
    </div>
  )
}

export default PostCommentItem

import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'
export function useGetUserType() {
  const { userData } = useSelector((state: ApplicationState) => state.app)

  switch (userData.user_type) {
    case 1:
      return 'business-owner'
    case 2:
      return 'customer'
    case 5:
      return 'admin'
  }
}
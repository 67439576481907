import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Checkbox,
  Tooltip,
  TextField,
} from '@mui/material'
import moment from 'moment'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { cloneDeep, update } from 'lodash'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import UIConstants from '../../../../theme/constants/ui-constants'
import ImageScroller from './ImageScroller'
interface BathroomCleanLogCheckerProps {
  cleanlog_structure: any
  onAddImage: (image: any) => void
  updateUploadImages: (images: any) => void
  uploadImages: any
  setBrokenPresent: (value: boolean) => void
}

interface LogItem {
  id: number
  disabled: boolean
  text: string
  checked: boolean
  broken?: boolean
  empty?: boolean
  comment: string
  none?: boolean
  logTime: Date | null
  is_grayed_out: boolean
  category: string
  uploadedImages: any[]
}

interface CustomFile extends File {
  fileName?: string
}

const BathroomCleanLogChecker = forwardRef(
  (
    {
      cleanlog_structure,
      onAddImage,
      updateUploadImages,
      uploadImages,
      setBrokenPresent,
    }: BathroomCleanLogCheckerProps,
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      getCleanLogData() {
        return cleanLog
      },
      deleteImage(file: any) {
        handleDeleteImage(file)
      },
    }))

    const [cleanLog, setCleanLog] = useState<any>()
    const currentTime = moment().format('lll')

    const handleCheckItem = (selectedItem: LogItem) => {
      setBrokenPresent(false)
      const cleanLogClone = cloneDeep(cleanLog)

      if (!selectedItem.checked) {
        let updatedImages = cloneDeep(uploadImages)
        selectedItem.uploadedImages.forEach((image: any) => {
          updatedImages = updatedImages.filter(
            (item: any) => item.name !== image.name
          )
        })
        updateUploadImages(updatedImages)
      }

      const updatedCleanLog = cleanLogClone.map((category: any) => {
        if (category.name === selectedItem.category) {
          return {
            ...category,
            items: category.items.map((item: LogItem) => {
              if (item.id === selectedItem.id) {
                return {
                  ...item,
                  checked: !item.checked,
                  broken: false,
                  empty: false,
                  uploadedImages: [],
                }
              }
              return item
            }),
          }
        }
        return category
      })
      setCleanLog(updatedCleanLog)
    }

    const handleSelectAll = (selectedCategory: any) => {
      setBrokenPresent(false)
      const cleanLogClone = cloneDeep(cleanLog)
      let updatedImages = cloneDeep(uploadImages)
      console.log('cleanLogClone', cleanLogClone)
      cleanLogClone.forEach((category: any) => {
        category.items?.forEach((item: LogItem) => {
          if (item.uploadedImages && item.uploadedImages.length > 0) {
            Array.from(item.uploadedImages).forEach((image: any) => {
              updatedImages = updatedImages.filter(
                (item: any) => item.name !== image.name
              )
            })
          }
        })
      })
      updateUploadImages(updatedImages)
      const updatedCleanLog = cleanLogClone.map((category: any) => {
        if (category.name === selectedCategory.name) {
          return {
            ...category,
            //If all items are checked, make them all false, if only some are checked, make them all checked
            items: category.items.every(
              (item: LogItem) => item.checked === true
            )
              ? category.items.map((item: LogItem) => {
                  return {
                    ...item,
                    checked: false,
                    broken: false,
                    empty: false,
                    uploadedImages: [],
                  }
                })
              : category.items.map((item: LogItem) => {
                  return {
                    ...item,
                    checked: true,
                    broken: false,
                    empty: false,
                    uploadedImages: [],
                  }
                }),
          }
        }
        return category
      })

      setCleanLog(updatedCleanLog)
    }

    const handleInsertComment = (e: any, selectedItem: LogItem) => {
      const cleanLogClone = cloneDeep(cleanLog)
      const updatedCleanLog = cleanLogClone.map((category: any) => {
        if (category.name === selectedItem.category) {
          return {
            ...category,
            items: category.items.map((item: LogItem) => {
              if (item.id === selectedItem.id) {
                return {
                  ...item,
                  comment: e.target.value,
                }
              }
              return item
            }),
          }
        }
        return category
      })
      setCleanLog(updatedCleanLog)
    }

    const handleToggleStatus = (selectedItem: LogItem) => {
      setBrokenPresent(false)
      const cleanLogClone = cloneDeep(cleanLog)
      const updatedCleanLog = cleanLogClone.map((category: any) => {
        if (category.name === selectedItem.category) {
          return {
            ...category,
            items: category.items.map((item: LogItem) => {
              if (item.id === selectedItem.id) {
                return {
                  ...item,
                  broken:
                    item.category.toLowerCase() !== 'refillables' &&
                    !item.broken,
                  empty:
                    item.category.toLowerCase() === 'refillables' &&
                    !item.empty,
                }
              }
              return item
            }),
          }
        }
        return category
      })

      if (
        updatedCleanLog.some((category: any) =>
          category.items.some((item: LogItem) => item.broken)
        )
      ) {
        setBrokenPresent(true)
      }
      setCleanLog(updatedCleanLog)
    }

    const handleAddCleanLogImage = (e: any, selectedItem: LogItem) => {
      handleToggleStatus(selectedItem)
      const cleanLogClone = cloneDeep(cleanLog)
      const newImages: any[] = []
      Array.from(e.target.files).forEach((file: any) => {
        const newImage: CustomFile = new File(
          [file],
          Date.now() + '_' + file.name,
          {
            type: file.type,
          }
        )
        newImage.fileName = newImage.name
        newImages.push(newImage)
        onAddImage(newImage)
      })

      const updatedCleanLog = cleanLogClone.map((category: any) => {
        if (category.name === selectedItem.category) {
          return {
            ...category,
            items: category.items.map((item: LogItem) => {
              if (item.id === selectedItem.id) {
                return {
                  ...item,
                  broken: true,
                  uploadedImages: [...item.uploadedImages, ...newImages],
                }
              }
              return item
            }),
          }
        }
        return category
      })
      setCleanLog(updatedCleanLog)
      setBrokenPresent(true)
    }

    const handleDeleteImage = (item: any) => {
      const cleanLogClone = cloneDeep(cleanLog)
      const updatedCleanLog = cleanLogClone.map((category: any) => {
        return {
          ...category,
          items: category.items.map((logItem: LogItem) => {
            return {
              ...logItem,
              uploadedImages: logItem?.uploadedImages?.filter(
                (image: any) => image.name !== item.name
              ),
            }
          }),
        }
      })
      setCleanLog(updatedCleanLog)
      updateUploadImages(
        uploadImages.filter((image: any) => image.name !== item.name)
      )
    }

    //Effects

    //Initialize clean log
    useEffect(() => {
      if (cleanlog_structure.length > 0 && cleanLog === undefined) {
        setCleanLog(cleanlog_structure)
      }
    }, [cleanlog_structure, cleanLog])

    return (
      <div className="flex flex-col gap-2">
        <span className="text-sm text-subText">{currentTime}</span>
        <div>
          {cleanLog &&
            cleanLog.map(
              (category: any) =>
                category.items.every(
                  (item: LogItem) => item.disabled === false
                ) && (
                  <Accordion key={category.name} sx={{ width: '620px' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`${category.name}-content`}
                      id={`${category.name}-header`}
                    >
                      <Badge
                        badgeContent={`+${
                          category.items.length -
                          category.items.filter(
                            (item: LogItem) => item.disabled
                          ).length
                        }`}
                        color="primary"
                      >
                        <span className="text-mainText mr-[18px]">
                          {category.name}
                        </span>
                      </Badge>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="h-[64px] border-t border-b flex items-center justify-start">
                        <Tooltip title="Select All" arrow placement="right">
                          <Checkbox
                            size="small"
                            checked={category.items.every(
                              (item: any) => item.checked === true
                            )}
                            onChange={() => handleSelectAll(category)}
                          />
                        </Tooltip>
                        <span className="font-semibold text-mainText">
                          Item
                        </span>
                      </div>
                      {category.items.map(
                        (item: LogItem) =>
                          !item.disabled && (
                            <Tooltip
                              title={
                                item.is_grayed_out
                                  ? 'This item was recently reported as broken. Sorry for the inconvenience.'
                                  : ''
                              }
                              followCursor
                              key={item.id}
                            >
                              <div
                                className={
                                  item.is_grayed_out ? 'bg-gray-200' : ''
                                }
                              >
                                <div className="flex flex-col border-b pr-2">
                                  <div className="h-[64px] flex justify-between items-center">
                                    <div className="flex items-center">
                                      <Checkbox
                                        disabled={item.is_grayed_out}
                                        size="small"
                                        checked={
                                          item.is_grayed_out
                                            ? true
                                            : item.checked
                                        }
                                        onChange={() => handleCheckItem(item)}
                                      />
                                      <div className="w-[150px]">
                                        <span className="break-words text-mainText">
                                          {item.text}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="flex items-center gap-2">
                                      {item.checked ? (
                                        <span className="font-semibold text-[#9ABC66]">
                                          {item.is_grayed_out ? '' : 'Clean ✓'}
                                        </span>
                                      ) : (
                                        !item.is_grayed_out && (
                                          <>
                                            <Tooltip
                                              followCursor={true}
                                              title="Add a comment"
                                            >
                                              <TextField
                                                fullWidth
                                                placeholder="Not Clean ✘"
                                                size="small"
                                                className="text-sm"
                                                onChange={(e) =>
                                                  handleInsertComment(e, item)
                                                }
                                              />
                                            </Tooltip>
                                            {item.category !==
                                              'Refillables' && (
                                              <div className="relative p-2 hover:bg-gray-100 active:bg-gray-300 duration-150 rounded-full">
                                                <Tooltip
                                                  title="Add a photo"
                                                  placement="right"
                                                >
                                                  <AddPhotoAlternateIcon
                                                    sx={{
                                                      color:
                                                        UIConstants.subText,
                                                    }}
                                                  />
                                                </Tooltip>
                                                <input
                                                  type="file"
                                                  multiple
                                                  className="hover:cursor-pointer opacity-0 absolute inset-0 w-full h-full"
                                                  accept="image/*"
                                                  onChange={(e) =>
                                                    handleAddCleanLogImage(
                                                      e,
                                                      item
                                                    )
                                                  }
                                                />
                                              </div>
                                            )}
                                            <div className="flex items-center">
                                              <Tooltip
                                                title={
                                                  item.category.toLowerCase() ===
                                                  'refillables'
                                                    ? 'Item is empty'
                                                    : 'Item is broken'
                                                }
                                                placement="right"
                                              >
                                                <Checkbox
                                                  size="small"
                                                  checked={
                                                    item.category.toLowerCase() ===
                                                    'refillables'
                                                      ? item.empty
                                                      : item.broken
                                                  }
                                                  sx={{ marginLeft: '-12px' }}
                                                  onChange={() =>
                                                    handleToggleStatus(item)
                                                  }
                                                />
                                              </Tooltip>

                                              <span className="text-mainText">
                                                {item.category.toLowerCase() ===
                                                'refillables'
                                                  ? 'Empty'
                                                  : 'Broken'}
                                              </span>
                                            </div>
                                          </>
                                        )
                                      )}
                                    </div>
                                  </div>
                                  {item?.uploadedImages?.length > 0 && (
                                    <ImageScroller
                                      imageFileList={item.uploadedImages}
                                      deleteFile={handleDeleteImage}
                                    />
                                  )}
                                </div>
                              </div>
                            </Tooltip>
                          )
                      )}
                    </AccordionDetails>
                  </Accordion>
                )
            )}
        </div>
      </div>
    )
  }
)

export default BathroomCleanLogChecker

import React, { useEffect, useRef, useState } from 'react'
import BathroomCleanLogChecker from './BathroomCleanLogChecker'
import BathroomIncidentForm from './BathroomIncidentForm'
import BathroomReviewForm from './BathroomReviewForm'
import { Bathroom } from '@mui/icons-material'
import TsekmoButton from '../../TsekmoButton'
import {
  CircularProgress,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Theme,
  Tooltip,
} from '@mui/material'
import { ApplicationState } from '../../../../store'
import { useDispatch, useSelector } from 'react-redux'
import { submitUseBathroom } from '../../../../store/app/user/actions'
import Toaster from '../../../../theme/components/Toaster'

interface UseBathroomFormProps {
  handleCloseUseBathroom: (e: any, reason: string) => void
  listing: any
}

function UseBathroomForm({
  handleCloseUseBathroom,
  listing,
}: UseBathroomFormProps) {
  const dispatch = useDispatch()
  const [uploadImages, setUploadImages] = useState<any>([])
  const BathroomCleanLogCheckerRef = useRef<any>(null)
  const BathroomIncidentFormRef = useRef<any>(null)
  const BathroomReviewFormRef = useRef<any>(null)
  const [isBrokenPresent, setIsBrokenPresent] = useState<boolean>(false)
  const [submittingForm, setSubmittingForm] = useState<boolean>(false)

  const { loading, userData } = useSelector(
    (state: ApplicationState) => state.user
  )
  const { currentTransactionData } = useSelector(
    (state: ApplicationState) => state.listing
  )

  const setBrokenPresent = (value: boolean) => {
    setIsBrokenPresent(value)
  }

  const handleAddImage = (image: any) => {
    setUploadImages((prevState: any) => [...prevState, image])
  }

  const updateUploadImages = (images: any) => {
    setUploadImages(images)
  }

  const handleDeleteImageFromIncidentForm = (index: any) => {
    const currentImage = uploadImages[index]
    BathroomCleanLogCheckerRef.current?.deleteImage(currentImage)
  }

  const handleSubmit = () => {
    setSubmittingForm(true)
    const form_data = new FormData()
    const cleanLogData = {
      logged_at: Date.now(),
      items: BathroomCleanLogCheckerRef.current?.getCleanLogData(),
    }

    const incidentForm =
      BathroomIncidentFormRef.current?.getIncidentReportForm()
    const reviewForm = BathroomReviewFormRef.current?.getReviewForm()

    form_data.append('usage_id', currentTransactionData.usage_id)
    form_data.append('listing_id', listing.id)
    form_data.append('user_id', userData.id as any)

    if (isBrokenPresent) {
      if (
        incidentForm.getValues().incident_type !== '' &&
        incidentForm.getValues().description !== '' &&
        uploadImages.length > 0 &&
        reviewForm.getValues().rating !== ''
      ) {
        form_data.append('clean_log', JSON.stringify(cleanLogData))
        form_data.append(
          'incidentData',
          JSON.stringify({
            ...incidentForm.getValues(),
            reporter_id: userData.id,
            location: listing.location
          })
        )

        Array.from(uploadImages).forEach((image: any) => {
          form_data.append('uploadedImages', image)
        })
        form_data.append('reviewData', JSON.stringify(reviewForm.getValues()))
        return dispatch(submitUseBathroom(form_data))
      } else {
        Toaster.warning('Please fill up all required fields')
        reviewForm.trigger()
        incidentForm.trigger()
        setSubmittingForm(false)
      }
    } else {
      if (reviewForm.getValues().rating && reviewForm.getValues().review_desc) {
        form_data.append('clean_log', JSON.stringify(cleanLogData))
        form_data.append('reviewData', JSON.stringify(reviewForm.getValues()))
        return dispatch(submitUseBathroom(form_data))
      } else {
        reviewForm.trigger()
        Toaster.warning('Please fill up all required fields')
        return setSubmittingForm(false)
      }
    }
  }
  useEffect(() => {
    if (submittingForm && !loading.useBathroom) {
      handleCloseUseBathroom('', 'a')
    }
  }, [submittingForm, loading.useBathroom])

  const steps = ['Clean Log', 'Incident Report (optional)', 'Review']

  return (
    <div className="p-8 flex flex-col gap-6 max-h-[90vh] overflow-y-scroll">
      <div className="flex flex-col items-center mt-8 px-8">
        <div className="rounded-full w-[200px] h-[200px] bg-[#F8FAF5] flex justify-center items-center">
          <i className="text-[64px] text-[#9ABC66]">✓</i>
        </div>
        <span className="mt-4 text-3xl text-[#9ABC66] font-bold">Success!</span>
        <span className="text-xl text-subText mt-4">
          We have received your purchase request.
        </span>
      </div>
      <Stepper activeStep={0} orientation="vertical">
        {steps.map((label) => (
          <Step key={label} active={true}>
            <Tooltip
              title={
                label === steps[1]
                  ? 'Incident report is only applicable if you see a broken item and upload a picture'
                  : ''
              }
              arrow={true}
            >
              <StepLabel>{label}</StepLabel>
            </Tooltip>
            <StepContent>
              {label === steps[0] && (
                <BathroomCleanLogChecker
                  ref={BathroomCleanLogCheckerRef}
                  onAddImage={handleAddImage}
                  cleanlog_structure={JSON.parse(listing.cleanlog_structure)}
                  updateUploadImages={updateUploadImages}
                  uploadImages={uploadImages}
                  setBrokenPresent={setBrokenPresent}
                />
              )}
              {label === steps[1] && (
                <BathroomIncidentForm
                  ref={BathroomIncidentFormRef}
                  listing={listing}
                  uploadImages={uploadImages}
                  onAddImage={handleAddImage}
                  updateUploadImages={updateUploadImages}
                  handleDeleteImageFromIncidentForm={
                    handleDeleteImageFromIncidentForm
                  }
                  isBrokenPresent={isBrokenPresent}
                />
              )}
              {label === steps[2] && (
                <BathroomReviewForm ref={BathroomReviewFormRef} />
              )}
            </StepContent>
          </Step>
        ))}
      </Stepper>
      <div className="w-full flex justify-end mt-2 max-w-[620px] pl-3 gap-8 items-center">
        <ul className="list-disc">
          <li className="text-xs text-subText">
            You won't be able to edit or delete once you have already submitted
            this Incident Report.
          </li>
          <li className="text-xs text-subText">
            It goes straight to the Business Owner.
          </li>
          <li className="text-xs text-subText">
            Only the Business Owner can edit or delete your post.
          </li>
          <li className="text-xs text-subText">
            Once the Business Owner approves your post, you are entitled to have
            50% discount in Tsekrs Community.
          </li>
        </ul>
      </div>

      <TsekmoButton onClick={handleSubmit}>
        {loading.useBathroom ? <CircularProgress size={25} /> : 'Submit'}
      </TsekmoButton>
    </div>
  )
}

export default UseBathroomForm

import { useState } from 'react'
import * as React from 'react'
import { styled, alpha } from '@mui/material/styles'
import Menu, { MenuProps } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { CustomButton } from '../../../../../theme/components/buttons/Button'
import { Button, Dialog, IconButton } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { ListingModel } from '../../../../../store/app/listing/types'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import BlockIcon from '@mui/icons-material/Block'
import UIConstants from '../../../../../theme/constants/ui-constants'
import TsekmoButton from '../../../../common/TsekmoButton'

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}))

interface ListItemActionsProps {
  listing: ListingModel
  toggleActiveStatus: (e: any, listing_id: number, is_active: boolean) => void
  onDeleteListing: (e: any, listing_id: number, post_id: number) => void
  onUpdateListing: (e: any, listing: ListingModel) => void
  setShowTooltip: (condition: boolean) => void
}

export default function ListItemActions({
  listing,
  toggleActiveStatus,
  onDeleteListing,
  onUpdateListing,setShowTooltip,
}: ListItemActionsProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [dialogType, setDialogType] = useState<string>('')
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setShowTooltip(false)
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (e: any) => {
    e.stopPropagation()
    setAnchorEl(null)
  }

  const handleCloseDialog = (event: any) => {
    event.stopPropagation()
    setOpenDialog(false)
  }

  const handleOpenDialog = (event: any, dialogType: string) => {
    setShowTooltip(false)
    event.stopPropagation()
    setOpenDialog(true)
    setDialogType(dialogType)
  }

  const handleDialogMessage = () => {
    if (dialogType === 'Active') {
      if (listing.is_active) {
        return 'deactivate'
      } else {
        return 'activate'
      }
    }

    if (dialogType === 'Delete') {
      return 'delete'
    }
  }

  const handleDialogAction = (event: any) => {
    handleCloseDialog(event)
    if (dialogType === 'Active') {
      toggleActiveStatus(event, listing.id, listing.is_active)
    }
    if (dialogType === 'Delete') {
      onDeleteListing(event, listing.id, listing.post_id)
    }
  }
  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem onClick={(e) => onUpdateListing(e, listing)}>
          <EditIcon />
          Edit
        </MenuItem>
        <MenuItem onClick={(e) => handleOpenDialog(e, 'Delete')}>
          <DeleteIcon />
          Delete
        </MenuItem>
        <MenuItem onClick={(e) => handleOpenDialog(e, 'Active')}>
          {listing.is_active ? <BlockIcon /> : <CheckCircleOutlineIcon />}
          {listing.is_active ? 'Deactivate' : 'Activate'}
        </MenuItem>
      </StyledMenu>
      <Dialog open={openDialog} onClick={(e) => e.stopPropagation()} onClose={(e) => handleCloseDialog(e)}>
        <div className="p-8 max-w-[500px] flex flex-col gap-4 justify-center text-center bg-white">
          <p className="font-semibold">
            Are you sure you want to {handleDialogMessage()} this listing?
          </p>
          <div className="flex gap-2 justify-center w-full">
            <Button
              sx={{
                backgroundColor: 'transparent',
                color: UIConstants.mainText,
                textTransform: 'capitalize',
              }}
              onClick={(e) => handleCloseDialog(e)}
            >
              No
            </Button>
            <TsekmoButton onClick={(e) => handleDialogAction(e)}>
              Yes
            </TsekmoButton>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

import {
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
} from '@mui/material'
import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { Controller, useForm } from 'react-hook-form'
import UIConstants from '../../../../theme/constants/ui-constants'
import { useDispatch, useSelector } from 'react-redux'
import LocationSelector from '../../../../theme/components/input/LocationSelector'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import MobileTimePicker from '@mui/lab/MobileTimePicker'
import { ClockIcon } from '../../../../theme/components/icons/ClockIcon'
import { makeStyles } from '@mui/styles'
import { Toggle } from '../../../../theme/components/input/Toggle'
import DropUpload from '../../DropUpload'
import UploadImageList from '../../../../theme/components/image-list/UploadImageList'
import { fetchListingData } from '../../../../store/app/community/actions'
import { fetchListings } from '../../../../store/app/listing/actions'
import { ListingDataModel } from '../../../../store/app/community/types'
import { isJson } from '../../../../app/utils/common-utils'

interface IFormInput {
  incident_type: string
  listing_id: number
  description: string
  date: Date
  tagged_users: string
  image_list: string
  quick_estimate: number
  professional_estimate: number
  rules: string
  is_private: boolean
  can_share: boolean
  owner_id: number
  post_date: Date
  is_active: boolean
  location: string
  tag_customer: boolean
  file_list: any
  listing_type: any
  store_name: string
  store_address: any
  store_email: any
  store_contact_number: any
  anonymous: boolean
}

interface EditIncidentReportFormProps {
  incidentReport: any
  uploadedImages: any
  remoteImages: any
  handleDeleteImageFromIncidentForm: (index: number) => void
}

const useStyle = makeStyles((theme: Theme) => ({
  inputBorderPrimary: {
    '&.MuiInputBase-root': {
      '& > fieldset': {
        borderColor: UIConstants.primaryBgColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: UIConstants.primaryBgColor,
      },
    },
  },
  textInput: {
    '& label': {
      color: UIConstants.primaryBgColor,

      '&.Mui-disabled': {
        color: '#9d9e9e',
      },
    },

    '& label.Mui-focused': {
      color: UIConstants.primaryBgColor,
    },

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: UIConstants.primaryBgColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: UIConstants.primaryBgColor,
      },
    },
  },
  toggleBtn: {
    color: UIConstants.primaryBgColor,
    margin: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

const EditIncidentReportForm = forwardRef(
  (
    {
      incidentReport,
      uploadedImages,
      remoteImages,
      handleDeleteImageFromIncidentForm,
    }: EditIncidentReportFormProps,
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      getIncidentReportForm: () => {
        return incidentReportForm
      },
    }))
    const classes = useStyle()
    const incidentReportForm = useForm<IFormInput>({ mode: 'all' })
    // const { userData } = useSelector((state: any) => state.user)
    const { listingData } = useSelector((state: any) => state.listing)

    const deletePhoto = (index: number) => {
      handleDeleteImageFromIncidentForm(index)
    }

    const incidentTypeList: string[] = [
      'Vandalism',
      'Drugs Related',
      'Uncleanliness',
      'Overtime Usage',
      'Others',
    ]

    useEffect(() => {
      console.log(incidentReport)
      if (incidentReport) {
        incidentReportForm.reset({
          incident_type: incidentReport.incident_type,
          listing_id: incidentReport.listing_id,
          description: incidentReport.description,
          date: new Date(incidentReport.date),
          tagged_users: incidentReport.tagged_users,
          image_list: incidentReport.image_list,
          quick_estimate: incidentReport.quick_estimate,
          professional_estimate: incidentReport.professional_estimate,
          rules: incidentReport.rules,
          is_private: incidentReport.is_private,
          can_share: incidentReport.can_share,
          owner_id: incidentReport.owner_id,
          post_date: new Date(incidentReport.post_date),
          is_active: incidentReport.is_active,
          location: isJson(incidentReport.location)
            ? JSON.parse(incidentReport.location).label
            : incidentReport.location,
          tag_customer: incidentReport.tag_customer,
          file_list: [...remoteImages, ...uploadedImages],
          listing_type: incidentReport.listing_type,
          store_name: incidentReport.store_name,
          store_address: incidentReport.store_address,
          store_email: incidentReport.store_email,
          store_contact_number: incidentReport.store_contact_number,
          anonymous: incidentReport.anonymous,
        })
      }
    }, [])

    return (
      <form className="flex flex-col gap-4 max-w-[620px]">
        <Controller
          name="incident_type"
          control={incidentReportForm.control}
          defaultValue={''}
          render={({ field }) => (
            <FormControl size="small" className={classes.textInput}>
              <InputLabel
                sx={{
                  color: UIConstants.primaryBgColor,
                  '&.Mui-focused': { color: UIConstants.primaryBgColor },
                }}
                error={!!incidentReportForm.formState.errors?.incident_type}
              >
                Incident Type
              </InputLabel>
              <Select
                {...field}
                label="Incident Type"
                {...incidentReportForm.register('incident_type', {
                  required: 'This field is required',
                })}
                error={!!incidentReportForm.formState.errors?.incident_type}
              >
                {incidentTypeList?.map(
                  (incidentType: string, index: number) => (
                    <MenuItem value={incidentType} key={incidentType + index}>
                      {incidentType}
                    </MenuItem>
                  )
                )}
              </Select>
              {incidentReportForm.formState.errors?.incident_type && (
                <span className="text-xs font-normal text-red-600 mt-2 ml-4">
                  {incidentReportForm.formState.errors?.incident_type?.message}
                </span>
              )}
            </FormControl>
          )}
        />

        <Controller
          name="listing_id"
          control={incidentReportForm.control}
          defaultValue={-99}
          render={({ field }) => (
            <FormControl size="small" disabled={true}>
              <InputLabel
                sx={{
                  color: UIConstants.primaryBgColor,
                  '&.Mui-focused': { color: UIConstants.primaryBgColor },
                }}
              >
                Listing
              </InputLabel>
              <Select
                sx={{
                  '&:focus': {
                    backgroundColor: 'yellow',
                  },
                }}
                {...field}
                label="Listing"
                {...incidentReportForm.register('listing_id', {
                  required: 'This field is required',
                })}
                error={!!incidentReportForm.formState.errors?.listing_id}
              >
                {listingData?.map(
                  (listing: ListingDataModel, index: number) => (
                    <MenuItem value={listing.id} key={listing.title + index}>
                      {listing.title}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          )}
        />
        <Controller
          name="location"
          control={incidentReportForm.control}
          rules={{
            required: 'Location required' as string,
          }}
          defaultValue={''}
          render={({ field }) => (
            <LocationSelector
              {...field}
              disabled={true}
              label="Location or Addresss"
              size="small"
              error={incidentReportForm.formState.errors?.location}
              helperText={
                incidentReportForm.formState.errors?.location?.message
              }
            />
          )}
        />
        <Controller
          name="description"
          control={incidentReportForm.control}
          defaultValue={''}
          render={({ field }) => (
            <TextField
              {...field}
              label="Incident Report"
              size="small"
              className={classes.textInput}
              {...incidentReportForm.register('description', {
                required: 'This field is required',
              })}
              error={!!incidentReportForm.formState.errors?.description}
              helperText={
                incidentReportForm.formState.errors?.description?.message
              }
              variant="outlined"
              multiline
              minRows={2}
              maxRows={4}
            />
          )}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Controller
            name="date"
            control={incidentReportForm.control}
            defaultValue={new Date()}
            rules={{
              required: 'This field is required',
            }}
            render={({ field }) => (
              <MobileTimePicker
                ampm={true}
                value={field.value}
                onChange={field.onChange}
                disabled={true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={'Date and time'}
                    id="input-with-icon-textfield"
                    variant="outlined"
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <ClockIcon />
                        </InputAdornment>
                      ),
                    }}
                    error={!!incidentReportForm.formState.errors?.date}
                    helperText={
                      incidentReportForm.formState.errors?.date?.message
                    }
                  />
                )}
              />
            )}
          />
        </LocalizationProvider>
        <Controller
          name="file_list"
          control={incidentReportForm.control}
          defaultValue={null}
          rules={{
            required: 'A photo of the incident is required',
          }}
          render={({ field }) => (
            <>
              <DropUpload
                onFileDrop={function (event: any): void {
                  throw new Error('Function not implemented.')
                }}
                {...field}
                text="Add Photo"
                dragText="Upload Photo"
                //   onFileDrop={(event) => addPhoto(event)}
                error={!!incidentReportForm.formState.errors?.file_list}
              />
              {incidentReportForm.formState.errors?.file_list && (
                <span className="text-xs font-normal text-red-600 -mt-2 ml-4">
                  {incidentReportForm.formState.errors?.file_list?.message}
                </span>
              )}
            </>
          )}
        />

        <UploadImageList
          remoteImageFileList={remoteImages}
          removeDeleteIconForRemoteImages
          // imageFileList={uploadImages}
          onDelete={deletePhoto}
          columns={1}
          imageFileList={uploadedImages}
        />
        <FormControlLabel
          control={
            <Controller
              name="can_share"
              control={incidentReportForm.control}
              defaultValue={false}
              render={({ field }) => (
                <Toggle checked={field.value} onChange={field.onChange} />
              )}
            />
          }
          label={'Disable sharing of this post by others'}
          labelPlacement="start"
          className={classes.toggleBtn}
        />
      </form>
    )
  }
)

export default EditIncidentReportForm

import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme, useTheme } from "@mui/material/styles";
import QRCode from "react-qr-code";
import { CustomButton } from "../../../../theme/components/buttons/Button";
import { makeStyles } from "@mui/styles";
import { useReactToPrint } from "react-to-print";
import logo from "./../../../../assets/images/logo.svg";
import mobileLogo from "./../../../../assets/images/logo_mobile.svg";

interface QrCodePrintProps {
  open: boolean;
  qr_code: string;
  secureCode: boolean;
  handleClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    height: "100vh",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  printBody: {
    height: "100vh",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  flexdiv: {
    minHeight: "7rem",
    marginTop: "0%",
    position: "unset",
    top: "unset",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "white",
  },
  logo: {
    height: "70px",
    width: "40%",
  },
}));

export default function QrCodePrint({
  open,
  qr_code,
  handleClose,
  secureCode,
}: QrCodePrintProps) {
  // const [open, setOpen] = React.useState(false);
  // const [secureCode, setSecureCode] = React.useState(false);
  const classes = useStyles();
  const secureQR = secureCode;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  let componentRef = React.useRef(null);
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const appLogo = matches ? logo : mobileLogo;
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: handleClose,
    bodyClass: classes.content,
  });

  return (
    <div>
      <Dialog
        fullScreen={isMobile}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {secureQR ? "Print Store QR Code" : "Print Bathroom QR Code"}
        </DialogTitle>
        <DialogContent className={classes.content} ref={componentRef}>
          <div className={classes.flexdiv}>
            {" "}
            <img src={appLogo} alt={"appName"} className={classes.logo} />
          </div>
          {secureQR ? (
            <>
              <div className={classes.flexdiv}>
                <QRCode
                  bgColor={"rgb(255, 255, 255, 0)"}
                  fgColor={"rgb(28, 37, 119)"}
                  level={"M"}
                  value={qr_code}
                  size={300}
                />
              </div>
              <DialogContentText mt={24}>
                This QR code is for your store if you feel unsafe and want to
                verify your customers before they enter your store. This is
                ideal during after dark and you only have one employee at night.
              </DialogContentText>
            </>
          ) : (
            <>
              <QRCode
                bgColor={"rgb(255, 255, 255, 0)"}
                level={"M"}
                value={qr_code}
                size={300}
              />
              <DialogContentText mt={4}>
                Before you use the bathroom scan this QR code and follow
                instructions.
              </DialogContentText>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <CustomButton variant="contained" onClick={handlePrint} autoFocus>
            Print
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React from 'react'
import { CustomAppBar } from "../../theme/components/app-layout/header/AppBar";
import TsekmoLandingToolBar from "../components/ToolBar";
import Footer from "../../theme/components/app-layout/footer/Footer";
import { Main } from "../../theme/components/app-layout/main/Main";
import { makeStyles } from '@mui/styles';
import { Theme ,  Grid } from '@mui/material';
import UIConstants from '../../theme/constants/ui-constants';
import { useEffect } from 'react';

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    background: UIConstants.rootBgColor,
    height: `100%`,
    width: '100%',
    paddingBottom: `calc(${UIConstants.primaryFooterHeight} + ${theme.spacing(
      0
    )})`
  },
  content: {
    margin:  '2%'
  },
  optionsImages: {
    width: '350px',
    height: '244px',
    borderBottomRightRadius: '40px',
    borderBottomLeftRadius: '40px'
  },
  addExtraPadding: {
    paddingBottom: '70px'
  }
}));

const Gdpr = (props: any) => {
  const classes = useStyle();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <>
      {!props.isLogedIn && (
        <CustomAppBar>
          <TsekmoLandingToolBar />
        </CustomAppBar>
      )}

      {props.isLogedIn ? (
        <div
          style={{
            minHeight: '50vh'
          }}
        >
          <h3>General Data Protection Regulation (GDPR)</h3><br />
          <p>
          Last Updated: June  19, 2022
          </p><br />
          <p>
          GDPR-Compliant Privacy Policy
          </p><br />
          <p>
          Organizations established in the EU and processing personal data of EU-based individuals are, in almost all cases, required to comply with the GDPR as of May 25, 2018.

The GDPR updates and harmonizes the framework for processing personal data in the European Union, and brings with it new obligations for organizations and new rights for individuals.

          </p><br />
          <p>
            <b>
            Energizm  Inc.
            </b>
            <p>support@energizm.com </p>
          </p><br />
          <h4>
          Our Commitment
          </h4>
          <p>
          The team at Energizm is fully committed to the requirements of the GDPR. Our legal and policy experts have closely analyzed the requirements of the GDPR and continue to monitor new guidance on best practices for implementing the requirements of the GDPR. Energizm services comply with the GDPR as of May 25, 2018.

The purpose of the list stipulated below is to allow Users and Contacts to exercise their rights under applicable privacy and data protection regulations:

          </p><br />
          <h4>
          Right of Access: 
          </h4>
          <p>
          You may request to access your Personal Information and obtain a copy of Personal Information which is being processed by Energizm. In the event that you request to know what Personal Information is being processed by us, we will provide you with the following information free of charge: purposes of processing; categories of Personal Information processed; recipient(s) of Personal Information; length of time during which the Personal Information will be stored; your privacy rights; and information on data transfers. Such requests will be made by sending a request to support@Energizm.com, please make sure to provide your relevant details.
          </p><br />
          <h4>
          Right of Rectification: 
          </h4>
          <p>
          You may request to change, update or complete any missing data we process about you, by sending an email to support@Energizm.com with your relevant details. Please note that we may rectify, replenish or remove incomplete or inaccurate information, at any time and at our own discretion.
          </p><br />
          <h4>
          Right of Erasure: 
          </h4>
          <p>
          You may at any time withdraw your consent to our processing of your Personal Information. In this case, if there is no overriding legitimate interest for continuing the processing of your Personal Information (e.g. to comply with our legal obligations, resolve disputes, enforce our agreements, etc.) and the Personal Information is no longer necessary in relation to the purpose for which it was originally collected, we will erase your data. Such withdrawal of consent will be made by sending an email to support@Energizm.com with your relevant details.
          </p> <br />
          <h4>
          Right of Restriction of Processing: 
          </h4>
          <p>
          You may request us to restrict processing of your Personal Information if one of the following applies: (i) the accuracy of the Personal Information is contested by you; (ii) the processing is unlawful; or (iii) if we no longer need the Personal Information. Such request will be made by sending an email with the relevant details to support@Energizm.com.
          </p> <br />
          <h4>
          Right to Data Portability: 
          </h4>
          <p>
          You have the right to receive the Personal Information in a structured, commonly used and machine-readable format. Such request will be made by sending an email with your relevant details to support@Energizm.com If you are not satisfied with our response or believe we are collecting or processing your Personal Information not in accordance with the laws, you can complain to the applicable data protection authority.
          </p> <br/><br/><br />
        </div>
       ) : ( 
         <Main>
                  <div
          style={{
            minHeight: '50vh'
          }}
        >
          <h3>General Data Protection Regulation (GDPR)</h3><br />
          <p>
          Last Updated: June  19, 2022
          </p><br />
          <p>
          GDPR-Compliant Privacy Policy
          </p><br />
          <p>
          Organizations established in the EU and processing personal data of EU-based individuals are, in almost all cases, required to comply with the GDPR as of May 25, 2018.

The GDPR updates and harmonizes the framework for processing personal data in the European Union, and brings with it new obligations for organizations and new rights for individuals.

          </p><br />
          <p>
            <b>
            Energizm  Inc.
            </b>
            <p>support@energizm.com </p>
          </p><br />
          <h4>
          Our Commitment
          </h4>
          <p>
          The team at Energizm is fully committed to the requirements of the GDPR. Our legal and policy experts have closely analyzed the requirements of the GDPR and continue to monitor new guidance on best practices for implementing the requirements of the GDPR. Energizm services comply with the GDPR as of May 25, 2018.

The purpose of the list stipulated below is to allow Users and Contacts to exercise their rights under applicable privacy and data protection regulations:

          </p><br />
          <h4>
          Right of Access: 
          </h4>
          <p>
          You may request to access your Personal Information and obtain a copy of Personal Information which is being processed by Energizm. In the event that you request to know what Personal Information is being processed by us, we will provide you with the following information free of charge: purposes of processing; categories of Personal Information processed; recipient(s) of Personal Information; length of time during which the Personal Information will be stored; your privacy rights; and information on data transfers. Such requests will be made by sending a request to support@Energizm.com, please make sure to provide your relevant details.
          </p><br />
          <h4>
          Right of Rectification: 
          </h4>
          <p>
          You may request to change, update or complete any missing data we process about you, by sending an email to support@Energizm.com with your relevant details. Please note that we may rectify, replenish or remove incomplete or inaccurate information, at any time and at our own discretion.
          </p><br />
          <h4>
          Right of Erasure: 
          </h4>
          <p>
          You may at any time withdraw your consent to our processing of your Personal Information. In this case, if there is no overriding legitimate interest for continuing the processing of your Personal Information (e.g. to comply with our legal obligations, resolve disputes, enforce our agreements, etc.) and the Personal Information is no longer necessary in relation to the purpose for which it was originally collected, we will erase your data. Such withdrawal of consent will be made by sending an email to support@Energizm.com with your relevant details.
          </p> <br />
          <h4>
          Right of Restriction of Processing: 
          </h4>
          <p>
          You may request us to restrict processing of your Personal Information if one of the following applies: (i) the accuracy of the Personal Information is contested by you; (ii) the processing is unlawful; or (iii) if we no longer need the Personal Information. Such request will be made by sending an email with the relevant details to support@Energizm.com.
          </p> <br />
          <h4>
          Right to Data Portability: 
          </h4>
          <p>
          You have the right to receive the Personal Information in a structured, commonly used and machine-readable format. Such request will be made by sending an email with your relevant details to support@Energizm.com If you are not satisfied with our response or believe we are collecting or processing your Personal Information not in accordance with the laws, you can complain to the applicable data protection authority.
          </p> <br/><br/><br />
        </div>
        </Main>
      )}

      {!props.isLogedIn && (
        <Footer showTwoLevelFooter={true} />
      )}
    </>
  )
}

export default Gdpr
import {
	GoogleAuthProvider,
	FacebookAuthProvider,
	EmailAuthProvider,
	OAuthProvider,
} from 'firebase/auth';

const googleProvider = new GoogleAuthProvider();

const facebookProvider = new FacebookAuthProvider();

const appleProvider = new OAuthProvider('apple.com');
appleProvider.addScope('email');
appleProvider.addScope('name');
appleProvider.setCustomParameters({
	locale: 'en',
});

const emailProvider = new EmailAuthProvider();

export { googleProvider, facebookProvider, appleProvider, emailProvider };

import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  useStripe,
  useElements,
  CardElement,
  Elements,
} from '@stripe/react-stripe-js'
import axios from 'axios'
import StripePricingTable from './PricingTable'
import { TextField } from '@mui/material'

interface Price {
  id: string
  object: string
  active: boolean
  billing_scheme: string
  created: number
  currency: string
  custom_unit_amount: null
  livemode: boolean
  lookup_key: string | null
  metadata: Record<string, unknown>
  nickname: string
  product: string
  recurring: {
    aggregate_usage: string | null
    interval: string
    interval_count: number
    meter: null
    trial_period_days: number | null
    usage_type: string
  }
  tax_behavior: string
  tiers: Array<{
    flat_amount: null
    flat_amount_decimal: null
    unit_amount: number
    unit_amount_decimal: string
    up_to: number | null
  }>
  tiers_mode: string
  transform_quantity: null
  type: string
  unit_amount: number
  unit_amount_decimal: string | null
}

//Up to you if you want to use this ListProduct that has a data : Price[]
interface ListProducts {
  object: string
  data: Price[]
  has_more: boolean
  url: string
}

export default function StripeProcess() {
  const navigate = useNavigate()
  const stripe = useStripe()
  const elements = useElements()

  const [paymentError, setPaymentError] = useState<string | null>(null)
  const [paymentSuccess, setPaymentSuccess] = useState<string | null>(null)
  const [quantity, setQuantity] = useState(1) // Default quantity is 1
  const [accountId, setAccountId] = useState<string | null>(null)

  const [products, setProducts] = useState<Price[]>([])

  const productRowStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  }

  const productNameStyle = {
    fontWeight: 'bold',
  }

  const productUnitAmountStyle = {
    color: '#888',
  }

  const productTiersStyle = {
    display: 'flex',
    alignItems: 'center',
  }

  const calculatedPriceStyle = {
    marginLeft: '10px',
  }

  const connectData = {
    user_id: 79,
    refresh_url: `${process.env.REACT_APP_DOMAIN}/stripe-process`,
    return_url: `${process.env.REACT_APP_DOMAIN}`,
  }

  const payData = {
    bo_user_id: 79,
    user_id: 24,
    amount: 2000,
    currency: 'usd',
    email: 'customer2@example.com',
  }

  const headers = {
    'Content-Type': 'application/json',
    Authorization:
      'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjJkOWI0ZTY5ZTMyYjc2MTVkNGNkN2NhZmI4ZmM5YjNmODFhNDFhYzAiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiZ2lueHggdHJpcHBsZXgiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUNnOG9jTG9TeDZkVjRkd0wteXpVcDAzM21RNEZKaVYzY3I0NnBZcno0NHZGTzc1Um1yVER3PXM5Ni1jIiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL3RzZWttLWJldGEiLCJhdWQiOiJ0c2VrbS1iZXRhIiwiYXV0aF90aW1lIjoxNzE0MDUxOTY4LCJ1c2VyX2lkIjoiTlpXUkVrcVpQek5OZjBUYjl5aGdaRUthWmx1MSIsInN1YiI6Ik5aV1JFa3FaUHpOTmYwVGI5eWhnWkVLYVpsdTEiLCJpYXQiOjE3MTQwNTE5NjgsImV4cCI6MTcxNDA1NTU2OCwiZW1haWwiOiJnaW54eDE5MDVAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZ29vZ2xlLmNvbSI6WyIxMDkxMzY0MDg5MjAwNjkxNzIzMTQiXSwiZW1haWwiOlsiZ2lueHgxOTA1QGdtYWlsLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6Imdvb2dsZS5jb20ifX0.LS0O3N5yDkM94pIiZ9euLI8bTnHkEGXNQi0iS-T6gbQqq3IJtv-zkFSiy5oB-jxaadTa7fpG8E1QkX9TAGhaGRykKMCvyJHtAwVXyymnWtDvDEbIYjOj0gRiRhe8eTmRAXL82BI3IadQYwds74L-w5NXZMPEqBLBG3aE0adFZcvRFcT8hljLZjKVwY1wFyp0DPbJLrLCd8Y2wDTOrRuEWhShv5AEqV0SiTr8tM9_RZ1xbv7BnC3r2S_6VH2DRRf84rKpw5AaiQySSs2z-wYAK5Ne-hdlyyA9uXx-b61Ga8X0t_ngMbMG2CqhqG42gw7wN_84cmdxfHD1u1y7g37H9A',
  }

  const handlePayment = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return
    }

    const cardElement = elements.getElement(CardElement)

    if (!cardElement) {
      setPaymentError('Card information is invalid.')
      setPaymentSuccess(null)
      return
    }

    // Create a payment method using the card information
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    })

    if (error) {
      setPaymentError(
        error?.message || 'An error occurred during payment. Please try again.'
      )
      setPaymentSuccess(null)
    } else {
      // If payment method creation is successful, proceed with payment
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/private/payment_intent`,
          {
            ...payData,
            payment_method_id: paymentMethod?.id,
          },
          {
            headers: headers,
          }
        )
        .then((res) => {
          console.log(res)
          setPaymentSuccess(res.data.client_secret)
          setPaymentError(null)
        })
        .catch((err) => {
          console.log(err.response)
          setPaymentError('Payment failed. Please try again.')
          setPaymentSuccess(null)
        })
    }
  }

  /**
   * This endpoint will be called for linking the stripe account of the BO to our
   * platform
   */
  const connectStripe = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/private/connectstripeaccount`,
        connectData,
        {
          headers: headers,
        }
      )
      .then((res) => {
        console.log(res)
        console.log(res.data)
        window.open(res.data.url, '_blank')
      })
      .catch((err) => {
        if (err.response.status === 409) {
          console.log('Conflict 409')
        }
      })
  }

  const subscribeBO = (
    priceId: string,
    unit_amount: number,
    usage_type: string,
    subtotal: number,
    totalPerYear: number,
    quantity: number,
    product: string,
    nickname: string
  ) => {
    let subscribeData

    if (totalPerYear === 0) {
      subscribeData = {
        //change this success url where it has a schema for deep link
        //i.e (tsekmo://) and detect if the browser is in mobile if not
        //then proceed with the hosting address (i.e beta.tsekmo.com/landing) something
        //like that
        unit_amount: unit_amount,
        package_subscribed: product,
        package_quantity: 1,
        user_id: 79,
        price_id: priceId,
        email: 'ginxx1905@gmail.com',
        usage_type: usage_type,
        product_name: nickname,
      }
    } else {
      subscribeData = {
        //change this success url where it has a schema for deep link
        //i.e (tsekmo://) and detect if the browser is in mobile if not
        //then proceed with the hosting address (i.e beta.tsekmo.com/landing) something
        //like that
        unit_amount: totalPerYear,
        package_subscribed: product,
        package_quantity: quantity,
        user_id: 79,
        price_id: priceId,
        email: 'ginxx1905@gmail.com',
        usage_type: usage_type,
        product_name: nickname,
      }
    }

    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/private/usersubscription`,
        subscribeData,
        {
          headers: headers,
        }
      )
      .then((res) => {
        console.log(res.data)
        window.open(res.data.url, '_blank')
      })
      .catch((err) => {
        console.log(err)
      })
  }

  /**
   * End point for calling the product catalogs
   */
  const getAllProducts = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/private/product_list?limit=5`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        setProducts(res.data.products.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  /**
   * This is to mapped the total price like on the stripe when you edit the tiered pricing
   * @param product
   * @param quantity
   * @returns
   */
  const calculateTotalPrice = (product: Price, quantity: number) => {
    let subtotal = 0

    // Check if the product has tiers
    if (product.tiers && product.tiers.length > 0) {
      let remainingQuantity = quantity

      // Iterate through the tiers to calculate subtotal
      for (let i = 0; i < product.tiers.length; i++) {
        const tier = product.tiers[i]

        if (tier.up_to === null) break

        const tierQuantity = Math.min(
          remainingQuantity,
          tier.up_to - (product.tiers[i - 1]?.up_to || 0)
        )

        if (tierQuantity <= 0) break

        const tierAmount = tier.unit_amount * tierQuantity
        subtotal += tierAmount
        remainingQuantity -= tierQuantity
      }
    } else if (product.tiers_mode == null) {
      quantity = 1
      subtotal = product.unit_amount
    }

    // Total per year should be the same as the subtotal
    const totalPerYear = subtotal

    return {
      subtotal,
      totalPerYear,
      quantity,
    }
  }

  useEffect(() => {
    getAllProducts()
  }, [])

  return (
    <>
      <div>
        <button onClick={connectStripe}>Connect to stripe</button>
      </div>
      <div>
        <CardElement />
        <button onClick={handlePayment}>Pay</button>
      </div>

      {/**Note : Do not include embeded pricing table as we cannot control
       * We must create the pricing table from scratch and use the getAllProduct method to get
       * the whole information of the product
       */}
      {/* <div>
                <StripePricingTable/>
            </div> */}
      <div>
        <form>
          <label htmlFor="quantity">Input Quantity:</label>
          <input
            type="number"
            id="quantity"
            value={quantity}
            onChange={(e) => setQuantity(Number(e.target.value))}
          />
        </form>
        {products?.map((product, index) => (
          <div key={index} style={productRowStyle}>
            <div style={productNameStyle}>{product.nickname}</div>
            <div style={productUnitAmountStyle}>${product.unit_amount}</div>
            {product.tiers && (
              <div style={productTiersStyle}>
                <TextField
                  label="Quantity"
                  value={quantity}
                  onChange={(e) => setQuantity(Number(e.target.value))}
                />
                <div style={calculatedPriceStyle}>
                  <h3>Price Breakdown</h3>
                  {product.tiers.map((tier, tierIndex) => {
                    if (tier.up_to !== null) {
                      const previousTierUpTo =
                        product.tiers[tierIndex - 1]?.up_to || 0
                      const tierQuantity = Math.min(
                        quantity - previousTierUpTo,
                        tier.up_to - previousTierUpTo
                      )
                      if (tierQuantity > 0) {
                        const tierAmount = tier.unit_amount * tierQuantity
                        return (
                          <div key={tierIndex}>
                            {tierIndex === 0 ? 'First' : 'Next'} {tierQuantity}{' '}
                            × ${tier.unit_amount} = ${tierAmount}
                            <br />
                          </div>
                        )
                      }
                    }
                    return null
                  })}
                  <div>
                    Subtotal: ${calculateTotalPrice(product, quantity).subtotal}
                    <br />
                    Total per year: $
                    {calculateTotalPrice(product, quantity).totalPerYear}
                  </div>
                </div>
              </div>
            )}
            <button
              onClick={() =>
                subscribeBO(
                  product.id,
                  product.unit_amount,
                  product.recurring?.usage_type,
                  calculateTotalPrice(product, quantity).subtotal,
                  calculateTotalPrice(product, quantity).totalPerYear,
                  calculateTotalPrice(product, quantity).quantity,
                  product.product,
                  product.nickname
                )
              }
            >
              Subscribe
            </button>
          </div>
        ))}
      </div>
      {paymentError && <div>{paymentError}</div>}
      {paymentSuccess && <div>Payment successful!</div>}
    </>
  )
}

import { Dispatch, SetStateAction, useState } from 'react'
//CURRENTLY UNUSED CODE
interface LogItem {
  id: number
  disabled: boolean
  text: string
  checked: boolean
  broken?: boolean
  empty?: boolean
  comment: string
  logTime: Date | null
  category: string
}
type ToggleHandler = (
  index: number,
  setItems: Dispatch<SetStateAction<LogItem[]>>
) => void

interface CleanLogHook {
  selectedCleanLog: Record<string, LogItem[]>
  handleSaveCleanLog: (
    category: string,
    selectedItems: LogItem[],
    selectAll?: boolean
  ) => void
  selectAllStates: Record<string, boolean>
  handleSelectAll: (
    checked: boolean,
    category: string,
    setSelectAll: Dispatch<SetStateAction<boolean>>,
    setItems: Dispatch<SetStateAction<LogItem[]>>
  ) => void
}

export const useCleanLog = (): CleanLogHook => {
  const [selectedCleanLog, setSelectedCleanLog] = useState<
    Record<string, LogItem[]>
  >({})
  const [selectAllStates, setSelectAllStates] = useState<
    Record<string, boolean>
  >({})

  const handleSaveCleanLog = (
    category: string,
    selectedItems: LogItem[],
    selectAll: boolean = false
  ) => {
    setSelectAllStates((prev) => ({ ...prev, [category]: selectAll }))

    const updatedSelectedItems = selectAll
      ? selectedItems.map((item) => ({ ...item, checked: true }))
      : selectedItems

    setSelectedCleanLog((prev) => ({
      ...prev,
      [category]: updatedSelectedItems,
    }))
  }

  const handleSelectAll = (
    checked: boolean,
    category: string,
    setSelectAll: Dispatch<SetStateAction<boolean>>,
    setItems: Dispatch<SetStateAction<LogItem[]>>
  ) => {
    setSelectAll(checked)

    setItems((prevItems) =>
      prevItems.map((item) => ({
        ...item,
        checked,
        logTime: checked ? new Date() : item.logTime,
      }))
    )
  }

  return {
    selectedCleanLog,
    handleSaveCleanLog,
    selectAllStates,
    handleSelectAll,
  }
}

export const handleToggleBathroom: ToggleHandler = (index, setBathroom) => {
  setBathroom((prevItems) =>
    prevItems.map((item, i) =>
      i === index
        ? { ...item, checked: !item.checked, logTime: new Date() }
        : item
    )
  )
}

export const handleToggleToilet: ToggleHandler = (index, setToilet) => {
  setToilet((prevItems) =>
    prevItems.map((item, i) =>
      i === index
        ? { ...item, checked: !item.checked, logTime: new Date() }
        : item
    )
  )
}

export const handleToggleVanities: ToggleHandler = (index, setVanities) => {
  setVanities((prevItems) =>
    prevItems.map((item, i) =>
      i === index
        ? { ...item, checked: !item.checked, logTime: new Date() }
        : item
    )
  )
}

export const handleToggleShower: ToggleHandler = (index, setShower) => {
  setShower((prevItems) =>
    prevItems.map((item, i) =>
      i === index
        ? { ...item, checked: !item.checked, logTime: new Date() }
        : item
    )
  )
}

export const handleToggleCleaning: ToggleHandler = (index, setCleaning) => {
  setCleaning((prevItems) =>
    prevItems.map((item, i) =>
      i === index
        ? { ...item, checked: !item.checked, logTime: new Date() }
        : item
    )
  )
}

export const handleAddNewItemBathroom = (
  newItemName: string,
  setBathroom: Dispatch<SetStateAction<LogItem[]>>,
  Bathroom: LogItem[]
) => {
  if (newItemName.trim()) {
    const newId = Bathroom.length ? Bathroom[Bathroom.length - 1].id + 1 : 1
    setBathroom((prevItems) => [
      ...prevItems,
      {
        id: newId,
        disabled: false,
        text: newItemName,
        checked: true,
        logTime: null,
        broken: false,
        comment: '',
        category: 'Bathroom',
      },
    ])
  }
}
export const handleAddNewItemToilet = (
  newItemName: string,
  setToilet: Dispatch<SetStateAction<LogItem[]>>,
  Toilet: LogItem[]
) => {
  if (newItemName.trim()) {
    const newId = Toilet.length ? Toilet[Toilet.length - 1].id + 1 : 1
    setToilet((prevItems) => [
      ...prevItems,
      {
        id: newId,
        disabled: false,
        text: newItemName,
        checked: true,
        logTime: null,
        broken: false,
        comment: '',
        category: 'Toilet',
      },
    ])
  }
}
export const handleAddNewItemVanities = (
  newItemName: string,
  setVanities: Dispatch<SetStateAction<LogItem[]>>,
  vanities: LogItem[]
) => {
  if (newItemName.trim()) {
    const newId = vanities.length ? vanities[vanities.length - 1].id + 1 : 1
    setVanities((prevItems) => [
      ...prevItems,
      {
        id: newId,
        disabled: false,
        text: newItemName,
        checked: true,
        logTime: null,
        broken: false,
        comment: '',
        category: 'Vanities',
      },
    ])
  }
}

export const handleAddNewItemShower = (
  newItemName: string,
  setShower: Dispatch<SetStateAction<LogItem[]>>,
  Shower: LogItem[]
) => {
  if (newItemName.trim()) {
    const newId = Shower.length ? Shower[Shower.length - 1].id + 1 : 1
    setShower((prevItems) => [
      ...prevItems,
      {
        id: newId,
        disabled: false,
        text: newItemName,
        checked: true,
        logTime: null,
        broken: false,
        comment: '',
        category: 'Shower',
      },
    ])
  }
}

export const handleAddNewItemCleaning = (
  newItemName: string,
  setCleaning: Dispatch<SetStateAction<LogItem[]>>,
  Cleaning: LogItem[]
) => {
  if (newItemName.trim()) {
    const newId = Cleaning.length ? Cleaning[Cleaning.length - 1].id + 1 : 1
    setCleaning((prevItems) => [
      ...prevItems,
      {
        id: newId,
        disabled: false,
        text: newItemName,
        checked: true,
        logTime: null,
        broken: false,
        comment: '',
        category: 'Cleaning',
      },
    ])
  }
}

export const handleAddNewItemRefillables = (
  newItemName: string,
  setRefillables: Dispatch<SetStateAction<LogItem[]>>,
  Refillables: LogItem[]
) => {
  if (newItemName.trim()) {
    const newId = Refillables.length
      ? Refillables[Refillables.length - 1].id + 1
      : 1
    setRefillables((prevItems) => [
      ...prevItems,
      {
        id: newId,
        disabled: false,
        text: newItemName,
        checked: true,
        logTime: null,
        empty: false,
        comment: '',
        category: 'Refillables',
      },
    ])
  }
}
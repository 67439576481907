import React from 'react'
import { CustomAppBar } from "../../theme/components/app-layout/header/AppBar";
import TsekmoLandingToolBar from "../components/ToolBar";
import Footer from "../../theme/components/app-layout/footer/Footer";
import { Main } from "../../theme/components/app-layout/main/Main";

const CancelationOption = (props: any) => {
  return (
    <>
      {!props.isLogedIn && (
        <CustomAppBar>
          <TsekmoLandingToolBar />
        </CustomAppBar>
      )}

      {props.isLogedIn ? (
        <div
          style={{
            minHeight: '50vh'
          }}
        >
          cancellation Option
        </div>
      ) : (
        <Main>
          <div
            style={{
              minHeight: '50vh'
            }}
          >
            cancellation Option
          </div>
        </Main>
      )}

      {!props.isLogedIn && (
        <Footer showTwoLevelFooter={true} />
      )}
    </>
  )
}

export default CancelationOption
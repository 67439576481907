import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Checkbox,
  Tooltip,
  Typography,
} from '@mui/material'
import { cloneDeep } from 'lodash'
import { useState, useEffect } from 'react'
import TsekmoButton from '../../../common/TsekmoButton'
import { useDispatch } from 'react-redux'
import { resolveBrokenItems } from '../../../../store/app/listing/actions'

interface ResolveCleanLogProps {
  listing: any
  handleCloseForm: () => void
}

interface LogItem {
  id: number
  disabled: boolean
  text: string
  checked: boolean
  broken?: boolean
  empty?: boolean
  comment: string
  logTime: Date | null
  category: string
  imageList: FileList
  is_grayed_out: boolean | number
}

function ResolveCleanLog({ listing, handleCloseForm }: ResolveCleanLogProps) {
  const dispatch = useDispatch()
  const [cleanLog, setCleanLog] = useState<any>([])
  useEffect(() => {
    if (listing) {
      const currentCleanLog = JSON.parse(listing.cleanlog_structure)
      const startingCleanLog = currentCleanLog.map((category: any) => ({
        ...category,
        items: category.items.map((item: LogItem) => ({
          ...item,
          checked: item.is_grayed_out === 1 ? true : false,
        })),
      }))
      setCleanLog(startingCleanLog)
    }
  }, [listing])

  const handleSelectAll = (selectedCategory: any) => {
    const cleanLogClone = cloneDeep(cleanLog)
    const updatedCleanLog = cleanLogClone.map((category: any) => {
      if (category.name === selectedCategory.name) {
        return {
          ...category,
          items: category.items
            .filter((item: LogItem) => item.is_grayed_out === 1)
            .every((item: LogItem) => item.checked === true)
            ? category.items.map((item: LogItem) => {
                return {
                  ...item,
                  checked: false,
                  broken: false,
                  empty: false,
                  uploadedImages: [],
                }
              })
            : category.items.map((item: LogItem) => {
                return {
                  ...item,
                  checked: item.is_grayed_out === 1 ? true : false,
                  broken: false,
                  empty: false,
                  uploadedImages: [],
                }
              }),
        }
      }
      return category
    })
    setCleanLog(updatedCleanLog)
  }

  const handleCheckItem = (selectedItem: LogItem) => {
    const cleanLogClone = cloneDeep(cleanLog)

    const updatedCleanLog = cleanLogClone.map((category: any) => {
      if (category.name === selectedItem.category) {
        return {
          ...category,
          items: category.items.map((item: LogItem) => {
            if (item.id === selectedItem.id) {
              return {
                ...item,
                checked: !item.checked,
              }
            }
            return item
          }),
        }
      }
      return category
    })
    setCleanLog(updatedCleanLog)
  }

  const handleClickResolve = () => {
    const itemsToResolve: any = []
    cleanLog.forEach((category: any) => {
      if (category.items.some((item: LogItem) => item.checked)) {
        itemsToResolve.push({
          ...category,
          items: category.items
            .filter((item: LogItem) => item.checked)
            .map((item: LogItem) => item.text),
        })
      }
    })

    dispatch(
      resolveBrokenItems({
        listing_id: listing.id,
        item_to_resolve: itemsToResolve,
      })
    )
  }

  // TODO: CLOSE FORM AFTER RESOLVING

  return (
    <div className="p-8 w-[600px] flex flex-col">
      <span className="text-primaryBg font-semibold text-lg text-center">
        Resolve Broken Clean Log Items
      </span>
      <span className="text-sm text-subText text-center mt-2 pb-8">
        Resolving items are for the clean log only. To settle vandalism, you can
        go to the Tsekrs community to find out and get the money you've lost
        from the culprit.
      </span>
      {cleanLog &&
        cleanLog.map((category: any) => {
          if (
            category.items.some((item: LogItem) => item.is_grayed_out === 1)
          ) {
            return (
              <Accordion key={category.name}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${category.name}-content`}
                  id={`${category.name}-header`}
                >
                  <Badge
                    badgeContent={`+
                ${
                  category.items.filter((item: LogItem) => item.is_grayed_out)
                    .length
                }`}
                    color="primary"
                    sx={{ '&.MuiBadge-root': { fontSize: '12px' } }}
                  >
                    <Typography
                      sx={{ marginRight: '18px' }}
                      className="text-sm font-semibold text-mainText"
                    >
                      {category.name}
                    </Typography>
                  </Badge>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="-mt-6">
                    {/* TABLE */}
                    <div className="border">
                      {/* TABLE HEAD */}
                      <div className="border-b-[1px] w-full flex items-center gap-2 py-1 px-4">
                        <Tooltip title="Select All" arrow placement="right">
                          <Checkbox
                            size="small"
                            onClick={() => handleSelectAll(category)}
                            checked={category.items.every((item: LogItem) => {
                              if (item.is_grayed_out === 1) {
                                return item.checked
                              } else {
                                return true
                              }
                            })}
                          />
                        </Tooltip>
                        <span className="text-sm font-semibold text-mainText">
                          Item
                        </span>
                      </div>
                      {category.items.map((item: LogItem) => {
                        if (item.is_grayed_out === 1) {
                          return (
                            <div
                              key={item.id}
                              className="w-full flex items-center py-4 px-4 border border-gray-50 h-fit"
                            >
                              <div>
                                <Checkbox
                                  size="small"
                                  checked={item.checked}
                                  onClick={() => handleCheckItem(item)}
                                />
                              </div>
                              <div className="text-mainText text-sm font-medium ml-2 w-[20%]">
                                {item.text}
                              </div>
                            </div>
                          )
                        }
                      })}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            )
          }
          return ''
        })}
      <div className="mt-4 flex flex-col">
        <TsekmoButton onClick={handleClickResolve}>Resolve</TsekmoButton>
      </div>
    </div>
  )
}

export default ResolveCleanLog

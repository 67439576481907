import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileTimePicker from '@mui/lab/MobileTimePicker';
import { FormControl, Grid, InputAdornment, MenuItem } from '@mui/material';
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { ListingType } from '../../../store/app/listing/types';
import { CustomButton } from '../../../theme/components/buttons/Button';
import { ClockIcon } from '../../../theme/components/icons/ClockIcon';
import { InputText } from '../../../theme/components/input/TextField';

interface IFormInput {
    services: number;
    time: Date|null;
    location: string;
}

interface DashboardSearchProps {
    onSearch: (data: any) => void;
}

export const DashboardSearch = ({ onSearch }: DashboardSearchProps) => {
    const { t } = useTranslation();
    const { control, handleSubmit, reset, formState: { isValid, isDirty } } = useForm<IFormInput>({
        mode: 'all'
    });
    const listingTypes:any = [{
        id: 1,
        name: "Toilet",
        Description: "Toilet"

    }];
    // const onSearch = (data: any) => {
    //     console.log(data);
    // }
    return (<form onSubmit={handleSubmit(onSearch)}>
        <Grid container spacing={1}>
            <Grid item xs={6} sm={3}>
                <Controller
                    name="services"
                    control={control}
                    defaultValue={listingTypes.length > 0 ? listingTypes[0].id : 0}
                    render={({ field }) => <InputText
                        fullWidth
                        select
                        {...field}
                        label={t('customerDashboard.service')}
                        size="small"
                    >
                        <MenuItem
                            value={0}
                            key={'select-all'}
                        >
                            {'All'}
                        </MenuItem>
                        {listingTypes?.map((listingType: ListingType, index: number) =>
                        (<MenuItem
                            value={listingType.id}
                            key={listingType.name + index}
                        >
                            {listingType.name}
                        </MenuItem>))
                        }
                    </InputText>}
                />
            </Grid>
            <Grid item xs={6} sm={3}>
                <Controller
                    name='location'
                    control={control}
                    defaultValue={''}
                    render={({ field }) => (
                        <InputText fullWidth
                            variant="outlined"
                            size="small"
                            label={t('customerDashboard.location')}
                            {...field}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6} sm={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Controller
                        name='time'
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                            <MobileTimePicker
                                value={field.value}
                                ampm={true}
                                onChange={field.onChange}
                                renderInput={(params) => <FormControl fullWidth variant="standard">
                                    <InputText
                                        {...params}
                                        label={t('customerDashboard.time') as string}
                                        id="input-with-icon-textfield"
                                        variant="outlined"
                                        size="small"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    <ClockIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>}
                            />
                        )}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={6} sm={3}>
                <CustomButton
                    type="submit"
                    variant="contained"
                    disabled={!isValid || !isDirty}
                    fullWidth>
                    {"Search"}
                </CustomButton>
            </Grid>
        </Grid>
    </form>
    );
}

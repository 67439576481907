import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import io from 'socket.io-client';

// Define the shape of the context value
interface SocketContextType {
  socket: ReturnType<typeof io> | null;
  isConnected: boolean;
  connect: () => Promise<void>;
  disconnect: () => void;
}

// Create the context with an initial value
const SocketContext = createContext<SocketContextType>({
  socket: null,
  isConnected: false,
  connect: async () => {},
  disconnect: () => {},
});

// Custom hook to use the socket context
export const useSocket = () => useContext(SocketContext);

// SocketProvider component
export const SocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [socket, setSocket] = useState<ReturnType<typeof io> | null>(null);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    const newSocket = io('http://localhost:2017', {
      transports: ['websocket', 'polling'],
    });
    newSocket.on('connect', () => {  
      console.log('Connected to socket server');
      setIsConnected(true);
      const customerUserId = '80';
      const customerRoom = '80';
      newSocket.emit('registerCustomer', {customerUserId});

      newSocket.emit('startNewChat', {customerRoom})

      newSocket.on('AccountUpdated', (data) => {
        console.log('Received AccountUpdated event:', data);
      });
    })
    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
      setIsConnected(false);
    };
  }, []);

  const connect = useCallback(async () => {
    if (!socket) {
      throw new Error('Socket is not initialized');
    }
    await socket.connect();
    setIsConnected(true);
  }, [socket]);

  const disconnect = useCallback(() => {
    if (!socket) {
      throw new Error('Socket is not initialized');
    }
    socket.disconnect();
    setIsConnected(false);
  }, [socket]);

  return (
    <SocketContext.Provider value={{ socket, isConnected, connect, disconnect }}>
      {children}
    </SocketContext.Provider>
  );
};
import React from 'react'
import { CustomAppBar } from "../../theme/components/app-layout/header/AppBar";
import TsekmoLandingToolBar from "../components/ToolBar";
import Footer from "../../theme/components/app-layout/footer/Footer";
import { Main } from "../../theme/components/app-layout/main/Main";
import { Theme ,  Grid } from '@mui/material';
import UIConstants from '../../theme/constants/ui-constants';
import { useEffect } from 'react';
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    background: UIConstants.rootBgColor,
    height: `100%`,
    width: '100%',
    paddingBottom: `calc(${UIConstants.primaryFooterHeight} + ${theme.spacing(
      0
    )})`
  },
  content: {
    margin:  '2%'
  },
  optionsImages: {
    width: '350px',
    height: '244px',
    borderBottomRightRadius: '40px',
    borderBottomLeftRadius: '40px'
  },
  addExtraPadding: {
    paddingBottom: '70px'
  }
}));

const HelpCenter = (props: any) => {
  const classes = useStyle();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div className= {classes.root}>
      {!props.isLogedIn && (
        <CustomAppBar>
          <TsekmoLandingToolBar />
        </CustomAppBar>
      )}

      {props.isLogedIn ? (
        <div style={{ minHeight: '50vh' }}>
        <h3>Help Center</h3>
        <p className={classes.content}>
          We’re here to guide you. Get help with your reservations, account, and more
        </p>

        <h4>Last Updated: May 10, 2022</h4>
        <p className= {classes.content}>
          Guides for getting started
        </p>

        <h4>As a Host</h4>
        <p className= {classes.content}>
          Guides for getting started
        </p>
        <ul>
          <li>
            Creating an account.

             <ol className= {classes.content}>
               <li>
                <p>
                  Sign up (directly via your Phone/OTP) or indirectly via your Google, FB,
                  Email accounts
                </p>
               </li>
               <li>
                <p>
                  Toggle "become a host" button
                </p>
               </li>

               <li>
                <p>
                  Proceed with the steps
                </p>
               </li>
               <li>
                <p>
                  Subscribed to be able to add listings and post to community
                </p>
               </li>
               <li>
                <p>
                   Connect your Stripe Account to be able to get paid for your listings
                </p>
               </li>
             </ol>

          </li>
          <li>
              Add Listings
             <ol className= {classes.content}>
               <li>
                <p>
                 Sign in using your host credentials
                </p>
               </li>
               <li>
                <p>
                 Go to Listings
                </p>
               </li>

               <li>
                <p>
                  Add listing for every amenity you want to offer to guest; Initially we
                  support toilets/bathrooms:
                </p>
                  <ol type='a'>
                    <li>
                      <p>
                      As the Host, you will be presented with a few steps in order to capture
                      all the required the listing’s information
                      </p>
                      
                    </li>
                    <li>
                      <p>
                      Listing type

                      </p>
                    </li>
                    <li>
                      <p>
                       Title, Description, Rules and Host instructions to guests 
                      </p>
                    </li>
                    <li>
                      <p>
                        Address of your station/property
                      </p>
                    </li>
                    <li>
                      <p>
                      Price
                      </p>

                    </li>
                    <li>
                      <p>
                        Time that the bathroom will be available for
                      </p>
                    </li>
                    <li>
                      <p>
                        Can upload a few pictures of the Bathroom and submit
                      </p>
                    </li>
                  </ol>
               </li>
             </ol>
          </li>
          <li>
          Posting on Community Page/Flagging (Coming Soon)
          <ol className= {classes.content} >
            <li>
              <p>
               Host can post any arising issues and vandalisms to the community
             </p>
            </li>
            <li>
              <p>
               Host can flag any potential/alleged culprit
             </p>
            </li>
            <li>
              <p>
                The business owners have an option to flag the customer, the following color
                describes the user status
             </p>
             <ol type='a'>
               <li>
                 <p>
                  Orange [Marked as a suspect]
                 </p>
               </li>
               <li>
                 <p>
                  Red [Payment Due/Blocked]
                 </p>
               </li>
             </ol>
            </li>
            <li>
              <p>
                Community page is only accessible by Hosts
             </p>
            </li>
            <li>
              <p>
                All hosts can view, collaborate, learn and help to identify the potential culprits
             </p>
            </li>
            <li>
              <p>
                Host can then message the potential culprit and request to be paid for the
                damages
             </p>
            </li>
          </ol>
        </li>
        <li>
          Payments, pricing, and refunds
          <ol className= {classes.content}>
              <li>
                 Payment will be made to your Stripe account
              </li>
              <li>
              Guest pays using credit cards and GPay
              </li>
              <li>
              If Refund is made, it will be processed and made via Stripe
              </li>
          </ol>

        </li>
        
      </ul>
      <h4>As a Guest</h4>
      <ul>
        <li>
          Creating an account
          <ol className= {classes.content}>
                  <li>
                    <p>
                      Sign up (directly via your Phone/OTP) or indirectly via your Google, FB,
                      Email accounts
                    </p>
                  </li>
                  <li>
                    <p>
                      Proceed with the steps
                    </p>
                  </li>
                  <li>
                    <p>
                      Complete your profile
                    </p>
                  </li>
          </ol>
        </li>
        <li>
        Searching and booking
          <ol className= {classes.content}>
                  <li>
                    <p>
                      Sign in(directly via your Phone/OTP) or indirectly via your Google, FB, Email
                      accounts
                    </p>
                  </li>
                  <li>
                    <p>
                      Browse through all listings and or search for what you desired and available
                    </p>
                  </li>
          </ol>
        </li>

        <li>
          Your reservations
          <ol className= {classes.content}>
                  <li>
                    <p>
                      Sign in (directly via your Phone/OTP) or indirectly via your Google, FB, Email
                      accounts
                    </p>
                  </li>
                  <li>
                    <p>
                      Reserve pet day/time you desired and pay for the listing
                    </p>
                  </li>
          </ol>
        </li>

        <li>
          Your Reviews & Rating
          <ol className= {classes.content}>
                  <li>
                    <p>
                      You can add feedback and rating to any listing
                    </p>
                  </li>
          </ol>
        </li>

        <li>
         Payments, pricing, and refunds
          <ol className= {classes.content}>
            <li>
              <p>
                You can select and pay for any listing/services you desired. If not satisfied,
                please contact us to get refund. Tsekmo and its supporting and featured
                business owners honors Tsekmo terms & conditions.
              </p>
            </li>
          </ol>
        </li>

        
      </ul>
      <h4>Getting Support</h4>
      <ul>
        <li>
          Technical Support
          <ol className= {classes.content}>
            <li>
            <p><a href="mailto:support@tsekmo.com">support@tsekmo.com</a></p>
            </li>
          </ol>
        </li>
        <li>
          Contact us
          <ol className= {classes.content}>
            <li>
            <p><a href="mailto:support@tsekmo.com">support@tsekmo.com</a></p>
            </li>
          </ol>
        </li>
      </ul>

      <h4>Safety</h4>
      <ul>
        <li>
          Safety tips and guidelines
          <ol className= {classes.content}>
            <li>
            Safety and security are must-haves in any Gas Stations. In addition to the steps
            Tsekmo takes to help protect our community, we encourage both hosts and guests
            to stay aware and take certain safety precautions when they’re hosting their
            bathrooms or traveling
            </li>
          </ol>
        </li>
        <li>
        Reporting issues
          <ol className= {classes.content}>
            <li>
            <p>Please contact us at <a href="mailto:support@tsekmo.com">support@tsekmo.com</a></p>
            </li>
          </ol>
        </li>
      </ul>
      <h4>About Tsekmo</h4>
      <ul>
        <li>
          Getting started
          <ol className= {classes.content}>
            <li>
              Sign up and join our community
            </li>
          </ol>
        </li>
        <li>
          Our community policies

          <ol className= {classes.content}>
            <li>
              Since our community is built on trust, authenticity is essential—it requires a
              balance of shared expectations, honest interactions, and accurate information and
              details.
            </li>
            <li>
              You should not take property that isn’t yours, use someone’s property without
              their permission, copy others’ keys or identity documents, damage others’
              property, remain in the bathroom after a stay is concluded, or threaten anyone
              with bad ratings or any other penalty or harm to obtain compensation or other
              benefits
            </li>
          </ol>
        </li>
      </ul>
        
      </div>
      ) : (
        <Main>
          <div style={{ minHeight: '50vh' }}>
          <h3>Help Center</h3>
          <p className={classes.content}>
            We’re here to guide you. Get help with your reservations, account, and more
          </p>
          
          <h4>Last Updated: May 10, 2022</h4>
          <p className= {classes.content}>
            Guides for getting started
          </p>

          <h4>As a Host</h4>
          <p className= {classes.content}>
            Guides for getting started
          </p>
          <ul>
            <li>
              Creating an account.

               <ol className= {classes.content}>
                 <li>
                  <p>
                    Sign up (directly via your Phone/OTP) or indirectly via your Google, FB,
                    Email accounts
                  </p>
                 </li>
                 <li>
                  <p>
                    Toggle "become a host" button
                  </p>
                 </li>

                 <li>
                  <p>
                    Proceed with the steps
                  </p>
                 </li>
                 <li>
                  <p>
                    Subscribed to be able to add listings and post to community
                  </p>
                 </li>
                 <li>
                  <p>
                     Connect your Stripe Account to be able to get paid for your listings
                  </p>
                 </li>
               </ol>

            </li>
            <li>
                Add Listings
               <ol className= {classes.content}>
                 <li>
                  <p>
                   Sign in using your host credentials
                  </p>
                 </li>
                 <li>
                  <p>
                   Go to Listings
                  </p>
                 </li>

                 <li>
                  <p>
                    Add listing for every amenity you want to offer to guest; Initially we
                    support toilets/bathrooms:
                  </p>
                    <ol type='a'>
                      <li>
                        <p>
                        As the Host, you will be presented with a few steps in order to capture
                        all the required the listing’s information
                        </p>
                        
                      </li>
                      <li>
                        <p>
                        Listing type

                        </p>
                      </li>
                      <li>
                        <p>
                         Title, Description, Rules and Host instructions to guests 
                        </p>
                      </li>
                      <li>
                        <p>
                          Address of your station/property
                        </p>
                      </li>
                      <li>
                        <p>
                        Price
                        </p>
 
                      </li>
                      <li>
                        <p>
                          Time that the bathroom will be available for
                        </p>
                      </li>
                      <li>
                        <p>
                          Can upload a few pictures of the Bathroom and submit
                        </p>
                      </li>
                    </ol>
                 </li>
               </ol>
            </li>
            <li>
            Posting on Community Page/Flagging (Coming Soon)
            <ol className= {classes.content} >
              <li>
                <p>
                 Host can post any arising issues and vandalisms to the community
               </p>
              </li>
              <li>
                <p>
                 Host can flag any potential/alleged culprit
               </p>
              </li>
              <li>
                <p>
                  The business owners have an option to flag the customer, the following color
                  describes the user status
               </p>
               <ol type='a'>
                 <li>
                   <p>
                    Orange [Marked as a suspect]
                   </p>
                 </li>
                 <li>
                   <p>
                    Red [Payment Due/Blocked]
                   </p>
                 </li>
               </ol>
              </li>
              <li>
                <p>
                  Community page is only accessible by Hosts
               </p>
              </li>
              <li>
                <p>
                  All hosts can view, collaborate, learn and help to identify the potential culprits
               </p>
              </li>
              <li>
                <p>
                  Host can then message the potential culprit and request to be paid for the
                  damages
               </p>
              </li>
            </ol>
          </li>
          <li>
            Payments, pricing, and refunds
            <ol className= {classes.content}>
                <li>
                   Payment will be made to your Stripe account
                </li>
                <li>
                Guest pays using credit cards and GPay
                </li>
                <li>
                If Refund is made, it will be processed and made via Stripe
                </li>
            </ol>

          </li>
          
        </ul>
        <h4>As a Guest</h4>
        <ul>
          <li>
            Creating an account
            <ol className= {classes.content}>
                    <li>
                      <p>
                        Sign up (directly via your Phone/OTP) or indirectly via your Google, FB,
                        Email accounts
                      </p>
                    </li>
                    <li>
                      <p>
                        Proceed with the steps
                      </p>
                    </li>
                    <li>
                      <p>
                        Complete your profile
                      </p>
                    </li>
            </ol>
          </li>
          <li>
          Searching and booking
            <ol className= {classes.content}>
                    <li>
                      <p>
                        Sign in(directly via your Phone/OTP) or indirectly via your Google, FB, Email
                        accounts
                      </p>
                    </li>
                    <li>
                      <p>
                        Browse through all listings and or search for what you desired and available
                      </p>
                    </li>
            </ol>
          </li>

          <li>
            Your reservations
            <ol className= {classes.content}>
                    <li>
                      <p>
                        Sign in (directly via your Phone/OTP) or indirectly via your Google, FB, Email
                        accounts
                      </p>
                    </li>
                    <li>
                      <p>
                        Reserve pet day/time you desired and pay for the listing
                      </p>
                    </li>
            </ol>
          </li>

          <li>
            Your Reviews & Rating
            <ol className= {classes.content}>
                    <li>
                      <p>
                        You can add feedback and rating to any listing
                      </p>
                    </li>
            </ol>
          </li>

          <li>
           Payments, pricing, and refunds
            <ol className= {classes.content}>
              <li>
                <p>
                  You can select and pay for any listing/services you desired. If not satisfied,
                  please contact us to get refund. Tsekmo and its supporting and featured
                  business owners honors Tsekmo terms & conditions
                </p>
              </li>
            </ol>
          </li>

          
        </ul>
        <h4>Getting Support</h4>
        <ul>
          <li>
            Technical Support
            <ol className= {classes.content}>
              <li>
              <p><a href="mailto:support@tsekmo.com">support@tsekmo.com</a></p>
              </li>
            </ol>
          </li>
          <li>
            Contact us
            <ol className= {classes.content}>
              <li>
              <p><a href="mailto:support@tsekmo.com">support@tsekmo.com</a></p>
              </li>
            </ol>
          </li>
        </ul>

        <h4>Safety</h4>
        <ul>
          <li>
            Safety tips and guidelines
            <ol className= {classes.content}>
              <li>
              Safety and security are must-haves in any Gas Stations. In addition to the steps
              Tsekmo takes to help protect our community, we encourage both hosts and guests
              to stay aware and take certain safety precautions when they’re hosting their
              bathrooms or traveling
              </li>
            </ol>
          </li>
          <li>
          Reporting issues
            <ol className= {classes.content}>
              <li>
              <p>Please contact us at <a href="mailto:support@tsekmo.com">support@tsekmo.com</a></p>
              </li>
            </ol>
          </li>
        </ul>
        <h4>About Tsekmo</h4>
        <ul>
          <li>
            Getting started
            <ol className= {classes.content}>
              <li>
                Sign up and join our community
              </li>
            </ol>
          </li>
          <li>
            Our community policies

            <ol className= {classes.content}>
              <li>
                Since our community is built on trust, authenticity is essential—it requires a
                balance of shared expectations, honest interactions, and accurate information and
                details
              </li>
              <li>
                You should not take property that isn’t yours, use someone’s property without
                their permission, copy others’ keys or identity documents, damage others’
                property, remain in the bathroom after a stay is concluded, or threaten anyone
                with bad ratings or any other penalty or harm to obtain compensation or other
                benefits
              </li>
            </ol>
          </li>
        </ul>
          
        </div>
        <div className= {classes.addExtraPadding}></div>

        </Main>
      )}

      {!props.isLogedIn && (
        <Footer showTwoLevelFooter={true} />
      )}
    </div>
  )
}

export default HelpCenter

import { CircularProgress, Paper, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from '@mui/styles';
import OtpInput from "react-otp-input";
import UIConstants from "../../../theme/constants/ui-constants";
import otpMobileLogo from "../../../assets/images/otp-mobile.svg";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../../../theme/components/buttons/Button";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";

const useStyles = makeStyles((theme: Theme) => ({
    formControl: {
        margin: `${theme.spacing(3)} ${theme.spacing(1)}`,
        display: 'flex',
        // flexDirection: 'column-reverse',
    },
    heading: {
        margin: `${theme.spacing(1)} ${theme.spacing(4)}`,
        textAlign: 'center',
        '& .mainHeading': {
            fontWeight: 600,
            fontSize: '18px',
            fontFamily: UIConstants.appFont,
            color: UIConstants.primaryBgColor,
            textAlign: 'center',
            marginBottom: theme.spacing(3)
        },
        '& .subHeading': {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '27px',
            fontFamily: UIConstants.appFont,
            color: UIConstants.primaryBgColor,
            textAlign: 'left'
        }
    },
    signUpInputBox: {
        minWidth: '250px',
        margin: `${theme.spacing(4)} 0`,
        width: '100%',
        [theme.breakpoints.up("sm")]: {
            minWidth: '350px',
        },
    },
    signupBox: {
        background: UIConstants.rootBgColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: "100%",
        padding: `0 ${theme.spacing(3)}`,
        position: 'relative',
        top: '4rem',
        height: "calc(100vh - 9rem)",
        [theme.breakpoints.up("sm")]: {
            position: 'unset',
            maxWidth: '400px',
            height: "100%",
            margin: 'auto',
            top: 'unset',
            padding: `${theme.spacing(3)}`,
        },
    },
    countdown: {
        width: "100%",
        textAlign: "center",
        color: UIConstants.primaryBgColor,
    }
}));

interface OTPReadComponentProps {
    countdown: string;
    verifyOtp: (code: string) => void;
}

interface IFormInput {
    code: string;
}

export default function OTPReadComponent({ countdown, verifyOtp }: OTPReadComponentProps) {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const { loading } = useSelector((state: ApplicationState) => state.user);

    const { control, handleSubmit, formState: { isValid } } = useForm<IFormInput>({
        mode: 'all'
    });

    const onSubmit = (data: any) => {
        verifyOtp(data.code);
    }

    return (
        <Paper elevation={matches ? 3 : 0} className={classes.signupBox}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={classes.heading}>
                    <img
                        src={otpMobileLogo}
                        alt="otp-verify-mobile"

                    />
                    <Typography className='mainHeading'>
                        {t('otpScreens.mainHeading')}
                    </Typography>
                </div>
                <div className={classes.signUpInputBox}>
                    <div className={classes.formControl}>
                        <div id="recaptcha-container" style={{ display: "flex", justifyContent: "center", width: "100%" }}></div>
                    </div>

                    <Typography className='subHeading'>
                        <span style={{ opacity: .5 }}>{t('otpScreens.subHeading')}</span>
                    </Typography>
                    {countdown &&
                        <div className={classes.formControl} style={{ justifyContent: "center" }}>
                            <Controller
                                name="code"
                                control={control}
                                defaultValue=""
                                rules={{ required: t('signUp.required') as string }}
                                render={({ field }) => <OtpInput
                                    isDisabled={!countdown}
                                    isInputNum={true}
                                    numInputs={6}
                                    {...field}
                                    inputStyle={{
                                        width: "2rem",
                                        height: "2rem",
                                        margin: "0 .5rem",
                                        borderRadius: 4,
                                        outline: 'unset',
                                        color: `${UIConstants.primaryBgColor} !important`,
                                        border: `1px solid ${UIConstants.primaryBgColor}`
                                    }}
                                />} />

                        </div>
                    }
                    <div className={classes.formControl}>
                        <span className={classes.countdown}>{countdown}</span>
                    </div>
                    <div className={classes.formControl}>
                        <CustomButton
                            type="submit"
                            variant="contained"
                            disabled={!isValid}
                            fullWidth>
                            {loading.userData ? (
                                <CircularProgress />
                            ) : (
                                <>
                                    {t('signUp.continue')}
                                </>
                            )}
                        </CustomButton>
                    </div>
                </div>
            </form>
        </Paper >
    );
}



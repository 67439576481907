import {
  Avatar,
  Button,
  Card,
  Theme,
  Popover,
  TextField,
  Tooltip,
} from '@mui/material'
import React, { useRef, useState } from 'react'
import ImageCarouselList from '../../../../theme/components/image-list/ImageCarousel'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import UIConstants from '../../../../theme/constants/ui-constants'
import ChatIcon from '@mui/icons-material/Chat'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import SendIcon from '@mui/icons-material/Send'
import { makeStyles } from '@mui/styles'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  LinkedinShareButton,
  LinkedinIcon,
  RedditShareButton,
  RedditIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from 'react-share'
import PostCommentItem from './PostCommentItem'

const useStyle = makeStyles((theme: Theme) => ({
  commentInput: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '24px',
      borderColor: UIConstants.subText,
      fontSize: '12px',
    },
  },
}))
function TsekrsPostItem() {
  const classes = useStyle()
  const [showDescription, setShowDescription] = useState<boolean>(false)
  const emojis = ['👍', '❤️', '😂', '😢', '😡', '😎']
  const [anchorEl1, setAnchorEl1] = useState(null)
  const [anchorEl2, setAnchorEl2] = useState(null)
  const commentInputRef = useRef<any>()
  const [comments, setComments] = useState([
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
  ])

  const imageList = [
    {
      name: 'test',
      location:
        'https://www.thespruce.com/thmb/Ba5hg_Dly4IrrInQ-XdKiGVAePo=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/fancy-bathroom-ideas-4325947-hero-4777bf14fe2b447b86a21e8a64194c29.jpg',
    },
    {
      name: 'test',
      location:
        'https://hips.hearstapps.com/hmg-prod/images/toledo-geller-tenafly-210924-024-copy-1668037377-644ab01ecb59f.jpg?crop=0.889xw:1.00xh;0,0&resize=980:*',
    },
    {
      name: 'test',
      location:
        'https://www.thespruce.com/thmb/Ba5hg_Dly4IrrInQ-XdKiGVAePo=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/fancy-bathroom-ideas-4325947-hero-4777bf14fe2b447b86a21e8a64194c29.jpg',
    },
  ]

  const handleClosePopover = (e: any, anchor: string) => {
    if (anchor === 'react') {
      setAnchorEl1(null)
    } else {
      setAnchorEl2(null)
    }
  }

  const handleClickPopover = (e: any, anchor: string) => {
    if (anchor === 'react') {
      setAnchorEl1(e.currentTarget)
    } else {
      setAnchorEl2(e.currentTarget)
    }
  }

  const setTextInputRef = (element: HTMLInputElement) => {
    commentInputRef.current = element
  }

  const handleFocusTextField = () => {
    if (commentInputRef.current) {
      commentInputRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'center',
      })
      commentInputRef.current.focus({ preventScroll: true })
    }
  }
  return (
    <div className="flex flex-col lg:w-[60%] bg-white rounded-md shadow-md">
      <div className="flex px-5 pt-5">
        <Avatar sx={{ marginTop: '5px' }} />
        <div className="ml-4">
          <div className="flex justify-between items-center">
            <p className="font-semibold text-xl text-mainText">Cyril Verdad</p>

          </div>
          <p className="text-subText text-xs">Nov 23, 2023 08:45pm</p>
          <p className="text-subText text-xs line-clamp-1">
            Hidalgo County, This is a long address
            This is a long address This is a long address This is a long address
            This is a long address{' '}
          </p>
        </div>
      </div>
      <div className="w-full mt-5 px-5">
        <ImageCarouselList orientation="V" imageFileList={imageList} />
      </div>
      <div
        className={`w-full text-mainText px-10 pt-5 ${
          showDescription ? '' : 'line-clamp-4'
        }`}>
          <p className="text-xl font-bold">Best Bathroom Ever</p>
        <p className="text-md mt-1">
          Exercitation nostrud magna sit incididunt Lorem proident laboris
          laboris dolore do cillum nisi. Do incididunt dolore consectetur et
          fugiat mollit duis ullamco. Consectetur culpa velit reprehenderit non
          et voluptate ipsum ipsum proident ex. Anim sunt nulla qui consectetur
          nisi. Minim et excepteur commodo proident pariatur nulla dolore nulla
          proident non officia occaecat aute incididunt. Enim non sint aliquip
          aliquip id aute amet eiusmod incididunt nulla magna occaecat nostrud.
        </p>
        
      </div>
      <div className="flex justify-end px-5">
        <button
          className="text-sm text-[#0c4b9c] font-bold pr-5 mt-2 hover:underline"
          onClick={() => setShowDescription(!showDescription)}>
          {showDescription ? 'Show Less' : 'Show More'}
        </button>
      </div>
      {/* React Counter */}
      <div className="flex items-center justify-between px-10 mt-4">
        <Tooltip
          placement="right"
          title={
            <p>
              {emojis[1]} 6 {emojis[2]} 6
            </p>
          }>
          <p className="text-subText text-xs">
            {' '}
            {emojis[1]}
            {emojis[2]} 12
          </p>
        </Tooltip>
        <p
          className="text-xs text-subText hover:underline hover:cursor-pointer"
          onClick={handleFocusTextField}>
          2 comments
        </p>
      </div>
      {/* Action Bar */}
      <div className="grid grid-cols-3 px-5 mt-2">
        <div className="flex col-span-1 justify-center">
          <Button
            sx={{
              display: 'flex',
              color: UIConstants.subText,
              gap: '5px',
              textTransform: 'capitalize',
            }}
            onMouseEnter={(e) => handleClickPopover(e, 'react')}>
            <ThumbUpOffAltIcon sx={{ width: '20px', height: '20px' }} />
            <p>React</p>
          </Button>
          <Popover
            open={Boolean(anchorEl1)}
            anchorEl={anchorEl1}
            onClose={(e) => handleClosePopover(e, 'react')}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}>
            <div
              onMouseLeave={(e) => handleClosePopover(e, 'react')}
              style={{ display: 'flex', gap: '5px', padding: '8px' }}>
              {emojis.map((emoji, index) => (
                <div
                  key={emoji}
                  className="w-8 h-8 text-md flex items-center p-2 hover:bg-blue-100 hover:cursor-pointer rounded-full">
                  <p className="-ml-[2.5px]">{emoji}</p>
                </div>
              ))}
            </div>
          </Popover>
        </div>
        <div className="flex col-span-1 justify-center">
          <Button
            sx={{
              display: 'flex',
              color: UIConstants.subText,
              gap: '5px',
              textTransform: 'capitalize',
            }}
            onClick={handleFocusTextField}>
            <ChatIcon sx={{ width: '20px', height: '20px' }} />
            <p>Comment</p>
          </Button>
        </div>
        <div className="flex col-span-1 justify-center">
          <Button
            sx={{
              display: 'flex',
              color: UIConstants.subText,
              gap: '5px',
              textTransform: 'capitalize',
            }}
            onMouseEnter={(e) => handleClickPopover(e, 'share')}>
            <SendIcon sx={{ width: '20px', height: '20px' }} />
            <p>Share</p>
          </Button>
          <Popover
            open={Boolean(anchorEl2)}
            anchorEl={anchorEl2}
            onClose={(e) => handleClosePopover(e, 'share')}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}>
            <div
              className="p-4"
              onMouseLeave={(e) => handleClosePopover(e, 'share')}>
              <div className="flex justify-center gap-2">
                <FacebookShareButton
                  url={''}
                  quote={'Tsekmo Community'}
                  hashtag={'#Tsekers'}>
                  <FacebookIcon size={32} round={true} />
                </FacebookShareButton>
                <TwitterShareButton
                  url={''}
                  title={'Tsekmo Community'}
                  hashtags={['#Tsekers']}>
                  <TwitterIcon size={32} round={true} />
                </TwitterShareButton>
                <TelegramShareButton url={''} title={'Tsekmo Community'}>
                  <TelegramIcon size={32} round={true} />
                </TelegramShareButton>
                <LinkedinShareButton url={''} title={'Tsekmo Community'}>
                  <LinkedinIcon size={32} round={true} />
                </LinkedinShareButton>
                <RedditShareButton url={''} title={'Tsekmo Community'}>
                  <RedditIcon size={32} round={true} />
                </RedditShareButton>
                <WhatsappShareButton url={''} title={'Tsekmo Community'}>
                  <WhatsappIcon size={32} round={true} />
                </WhatsappShareButton>
                <EmailShareButton url={''} title={'Tsekmo Community'}>
                  <EmailIcon size={32} round={true} />
                </EmailShareButton>
              </div>
              <div className="flex gap-2 items-center mt-3">
                <TextField size="small"></TextField>
                <Button
                  sx={{
                    borderRadius: '100%',
                    padding: '8px',
                    minWidth: 'fit-content',
                    color: UIConstants.subText,
                  }}>
                  <ContentPasteIcon />
                </Button>
              </div>
            </div>
          </Popover>
        </div>
      </div>
      <div className="flex flex-col justify-between bg-foreground min-h-[200px] mt-4 p-5">
        <div>
          <button className="text-xs text-subText hover:text-mainText font-bold pr-5 mt-2 hover:underline">
            <p>View more comments</p>
          </button>
        </div>
        <div className="flex flex-col gap-4 my-4">
          {comments &&
            comments.map((comment) => {
              return <PostCommentItem key={comment.id} />
            })}
        </div>
        <div className="flex mt-4">
          <TextField
            inputRef={setTextInputRef}
            placeholder="Write a comment"
            size="small"
            fullWidth
            className={classes.commentInput}
            multiline
            maxRows={3}></TextField>
          <div className=" w-20 grid place-items-center">
            <Button
              sx={{
                color: UIConstants.subText,
                borderRadius: '100%',
                padding: '8px',
                minWidth: 'fit-content',
              }}>
              <SendIcon sx={{ width: '20px', height: '20px' }} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TsekrsPostItem

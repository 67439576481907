import { Switch } from '@mui/material'
import React from 'react'

function ListingSettings() {
  return (
    <div className="flex gap-2 items-center p-8 px-12">
        <Switch defaultChecked />
        <span>Enable ID Verification</span>
    </div>
  )
}

export default ListingSettings
import React from 'react'
import { CustomAppBar } from "../../theme/components/app-layout/header/AppBar";
import TsekmoLandingToolBar from "../components/ToolBar";
import Footer from "../../theme/components/app-layout/footer/Footer";
import { Main } from "../../theme/components/app-layout/main/Main";
import { useMediaQuery, Theme, useTheme, Grid ,Paper } from '@mui/material';
import UIConstants from '../../theme/constants/ui-constants';
import { makeStyles } from '@mui/styles';
import mailImage from '../../assets/images/mail.png'
import { Controller, useForm } from "react-hook-form";
import { CustomButton } from "../../theme/components/buttons/Button";
import { InputText } from "../../theme/components/input/TextField";
import toaster from '../../theme/components/Toaster';
import { useDispatch , useSelector} from 'react-redux';
import { sendContactUsInfo ,  updateContactUsInfoSucess } from './../../store/app/user/actions';
import { ApplicationState } from './../../store';
import { useEffect } from 'react';

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    background: UIConstants.rootBgColor,
    height: `100%`,
    width: '100%',
    paddingBottom: `calc(${UIConstants.primaryFooterHeight} + ${theme.spacing(
      0
    )})`
  },
  optionsImages: {
    maxWidth: '40px',
    width:'40%',
    height: '50px',
  },
  contentBox : {
    color: 'rgb(28, 37, 119)'
  },
  content: {
    margin:  '5%'
    
  },
  addExtraPadding: {
    paddingBottom: '70px'
  },
  subBoxOne: {
    backgroundColor:'#c5ffff',
    color: 'rgb(28, 37, 119)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    
  },
  subBoxOneContainier: {
    display:'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding:'10%'
  },
  subBoxOneContainierMobile: {
    display:'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  subBoxTwo: {
    width:'70%',
    paddingLeft:'5%',
    paddingTop:'5%',
  },
  subBoxTwoMobile: {
    paddingLeft:'5%',
    paddingTop:'5%',
    padding:'5%',
  },
  subBoxTowInput:{
    marginBottom:'2%'
  },
  formControl: {
    margin: '2%',
    // flexDirection: 'column-reverse',
  },
}));

interface IFormInput {
  firstName: string;
  lastName: string;
  email:string
  message:string;
}
const ContactUs = (props: any) => {
  
  const classes = useStyle();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { control, handleSubmit, register, formState: { isValid, errors, isDirty } ,reset } = useForm<IFormInput>({
    mode: 'all'
  });

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const { isConatctInfoUpdatedSucess  } = useSelector((state: ApplicationState) => state.user);
  
  useEffect(() => {
    if (isConatctInfoUpdatedSucess) {
      reset();
    }
  }, [isConatctInfoUpdatedSucess]);


  const isValidEmail = (email: string) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
  );

  const onSubmit = (data: any) => {
    dispatch(updateContactUsInfoSucess(false));
    dispatch(sendContactUsInfo(data));
  }

  return (
    <div className= {classes.root}>
      {!props.isLogedIn && (
        <CustomAppBar>
          <TsekmoLandingToolBar />
        </CustomAppBar>
      )}

      {props.isLogedIn ? (
        <div
        style={{
          minHeight: '50vh'
        }}
      >
      <div className={classes.contentBox}>
        <Paper elevation={3}>
          <Grid container>
              <Grid item xs={12} sm={4}  className={classes.subBoxOne} >
                <div className={ !isMobile  ? classes.subBoxOneContainier: classes.subBoxOneContainierMobile}>
                  <img className={classes.optionsImages} src={mailImage} />
                  <h4>Contact Us</h4>
                  <h5>We would love to hear from you !</h5>
                </div> 

              </Grid>
              <Grid item xs={12} sm={8} >
              <div className={ !isMobile ? classes.subBoxTwo: classes.subBoxTwoMobile}> 
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={ classes.formControl}>
                        <Controller
                          name='firstName'
                          control={control}
                          defaultValue={''}
                          render={({ field }) => (
                            <InputText 
                              fullWidth
                              variant="outlined"
                              error={!!errors?.firstName}
                              size="small"
                              label= 'First Name'
                              {...register('firstName', {
                                  required: 'first name is required',
                              })}
                              {...field}
                              helperText={errors?.firstName?.message} />
                          )}
                        />
                    </div>
                    
                    <div className={ classes.formControl}>
                      <Controller
                        name='lastName'
                        control={control}
                        defaultValue={''}
                        render={({ field }) => (
                          <InputText 
                            fullWidth
                            variant="outlined"
                            error={!!errors?.lastName}
                            size="small"
                            label= 'Last Name'
                            {...register('lastName', {
                                required: 'last name is required',
                            })}
                            {...field}
                            helperText={errors?.lastName?.message} />
                        )}
                      />
                    </div>
                    <div className={ classes.formControl}>
                      <Controller
                        name='email'
                        control={control}
                        defaultValue={''}
                        render={({ field }) => (
                        <InputText fullWidth
                          variant="outlined"
                          error={!!errors?.email}
                          size="small"
                          label= 'Email'
                          {...register('email', {
                            required: 'Email is required',
                            validate: {
                              isValidEmail: (value) => isValidEmail(value) ? true : 'Email is invalid'
                            }

                          })}
                          {...field}
                          helperText={errors?.email?.message} />
                        )}
                      />
                    </div>
                    <div className={ classes.formControl}>
                      <Controller
                        name='message'
                        control={control}
                        defaultValue={''}
                        render={({ field }) => (
                          <InputText
                            fullWidth
                            variant='outlined'
                            size='small'
                            label='Message'
                            error={!!errors?.message}
                            multiline
                            minRows={4}
                            maxRows={10}
                            {...register('message', {
                              required: 'Please add a message',
                          })}
                          {...field}
                          helperText={errors?.message?.message} 
                          />
                        )}
                      />
                    </div>
                    <CustomButton
                      type="submit"
                      variant="contained"
                      className={ classes.formControl}
                      disabled={!isValid || !isDirty}
                    >Submit</CustomButton>
                </form>
              </div>
                
              </Grid>
          </Grid>
        </Paper>
        
      </div>
       <div className= {classes.addExtraPadding}></div>
      </div>
      
      ) : (
        <Main>
          <div
            style={{
              minHeight: '50vh'
            }}
          >
          <div className={classes.contentBox}>
            <Paper elevation={3}>
              <Grid container>
                  <Grid item xs={12} sm={4}  className={classes.subBoxOne} >
                    <div className={classes.subBoxOneContainier}>
                      <img className={classes.optionsImages} src={mailImage} />
                      <h4>Contact Us</h4>
				              <h5>We would love to hear from you !</h5>
                    </div> 

                  </Grid>
                  <Grid item xs={12} sm={8} >
                  <div className={ !isMobile ? classes.subBoxTwo: classes.subBoxTwoMobile}> 
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={ classes.formControl}>
                            <Controller
                              name='firstName'
                              control={control}
                              defaultValue={''}
                              render={({ field }) => (
                                <InputText 
                                  fullWidth
                                  variant="outlined"
                                  error={!!errors?.firstName}
                                  size="small"
                                  label= 'First Name'
                                  {...register('firstName', {
                                      required: 'first name is required',
                                  })}
                                  {...field}
                                  helperText={errors?.firstName?.message} />
                              )}
                            />
                        </div>
                        
                        <div className={ classes.formControl}>
                          <Controller
                            name='lastName'
                            control={control}
                            defaultValue={''}
                            render={({ field }) => (
                              <InputText 
                                fullWidth
                                variant="outlined"
                                error={!!errors?.lastName}
                                size="small"
                                label= 'Last Name'
                                {...register('lastName', {
                                    required: 'last name is required',
                                })}
                                {...field}
                                helperText={errors?.lastName?.message} />
                            )}
                          />
                        </div>
                        <div className={ classes.formControl}>
                          <Controller
                            name='email'
                            control={control}
                            defaultValue={''}
                            render={({ field }) => (
                            <InputText fullWidth
                              variant="outlined"
                              error={!!errors?.email}
                              size="small"
                              label= 'Email'
                              {...register('email', {
                                required: 'Email is required',
                                validate: {
                                  isValidEmail: (value) => isValidEmail(value) ? true : 'Email is invalid'
                                }

                              })}
                              {...field}
                              helperText={errors?.email?.message} />
                            )}
                          />
                        </div>
                        <div className={ classes.formControl}>
                          <Controller
                            name='message'
                            control={control}
                            defaultValue={''}
                            render={({ field }) => (
                              <InputText
                                fullWidth
                                variant='outlined'
                                size='small'
                                label='Message'
                                error={!!errors?.message}
                                multiline
                                minRows={4}
                                maxRows={10}
                                {...register('message', {
                                  required: 'Please add a message',
                              })}
                              {...field}
                              helperText={errors?.message?.message} 
                              />
                            )}
                          />
                        </div>
                        <CustomButton
                          type="submit"
                          variant="contained"
                          className={ classes.formControl}
                          disabled={!isValid || !isDirty}
                        >Submit</CustomButton>
                    </form>
                  </div>
                    
                  </Grid>
              </Grid>
            </Paper>
            
          </div>
          </div>
          <div className= {classes.addExtraPadding}></div>
        </Main>
      )}

      {!props.isLogedIn && (
        <Footer showTwoLevelFooter={true} />
      )}
    </div>
  )
}

export default ContactUs
import React, { useState, useEffect } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Theme,
  Badge,
  IconButton,
  Tooltip,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import UIConstants from '../../../../../theme/constants/ui-constants'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import BlockIcon from '@mui/icons-material/Block'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'

const useStyles = makeStyles((theme: Theme) => ({
  Scrollable: {
    maxHeight: '200px',
  },
  Text: {
    fontSize: '10px',
  },
  header: {
    padding: '0px',
    '&.MuiTableCell-root': {
      color: UIConstants.mainText,
      padding: '0px',
    },
  },
  body: {
    '&.MuiTableRow-root': {
      color: UIConstants.mainText,
      backgroundColor: 'white',
    },
    '& .MuiTableRow-root': {
      borderRadius: '100px',
    },
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  table: {
    '&.MuiTable-root': {
      borderCollapse: 'separate',
      borderSpacing: '0 10px',
    },
  },
  delete__btn: {
    backgroundColor: '#d32f2f',
    minWidth: '30px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#f44336',
    },
  },
  deactivate__btn: {
    backgroundColor: 'transparent',
    minWidth: '30px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#f44336',
    },
  },
}))

interface LogItem {
  id: number
  disabled: boolean
  text: string
  checked: boolean
  broken?: boolean
  empty?: boolean
  comment: string
  logTime: Date | null
  category: string
}

interface CleanLogTableProps {
  bathroom: LogItem[]
  toilet: LogItem[]
  vanities: LogItem[]
  shower: LogItem[]
  cleaning: LogItem[]
  refillables: LogItem[]
  selectAllBathroom: boolean
  selectAllToilet: boolean
  selectAllVanities: boolean
  selectAllShower: boolean
  selectAllCleaning: boolean
  setBathroom: React.Dispatch<React.SetStateAction<LogItem[]>>
  setToilet: React.Dispatch<React.SetStateAction<LogItem[]>>
  setVanities: React.Dispatch<React.SetStateAction<LogItem[]>>
  setShower: React.Dispatch<React.SetStateAction<LogItem[]>>
  setCleaning: React.Dispatch<React.SetStateAction<LogItem[]>>
  setRefillables: React.Dispatch<React.SetStateAction<LogItem[]>>
  setSelectAllBathroom: React.Dispatch<React.SetStateAction<boolean>>
  setSelectAllToilet: React.Dispatch<React.SetStateAction<boolean>>
  setSelectAllVanities: React.Dispatch<React.SetStateAction<boolean>>
  setSelectAllShower: React.Dispatch<React.SetStateAction<boolean>>
  setSelectAllCleaning: React.Dispatch<React.SetStateAction<boolean>>
}

function CleanLogTable({
  bathroom,
  toilet,
  vanities,
  shower,
  cleaning,
  refillables,
  selectAllBathroom,
  selectAllToilet,
  selectAllVanities,
  selectAllShower,
  selectAllCleaning,
  setBathroom,
  setToilet,
  setVanities,
  setShower,
  setCleaning,
  setRefillables,
  setSelectAllBathroom,
  setSelectAllToilet,
  setSelectAllVanities,
  setSelectAllShower,
  setSelectAllCleaning,
}: CleanLogTableProps) {
  const classes = useStyles()
  const [newItemNameBathroom, setNewItemNameBathroom] = useState('')
  const [newItemNameToilet, setNewItemNameToilet] = useState('')
  const [newItemNameVanities, setNewItemNameVanities] = useState('')
  const [newItemNameShower, setNewItemNameShower] = useState('')
  const [newItemNameCleaning, setNewItemNameCleaning] = useState('')
  const [newItemNameRefillables, setNewItemNameRefillables] = useState('')
  const [cleanLogItems, setCleanLogItems] = useState<any>([])
  const defaultItemFormat = {
    id: 0,
    disabled: false,
    text: '',
    checked: true,
    logTime: null,
    comment: '',
    category: '',
    uploadedImages: [],
    is_grayed_out: false,
  }

  useEffect(() => {
    const cleanLogArr = [
      { name: 'Refillables', items: refillables },
      { name: 'Vanities', items: vanities },
      { name: 'Bathroom', items: bathroom },
      { name: 'Toilet', items: toilet },
      { name: 'Shower', items: shower },
      { name: 'Cleaning', items: cleaning },
    ]

    setCleanLogItems(cleanLogArr)
  }, [bathroom, toilet, vanities, shower, cleaning, refillables])

  const handleRemoveItem = (cleanLogItem: LogItem) => {
    switch (cleanLogItem.category) {
      case 'Bathroom':
        setBathroom(bathroom.filter((item) => item.id !== cleanLogItem.id))
        break
      case 'Toilet':
        setToilet(toilet.filter((item) => item.id !== cleanLogItem.id))
        break
      case 'Vanities':
        setVanities(vanities.filter((item) => item.id !== cleanLogItem.id))
        break
      case 'Shower':
        setShower(shower.filter((item) => item.id !== cleanLogItem.id))
        break
      case 'Cleaning':
        setCleaning(cleaning.filter((item) => item.id !== cleanLogItem.id))
        break
      case 'Refillables':
        setRefillables(
          refillables.filter((item) => item.id !== cleanLogItem.id)
        )
        break
    }
  }

  const handleToggleDisable = (cleanLogItem: LogItem) => {
    let currentItem = cleanLogItem
    currentItem.disabled = !currentItem.disabled
    switch (cleanLogItem.category) {
      case 'Bathroom':
        setBathroom((prevItems) => {
          prevItems[currentItem.id - 1] = currentItem
          return [...prevItems]
        })
        break
      case 'Toilet':
        setToilet((prevItems) => {
          prevItems[currentItem.id - 1] = currentItem
          return [...prevItems]
        })
        break
      case 'Vanities':
        setVanities((prevItems) => {
          prevItems[currentItem.id - 1] = currentItem
          return [...prevItems]
        })
        break
      case 'Shower':
        setShower((prevItems) => {
          prevItems[currentItem.id - 1] = currentItem
          return [...prevItems]
        })
        break
      case 'Cleaning':
        setCleaning((prevItems) => {
          prevItems[currentItem.id - 1] = currentItem
          return [...prevItems]
        })
        break
      case 'Refillables':
        setRefillables((prevItems) => {
          prevItems[currentItem.id - 1] = currentItem
          return [...prevItems]
        })
        break
    }
  }

  const handleAddNewItem = (cleanLogItem: any) => {
    let newId: number
    let category: string = cleanLogItem.name
    switch (cleanLogItem.name) {
      case 'Bathroom':
        newId = bathroom.length ? bathroom[bathroom.length - 1].id + 1 : 1
        setBathroom((prevItems) => [
          ...prevItems,
          {
            ...defaultItemFormat,
            id: newId,
            broken: false,
            text: newItemNameBathroom,
            category: category,
          },
        ])
        setNewItemNameBathroom('')
        break
      case 'Toilet':
        newId = toilet.length ? toilet[toilet.length - 1].id + 1 : 1
        setToilet((prevItems) => [
          ...prevItems,
          {
            ...defaultItemFormat,
            id: newId,
            broken: false,
            text: newItemNameToilet,
            category: category,
          },
        ])
        setNewItemNameToilet('')
        break
      case 'Vanities':
        newId = vanities.length ? vanities[vanities.length - 1].id + 1 : 1
        setVanities((prevItems) => [
          ...prevItems,
          {
            ...defaultItemFormat,
            id: newId,
            broken: false,
            text: newItemNameVanities,
            category: category,
          },
        ])
        setNewItemNameVanities('')
        break
      case 'Shower':
        newId = shower.length ? shower[shower.length - 1].id + 1 : 1
        setShower((prevItems) => [
          ...prevItems,
          {
            ...defaultItemFormat,
            id: newId,
            broken: false,
            text: newItemNameShower,
            category: category,
          },
        ])
        setNewItemNameShower('')
        break
      case 'Cleaning':
        newId = cleaning.length ? cleaning[cleaning.length - 1].id + 1 : 1
        setCleaning((prevItems) => [
          ...prevItems,
          {
            ...defaultItemFormat,
            id: newId,
            broken: false,
            text: newItemNameCleaning,
            category: category,
          },
        ])
        setNewItemNameCleaning('')
        break
      case 'Refillables':
        newId = refillables.length
          ? refillables[refillables.length - 1].id + 1
          : 1
        setRefillables((prevItems) => [
          ...prevItems,
          {
            ...defaultItemFormat,
            id: newId,
            broken: false,
            text: newItemNameRefillables,
            category: category,
          },
        ])
        setNewItemNameRefillables('')
        break
    }
  }

  const handleToggleActivateCategory = (e: any, cleanLogItem: any) => {
    // e.stopPropagation()
    const updatedCleanLogItems = cleanLogItems
    let itemCategory = updatedCleanLogItems.find(
      (item: any) => item.name === cleanLogItem.name
    )
    const itemCategoryIdx = cleanLogItems.indexOf(itemCategory)

    if (cleanLogItem.items.some((item: LogItem) => item.disabled === false)) {
      itemCategory.items.forEach((_item: any, i: any) => {
        itemCategory.items[i].disabled = true
      })
    } else {
      itemCategory.items.forEach((_item: any, i: any) => {
        itemCategory.items[i].disabled = false
      })
    }

    updatedCleanLogItems[itemCategoryIdx] = itemCategory
    setCleanLogItems([...updatedCleanLogItems])
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col">
        {cleanLogItems.map(
          (cleanLogItem: any) =>
            cleanLogItem.items.some(
              (item: LogItem) => item.disabled === false
            ) && (
              <Accordion key={cleanLogItem.name}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${cleanLogItem.name}-content`}
                  id={`${cleanLogItem.name}-header`}
                >
                  <div className="w-full flex justify-between items-center">
                    <Badge
                      badgeContent={'+' + cleanLogItem.items.length}
                      color="primary"
                      sx={{ '&.MuiBadge-root': { fontSize: '12px' } }}
                    >
                      <Typography
                        sx={{ marginRight: '18px' }}
                        className="text-sm font-semibold text-mainText"
                      >
                        {cleanLogItem.name}
                      </Typography>
                    </Badge>
                    <Tooltip
                      title={
                        !cleanLogItem.items.every(
                          (item: LogItem) => item.disabled === true
                        )
                          ? `Deactivate all ${cleanLogItem.name}.`
                          : `Activate all ${cleanLogItem.name}.`
                      }
                      placement="right"
                      arrow
                    >
                      <IconButton
                        onClick={(e) =>
                          handleToggleActivateCategory(e, cleanLogItem)
                        }
                      >
                        {!cleanLogItem.items.every(
                          (item: LogItem) => item.disabled === true
                        ) ? (
                          <BlockIcon
                            sx={{
                              width: '20px',
                              height: '20px',
                              color: 'gray',
                            }}
                          />
                        ) : (
                          <KeyboardDoubleArrowRightIcon
                            sx={{
                              width: '20px',
                              height: '20px',
                              color: '#9ABC66',
                            }}
                          />
                        )}
                      </IconButton>
                    </Tooltip>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="-mt-6">
                    <Table size="small" className="border">
                      <TableHead className="border">
                        <TableRow>
                          <TableCell>Item</TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={3} className="pl-0">
                            <div className="max-w-[500px] flex gap-4">
                              <FormControl fullWidth>
                                <TextField
                                  size="small"
                                  value={
                                    cleanLogItem.name === 'Bathroom'
                                      ? newItemNameBathroom
                                      : cleanLogItem.name === 'Toilet'
                                      ? newItemNameToilet
                                      : cleanLogItem.name === 'Vanities'
                                      ? newItemNameVanities
                                      : cleanLogItem.name === 'Shower'
                                      ? newItemNameShower
                                      : cleanLogItem.name === 'Cleaning'
                                      ? newItemNameCleaning
                                      : cleanLogItem.name === 'Refillables'
                                      ? newItemNameRefillables
                                      : false
                                  }
                                  placeholder={`Enter new item`}
                                  onChange={(e) =>
                                    cleanLogItem.name === 'Bathroom'
                                      ? setNewItemNameBathroom(e.target.value)
                                      : cleanLogItem.name === 'Toilet'
                                      ? setNewItemNameToilet(e.target.value)
                                      : cleanLogItem.name === 'Vanities'
                                      ? setNewItemNameVanities(e.target.value)
                                      : cleanLogItem.name === 'Shower'
                                      ? setNewItemNameShower(e.target.value)
                                      : cleanLogItem.name === 'Cleaning'
                                      ? setNewItemNameCleaning(e.target.value)
                                      : cleanLogItem.name === 'Refillables'
                                      ? setNewItemNameRefillables(
                                          e.target.value
                                        )
                                      : null
                                  }
                                  sx={{
                                    margin: '0 15px',
                                    border: 'none',
                                    outline: 'none',
                                  }}
                                />
                              </FormControl>
                              <Button
                                onClick={() => handleAddNewItem(cleanLogItem)}
                              >
                                Add
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                        {cleanLogItem.items.map(
                          (item: LogItem) =>
                            !item.disabled && (
                              <TableRow>
                                <TableCell
                                  sx={{
                                    color: !item.disabled
                                      ? UIConstants.mainText
                                      : 'gray',
                                  }}
                                >
                                  {item.text}
                                </TableCell>
                                <TableCell
                                  width="5%"
                                  sx={{ padding: '10px 0 10px 10px' }}
                                ></TableCell>
                                <TableCell
                                  width="5%"
                                  sx={{ padding: '10px 0 10px 10px' }}
                                >
                                  <Tooltip
                                    title={
                                      !item.disabled
                                        ? 'Deactivate this item.'
                                        : 'Activate this item.'
                                    }
                                    placement="right"
                                    arrow
                                  >
                                    <IconButton
                                      onClick={() => handleToggleDisable(item)}
                                    >
                                      {!item.disabled ? (
                                        <BlockIcon
                                          sx={{
                                            width: '20px',
                                            height: '20px',
                                            color: 'gray',
                                          }}
                                        />
                                      ) : (
                                        <KeyboardDoubleArrowRightIcon
                                          sx={{
                                            width: '20px',
                                            height: '20px',
                                            color: '#9ABC66',
                                          }}
                                        />
                                      )}
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            )
                        )}
                      </TableBody>
                    </Table>
                    {cleanLogItem.items.some(
                      (item: LogItem) => item.disabled === true
                    ) && (
                      <Table size="small" className="mt-8 border">
                        <TableHead className="border">
                          <TableRow>
                            <TableCell>Disabled Items</TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <>
                            {cleanLogItem.items.map(
                              (item: LogItem) =>
                                item.disabled && (
                                  <TableRow>
                                    <TableCell
                                      sx={{
                                        color: !item.disabled
                                          ? UIConstants.mainText
                                          : 'gray',
                                      }}
                                    >
                                      {item.text}
                                    </TableCell>
                                    <TableCell
                                      width="5%"
                                      sx={{ padding: '10px 0 10px 10px' }}
                                    >
                                      <Tooltip
                                        title="Permanently delete this item."
                                        placement="right"
                                        arrow
                                      >
                                        <Button
                                          className={classes.delete__btn}
                                          size="small"
                                          variant="contained"
                                          onClick={() => handleRemoveItem(item)}
                                        >
                                          <DeleteOutlineIcon
                                            sx={{
                                              width: '20px',
                                              height: '20px',
                                            }}
                                          />
                                        </Button>
                                      </Tooltip>
                                    </TableCell>
                                    <TableCell
                                      width="5%"
                                      sx={{ padding: '10px 0 10px 10px' }}
                                    >
                                      <Tooltip
                                        title={
                                          !item.disabled
                                            ? 'Deactivate this item.'
                                            : 'Activate this item.'
                                        }
                                        placement="right"
                                        arrow
                                      >
                                        <IconButton
                                          onClick={() =>
                                            handleToggleDisable(item)
                                          }
                                        >
                                          {!item.disabled ? (
                                            <BlockIcon
                                              sx={{
                                                width: '20px',
                                                height: '20px',
                                                color: 'gray',
                                              }}
                                            />
                                          ) : (
                                            <KeyboardDoubleArrowRightIcon
                                              sx={{
                                                width: '20px',
                                                height: '20px',
                                                color: '#9ABC66',
                                              }}
                                            />
                                          )}
                                        </IconButton>
                                      </Tooltip>
                                    </TableCell>
                                  </TableRow>
                                )
                            )}
                          </>
                        </TableBody>
                      </Table>
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
            )
        )}
      </div>
      <div className="flex flex-col">
        {cleanLogItems.map(
          (cleanLogItem: any) =>
            cleanLogItem.items.every(
              (item: LogItem) => item.disabled === true
            ) && (
                <Accordion key={cleanLogItem.name} disableGutters>
                  <AccordionSummary
                    aria-controls={`${cleanLogItem.name}-content`}
                    id={`${cleanLogItem.name}-header`}
                    sx={{ backgroundColor: 'rgba(77, 77, 77, 0.1)' }}
                  >
                    <div className="w-full flex justify-between items-center">
                      <Badge
                        badgeContent={'+' + cleanLogItem.items.length}
                        color="primary"
                        sx={{ '&.MuiBadge-root': { fontSize: '12px' } }}
                      >
                        <Typography
                          sx={{ marginRight: '18px' }}
                          className="text-sm font-semibold text-mainText"
                        >
                          {cleanLogItem.name}
                        </Typography>
                      </Badge>
                      <Tooltip
                        title={
                          !cleanLogItem.items.every(
                            (item: LogItem) => item.disabled === true
                          )
                            ? `Deactivate all ${cleanLogItem.name}.`
                            : `Activate all ${cleanLogItem.name}.`
                        }
                        placement="right"
                        arrow
                      >
                        <IconButton
                          onClick={(e) =>
                            handleToggleActivateCategory(e, cleanLogItem)
                          }
                        >
                          {!cleanLogItem.items.every(
                            (item: LogItem) => item.disabled === true
                          ) ? (
                            <BlockIcon
                              sx={{
                                width: '20px',
                                height: '20px',
                                color: 'gray',
                              }}
                            />
                          ) : (
                            <KeyboardDoubleArrowRightIcon
                              sx={{
                                width: '20px',
                                height: '20px',
                                color: '#9ABC66',
                              }}
                            />
                          )}
                        </IconButton>
                      </Tooltip>
                    </div>
                  </AccordionSummary>
                </Accordion>
            )
        )}
      </div>
    </div>
  )
}

export default CleanLogTable

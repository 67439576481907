import React from "react";
import ChatWindow from "./ChatWindow";

const Chat = () => {
    return (
        <div className="App">
            <h1>Simple Chat System</h1>
            <ChatWindow />
        </div>
    )
}

export default Chat;
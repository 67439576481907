import React, { useEffect, useState } from 'react'

const Subscriptions: React.FC = () => {

  const [userId, setUserId] = useState<number>(0);
  const [usersType, setUsersType] = useState<number>(1);

  /**
   * On mobile it should have like this
   * const url = `https://beta.tsekmo.com/subscription-mobile?user_id=${encodeURIComponent(user_id)}&user_type=${encodeURIComponent(user_type)}`;
   * Linking.openURL(url);
   */
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setUserId(params.get('user_id') ? Number(params.get('user_id')) : 0);
    setUsersType(params.get('user_type') ? Number(params.get('user_type')) : 1);
  }, []);

  const UseStripePricingTable = (pricingTableId: string, clientReferenceId?: number) => {
    useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://js.stripe.com/v3/pricing-table.js';
      script.async = true;
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      };
    }, []);
  
    return React.createElement('stripe-pricing-table', {
      'pricing-table-id': pricingTableId,
      'publishable-key': 'pk_test_51K5vZMBawIx9AGNeAmLKm75HaEmMKkqH8pnA5tyfvJGnTkLVOXemqH7S13Xlc7yFy0UTaKDGonD1ymZzfvynoPED005xfzH2Q6',
      'client-reference-id': clientReferenceId,
    });
  };
  
  const StripePricingTable = ({ userType }: { userType: number }) => {
    if (userType === 1) {
      return UseStripePricingTable('prctbl_1PGPi0BawIx9AGNegiUWKA6F', userId);
    } else if (userType === 3) {
      return UseStripePricingTable('prctbl_1PEmZeBawIx9AGNek3a2uLdl', userId);
    } else if (userType === 4) {
      return UseStripePricingTable('prctbl_1PEmhdBawIx9AGNe7RYRNBp3', userId);
    } else {
      return null;
    }
  };

  return (
    <div>
      <StripePricingTable userType={usersType} />
    </div>
  )
  
}

export default Subscriptions;

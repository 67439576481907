import React from 'react'
import { Avatar } from '@mui/material'
import moment from 'moment'

function PostCommentItem({ commentData }: any) {
  return (
    <div className="flex gap-2 text-sm">
      <Avatar sx={{ width: '24px', height: '24px', marginTop: '4px' }} />
      <div className="w-full bg-white rounded-xl p-2 px-4 text-mainText">
        <p className="text-normal font-semibold">Cyril Verdad</p>
        <p className="text-xs text-subText">{moment(commentData.created_at).format('lll')}</p>
        <p className="text-sm">{commentData.comment}</p>
      </div>
    </div>
  )
}

export default React.memo(PostCommentItem)

import React from 'react'
import {
  Card,
  CardContent,
  Typography,
  Button,
  CardActions,
  Theme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import UIConstants from '../../../../theme/constants/ui-constants'

const useStyle = makeStyles((theme: Theme) => ({
  content: {
    color: '#0c4b9c',
    textShadow: '-1px -1px 2px rgba(0, 0, 0, .15)',
  },
}))

function InfoCard(props: any) {
  const classes = useStyle()
  return (
    <Card
      sx={{
        minWidth: '100%',
        minHeight: '250px',
        maxHeight: '250px',
        borderRadius: 3,
        display: 'flex',
        color: UIConstants.mainText,
      }}>
      <CardContent sx={{ flex: '1' }}>
        <div className="w-full h-full flex flex-col">
          <p className="text-md font-bold">{props.title}</p>
          <div className="mt-12 flex flex-col gap-2 mx-auto justify-center items-center">
            <p className={`text-[3rem] md:text-[2.5rem] lg:text-[3rem] font-black ${classes.content}`}>
              {props.content}
            </p>
            <span>{props.info}</span>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

export default InfoCard

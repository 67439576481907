import React, { useState } from 'react'
import { InputText } from '../../../theme/components/input/TextField'
import { CustomButton } from '../../../theme/components/buttons/Button'

function Verification() {
  const [verificationCode, setVerificationCode] = useState('')
  const handleSubmitVerification = (e: any) => {
    if (verificationCode === '93210') {
        localStorage.setItem('verified', 'true')
        window.location.reload()
    } else {
        alert('Invalid verification code')
    }
  }
  return (
    <form className="flex flex-col gap-4" onSubmit={handleSubmitVerification}>
      <h1>Enter the verification code</h1>
      <InputText
        placeholder="Verification code"
        size="small"
        onChange={(e) => setVerificationCode(e.target.value)}
      />
      <CustomButton type="submit" onClick={handleSubmitVerification}>
        Submit
      </CustomButton>
    </form>
  )
}

export default Verification
